import 'normalize.css'
import './index.css';
import '@/common/field/field';



global.__DEV__ = process.env.REACT_APP_ENV === "development"
global.__TEST__ = process.env.REACT_APP_ENV === "test"
global.__PRO__ = process.env.REACT_APP_ENV === "production"





