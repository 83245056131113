import withComponent from '@/hoc/withComponent';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import useForceUpdate from '@/hooks/useForceUpdate';
import moment from 'moment';
import store from 'store';
import {expiresTime} from "@/components/PayModal/index";

const TimeStyled = styled.div`
  padding: 2px 3px;
  background: #FFF3D3;
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #E00006;
`;
const TimeBoxStyled = styled.div`
  position: absolute;
  top: 207px;
  left: 335px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: SimHei;
  color: #FFE18E;
`;

export default withComponent(() => {
  const is_half_price_date = useMemo(() => store.get('is_half_price_date'), []);

  const time = new Date().getTime() - new Date(is_half_price_date).getTime();
  const time1 = (time > expiresTime) ? 1 : (expiresTime - time);
  const that = useRef({ duration: moment.duration(time1 / 1000, 'seconds') }).current;
  const [, update] = useForceUpdate();
  useEffect(() => {
    that.timer = setInterval(() => {
      if (that.duration.asSeconds() > 1) {
        that.duration = that.duration.subtract(1, 'seconds');
        update();
      } else {
        clearInterval(that.timer);
      }
    }, 1000);
    return () => {
      clearInterval(that.timer);
    };
  }, []);

  let minutes = that.duration?.get('minutes');
  let seconds = that.duration?.get('seconds');
  if (minutes?.toString().length === 1) {
    minutes = '0' + minutes;
  }
  if (seconds?.toString().length === 1) {
    seconds = '0' + seconds;
  }
  const i7 = "https://oss.jianzeppt.com/user/587697/d7ff051f-1249-48b0-8ac9-16a4b2a2ae88.png?x-oss-process=image/resize,h_280,w_923";
  const i3 = "https://oss.jianzeppt.com/user/587697/c878336e-a068-4b20-b975-6152c544fcdb.png?x-oss-process=image/resize,h_280,w_923";
  return <div style={{ position: 'relative' }}>
    <img src={i7} width={923} height={280} style={{ position: 'relative', left: -41 }} />
    <TimeBoxStyled>
      <div>优惠倒计时:</div>
      <TimeStyled style={{ marginRight: 4 }}>00</TimeStyled>
      <div style={{ marginRight: 4 }}>时</div>
      <TimeStyled style={{ marginRight: 4 }}>{minutes}</TimeStyled>
      <div style={{ marginRight: 4 }}>分</div>
      <TimeStyled style={{ marginRight: 4 }}>{seconds}</TimeStyled>
      <div>秒</div>
    </TimeBoxStyled>
  </div>;
});
