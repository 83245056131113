import styled from "styled-components"


export const ModuleManageStyled = styled.div`
    background-color: #F7F8F9;
    .manage-title {
        padding-bottom: 6px;
        border-bottom: 1px solid #F4F4F4;
    }
    .gray {
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #BEBEBE;
    }
    .bg-white {
        background-color: #FFFFFF;
    }
    .trigger {
        color: #11BBA6;
    }
    .manage-btn {
        width: 54px;
        height: 24px;
        background: #11BBA6;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
    .add-btn {
        background: rgba(17,187,166,0.06);
        border-radius: 4px;
        border: 1px dashed #00BCA6;
        height: 44px;
        width: 100%;

        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #11BBA6;
    }
`

export const CellStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  height: 50px;
  padding: 0 8px;


  &:hover {
    background: #F7F7F7;
  }

`
export const CellIconStyled = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`
export const CellTitleStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323232;
  line-height: 20px;
`
export const CellSwitchPositionStyled = styled.div`
  position: absolute;
  top: 17px;
  right: 8px;
`
export const CellRequirePositionStyled = styled.div`
  position: absolute;
  top: 14px;
  right: 8px;
`

export const TitleStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  padding-bottom: 5px;
  padding-left: 16px;
`
export const RequireStyled = styled.div`
  width: 34px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #11BBA6;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #11BBA6;
  line-height: 22px;
  text-align: center;
`

export const sortSvg = <div className="flex-center" dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.897142987">
        <g id="模块管理-点击排序可拖动" transform="translate(-325.000000, -195.000000)" fill="#868686" fill-rule="nonzero">
            <g id="编辑备份-18" transform="translate(325.000000, 195.000000)">
                <g id="编组" transform="translate(6.000000, 6.800000)">
                    <path d="M11.6842105,9.12631581 C11.7679631,9.12631581 11.8482854,9.15958639 11.9075074,9.21880841 C11.9667294,9.27803043 12,9.35835272 12,9.4421053 L12,10.0736842 C12,10.1574368 11.9667294,10.2377591 11.9075074,10.2969811 C11.8482854,10.3562031 11.7679631,10.3894737 11.6842105,10.3894737 L0.315789469,10.3894737 C0.232036892,10.3894737 0.151714603,10.3562031 0.0924925888,10.2969811 C0.033270575,10.2377591 0,10.1574368 0,10.0736842 L0,9.4421053 C0,9.35835272 0.033270575,9.27803043 0.0924925888,9.21880841 C0.151714603,9.15958639 0.232036892,9.12631581 0.315789469,9.12631581 L11.6842105,9.12631581 Z" id="路径"></path>
                    <path d="M11.9075074,4.6556505 C11.9667294,4.71487252 12,4.79519481 12,4.87894739 L12,5.51052633 C12,5.5942789 11.9667294,5.6746012 11.9075074,5.73382321 C11.8482854,5.79304523 11.7679631,5.82631581 11.6842105,5.82631581 L0.315789469,5.82631581 C0.232036892,5.82631581 0.151714603,5.79304523 0.0924925888,5.73382321 C0.033270575,5.6746012 0,5.5942789 0,5.51052633 L0,4.87894739 C0,4.79519481 0.033270575,4.71487252 0.0924925888,4.6556505 C0.151714603,4.59642849 0.232036892,4.56315791 0.315789469,4.56315791 L11.6842105,4.56315791 C11.7679631,4.56315791 11.8482854,4.59642849 11.9075074,4.6556505 Z" id="路径"></path>
                    <path d="M11.6842105,0 C11.7679631,0 11.8482854,0.0332705827 11.9075074,0.0924925993 C11.9667294,0.151714616 12,0.232036907 12,0.315789484 L12,0.947368422 C12,1.031121 11.9667294,1.11144329 11.9075074,1.17066531 C11.8482854,1.22988732 11.7679631,1.26315791 11.6842105,1.26315791 L0.315789469,1.26315791 C0.232036892,1.26315791 0.151714603,1.22988732 0.0924925888,1.17066531 C0.033270575,1.11144329 0,1.031121 0,0.947368422 L0,0.315789484 C0,0.232036907 0.033270575,0.151714616 0.0924925888,0.0924925993 C0.151714603,0.0332705827 0.232036892,0 0.315789469,0 L11.6842105,0 L11.6842105,0 Z" id="路径"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

export const triggerSvg = <div className="flex-center" dangerouslySetInnerHTML={{
    __html: `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>切片</title>
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.897142987">
            <g id="模块管理" transform="translate(-309.000000, -103.000000)" fill="#11BBA6" fill-rule="nonzero">
                <g id="编辑备份-18" transform="translate(309.000000, 103.000000)">
                    <path d="M7.14034117,3.17266021 C7.14034117,2.80567082 6.84343015,2.5087598 6.47644076,2.5087598 C6.10945136,2.5087598 5.81254034,2.80567082 5.81254034,3.17266021 L5.81254034,11.2150761 L4.73370217,10.1362379 C4.4736745,9.87621024 4.05320424,9.87621024 3.79502075,10.1362379 C3.53499308,10.3962656 3.53499308,10.8167358 3.79502075,11.0749193 L5.92503458,13.2049331 C6.04490549,13.3819733 6.24591978,13.5 6.47644076,13.5 C6.66270171,13.5 6.83052098,13.4243891 6.95039189,13.3008299 L6.95408022,13.2971415 C7.11083449,13.1403873 7.17353619,12.9246196 7.14034117,12.719917 L7.14034117,3.17266021 Z M12.1915168,4.9153988 L9.97113877,2.69502075 C9.71111111,2.43499308 9.29064085,2.43499308 9.03245735,2.69502075 C8.90889811,2.81857999 8.84435224,2.97717842 8.83881973,3.13762102 C8.83881973,3.14868603 8.83697556,3.16159521 8.83697556,3.17266021 L8.83697556,12.8360996 C8.83697556,13.203089 9.13388658,13.5 9.50087598,13.5 C9.86786538,13.5 10.1647764,13.203089 10.1647764,12.8360996 L10.1647764,4.76602121 L11.2528354,5.85408022 C11.3819272,5.98317197 11.5515906,6.04771784 11.7230982,6.04771784 C11.8946058,6.04771784 12.0624251,5.98317197 12.193361,5.85408022 C12.4515445,5.59589673 12.4515445,5.17542646 12.1915168,4.9153988 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </svg>
`}} />
export const rightSvg = <div className="flex-center" dangerouslySetInnerHTML={{
    __html: `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="模块管理-点击排序可拖动" transform="translate(-309.000000, -101.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <g id="编辑备份-18" transform="translate(309.000000, 101.000000)">
                    <path d="M12.8174106,4.68374451 C12.5958275,4.46101323 12.2500111,4.44076493 12.0062617,4.62305248 L11.9364252,4.68381487 L6.80651249,9.83437271 L4.06351264,7.08040419 C3.82052489,6.83615417 3.42621792,6.83615417 3.18259883,7.08040419 C2.96349062,7.30065063 2.94157979,7.64471304 3.11681592,7.889672 L3.18252963,7.96734869 L6.36573859,11.1634554 C6.48488231,11.2825992 6.64232875,11.3467379 6.80651093,11.3467379 C6.93747108,11.3467379 7.06487619,11.3055502 7.17135973,11.2283155 L7.24702824,11.163711 L12.8174098,5.56995158 C13.036518,5.34970514 13.0584289,5.0062957 12.8831423,4.76141666 L12.8174106,4.68374451 Z" id="路径"></path>
                </g>
            </g>
        </g>
    </svg>
`}} />
export const addSvg = <div className="flex-center" dangerouslySetInnerHTML={{
    __html: `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="模块管理-点击排序可拖动" transform="translate(-123.000000, -966.000000)" fill="#11BBA6">
                <g id="搜索模板备份" transform="translate(123.000000, 966.000000)">
                    <g id="tianjia-8" transform="translate(4.000000, 4.000000)">
                        <path d="M8,0 C12.4182812,0 16,3.58171875 16,8 C16,12.4182813 12.4182813,16 8,16 C3.58171873,16 0,12.4182813 0,8 C0,3.58171876 3.58171875,0 8,0 Z M7.99999999,3.42859375 C7.684375,3.42859375 7.42859375,3.68453124 7.42859375,4 L7.42859375,4 L7.42859375,7.42859375 L4,7.42859375 C3.684375,7.42859375 3.42859375,7.68453126 3.42859375,8.00000001 C3.4346875,8.31312501 3.68703124,8.56531251 4,8.57140625 L4,8.57140625 L7.42859375,8.57140625 L7.42859375,12 C7.42859375,12.315625 7.68453124,12.5714062 7.99999999,12.5714062 C8.315625,12.5714062 8.57140625,12.3154688 8.57140625,12 L8.57140625,12 L8.57140625,8.57140625 L12,8.57140625 C12.315625,8.57140625 12.5714062,8.31562502 12.5714062,8.00000001 C12.5714062,7.684375 12.3154687,7.42859375 12,7.42859375 L12,7.42859375 L8.57140625,7.42859375 L8.57140625,4 C8.57140624,3.684375 8.31562499,3.42859375 7.99999999,3.42859375 Z" id="形状结合"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
`}} />