import { useRef } from "react"
import styled from "styled-components"
import { ReactComponent as SearchIcon } from '@/pages/Mobile/svg/search.svg'
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const SearchInputStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #59E0D5;
    border-radius: 20px;
    overflow: hidden;
    width: 146px;
    input {
        font-size: 14px;
        box-sizing: border-box;
        width: 100%;
        background: transparent;
        padding: 8px 16px;
        border: none;
        outline: none;
        color: #fff;
    }
    input::placeholder {
        color: #fff;
    }
    .svg {
        /* position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%); */
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
`

const SearchInput = ({ onChange }) => {
    const inputRef = useRef(null)
    const navigate = useNavigate()
    const filter = useSelector(state => state.mobileIndex.filter)

    const search = () => {
        onChange(inputRef.current.value)
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            search()
        }
    }

    // const handleClick = () => {
    //     search()
    // }
    const handleClick = () => {
        navigate('/mobile/search_word')
    }



    return <SearchInputStyled onClick={handleClick}>
        <input value={filter.searchWord} readOnly ref={inputRef} placeholder="搜索简历…" />
        <SearchIcon className="svg" />
    </SearchInputStyled>
}

export default SearchInput