// class Recorder {
//     constructor() {
//         this.recorder = null; // MediaRecorder实例
//         this.chunks = []; // 存储音频数据
//         this.stream = null; // 用户的音频流
//     }

// import Recorder from "js-audio-recorder"

//     // 开始录音
//     async startRecording() {
//         try {
//             // 获取音频流
//             this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//             this.recorder = new MediaRecorder(this.stream);

//             // 收集音频数据
//             this.recorder.ondataavailable = (e) => {
//                 this.chunks.push(e.data);
//             };

//             // 开始录音
//             this.recorder.start();
//             console.log('录音开始...');
//         } catch (error) {
//             console.error('无法开始录音: ', error);
//             throw error; // 抛出错误，允许调用者处理错误
//         }
//     }

//     // 停止录音，并返回录音数据
//     async stopRecording() {
//         if (!this.recorder) {
//             throw new Error('Recorder not started');
//         }

//         return new Promise((resolve) => {
//             this.recorder.onstop = () => {
//                 const blob = new Blob(this.chunks, { type: 'audio/wav' });
//                 this.chunks = []; // 重置chunks

//                 // 关闭音频流
//                 this.stream.getTracks().forEach(track => track.stop());

//                 // 返回录音数据
//                 resolve(blob);
//             };

//             // 停止录音
//             this.recorder.stop();
//             console.log('录音停止.');
//         });
//     }
// }
import Recorder from 'js-audio-recorder'
let recorder = new Recorder({
    sampleRate: 16000,
    compiling: true
});
// const recorder = new Recorder()

export default recorder