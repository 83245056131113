import React from 'react'
import { Button, Dialog, Space, Toast, Divider } from 'antd-mobile'
import { ExclamationCircleFill } from "antd-mobile-icons";
import './index.scss'
const SvipTipModal = ({ visible, onClickUpgrade, onCancel }) => {

    return (
        <Dialog
            visible={visible}
            header={(
                    <div className="mdl-title">
                        <ExclamationCircleFill
                        style={{
                        fontSize: 16,
                        color: 'var(--adm-color-warning)',
                        }}
                    />
                    <div className="title">提示</div>
                    </div>
                    )}
            content= {(<>
                <div className="mdl-text">练面试为SVIP会员专属功能</div>
                <div className="mdl-text">
                请先升级
                </div>
            </>)}
            closeOnAction
            maskStyle={{
                height: 'calc(100vh - 80px)'
            }}
            actions={
                [[
                    {
                    text: '取消',
                    key: 'cancel',
                    style: { color: '#000000' },
                    onClick: onCancel
                },
                {
                    text: '升级',
                    key: 'upgrade',
                    style: { color: '#11BBA6' },
                    onClick: onClickUpgrade
                }
                ]]
            }
        />
    )
}


export default SvipTipModal