import { ImageProps } from "antd"

const processImage = (imgSrc: string) => {
    if (!imgSrc) return ''
    // 判定为常规链接
    if (imgSrc.includes('http')) {
        return imgSrc
    }

    return `https://oss.jianzeppt.com/jz-ppt-img/${imgSrc}`
}

type JzImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    src?: string;
};

// 传入
const JzImage: React.FC<JzImageProps> =  ({ src = '', ...other}) => {

    const imgSrc = processImage(src)

    return <img src={imgSrc} {...other}  />
}

export default JzImage