import styled from 'styled-components';


export const ArticleContainerStyled = styled.div`
  width: 1200px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  background: white;
`;


export const ArticleTitleStyled = styled.div`
  text-align: center;
  font-size: 32px;
  color: #212121;
  font-weight: bolder;
  letter-spacing: 1px;
  margin-bottom: 20px;
  margin-top: 20px;
`;
export const ArticleTitle2Styled = styled.h2`
  margin: 15px 0;
  font-size: 21px;
  line-height: 21px;
  font-weight: 700;
  color: #212121;
  letter-spacing: 1px;
`;
export const ArticleParagraphStyled = styled.p`
  color: rgb(128, 128, 128);
  font-size: 14px;
  line-height: 30px;
  text-indent: ${props => props.textIndent ? props.textIndent + 'em' : '2em'};
  letter-spacing: 2px;
  margin: 0;
`;

export const ArticleBlodFont = styled.span`
  color: #212121;
  font-size: 14px;
  line-height: 30px;
  font-weight: bold;
  letter-spacing: 2px;
`
