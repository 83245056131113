import React, { useState } from 'react';
import FlexStyled from '@/styled/FlexStyled';



export default (props) => {

  return <FlexStyled $flexDirection='column' $alignItems='center' $justifyContent='center' style={{}}>
    <FlexStyled $justifyContent='center' style={{ marginTop: 20, fontSize: 16 }}>您的VIP已自动升级为SVIP</FlexStyled>

  </FlexStyled>;
};
