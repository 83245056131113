import { cityConfig, jobInterestRoleConfig } from "@/components/resume/const/getMenuConfigByField";
import { BASE_FIELD_ENUM, CAMPUS_ACTIVITIES_FIELD_ENUM, EDUCATIONAL_BACKGROUND_FIELD_ENUM, INTERNSHIP_EXPERIENCE_FIELD_ENUM, JOB_INTEREST_FIELD_ENUM, PROJECT_EXPERIENCE_FIELD_ENUM, WORK_EXPERIENCE_FIELD_ENUM } from "@/common/field/field";
import { BASE_Slot, EDUCATIONAL_BACKGROUND_SLOT_ENUM, JOB_INTEREST_SLOT_ENUM } from "@/common/slot/slot";
import { Module } from "@/common/module/module";
const marriageConfig = ['未婚', '已婚', '保密']
const yearsOfWorkingConfig = ['在校生', '应届生', '1年经验', '2年经验', '3年经验', '4年经验', '5年经验', '6年经验', '7年经验', '8年经验', '9年经验', '10年经验', '10年以上经验']
const politicsConfig = ['中共党员', '中共预备党员', '共青团员', '民主党派人士', '无党派民主人士', '普通公民'];
const educationConfig = ['初中及以下', '高中', '中技', '中专', '大专', '本科', '硕士', '博士'];
const jobInterestJobTypeConfig = ['全职', '兼职', '实习'];
const genderConfig = ["男","女"]



const configToPickerConfig = (arr) => {
    return arr.map(item => {
        return {
            label: item,
            value: item,
        }
    })
}

// name, label, value

const optionToPickerOption = (option) => {
    return option?.map(item => {
        if (item.children) {
            return {
                ...item,
                label: item.name,
                value: item.name,
                children: optionToPickerOption(item.children)
            }
        }
        return {
            ...item,
            label: item.name,
            value: item.name
        }
    })
}


const educationalBackgroundFieldList = [
    { label: "学校名称", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.SCHOOL, checked: true },
    // 这个特殊处理
    { label: "在校时间", value: EDUCATIONAL_BACKGROUND_FIELD_ENUM.DATE, checked: true, type: 'rangePicker' },
    { label: "学历", value: EDUCATIONAL_BACKGROUND_FIELD_ENUM.EDUCATION, checked: true, type: 'picker', config: [configToPickerConfig(educationConfig)] },
    { label: "专业名称", value: EDUCATIONAL_BACKGROUND_FIELD_ENUM.MAJOR, checked: true },
    { label: "在校经历", value: EDUCATIONAL_BACKGROUND_FIELD_ENUM.DETAILS, checked: true, type: 'longText' },
]
const workExperienceFieldList = [
    { label: "公司名称", value: WORK_EXPERIENCE_FIELD_ENUM.COMPANY,  checked: true },
    { label: "在职时间", value: WORK_EXPERIENCE_FIELD_ENUM.DATE,  checked: true, type: 'rangePicker'},
    { label: "岗位名称", value: WORK_EXPERIENCE_FIELD_ENUM.POSITION,  checked: true },
    { label: "工作内容", value: WORK_EXPERIENCE_FIELD_ENUM.DETAILS,  checked: true, type: 'longText' },
]
const projectExperienceFieldList = [
    { label: "项目名称", value: PROJECT_EXPERIENCE_FIELD_ENUM.NAME, checked: true },
    { label: "起始时间", value: PROJECT_EXPERIENCE_FIELD_ENUM.DATE, checked: true, type: 'rangePicker'},
    { label: "角色名称", value: PROJECT_EXPERIENCE_FIELD_ENUM.ROLE, checked: true },
    { label: "工作内容", value: PROJECT_EXPERIENCE_FIELD_ENUM.DETAILS,  checked: true, type: 'longText' },
]
const internshipExperienceFieldList = [
    { label: "公司名称", value: INTERNSHIP_EXPERIENCE_FIELD_ENUM.ACTIVITY_NAME,  checked: true },
    { label: "在职时间", value: INTERNSHIP_EXPERIENCE_FIELD_ENUM.DATE,  checked: true, type: 'rangePicker'},
    { label: "岗位名称", value: INTERNSHIP_EXPERIENCE_FIELD_ENUM.ROLE,  checked: true },
    { label: "工作内容", value: INTERNSHIP_EXPERIENCE_FIELD_ENUM.DETAILS,  checked: true, type: 'longText' },
]
const schoolExperienceFieldList = [
    { label: "活动名称", value: CAMPUS_ACTIVITIES_FIELD_ENUM.ACTIVITY_NAME, checked: true },
    { label: "起始时间", value: CAMPUS_ACTIVITIES_FIELD_ENUM.DATE, checked: true, type: 'rangePicker'},
    { label: "活动职责", value: CAMPUS_ACTIVITIES_FIELD_ENUM.ROLE, checked: true },
    { label: "工作内容", value: CAMPUS_ACTIVITIES_FIELD_ENUM.DETAILS, checked: true, type: 'longText' },
]

// details
// education
// end_date
// major
// school
// start_date

export const mapModuleNameToFieldList = {
    [Module.EDUCATIONAL_BACKGROUND]: educationalBackgroundFieldList,
    [Module.WORK_EXPERIENCE]: workExperienceFieldList,
    [Module.PROJECT_EXPERIENCE]: projectExperienceFieldList,
    [Module.INTERNSHIP_EXPERIENCE]: internshipExperienceFieldList,
    [Module.CAMPUS_ACTIVITIES]: schoolExperienceFieldList,
}


export const getFieldConfigByModuleName = (moduleName) => {
    return mapModuleNameToFieldList[moduleName]
}