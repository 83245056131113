import {BASE_FIELD_ENUM} from "@/common/field/field";
const marriageConfig = ['未婚', '已婚', '保密']
const yearsOfWorkingConfig = ['在校生', '应届生', '1年经验', '2年经验', '3年经验', '4年经验', '5年经验', '6年经验', '7年经验', '8年经验', '9年经验', '10年经验', '10年以上经验']
const politicsConfig = ['中共党员', '中共预备党员', '共青团员', '民主党派人士', '无党派民主人士', '普通公民'];
const educationConfig = ['初中及以下', '高中', '中技', '中专', '大专', '本科', '硕士', '博士'];
const jobInterestJobTypeConfig = ['全职', '兼职', '实习'];
const genderConfig = ["男","女"]

const configToPickerConfig = (arr) => {
    return arr.map(item => {
        return {
            label: item,
            value: item,
        }
    })
}

export const yearsOfWorkCol = [configToPickerConfig(yearsOfWorkingConfig)]

export const pickerModuleList = [
    {
        key: BASE_FIELD_ENUM.POLITICS_STATUS,
        columns: [configToPickerConfig(politicsConfig)],
    },
    {
        key: BASE_FIELD_ENUM.MARITAL_STATUS,
        columns: [configToPickerConfig(marriageConfig)],
    },
    {
        key: BASE_FIELD_ENUM.EDUCATION_BACKGROUND,
        columns: [configToPickerConfig(educationConfig)],
    },
    {
        key: BASE_FIELD_ENUM.YEARS_OF_WORKING,
        columns: yearsOfWorkCol,
    },
    {
        key: BASE_FIELD_ENUM.GENDER,
        columns: [configToPickerConfig(genderConfig)],
    },
]