import { Modal } from 'antd';
import React from 'react';
import FlexStyled from '@/styled/FlexStyled';
import Pointer from '@/styled/Pointer';

const closeIcon = <div dangerouslySetInnerHTML={{
  __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='输入主题，流程弹窗◉◉' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='弹窗' transform='translate(-1124.000000, -381.000000)' fill='#999999' fill-rule='nonzero'>
            <g id='搜索' transform='translate(1124.000000, 381.000000)'>
                <g id='编组-11' transform='translate(4.500000, 4.500000)'>
                    <polygon id='路径' points='0.0573613767 13.6704343 13.6387704 0 14.9426386 1.32956573 1.36012649 15'></polygon>
                    <polygon id='路径' points='13.709369 14.9408834 0 1.40095181 1.29063098 0.0591165973 15 13.5979328'></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;

const Content = (props) => {
  const { onCancel } = props;
  return <FlexStyled flexDirection='column' alignItems='center' justifyContent='center' style={{
    width: 400,
    height: 160,
    background: '#FFFFFF',
    borderRadius: 10,
    pointerEvents: 'auto',
  }}>
    <FlexStyled style={{ position: 'absolute', top: 12, right: 12 }} justifyContent='flex-end'>
      <Pointer onClick={onCancel}>{closeIcon}</Pointer>
    </FlexStyled>
    <FlexStyled justifyContent='center' style={{ marginTop: 20, marginLeft: 20, fontSize: 16 }}>
      <div>客服：微信 fantuuan</div>
    </FlexStyled>
  </FlexStyled>;
};


export default (props) => {


  return <Modal width={'auto'}
                open={props.visible}
                footer={false}
                trwansitionName=''
                mask={true}
                destroyOnClose
                getContainer={null}
                maskClosable
                centered
                zIndex={10000}
                onCancel={props.onCancel}
                maskTransitionName=''
                modalRender={() => <Content {...props} />}
  />;
}
