import { Tabs } from "antd-mobile"
import styled from "styled-components"
import ThemeTab from "../ThemeTab"
import SwitchTemplateTab from "../SwitchTemplateTab"
import { memo } from "react"

const TemplateConfigStyled = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 76px;
    background: #FFFFFF;
    box-shadow: 0px -4px 10px 0px rgba(0,0,0,0.13);
    border-radius: 8px 8px 0px 0px;
    z-index: 100;
    .adm-tabs-header {
        padding-top: 3px;
    }
`

const TemplateConfig = () => {
    
    return <TemplateConfigStyled>
        <div>
            <Tabs 
            activeLineMode="fixed"
            style={{
                '--title-font-size': '12px',
                '--content-padding': '20px 15px',
                '--fixed-active-line-width': '20px',
            }}>
                <Tabs.Tab title='切换模板' key='fruits'>
                    <SwitchTemplateTab />
                </Tabs.Tab>
                <Tabs.Tab title='主题设置' key='vegetables'>
                    <ThemeTab />
                </Tabs.Tab>
            </Tabs>
        </div>
    </TemplateConfigStyled>
}

export default TemplateConfig