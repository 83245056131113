import styled from "styled-components"
import {JOB_INTEREST_FIELD_ENUM} from "@/common/field/field";
import ModuleBox from "../ModuleBox"
import useQueryParam from "@/hooks/useQueryParam";
import { useNavigate } from "react-router-dom";
import { Module } from "@/common/module/module";
import useQuerySlot from "@/pages/Mobile/hooks/useQuerySlot";
import { JOB_INTEREST_SLOT_ENUM } from "@/common/slot/slot";

const TargetItem = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: #555555;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
`

const jobStatusList = [
    {
        value: "DIMISSION_SEARCH_JOB",
        label: "离职找工作",
    },
    {
        value: "ON_JOb_SEARCH_JOB",
        label: "在职找工作",
    },
    {
        value: "ON_JOB_LOOK_FOR_CHANCE",
        label: "在职看机会",
    },
    {
        value: "NO_SEARCH_JOB",
        label: "暂时不找工作",
    },
]

const jobArrivalTimeList = [
    {
        value: "ONE_WEEK_TO_WORK",
        label: "一周内到岗",
    },
    {
        value: "ONE_MONTH_TO_WORK",
        label: "一个月内到岗",
    },
    {
        value: "THREE_MONTH_TO_WORK",
        label: "三个月内到岗",
    },
    {
        value: "LATER",
        label: "到岗时间另议",
    },
]


const JobInterest = ({ data = {}, moduleName}) => {
    const title = useQuerySlot({
        moduleName,
        slotName: JOB_INTEREST_SLOT_ENUM.TITLE_TEXT
    })
    const infoList = [
        {
            key: JOB_INTEREST_FIELD_ENUM.JOB_TYPE,
            text: '求职类型'
        },
        {
            key: JOB_INTEREST_FIELD_ENUM.ROLE,
            text: '意向岗位'
        },
        {
            key: JOB_INTEREST_FIELD_ENUM.CITY,
            text: '意向城市'
        },
        {
            key: JOB_INTEREST_FIELD_ENUM.SALARY,
            text: '薪资要求'
        },
        {
            key: JOB_INTEREST_FIELD_ENUM.JOB_STATUS,
            text: '求职状态'
        },
    ].filter(item => data[item.key])
    

    return <ModuleBox name={moduleName} title={title || "求职意向"}>
        {
            infoList.map(info => {
                let value = data[info.key]
                // 特殊处理求职状态模块
                if (info.key === JOB_INTEREST_FIELD_ENUM.JOB_STATUS) {
                    value = jobStatusList.find(item => item.value === data[JOB_INTEREST_FIELD_ENUM.JOB_STATUS])?.label
                    if (data['jobArrivalTime']) {
                        value = value + ' - ' + jobArrivalTimeList.find(item => item.value === data['jobArrivalTime'])?.label
                    }
                }
                return <TargetItem key={info.key}>{info.text}：{value}</TargetItem>
            })
        }
    </ModuleBox>
}

export default JobInterest
