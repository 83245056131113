import withComponent from "@/hoc/withComponent";
import {useSelector} from "@/components/resume/redux/hooks";
import {createElement} from "react";
import getNestedProperty from "@/utils/getNestedProperty";
import getBaeInfoPathBySlot from "@/common/config/getBaeInfoPathBySlot";
import withField from "@/components/resume/hoc/withField";


export default withComponent(withField((props) => {
    const {tagName, style, className,fieldRef} = props;
    const birthdayYear = useSelector(state => getNestedProperty(state.resumeData, getBaeInfoPathBySlot("生日年份")));
    const year = new Date().getFullYear();
    const text = Number.isNaN(year - Number(birthdayYear)) ? 0 : year - Number(birthdayYear)
    return createElement(tagName, {style, className,ref:fieldRef}, text + '岁');
}))
