import { Popover } from "antd"
import { useState } from "react"
import Select from "@/components/resume/components/Select";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import getResumePath from "@/common/resume/getResumePath";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import getNestedProperty from "@/utils/getNestedProperty";
import EditableTable from "./components/EditableTable";
import getFieldVisible from "@/common/config/getFieldVisible";
import styled from "styled-components";

// 

const ApplicationInfoStyled = styled.div`
    .ap-input {
        border: none;
        outline: none;
        padding: 0;
        background-color: transparent;
    }
`

const ApplicationInfo = ({ slot_value }) => {
    const [visible, setVisible] = useState(false)
    const [visible2, setVisible2] = useState(false)
    const dispatch = useDispatch();
    const moduleInstance = useModuleInstance();
    const fieldInstance = useFieldInstance();
    const moduleContentInstance = useModuleContentInstance();

    const moduleManageVisible = useSelector((state) => state.hover?.moduleId === moduleInstance.data?.id || state.focus?.moduleId === moduleInstance.data?.id);
    const schoolPath = `['${moduleInstance.data.id}'].data['school']`
    const majorPath = `['${moduleInstance.data.id}'].data['major']`
    const tablePath = `['${moduleInstance.data.id}'].data['table']`


    const schoolValue = useSelector((state) => getNestedProperty(state.resumeData, schoolPath));
    const majorValue = useSelector((state) => getNestedProperty(state.resumeData, majorPath));
    const tableValue = useSelector((state) => getNestedProperty(state.resumeData, tablePath)) || [];
    const schoolVisible = useSelector((state) => getFieldVisible(state.resumeData, moduleInstance.data?.id, 'school'));
    const majorVisible = useSelector((state) => getFieldVisible(state.resumeData, moduleInstance.data?.id, 'major'));
    const tableVisible = useSelector((state) => getFieldVisible(state.resumeData, moduleInstance.data?.id, 'table'));

    const onClickMenuItem = (type, value) => {
        const path = `['${moduleInstance.data.id}'].data['${type}']`
        dispatch(resumeDataSlice.actions.changeValueByPath({path: path, value: value}));
        setVisible(false)
        setVisible2(false)
    }

    const onTableChange = (value) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({path: tablePath, value: value}));
    }

    return <ApplicationInfoStyled>
        
        <div className="flex mb-10">
            {
                schoolVisible && <div className="flex-1">报考院校：
                    <input className="ap-input" value={schoolValue} onChange={(e) => onClickMenuItem('school', e.target.value)} />
                </div>
            }

            {
                majorVisible && <div className="flex-1">报考专业：
                    <input className="ap-input" value={majorValue} onChange={(e) => onClickMenuItem('major', e.target.value)} />
                </div>
            }
        </div>
        <div>
            {
                tableVisible && <EditableTable headerVisible={moduleManageVisible} value={tableValue} onChange={onTableChange} />
            }
        </div>
    </ApplicationInfoStyled>
}

export default ApplicationInfo