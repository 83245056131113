import withComponent from '@/hoc/withComponent';
import styled from 'styled-components';
import FlexStyled from '@/styled/FlexStyled';
import QRCode from 'qrcode.react';
import { Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import useForceUpdate from '@/hooks/useForceUpdate';
import { getUserPayHistory } from '@/http/api';
import { payLogoImage, weChatLogoImage } from '@/const/pay';
import Pointer from '@/styled/Pointer';
import ServiceModal from '@/components/ServiceModal';
import { isB } from '@/common/AOrB';
import {  useSelector } from 'react-redux';

const SwiperContainerStyled = styled.div`
  position: relative;
  width: 263px;
  height: 28px;
  background: #EFEFEF;
  border-radius: 14px;
  overflow: hidden;

`;
const SwiperItemStyled = styled.div`
  padding-left: 12px;
  white-space: nowrap;
  height: 28px;
  background: #F7F9FC;
  border-radius: 24px;
  transform: translateY(${(props) => props.index * 28 - props.base * 28}px);
  transition: transform 0.5s;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;
const SwiperItemTextStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 12px;
`;


const icon = <div style={{ width: 14, height: 12, position: 'relative' }} dangerouslySetInnerHTML={{
  __html: `<svg width='14px' height='12px' viewBox='0 0 14 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='position:absolute;top:0;left:0;'>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='轮播优化' transform='translate(-759.000000, -332.000000)' fill='#A6ABAF' fill-rule='nonzero'>
            <g id='喇叭' transform='translate(759.000000, 332.000000)'>
                <path d='M7.61250808,1.71093823 L4.37500392,3.33302377 L4.37500392,8.49419117 L7.61250808,10.1162582 C8.22499835,10.4111898 8.75000607,10.1899998 8.75000607,9.60013665 L8.75000607,2.22705982 C8.75000607,1.63721515 8.2250011,1.34229051 7.61250808,1.71093823 Z M0.875,4.43898772 L0.875,7.38822029 C0.875,7.97806958 1.4874985,8.49419117 2.18750146,8.49419117 L3.50000294,8.49419117 L3.50000294,3.33302376 L2.18750146,3.33302376 C1.48749849,3.33302376 0.875,3.84913843 0.875,4.43898772 Z M12.687505,5.54495166 L10.9375167,5.54495166 C10.6749923,5.54495166 10.500008,5.69241167 10.500008,5.9136063 C10.500008,6.1347894 10.6749923,6.28226095 10.9375167,6.28226095 L12.687505,6.28226095 C12.9500157,6.28226095 13.125,6.1347894 13.125,5.9136063 C13.125,5.69241167 12.9500157,5.54495166 12.687505,5.54495166 Z M12.4250107,9.30523047 L10.9375195,8.56790963 C10.7625078,8.49419117 10.5000108,8.49419117 10.3250128,8.71536964 C10.2375001,8.86284119 10.2375001,9.08401967 10.5000108,9.23149122 L11.9875184,9.96882361 C12.1625164,10.0425421 12.4250107,10.0425421 12.6000087,9.82135206 C12.687505,9.6738805 12.6000087,9.37896048 12.4250107,9.30523047 Z M10.850004,3.25928683 L12.3375117,2.52198215 C12.512496,2.44825214 12.6000087,2.22705751 12.512496,2.00586749 C12.4250107,1.85840747 12.1625137,1.78467285 11.9875184,1.85840747 L10.5000108,2.59571677 C10.3250128,2.66943985 10.2375001,2.8906368 10.3250128,3.11183144 C10.4124981,3.25928683 10.6749923,3.33302376 10.850004,3.25928683 Z' id='形状'></path>
            </g>
        </g>
    </g>
</svg>`,
}} />;


let list = [];
export const Swiper = (props) => {
  const that = useRef({ base: 0, list: [], key: 0 }).current;
  const [, update] = useForceUpdate();
  const init = async () => {
    list = await getUserPayHistory({}, { abType: isB() ? 1 : 0 });
    update();
  };


  useEffect(() => {
    init();
    that.timer = setInterval(() => {
      if (that.base + 1 >= list.length) {
        that.base = 0;
        that.key += 1;
      } else {
        that.base = that.base + 1;
      }
      update();
    }, 3000);
    return () => {
      clearInterval(that.timer);
    };
  }, []);
  return (
    <SwiperContainerStyled>
      {list.map((item, index) => {
        return (
          <SwiperItemStyled index={index} base={that.base} key={index + that.key} $alignItems='center'>
            <div style={{ marginRight: 4 }}>{icon}</div>
            <SwiperItemTextStyled>
              {item.time}分钟前 {item.name} 购买了{item.money}元-{item.vipType}
            </SwiperItemTextStyled>
          </SwiperItemStyled>
        );
      })}
    </SwiperContainerStyled>
  );
};

const FooterStyled = styled.div`
  flex-grow: 1;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: row-reverse;
`;
const FooterAgreementTextStyled = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #CBCBCB;
  line-height: 12px;
`;
const ServiceTextStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #B2B2B2;
  line-height: 12px;
  display: flex;
  align-items: center;
`;

export default withComponent((props) => {
  const { orderConfigData, tabIndex, orderDataMap, QRObj, style = {}, vipType } = props;
  const [visible, setVisible] = useState(false);
  const user = useSelector((state) => state.user);

  const onClick = () => {
    window.open('/agreement/member');
  };

  const item = orderConfigData?.[tabIndex];

  let sp;
  try {
    const num = Number(item?.originPrice - item?.price);
    if (!Number.isNaN(num) && num > 0) {
      sp = Number(num.toFixed(2));
    }
  } catch (e) {

  }

  return <>
    <FooterStyled style={{ marginTop: 14, columnGap: 69 }}>
      <FlexStyled $justifyContent='space-between' 
      $alignItems='flex-end' 
      style={{ flexDirection: 'column', marginRight: 16, padding: '20px 0' }}
      >
        <div>
          <span style={{ fontSize: 16, lineHeight: '16px', color: '#353A40', fontWeight: 500 }}>支付金额：</span>
          <span style={{ fontSize: 22, color: 'rgba(239, 51, 63, 1)', lineHeight: '22px', fontWeight: 'bold' }}>
                {orderConfigData[tabIndex]?.price}
              </span>
          <span style={{ fontSize: 16, lineHeight: '16px', color: '#353A40', fontWeight: 500 }}> 元</span>
          {
            sp && <span style={{ color: '#FF525A', fontSize: 14 }}> (已优惠{sp}元)</span>
          }
        </div>
        <Swiper orderDataMap={orderDataMap} vipType={vipType} />
      </FlexStyled>
      <FlexStyled flexGrow={1} $alignItems='center' $justifyContent='center' style={{paddingTop:20, marginLeft: 32}}>
        <FlexStyled>
          <div style={{ marginRight: 24 }}>
            <FlexStyled
              $alignItems='center'
              $justifyContent='center'
              style={{
                width: 110,
                height: 110,
                outline: '1px solid #2BA144',
                borderRadius: 4,
              }}
            >
              <div style={{ width: 100, height: 100 }}>
                {QRObj.wechatUrl ? (
                  <QRCode
                    value={QRObj.wechatUrl}
                    fgColor='#000000'
                    style={{
                      width: '100%',
                      height: '100%',
                      background: '#ffffff',
                    }}
                  />
                ) : (
                  <FlexStyled
                    $alignItems='center'
                    $justifyContent='center'
                    style={{ height: '100%', width: '100%' }}
                  >
                    <Spin />
                  </FlexStyled>
                )}
              </div>
            </FlexStyled>
            <FlexStyled $justifyContent='center'>
              <img
                src={payLogoImage}
                width={82}
                height={20}
                style={{ marginTop: 10 }}
              />
            </FlexStyled>
          </div>
          <div>
            <FlexStyled
              $alignItems='center'
              $justifyContent='center'
              style={{
                width: 110,
                height: 110,
                outline: '1px solid #1577FF',
                borderRadius: 4,
              }}
            >
              <div style={{ width: 100, height: 100 }}>
                {QRObj.payUrl ? (
                  <QRCode
                    value={QRObj.payUrl}
                    fgColor='#000000'

                    style={{
                      width: '100%',
                      height: '100%',
                      background: '#ffffff',
                    }}
                  />
                ) : (
                  <FlexStyled
                    $alignItems='center'
                    $justifyContent='center'
                    style={{ height: '100%', width: '100%' }}
                  >
                    <Spin />
                  </FlexStyled>
                )}
              </div>
            </FlexStyled>
            <FlexStyled $alignItems='center' $justifyContent='center'>
              <img
                src={weChatLogoImage}
                width={96}
                height={20}
                style={{ marginTop: 10 }}
              />
            </FlexStyled>
          </div>
        </FlexStyled>
      </FlexStyled>
    </FooterStyled>
    <FlexStyled $alignItems='center' $justifyContent='space-between'>
      <FooterAgreementTextStyled>支付即视为你同意<Pointer onClick={onClick} style={{ color: '#A5A4A4' }}>《会员协议》</Pointer>条例</FooterAgreementTextStyled>
      {/* {
        !user?.isPhoneLogin && <FooterAgreementTextStyled>我已是移动端会员，<a target='_blank' href='/pptx/personalCenter?type=telephone' style={{ color: '#A5A4A4' }}>绑定手机号</a></FooterAgreementTextStyled>
      } */}
      <ServiceTextStyled>
        <div style={{ color: '#C9C9C9' }}>如有疑问</div>
        <FlexStyled style={{ color: '#B2B2B2' }}>，<Pointer onClick={() => setVisible(true)} style={{ textDecoration: 'underline', color: '#B2B2B2' }}>联系客服</Pointer></FlexStyled>
      </ServiceTextStyled>
      <ServiceModal visible={visible} onCancel={() => setVisible(false)} />
    </FlexStyled>
  </>;
});
