import withComponent from "@/hoc/withComponent"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { fetchResumeSearch, getDataFromTemplate, reachUserCrateResumeLimit } from "@/http/api"
import { Popup, Toast } from "antd-mobile"
import { Ellipsis } from "antd-mobile"
import RatioImg from "@/pages/Mobile/components/RatioImg"
import getQuery from "@/utils/getQuery"
import strictStringify from "@/common/strictStringify"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
// import { clearFilter } from "../../TemplateList/indexSlice"
import isLogin from "@/common/isLogin"
import errorSlice from "@/redux/services/errorSlice"
import store from "@/redux/store"
import { clearFilter } from "@/pages/Mobile/pages/TemplateList/indexSlice"
import { PopupContentStyled, ResumeButton } from "@/pages/Mobile/pages/Home/components/ResumeListStyled"

const CommandListStyled = styled.div`
    /* background: #F5F5F5; */
    font-family: PingFangSC, PingFang SC;
    padding-top: 95px;
    .command-h1 {
        font-size: 36px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 24px;
        text-align: center;
    }

    .command-p {
        font-size: 18px;
        font-weight: 400;
        color: #555555;
        margin-bottom: 60px;
        text-align: center;
    }

    .resume-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 26px;
        row-gap: 40px;
        .resume-item {
            position: relative;
            box-shadow: 0px 0px 8px 0px #757575;
            cursor: pointer;
            &-img {
                border: 1px solid #E2E2E2;
            }
            &-title {
                padding-left: 2px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #343542;
            }
            &-mask {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 159px;
                background: linear-gradient( 180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.11) 40%, rgba(0,0,0,0.69) 100%);
                align-items: end;
            }
            &-btn {
                width: 180px;
                height: 42px;
                background: linear-gradient( 144deg, #27DFD2 0%, #11BBA6 100%);
                border-radius: 21px;

                font-weight: 600;
                font-size: 16px;
                color: #FFFFFF;
                margin-bottom: 20px;
            }
        }
    }

    .more-btn {
        /* background: #FFFFFF; */
        border: 1px solid #67BBD0;
        width: 254px;
        height: 54px;
        cursor: pointer;
        color: #4195AA;
        font-size:16px;
        border-radius: 27px;
        &:hover{
            border-color:#5CA7BA;
            color:#3A8598;
        }
    }
`

const svg = <div className="flex-center pl-6" dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="移动端推广页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="推广页" transform="translate(-230.000000, -2104.000000)" fill="#11BBA6" fill-rule="nonzero">
            <g id="编组-11备份-2" transform="translate(119.000000, 2094.000000)">
                <g id="文本备份-15" transform="translate(111.000000, 10.000000)">
                    <path d="M7.7057857,1.39376887 C7.9173156,1.17934942 8.26037555,1.17010826 8.48314213,1.37282887 C8.70590872,1.57554948 8.72896206,1.91795869 8.53537983,2.14871077 L8.50282473,2.18463366 L4.68826333,6.02922433 L8.49945696,9.81010802 C8.70613357,10.0151516 8.72173196,10.3441804 8.53537983,10.5678564 L8.50282473,10.6040599 C8.29770637,10.8106526 7.96868119,10.8261289 7.74507636,10.6397021 L7.70887283,10.607147 L3.4991597,6.4311116 C3.29256705,6.22599325 3.27709067,5.89696807 3.46351745,5.67336324 L3.49607257,5.63715971 L7.7057857,1.39376887 L7.7057857,1.39376887 Z" id="路径" transform="translate(6.000000, 5.998276) scale(-1, 1) translate(-6.000000, -5.998276) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const CommandListItem = ({ item, onClick }) => {
    const [hover, setHover] = useState(false)

    const handleClickTemplate = () => {
        reachUserCrateResumeLimit().then((data) => {
            if (data === false) {
              // 先调一下接口，没问题才下一步
                const query = {
                    absolute_laout: getQuery().absolute_laout, id: item.id, create_flag: "1"
                }
                window.open("/resume" + strictStringify(query))
            }
        })
    }
    return <div 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}  
        className="resume-item" 
        onClick={() => handleClickTemplate(item)}
    >
        <RatioImg className="resume-item-img" src={item.cover} ratio={[272, 386]} style={{ border: 'none', borderRadius: 0 }} />
        {
            hover && <div className="flex justify-center resume-item-mask">
                <div className="flex-center resume-item-btn">在线制作</div>
            </div>
        }
    </div>
}

const CommandList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [dataList, setDataList] = useState([])

    useEffect(() => {
        fetchList()
    }, [])

    const fetchList = async () => {
        try {
            const res = await fetchResumeSearch({
                query: {
                    page: 1,
                    limit: 12,
                },
            })
            const { dataList } = res;
            setDataList(dataList)    
        } catch (error) {
            Toast.show("网络繁忙，请重试")
        }
    };

    const handleMore = () => {
        dispatch(clearFilter())
        window.open('/template')
    }

    return <CommandListStyled>
        <div className="command-h1">海量精美简历模板</div>
        <div className="command-p">各行各业精美模板，帮你提升求职成功率</div>

        <div className="resume-list" style={{ maxWidth: 1186, margin: '0 auto'}}>
            {
                dataList.map((item, index) => {
                    return <CommandListItem key={index} item={item} />
                })
            }
        </div>
        
        <div  className="flex-center" style={{ paddingTop: 60, paddingBottom: 50}}>
            <div className="more-btn flex-center" onClick={handleMore}>
                查看更多模板
            </div>
        </div>
    </CommandListStyled>
}

export default withComponent(CommandList)