export enum Module {
    BASE = '基本信息',  //基本信息
    AVATAR = 'avatar',//头像
    JOB_INTEREST = 'job_interest',//求职意向
    EDUCATIONAL_BACKGROUND = 'educational_background',////教育背景
    CAMPUS_ACTIVITIES = 'campus_activities',////校园活动
    INTERNSHIP_EXPERIENCE = "internship_experience",//实习经历
    WORK_EXPERIENCE = 'work_experience',//工作经验
    PROJECT_EXPERIENCE = 'project_experience',//项目经验
    PORTFOLIO = 'portfolio',//作品展示
    LANGUAGE_PROFICIENCY = 'language_proficiency',//语言能力
    INTEREST = 'interest',//兴趣爱好
    SKILLS = 'skills',//技能特长
    SELF_EVALUATION = 'self_evaluation',//  自我评价
    CUSTOM_MODULE = 'custom_module',//自定义
    HONORS_AND_AWARDS = 'honors_and_awards',//奖项荣誉
    CONTACT_INFORMATION = 'CONTACT_INFORMATION',//联系方式
    APPLICATION_INFO = 'APPLICATION_INFO', // 报考信息

    /*    MY_STRENGTHS = 'my_strengths',//我的优势
        SELF_INTRODUCTION = 'self_introduction',//自荐信
        SOCIAL_MEDIA = 'social_media',//社交主页
        QR_CODE = 'qr_code',//二维码
        COVER = 'cover',//封面*/
}

// @ts-ignore
export const getModuleList = () => Object.keys(Module).map((key) => Module[key])

