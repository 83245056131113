import {Modal} from 'antd';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import withComponent from '@/hoc/withComponent';
import E10101 from '@/components/ErrorModal/component/E10101';
import E10001 from '@/components/ErrorModal/component/E10001';
import E10003 from '@/components/ErrorModal/component/E10003';
import FlexStyled from '@/styled/FlexStyled';
import Pointer from '@/styled/Pointer';
import E10201 from '@/components/ErrorModal/component/E10201';
import E10202 from '@/components/ErrorModal/component/E10202';
import E10203 from '@/components/ErrorModal/component/E10203';
import E10301 from '@/components/ErrorModal/component/E10301';
import E10303 from '@/components/ErrorModal/component/E10303';
import E11111111111111 from '@/components/ErrorModal/component/E11111111111111';
import LoginModal from '@/components/user/LoginModal';
import PayModal from '@/components/PayModal';
import isVip2 from '@/common/isVip';
import E10103 from '@/components/ErrorModal/component/E10103';
import styled from "styled-components";
import store from "@/redux/store"
import errorSlice from "@/redux/services/errorSlice";
import E10102 from "@/components/ErrorModal/component/E10102";
import { useNavigate } from 'react-router-dom';

const closeIcon = <div dangerouslySetInnerHTML={{
    __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='输入主题，流程弹窗◉◉' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='弹窗' transform='translate(-1124.000000, -381.000000)' fill='#999999' fill-rule='nonzero'>
            <g id='搜索' transform='translate(1124.000000, 381.000000)'>
                <g id='编组-11' transform='translate(4.500000, 4.500000)'>
                    <polygon id='路径' points='0.0573613767 13.6704343 13.6387704 0 14.9426386 1.32956573 1.36012649 15'></polygon>
                    <polygon id='路径' points='13.709369 14.9408834 0 1.40095181 1.29063098 0.0591165973 15 13.5979328'></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;

export const BtnStyleEl = styled.div`
  background-color: #11BBA6;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const BtnStyleEl2 = styled.div`
  background-color: #ffffff;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #11BBA6;
  color: #11BBA6;
  font-size: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`


export const errorCallbackObj = {
    edit: undefined,
    upload: undefined,
};
export default withComponent((props) => {
    const user = useSelector((state) => state.user);
    const errorCode = useSelector((state) => state.error.code);
    const dispatch = useDispatch();
    const that = useRef({cancelErrorCode: undefined}).current;
    const [payModalVisible, setVisible] = useState(false);
    const navigate = useNavigate()
    const showLogin = () => {
        setVisible(true);
    };
    let Component;
    const visible = useMemo(() => {
        switch (errorCode) {
            //生成页面
            case 10001: {
                Component = E10001;
                return true;
            }
            case 10002  : {
                Component = E10003;
                return true;
            }
            case 10003  : {
                Component = E10003;
                return true;
            }
            //导出PPT数量限制
            case 10101: {
                Component = E10101;
                return true;
            }
            case 10102: {
                Component = E10102;
                return true;
            }
            case 10103: {
                Component = E10103;
                return true;
            }
            case 10201: {
                Component = E10201;
                return true;
            }
            case 10202: {
                Component = E10202;
                return true;
            }
            case 10203: {
                Component = E10203;
                return true;
            }
            case 10301: {
                Component = E10301;
                return true;
            }
            case 10302: {
                Component = E10301;
                return true;
            }
            case 10303: {
                Component = E10303;
                return true;
            }
            //临时
            case 11111111111111: {
                Component = E11111111111111;
                return true;
            }
        }
    }, [errorCode]);
    const isVip = isVip2();
    const onCancel = (callback) => {

        that.cancelErrorCode = errorCode;
        switch (errorCode) {
            //生成页面
            case 10001:
            case 10201:
            case 10202:
            case 10004: {
                setVisible(true)
                break;
            }
        }
        dispatch(errorSlice.actions.setCode(undefined))
    };
    const onSucessd = () => {
        dispatch(errorSlice.actions.setCode(undefined));
        errorCallbackObj.chatcallback?.()
    };
    const onLoginCancel = () => {
        dispatch(errorSlice.actions.setCode(undefined));
        errorCallbackObj.chatLoginCancelcallback?.()
    };
    const onSuccessPay = () => {
        setVisible(false);
        dispatch(errorSlice.actions.setCode(undefined));
        errorCallbackObj.chatcallback?.()
    }
    const onNavigateWorkspace = () => {
        dispatch(errorSlice.actions.setCode(undefined))
        navigate("/workspace")
    }
    if (errorCode === 10004) {
        return <LoginModal zIndex={999999999} onSuccess={onSucessd} visible onCancel={onLoginCancel} getContainer={null}/>;
    }
    return <>
        <Modal width={'auto'}
               visible={visible}
               footer={false}
               trwansitionName=''
               mask={true}
               destroyOnClose
               zIndex={999999}
               getContainer={null}
               maskClosable
               centered
               onCancel={onCancel}
               maskTransitionName=''
               modalRender={() => <FlexStyled flexDirection='column' alignItems='flex-start' justifyContent='flex-start'
                                              style={{
                                                  width: 400,
                                                  minHeight: 160,
                                                  background: '#FFFFFF',
                                                  borderRadius: 10,
                                                  pointerEvents: 'auto',
                                              }}>
                   <FlexStyled style={{position: 'absolute', top: 12, right: 12}} justifyContent='flex-end'>
                       <Pointer onClick={onCancel}>{closeIcon}</Pointer>
                   </FlexStyled>
                   <Component {...props} onCancel={onCancel} showLogin={showLogin} onNavigate={onNavigateWorkspace} />
               </FlexStyled>}
        />
        <PayModal zIndex={999}
                  errorCode={that.cancelErrorCode}
                  defaultVipType={isVip ? 'upgradeSVip' : undefined} 
                  id={-1} 
                  getContainer={null}
                  visible={payModalVisible}
                  onSuccess={onSuccessPay}
                  onCancel={() => setVisible(false)}
                  onClose={() => setVisible(false)}/>
    </>;
});


window.throwErrorCustomWithErrorCodeModal = async (errorCode) => {
    store.dispatch(errorSlice.actions.setCode(errorCode))
};
