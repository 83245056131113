import styled from "styled-components"

export const ContainerStyled = styled.div`
  /* flex-shrink: 0; */
  flex: 1;
  width: 360px;
  position: relative;
  margin-top: 16px;
  background: white;
  border-left: 1px solid #ECECEC;
`
export const MessageContainerStyled = styled.div`
  height:100%;
`
export const MessageScrollContainerStyled = styled.div`
  padding-left: 16px;
  padding-right: 20px;
  min-height: 100%;
  padding-top: 24px;
`
export const LogoImageStyled = styled.img`
  position: absolute;
  top: 19px;
  right: 30px;
  z-index: 1;
`
export const SystemChatBoxStyled = styled.div`
  position: relative;
  width: fit-content;
  max-width: 282px;
  min-height: 18px;
  background: #F7F7F8;
  border-radius: 12px 12px 12px 0px;
  border: 1px solid #F7F7F8;
  padding: 10px 16px;
  box-sizing: border-box;
  padding-right: 6px;
`
export const SystemChatTextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #343542;
  line-height: 20px;
`
export const UserChatBoxPositionStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const UserChatBoxStyled = styled.div`
  width: fit-content;
  max-width: 282px;
  min-height: 18px;
  background: #DBF4F1;
  border-radius: 12px 12px 0px 12px;
  padding: 10px 16px;
  box-sizing: border-box;
`
export const UserChatTextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #343542;
  line-height: 20px;
`
export const ChatItemLayoutStyled = styled.div`
  position: relative;
  //margin-top: 20px;
  margin-bottom: 20px;
`;
export const InputPositionStyled = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  left: 16px;
  display: flex;
  justify-content: center;
`
export const InputContainerStyled = styled.div`
  display: flex;
  align-items: center;
  width: 394px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 2px solid #66D3C5;

  ::placeholder {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #C4C4C4;
  }
`
export const InputStyled = styled.input`
  outline: none;
  border: none;
  flex-grow: 1;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
`
export const InputIconStyled = styled.div`
  margin-left: 12px;
  margin-right: 8px;
`
export const InputEnterIconStyled = styled.div`
  margin: 0 12px;

`
export const IconStyled = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;

  &:hover {
    opacity: 0.9;
  }
;
`
export const DivStyled1 = styled.div`
  /* width: 282px;
  background: #F7F7F8; */
  border-radius: 12px 12px 12px 0px;
  /* border: 1px solid #F7F7F8; */
  box-sizing: border-box;
`
export const DivStyled2 = styled.div`
  position: relative;
  width: 250px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`
export const DivStyled3 = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323232;
  line-height: 20px;
`
export const DivStyled4 = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-bottom: 13px;
`
export const DivStyled5 = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #343542;
`
export const DivStyled6 = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
`
export const DivStyled7 = styled.div`
  position: absolute;
  top: 13px;
  right: 12px;
  display: flex;
`
export const DivStyled8 = styled.div`
  width: 328px;
  height: 44px;
  background: #F7F7F8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 18px;
`
export const DivStyled9 = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
`
export const DivStyled10 = styled.div`
  width: 54px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #E1E1E6;

  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9E9EA6;
  line-height: 28px;
  margin-left: 3px;
  text-align: center;
  cursor: pointer;

  :active {
    opacity: 0.8;
  }
`
export const DivStyled11 = styled.div`
  position: relative;
  width: 312px;
  /* min-height: 264px; */
  padding: 10px 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  background: #F7F7F8;
  border-radius: 12px 12px 12px 0px;
  border: 1px solid #F7F7F8;
`
export const DivStyled12 = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #343542;
  line-height: 20px;
  /* white-space: nowrap; */
`
export const DivStyled13 = styled.textarea`
  padding: 12px 14px;
  box-sizing: border-box;
  width: 100%;
  height: 190px;
  resize: none;
  background: #FFFFFF;
  border: none;
  outline: none;

  &::placeholder {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #D3D3D3;
    line-height: 17px;
  }
`
export const DivStyled14 = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  cursor: pointer;
  width: 72px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid #F7F7F7;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
  text-align: center;
`
export const DivStyled15 = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background-image: url("https://oss.jianzeppt.com/user/3394743/2f327e3c-5f5e-4215-8fc8-fcb54d1e54f8.png");
  background-size: 100% 100%;
  cursor: pointer;
`
export const DivStyled16 = styled.div`
  margin-top: 8px;
  position: relative;
  width: 280px;
  /* height: 350px; */
  background: #FFFFFF;
  border-radius: 6px;
`
export const DivStyled17 = styled.div`
  padding: 8px;
  box-sizing: border-box;
  width: 306px;
  min-height: 186px;
  background: rgba(44,44,44,0.8);
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 17px;
`
export const DivStyled18= styled.div`
  position: absolute;
  background-image: url("https://oss.jianzeppt.com/user/3394743/eddccb3e-c91e-439f-a32b-9d2db044436f.png");
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  top: 6px;
  right: 6px;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
  :active {
    opacity: 0.8;
  }
`
export const DivStyled20= styled.div`
  position: absolute;
  right: -58px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 54px;
  height: 25px;
  border-radius: 2px;
  border: 1px solid rgb(211, 212, 214);
  color: rgb(153, 153, 153);
  margin-left: 10px;
  font-size: 12px;
`

export const JzButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px 12px;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;

  color: #888894;
  background: #EAEAEF;
  ${props => props.$type === 'primary' && `color: #FFFFFF;background: #11BBA6;`}
  border-radius: 6px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`

export const JzButtonIcon = styled.div`
  margin-right: 6px;

  svg g{
    ${props => props.$fill && `fill: ${props.$fill}`};
  }
`

