enum Base {
    NAME = "姓名",
    DES = "BASE_DES",
    PHONE_NUMBER = "手机号",
    MAIL = "邮箱",
    EDUCATION_BACKGROUND = "学历",
    MARITAL_STATUS = "婚姻状况",
    YEARS_OF_WORKING = "工作年限",
    HEIGHT_AND_WEIGHT = "身高体重",
    PRESENT_ADDRESS = "现居地",
    GENDER = "性别",
    AVATAR = "头像",
    WECHAT_ID = "微信号",
    NATION = "民族",
    ADDRESS_AND_ORIGIN = "籍贯",
    POLITICS_STATUS = "政治面貌",
    JOB_INTENTION = "求职岗位",
    BIRTHDAY_YEAR = "生日年份",
    BIRTHDAY_MONTH = "生日月份",
    BIRTHDAY_DAY = "生日日期",
    AGE = "年龄",
    HEIGHT = "身高",
    WEIGHT = "体重",
}
enum CONTACT_INFORMATION {
    TITLE = 'CONTACT_INFORMATION_TITLE',
    PHONE_NUMBER = "CONTACT_INFORMATION_PHONE_NUMBER",
    MAIL = "CONTACT_INFORMATION_MAIL",
}
enum WORK_EXPERIENCE {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    TIME = 'time', // 申请时间
    START_DATE = "start_date",  //开始日期
    END_DATE = "end_date",  //结束日期
    POSITION = 'position', // 岗位名称
    COMPANY = 'company', // 公司名称
    DETAILS = 'details', // 申请详情
    DATE = "time"
}

enum JOB_INTEREST {
    TITLE_TEXT = 'JOB_INTEREST_TITLE_TEXT',
    TITLE_ICON = 'JOB_INTEREST_TITLE_ICON',
    JOB_TYPE = "JOB_INTEREST_JOB_TYPE",//求职类型
    ROLE = 'JOB_INTEREST_ROLE', // 岗位
    CITY = 'JOB_INTEREST_CITY', // 城市
    SALARY = 'JOB_INTEREST_SALARY', // 薪资
    Max_SALARY = 'JOB_INTEREST_MAX_SALARY', // 薪资
    JOB_STATUS = 'JOB_INTEREST_JOB_STATUS', // 求职状态
    JOB_TYPE_ICON = "JOB_INTEREST_JOB_TYPE_ICON",
    ROLE_ICON = 'JOB_INTEREST_ROLE_ICON',
    CITY_ICON = 'JOB_INTEREST_CITY_ICON',
    SALARY_ICON = 'JOB_INTEREST_SALARY_ICON',
    JOB_STATUS_ICON = 'JOB_INTEREST_JOB_STATUS_ICON',
}

enum PROJECT_EXPERIENCE {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    START_DATE = "start_date",  //开始日期
    END_DATE = "end_date",  //结束日期
    ROLE = 'role', // 角色名称
    NAME = 'name', // 项目名称
    DETAILS = 'details', // 项目详情
    DATE = "time"
}

enum INTERNSHIP_EXPERIENCE {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    START_DATE = "start_date",  //开始日期
    END_DATE = "end_date",  //结束日期
    ACTIVITY_NAME = 'activity_name', // 活动名称
    ROLE = 'role', // 角色名称
    DETAILS = 'details', // 项目详情
    DATE = "time"
}

enum CAMPUS_ACTIVITIES {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    START_DATE = "start_date",  //开始日期
    END_DATE = "end_date",  //结束日期
    ACTIVITY_NAME = 'activity_name', // 活动名称
    ROLE = 'role', // 角色名称
    DETAILS = 'details', // 项目详情
    DATE = "time"
}

enum HONORS_AND_AWARDS {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    DETAILS = 'details',
}

enum SELF_EVALUATION {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    DETAILS = 'details',
}

enum EDUCATIONAL_BACKGROUND {
    TITLE_ICON = "title_icon",  //标题ICON
    TITLE_TEXT = "title_text",  //标题文字
    SCHOOL = 'school', // 学校名称
    MAJOR = 'major', // 专业
    EDUCATION = "education",//学历
    LOGO = "logo",//logo
    DETAILS = "details",//logo
    START_DATE = "start_date",  //开始日期
    END_DATE = "end_date",  //结束日期
    DATE = "time",
}

enum SKILLS {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    DETAILS = "details",
}

enum INTEREST {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    DETAILS = "details",
}

enum LANGUAGE_PROFICIENCY {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    DETAILS = "details",
}

enum CUSTOM_MODULE {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
    DETAILS = "details",
}

enum PORTFOLIO {
    TITLE_ICON = 'title_icon',
    TITLE_TEXT = 'title_text',
}


export {
    Base as BASE_Slot,
    EDUCATIONAL_BACKGROUND as EDUCATIONAL_BACKGROUND_SLOT_ENUM,
    WORK_EXPERIENCE as WORK_EXPERIENCE_SLOT_ENUM,
    HONORS_AND_AWARDS as HONORS_AND_AWARDS_SLOT_ENUM,
    SELF_EVALUATION as SELF_EVALUATION_SLOT_ENUM,
    PROJECT_EXPERIENCE as PROJECT_EXPERIENCE_SLOT_ENUM,
    INTERNSHIP_EXPERIENCE as INTERNSHIP_EXPERIENCE_SLOT_ENUM,
    CAMPUS_ACTIVITIES as CAMPUS_ACTIVITIES_SLOT_ENUM,
    SKILLS as SKILLS_SLOT_ENUM,
    JOB_INTEREST as JOB_INTEREST_SLOT_ENUM,
    INTEREST as INTEREST_SLOT_ENUM,
    LANGUAGE_PROFICIENCY as LANGUAGE_PROFICIENCY_SLOT_ENUM,
    CUSTOM_MODULE as CUSTOM_MODULE_SLOT_ENUM,
    PORTFOLIO as PORTFOLIO_SLOT_ENUM,
    CONTACT_INFORMATION as CONTACT_INFORMATION_SLOT_ENUM,
};
