export function validatePhoneNumber(phoneNumber) {
    // 使用正则表达式匹配手机号的常见格式
    const phoneNumberPattern = /^1\d{10}$/;
    return phoneNumberPattern.test(phoneNumber);
}


export function validateEmail(email) {
    // 使用正则表达式匹配常见的邮箱格式
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}

