import { get } from "lodash"
import { useSelector } from "react-redux"

const useQuerySlot = ({ moduleName, slotName, customId }) => {
    const resumeData = useSelector(state => state.mobileEdit.data?.resumeData)
    if (customId) {
        return get(resumeData, `${customId}.data.${slotName}`)
    } else {
        return get(resumeData, `${moduleName}.data.${slotName}`)
    }
        
}

export default useQuerySlot