import HeaderBoard from "./Header/HeaderBoard";
import Filter from "./Filter";
import { useCallback, useEffect, useRef, useState } from "react";
import useForceUpdate from "../../hooks/useForceUpdate";
import ResumePreview from "./ResumePreview";
import styled from "styled-components";
import { fetchResumeSearch, postSearchResume } from "@/http/api";
import Pagination_c from "@/pages/Home/Pagination_c";
import useQuery from "@/hooks/useQuery";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ListLoading from "@/pages/Home/ListLoading";
import { message } from "antd";
import getQuery from "@/utils/getQuery";
import strictStringify from "@/common/strictStringify";
import Footer from "@/components/Footer";
import { getConfigNameByTypeAndIndex } from "./config";
import FilterListBar from "./FilterListBar";
import withConfigProvider from "@/hoc/withConfigProvider";
import { getSearchTagsJson } from "@/http/api";

const listBoxWidth = 1504;
const minListBoxWidth = 1180;
const templateContainerMarginRight = 18;

const PageContainerStyled = styled.div`
  min-height: 100vh;
  background: #f7f7f7;
`;
const ListContainerPositionStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const ListContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > :not(:nth-child(${(props) => props.containerCount}n)) {
    margin-right: ${templateContainerMarginRight}px;
  }
`;

export const filterCurrentToName = (filterCurrent) => {
  // 注意顺序
  const [colorIndex = "全部", styleIndex = "全部", useIndex = "全部"] =
    filterCurrent;
  const color = getConfigNameByTypeAndIndex("颜色", colorIndex);
  const style = getConfigNameByTypeAndIndex("风格", styleIndex);
  const language = getConfigNameByTypeAndIndex("语言", "全部");
  /* const profession = getConfigNameByTypeAndIndex('行业', professionIndex) */
  const use = getConfigNameByTypeAndIndex("用途", useIndex);
  const position = getConfigNameByTypeAndIndex("职位", "全部");
  return {
    style,
    use,
    language,
    position,
    color,
    /* profession */
  };
};

export const Component = () => {
  const mainContentRef = useRef();
  const query = useQuery();
  const that = useRef({
    initListLoading: false,
    searchValue: "",
    filterCurrent: [],
    list: [],
    fixedHeaderVisible: false,
    current: 1, //当前页数
    defaultCurrent: 1, //默认当前页
    defaultPageSize: 20, //默认单页大小
    pageSize: 20, //单页大小
    total: undefined, //总数
    listCancelToken: undefined,
    listLoading: false,
  }).current;
  const navigate = useNavigate();
  const location = useLocation();
  const [, update] = useForceUpdate();
  const [filterKey, setFilterKey] = useState(0);

  const fetchList = useCallback(async (current) => {
    if (that.listCancelToken) {
      that.listCancelToken.cancel();
    }
    let source = axios.CancelToken.source();
    that.listCancelToken = source;
    that.listLoading = true;
    update();
    const { style, language, profession, use, position, color } =
      filterCurrentToName(that.filterCurrent);
    const res = await postSearchResume({
      query: {
        page: current,
        limit: that.pageSize,
        position: position === "全部" ? "" : position, // 职位
        language: language === "全部" ? "" : language,
        // profession,
        searchWord: that.searchValue, // 用户搜索词
        style: style === "全部" ? "" : style, // 风格
        use: use === "全部" ? "" : use, // 用途
        color: color === "全部" ? "" : color,
      },
      cancelToken: source,
    }).finally(() => {
      that.listCancelToken = undefined;
      that.listLoading = false;
    });
    const { dataList, count, extraDataList } = res;
    setTags(extraDataList?.tagsJson || {});
    console.log(count);
    that.total = count;
    that.list = dataList;
    update();
  }, []);
  const getFixedElement = useCallback(() => {
    return mainContentRef.current;
  }, []);
  const fetchListByQuery = async () => {
    let hide;
    if (that.list?.length > 0) {
      hide = message.loading("", 0);
    } else {
      that.initListLoading = true;
      update();
    }
    // 确保用户切页的时候，滚动到搜索区，初次加载时滚动到顶部
    if (that.list?.length > 0) {
      window.scrollTo({ top: 303 });
    } else {
      setTimeout(() => {
        if (location?.state?.scroll) {
          window.scrollTo({ top: 303 });
        }
      }, 100);
    }
    await fetchList(that.current || that.defaultCurrent).finally(() => {
      if (type.current === "enterSearch") {
        that.filterCurrent = [];
        setFilterKey((n) => {
          return n + 1;
        });
      }
      that.initListLoading = false;
      hide?.();
      update();
      type.current === "nothing";
    });
  };
  const onChangeCurrent = useCallback((current) => {
    // const query = getQuery();
    // query.current = current;
    // navigate(location.pathname + strictStringify(query), {replace: true})
    type.current = "nothing";
    that.current = current;
    update();
  }, []);
  const changeFilterCurrent = useCallback((groundIndex, index) => {
    setSubText();
    that.filterCurrent[groundIndex] = index;
    that.filterCurrent = [...that.filterCurrent];
    type.current = "nothing";
    that.current = 1;
    update();
  }, []);
  const [subText, setSubText] = useState("");
  const changePosition = async (v) => {
    if (that.listCancelToken) {
      that.listCancelToken.cancel();
    }
    let source = axios.CancelToken.source();

    that.listCancelToken = source;

    that.listLoading = true;
    setSubText(v);
    const { style, language, profession, use, position, color } =
      filterCurrentToName(that.filterCurrent);
    const res = await postSearchResume({
      query: {
        page: that.current,
        limit: that.pageSize,
        position: position === "全部" ? "" : position, // 职位
        language: language,
        // profession,
        searchWord: that.searchValue, // 用户搜索词
        style: style === "全部" ? "" : style, // 风格
        use: use === "全部" ? "" : use, // 用途
        color,
      },
      cancelToken: source,
    }).finally(() => {
      that.listCancelToken = undefined;
      that.listLoading = false;
    });
    const { dataList, count } = res;
    that.total = count;
    that.list = dataList;
    update();
  };
  const type = useRef("");

  const handleUpdateValueAndSearch = (value, type1) => {
    if (type1 === 1) {
      type.current = "nothing";
    } else {
        
    }
    setTimeout(() => {
      that.searchValue = value;
      that.current = 1;
      that.filterCurrent = []

      update();
      handleSearch();
    }, 0);
  };
  const handleSearch = () => {
    fetchListByQuery();
  };
  useEffect(() => {
    if (type.current === "enterSearch") {
      type.current = "nothing";
      return;
    }
    fetchListByQuery();
  }, [that.current, that.filterCurrent]);
  useEffect(() => {
    const handler = () => {
      const element = getFixedElement?.();
      if (document.documentElement.scrollTop >= element?.offsetTop - 60) {
        if (!that.show) {
          that.fixedHeaderVisible = true;
          update();
        }
      } else {
        if (that.fixedHeaderVisible) {
          that.fixedHeaderVisible = false;
          update();
        }
      }
    };
    window?.addEventListener("scroll", handler);
    return () => {
      window?.removeEventListener("scroll", handler);
    };
  }, []);

  const isMin = window.innerWidth < 1600;
  const listBoxWidth$1 = isMin ? minListBoxWidth : listBoxWidth;
  const containerCount = isMin ? 4 : 5;
  const containerWidth =
    (listBoxWidth$1 - (containerCount - 1) * templateContainerMarginRight) /
    containerCount;

  const [tags, setTags] = useState([]);
  useEffect(() => {
    getSearchTagsJson().then((res) => {
      setTags(res);
    });
  }, []);
  const tagsNum =(tag)=>{
              console.log(tag)
              if(tag.use){
                return tag.color?.length + tag.first_job?.length + tag.style?.length + tag.use?.length
              }else{
                return 1
              }
            } 
  
  return (
    <PageContainerStyled>
      <HeaderBoard
        searchValue={that.searchValue}
        fixedHeaderVisible={that.fixedHeaderVisible}
        onUpdateValueAndSearch={(v) => handleUpdateValueAndSearch(v, 2)}
      />
      <div ref={mainContentRef} style={{ paddingTop: 30, paddingBottom: 40,minHeight:"calc(100vh - 700px)" }}>
        {(tagsNum(tags)!==0) &&<Filter
          tags={tags}
          newKey={filterKey}
          width={listBoxWidth$1}
          filterCurrent={that.filterCurrent}
          changeFilterCurrent={changeFilterCurrent}
          changePosition={changePosition}
        />}
        <FilterListBar
          total={that.total}
          width={listBoxWidth$1}
          filterCurrent={that.filterCurrent}
          changeFilterCurrent={changeFilterCurrent}
          value={that.searchValue}
          onDeleteValue={(v) => handleUpdateValueAndSearch(v, 1)}
          subText={subText}
        />
        {that.initListLoading && <ListLoading />}
        <ListContainerPositionStyled>
          <ListContainerStyled
            containerCount={containerCount}
            style={{ width: listBoxWidth$1 }}
          >
            {that.list &&
              that.list.map((item, index) => {
                const marginTop = 20;
                return (
                  <ResumePreview
                    key={item.id}
                    item={item}
                    width={containerWidth}
                    style={{ marginTop }}
                  />
                );
              })}
          </ListContainerStyled>
        </ListContainerPositionStyled>
        <Pagination_c
          defaultCurrent={that.defaultCurrent}
          defaultPageSize={that.defaultPageSize}
          current={that.current || 1}
          pageSize={that.pageSize}
          total={that.total}
          onChange={onChangeCurrent}
        />
      </div>
      <Footer />
    </PageContainerStyled>
  );
};
export default withConfigProvider(Component);
