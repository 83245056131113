import {BASE_FIELD_ENUM, JOB_INTEREST_FIELD_ENUM} from "@/common/field/field";
import {Module} from "@/common/module/module";

export default (slot) => {
    switch (slot) {
        case BASE_FIELD_ENUM.NAME: {
            return Module.BASE
        }
        case JOB_INTEREST_FIELD_ENUM.JOB_STATUS: {
            return Module.JOB_INTEREST
        }
        case JOB_INTEREST_FIELD_ENUM.JOB_TYPE: {
            return Module.JOB_INTEREST
        }
        case JOB_INTEREST_FIELD_ENUM.ROLE: {
            return Module.JOB_INTEREST
        }
        case JOB_INTEREST_FIELD_ENUM.CITY: {
            return Module.JOB_INTEREST
        }
        case JOB_INTEREST_FIELD_ENUM.SALARY: {
            return Module.JOB_INTEREST
        }
    }
}
