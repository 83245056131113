import {Module} from "@/common/module/module";

export default (module) => {
    switch (module) {
        case Module.BASE: {
            return "基本信息"
        }
        case Module.AVATAR: {
            return "头像"
        }
        case Module.JOB_INTEREST: {
            return "求职意向"
        }
        case Module.WORK_EXPERIENCE: {
            return "工作经验"
        }
        case Module.SKILLS: {
            return "技能特长"
        }
        case Module.PORTFOLIO: {
            return "作品展示"
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            return "教育背景"
        }
        case Module.CAMPUS_ACTIVITIES: {
            return "校园活动"
        }
        case Module.PROJECT_EXPERIENCE: {
            return "项目经验"
        }
        case Module.LANGUAGE_PROFICIENCY: {
            return "语言能力"
        }
        case Module.SELF_EVALUATION: {
            return "自我评价"
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            return "实习经历"
        }
        case Module.INTEREST: {
            return "兴趣爱好"
        }
        case Module.HONORS_AND_AWARDS: {
            return "荣誉证书"
        }
        case Module.CUSTOM_MODULE: {
            return "自定义模块"
        }
        case Module.CONTACT_INFORMATION: {
            return "联系方式"
        }
        case Module.APPLICATION_INFO: {
            return "报考信息"
        }
    }
}
