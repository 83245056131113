import { useMemo, useState } from "react"
import styled from "styled-components"
import { deleteSvg, moreSvg } from "./TabList"
import { Tooltip } from "antd"


const SelectWrapStyled = styled.div`
    position: relative;
    cursor: pointer;
`

const SelectLabelStyled = styled.div`
    /* display: flex;
    ${props => props.$visible && `background-color: #F4F6F7;`}
    color: #6E727C;
    width: 90px;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 4px;
    padding-right: 0; */


    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    /* background: rgba(0,0,0,0.05); */
    border-radius: 6px;
`

const SelectOptionsStyled = styled.div`
    position: absolute;
    z-index: 1000;
    left: -71px;
    bottom: -4px;
    background: #FFFFFF;
    box-shadow: 0px 5px 12px 0px rgba(146,155,158,0.5);
    border-radius: 8px;
    outline: 1px solid #E6E6E6;
    padding: 6px;
    transform: translateY(100%);
    
`

const SelectItemStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 4px;
    width: 146px;
    padding: 7px;
    color: #6E727C;
    font-size: 12px;
    ${props => props.$active && `background-color: #F4F6F7;`}
    &:hover {
        background-color: #F4F6F7;
        cursor: pointer;
    }
    .delete-icon {
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
    }

    svg:hover {
        g {
            fill: #FF6C6B;
        }
    }
`

const MaskStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8;
`

const icon = <div className="flex-center" style={{ marginLeft: 4 }} dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="新建一个职位描述收起状态" transform="translate(-122.000000, -78.000000)" fill="#AAAAAA" fill-rule="nonzero">
            <g id="模块管理备份-6" transform="translate(129.000000, 85.000000) rotate(180.000000) translate(-129.000000, -85.000000) translate(122.000000, 78.000000)">
                <path d="M8.94317308,1.83164774 L4.05351992,6.48098417 C3.77703312,6.77158698 3.77703312,7.22948594 4.04753252,7.51360893 L8.94926776,12.174644 C9.07656209,12.3088997 9.25616104,12.3854003 9.44304095,12.3854003 C9.63022561,12.3854003 9.80994244,12.3086644 9.94296242,12.1677476 L10.0048333,12.0933176 C10.211068,11.8086343 10.190414,11.4025264 9.94256553,11.1417407 L5.54753855,7 L9.93592541,2.86468615 C10.2129627,2.57356221 10.2129627,2.11566325 9.94256554,1.83164774 C9.66752494,1.54225042 9.21821368,1.54225042 8.94317308,1.83164774 Z" id="-" transform="translate(6.995400, 7.000000) scale(-1, -1) rotate(-90.000000) translate(-6.995400, -7.000000) "></path>
            </g>
        </g>
    </g>
</svg>
`}} />

const SelectJd = ({ options, value, onChange, onDelete }) => {
    const label = useMemo(() => {
        const index = options.findIndex(item => item.id === value)
        if (index === -1 || index === 0) return '目标岗位'
        return '目标岗位' + (index+1)
    }, [options, value])

    const [visible, setVisible] = useState(false)
    const [mouseEnter, setMouseEnter] = useState(null)

    const handleSelectItem = (id) => {
        onChange && onChange(id)
        setVisible(false)
    }

    const handleClick = () => {
        setVisible(true)
    }

    const handleDelete = (id) => {
        onDelete && onDelete(id)
    }

    return <SelectWrapStyled className="select-list">
        <Tooltip title="展开目标岗位" placement='bottom'>
            <SelectLabelStyled onClick={handleClick}>{moreSvg}</SelectLabelStyled>
        </Tooltip>
        {
            visible && 
            <SelectOptionsStyled>
                {options.map((item, index) => {
                    const isActive = item.id === value
                    const isMouse = item.id === mouseEnter
                    return <SelectItemStyled 
                        $active={item.id === value} 
                        onClick={() => handleSelectItem(item.id)}
                        onMouseEnter={() => setMouseEnter(item.id)}
                        onMouseLeave={() => setMouseEnter(null)}
                    >
                        {`目标岗位 ${index ? index+1 : ''}`}
                        {
                            (isActive || isMouse) && <Tooltip title="删除目标岗位" placement="bottom">
                                <div className="delete-icon" onClick={() => handleDelete(item.id)}>{deleteSvg}</div>
                            </Tooltip>
                        }
                    </SelectItemStyled>
                })}
            </SelectOptionsStyled>
        }
        {
            visible && <MaskStyled onClick={() => setVisible(false)} />
        }
    </SelectWrapStyled>
}

export default SelectJd