import styled from "styled-components"
import InitComponent from "./components/InitComponent"
import KeyboardComponent from "./components/KeyboardComponent"
import FeedBackComponent from "./components/FeedBackComponent"
import SystemInitComponent from "./components/SystemInitComponent"
import SystemFeedBackComponent from "./components/SystemFeedBackComponent"
import { useState } from "react"
import { Spin, message } from "antd"
import { generateReference } from "@/http/api"
import useQueryParam from "@/hooks/useQueryParam"

const SystemAnswerStyled = styled.div`
    margin-top: 20px;
    .system-header {
        font-size: 14px;
        color: #343542;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        .re-submit {
            width: 100px;
            height: 26px;
            background: #11BBA6;
            border-radius: 4px;
            color: #fff;
            font-size: 12px;
        }
        .re-submit:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
    
`

const svgIcon = <div className="flex-center" style={{ marginRight: 6}} dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="可重新作答、重新生成示例" transform="translate(-732.000000, -692.000000)" fill="#FFFFFF">
            <g id="编组-3" transform="translate(164.000000, 243.000000)">
                <g id="编组-7备份" transform="translate(552.000000, 443.000000)">
                    <g id="文本备份-22" transform="translate(16.000000, 6.000000)">
                        <g id="magic-full" transform="translate(1.750000, 1.750000)">
                            <path d="M8.12857671,6.43425479 L5.49973509,7.87069354 L4.06329634,10.4975512 L2.62685759,7.87069354 L0,6.43425479 L2.62685759,4.99781606 L4.06329634,2.36897442 L5.49973509,4.99781606 L8.12857671,6.43425479 Z M9.2000617,1.2999383 L10.5,2.0117201 L9.2000617,2.72350186 L8.48827992,4.02344018 L7.77837125,2.72162875 L6.47655982,2.01172008 L7.77837125,1.30181141 L8.4882799,5.88418203e-14 L9.2000617,1.2999383 Z M1.59542385,0.890461998 L2.35794259,1.30691455 L1.59542383,1.72336709 L1.17897129,2.48588584 L0.76251874,1.7233671 L1.32804878e-12,1.30691455 L0.76251874,0.890461998 L1.17897129,0.127943257 L1.59542385,0.890461998 Z" id="形状"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const SystemAnswer = ({ isActive, status = 'feedback', answer, onChange, questionId, referenceId}) => {

    const [loading, setLoading] = useState(false)
    const resumeId = useQueryParam('id')

    // init初始态，用户答案answer为空
    // keyboard
    const mapStatusToCom = {
        'init': <SystemInitComponent questionId={questionId} referenceId={referenceId} onChange={(value) => onChange(value)} />,
        'keyboard': <KeyboardComponent onChange={(value) => onChange('feedback',value)} />,
        'feedback': <SystemFeedBackComponent answer={answer} onClick={() => onChange('keyboard')} />
    }

    const handleChange = (event) => {
        event.stopPropagation()
        if (loading) return 
        setLoading(true)
        generateReference({
            query: {
                resumeId: parseInt(resumeId),
                questionId,
                // referenceId
            }
        }).then(res => {
            onChange && onChange('feedback',res)
        }).finally(() => {
            setLoading(false)
        })
    }

    return <div>
        {
            (status === 'init' && !isActive) ? <></> : <SystemAnswerStyled>
            <div className="system-header">
                💡 参考示例：
    
                {isActive && status === 'feedback' && <Spin spinning={loading}><div onClick={handleChange} className="re-submit flex-center">{svgIcon} 重新生成</div></Spin>}
            </div>
            <div className="content">
                {mapStatusToCom[status]}
            </div>
        </SystemAnswerStyled>
        }
    </div>
}

export default SystemAnswer