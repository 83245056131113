import withComponent from '@/hoc/withComponent';
import config, {hostname} from '@/const';
import styled from 'styled-components'


const FlexStyled = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  align-self: ${(props) => props.alignSelf};
  flex: ${(props) => props.flex};
  flex-wrap: ${(props) => props.flexWrap};
  flex-wrap: ${(props) => props.flexWrap};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
`;
const AStyled = styled.a`
  color: rgb(128, 128, 128);

  :hover {
    color: #1890ff;
    text-decoration: underline;
  }
`;

const HoverStyled = styled.a`
    color: #999999;
    &:hover{
        color: #fff;
    }
`

export default withComponent(() => {
    let flag = !(hostname.indexOf('10sppt.com') !== -1)
    return (
        <FlexStyled
            justifyContent='center'
            style={{
                background: '#000000',
                fontSize: 14,
                color: '#999999',
                fontWeight: 400,
            }}
        >
            <div>
                <FlexStyled
                    alignItems='center'
                    justifyContent='center'
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: '#D2D2D2',
                        height: 110,
                        borderBottom: '1px solid #242424',
                    }}
                >
                    <FlexStyled>
                        <FlexStyled alignItems='center' style={{marginRight: 44}}>
                            <img
                                width={30}
                                height={30}
                                style={{marginRight: 30}}
                                src='https://oss.jianzeppt.com/user/18995/431ea79f-56c2-47c7-bfa8-7c145be4a26c.png'
                            />
                            精品臻选
                        </FlexStyled>
                        <FlexStyled alignItems='center' style={{marginRight: 44}}>
                            <img
                                width={30}
                                height={30}
                                style={{marginRight: 30}}
                                src='https://oss.jianzeppt.com/user/17814/48c015e4-fb22-47d3-818c-370a955e618d.png'
                            />
                            提升效率

                        </FlexStyled>
                        <FlexStyled alignItems='center' style={{marginRight: 44}}>
                            <img
                                width={30}
                                height={30}
                                style={{marginRight: 30}}
                                src='https://oss.jianzeppt.com/user/17814/4a67cbb7-d12a-4131-9742-9431ed4a6c29.png'
                            />
                            质优价廉

                        </FlexStyled>
                        <FlexStyled alignItems='center'>
                            <img
                                width={30}
                                height={30}
                                style={{marginRight: 30}}
                                src='https://oss.jianzeppt.com/user/17814/47ef6dd1-3421-428c-a615-87b7fb0f3418.png'
                            />
                            100%满意

                        </FlexStyled>
                    </FlexStyled>
                </FlexStyled>
                {(
                    <>
                        <FlexStyled
                            alignItems='center'
                            justifyContent='center'
                            style={{marginTop: 30, marginBottom: 18, width: '100%'}}
                        >
                            {
                                !flag && <div style={{
                                    height: 66,
                                    width: 66,
                                    backgroundImage: `url(${config.publicAccountQR})`,
                                    backgroundSize: '100% 100%',
                                }}/>
                            }
                        </FlexStyled>
                        <FlexStyled
                            justifyContent='center'
                            alignItems='center'
                            className='cmlayout'
                            style={{
                                fontSize: 12,
                                color: '#808080',
                                fontFamily: 'PingFangSC-Medium, PingFang SC',
                            }}
                        >
                            <div>
                                {
                                    !flag && <FlexStyled
                                        justifyContent='center'
                                        alignItems='center'
                                        style={{paddingBottom: 18}}
                                    >
                                        <a
                                            rel="nofollow"
                                            target='_blank'
                                            href={'/pptx/template/abu'}
                                            style={{color: '#999999'}}
                                        >
                                            关于我们
                                        </a>{' '}
                                        <span
                                            style={{
                                                height: 14,
                                                width: 1,
                                                background: '#D8D8D8',
                                                margin: '0 24px',
                                            }}
                                        />{' '}
                                        <a
                                            rel="nofollow"
                                            target='_blank'
                                            href={'/agreement/user'}
                                            style={{color: '#999999'}}
                                        >
                                            用户协议

                                        </a>{' '}
                                        <span
                                            style={{
                                                height: 14,
                                                width: 1,
                                                background: '#D8D8D8',
                                                margin: '0 24px',
                                            }}
                                        />{' '}
                                        <a
                                            rel="nofollow"
                                            target='_blank'
                                            href={'/agreement/law'}
                                            style={{color: '#999999'}}
                                        >
                                            版权声明
                                        </a>
                                    </FlexStyled>
                                }
                                <FlexStyled justifyContent='center' style={{paddingBottom: 30}}>
                                    <div>
                                        <div>Copyright © 2022 {'了了简历' || config.productName} All Rights Reserved
                                            ·{config.companyName}</div>
                                        <div style={{textAlign: 'center', marginTop: 10}}><AStyled rel="nofollow"
                                                                                                   target='_blank'
                                                                                                   href='http://beian.miit.gov.cn/'>{config.record}</AStyled>
                                        </div>
                                        {
                                            window.location.href.indexOf("cv.xals77.cn") !== -1 ? '' :
                                                <div style={{textAlign: 'center', marginTop: 10}}>友情链接：<HoverStyled
                                                    className='hover-white'
                                                    target='_blank'
                                                    href={'https://jzmbti.com/'}
                                                    style={{ textDecoration: 'none'}}
                                                >
                                                    简则MBTI
                                                </HoverStyled>
                                                <HoverStyled
                                                    target='_blank'
                                                    href={'https://10sppt.com/pptx'}
                                                    className='hover-white'
                                                    style={{ textDecoration: 'none',paddingLeft:'30px'}}
                                                >
                                                    秒出PPT
                                                </HoverStyled>
                                                </div>
                                        }

                                    </div>
                                </FlexStyled>
                            </div>
                        </FlexStyled>
                    </>
                )}
            </div>
        </FlexStyled>
    );
});
