import axios from "axios";
import store from "@/redux/store"
import {fetchAnonymousUserThunk} from "@/redux/thunk/user";
import strictStringify from "@/common/strictStringify";

let request = axios.create({
    baseURL: 'https://api-test.jianzeppt.cn'
})

request.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

request.interceptors.request.use(async function (config) {
    const {user} = store.getState();
    return config;
});


request.interceptors.response.use(
    response => {
        switch (response.data.code) {
            case 0: {
                return response.data.data;
            }
            case 1001: {
                break
            }
            default: {
                return Promise.reject(response.data);
            }
        }
    },
    error => {
        return Promise.reject(error.response);
    }
)

let http = {
    get: (url, options = {}) => {
        return request.get(url + strictStringify(options.query), options.config)
    },
    post: (url, options) => {
        return request.post(url + strictStringify(options.query), options.body, options.config)
    }
}

export default http;
