import styled from 'styled-components';
import withComponent from '@/hoc/withComponent';
import React, {useEffect, useRef, useState} from 'react';
import FlexStyled from '@/styled/FlexStyled';
import LogoComponent from "@/components/Logo"
import User from '@/components/user/index';
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
`;

const TabsSection = styled.div`
    display: flex;
    align-items: center;
    column-gap: 50px;
    margin-right: 50px;
    color: #2C2C2C;
    font-size: 14px;
    .tab-1,
    .tab-2{
        cursor: pointer;
    }
`


export default withComponent((props) => {

    const navigate = useNavigate()
    

    const navToPage = (type) => {
        navigate(`/resume?tabKey=${type}`)
    }

    return (
        <Container>
            <FlexStyled $flexShrink={0} $justifyContent='center' style={{}}>
                <FlexStyled $justifyContent='space-between' $alignItems='center'
                            style={{width: '100%', height: 60, padding: '0 50px', position: 'relative'}}>
                    <div>
                        <LogoComponent isDark={false}/>
                    </div>
                    <FlexStyled $alignItems='center'>
                        <TabsSection>
                            <div onClick={() => navToPage('resume')} className='tab-1'>改简历</div>
                            <div onClick={() => navToPage('question')} className='tab-2'>练面试</div>
                        </TabsSection>
                        <User/>
                    </FlexStyled>
                </FlexStyled>
            </FlexStyled>
        </Container>
    );
});

