import styled from 'styled-components';
import { useSelector } from 'react-redux';
import FlexStyled from '@/styled/FlexStyled';
import Remind from '@/pages/PersonalCenter/component/Remind';
import { Cell1Styled, Cell2Styled, Cell3Styled, Header2Styled } from '@/pages/PersonalCenter/styled';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {getDownHistory, getUserDownloadHistory, pptAuthorizedDownloadById} from '@/http/api';
import useForceUpdate from '@/hooks/useForceUpdate';
import moment from 'moment';
import fetchFile from '@/common/fetchFile';
import LoadingModal from '@/components/LoadingModal';
import downFile from '@/common/downFile';
import { message } from 'antd';

const BoxStyled = styled.div`
  width: 100%;
  padding: 0 30px;
`;

const ButtonStyled = styled.div`
  width: 56px;
  height: 24px;
  line-height: 24px;
  background: #00BCA6;
  border-radius: 4px;

  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  display: inline-block;
  cursor: pointer;
`;
export default () => {
  const that = useRef({
    loading: false,
  }).current;
  const userRemainingDetail = useSelector((state) => state.user?.userRemainingDetail);

  const [, update] = useForceUpdate();
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [name, setName] = useState(0);
  const coList = [{ id: '1', text: '时间' }, { id: '2', text: '简历名称' }, { id: '3', text: '操作' }];
  const fetchData = async () => {
    that.loading = true;
    update();
    try {
      const { dataList } = await getDownHistory({query: { limit: 999, page: 1 }});
      const list = dataList.map((item) => {
        return [
          { id: 'time' + item.id, text: moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') },
          { id: 'text' + item.id, text: item.fileName ? item.fileName : '未命名' },
          { id: item.id, text: '操作', pptPath: item.pptPath, fileName: item.fileName }];
      });
      setList(list);
    } catch (e) {

    } finally {

    }

  };
  const onClickDown = async (item) => {
    setVisible(true);
    setName(item.fileName);

    let url;
    try {
      try {
        url = await pptAuthorizedDownloadById(item.id);
      } catch (e) {
        message.info('链接已过期');
        throw  new Error();
      }
      const name = `${item.fileName || '未命名'}` + `pptx`;
      const blob = await fetchFile(url, name, (percent) => {
        setProgress(percent);
      });
      await downFile(blob, name);
    } catch (e) {

    } finally {
      setVisible(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  return <BoxStyled>
    <Remind text1={userRemainingDetail ? `本月剩余量：本月还剩 ${userRemainingDetail?.remainingDownNum} 次 (${Number((userRemainingDetail?.remainingDownNum / userRemainingDetail.downNumSum * 100)?.toFixed?.(2))}%)` : ''}
            text2={userRemainingDetail ? `你的 ${userRemainingDetail.downNumSum} 次PPT下载将于${30 - userRemainingDetail.curMonthUsedDay}天后恢复` : ''}
    />
    <Header2Styled>
      {
        coList.map((item, index) => {
          let C;
          if (index === 0) {
            C = Cell1Styled;
          } else if (index === 1) {
            C = Cell2Styled;
          } else if (index === 2) {
            C = Cell3Styled;
          }
          return <C style={{ flex: 1, width: 'auto' }}>{item.text}</C>;
        })
      }
    </Header2Styled>
    <div>
      {
        list.map((row, index) => {
          return <FlexStyled key={index}>
            {row.map((r, i) => {
              let C;
              if (i === 0) {
                C = Cell1Styled;
              } else if (i === 1) {
                C = Cell2Styled;
              } else if (i === 2) {
                C = Cell3Styled;
              }
              return <C style={{ flex: 1, width: 'auto' }}>{i === 2 ? <ButtonStyled onClick={() => onClickDown(r)}>下载</ButtonStyled> : r.text}</C>;
            })}
          </FlexStyled>;
        })
      }
    </div>
    <LoadingModal visible={visible} onCancel={() => setVisible(false)} progress={progress} name={name} />
  </BoxStyled>;
}

