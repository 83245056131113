import styled from 'styled-components';
import {forwardRef, useRef} from 'react';


const Pointer = styled.div`
  user-select: none;
  opacity: ${(props) => (props['data-disabled'] ? (props.hoverOpacity ? props.hoverOpacity : 0.5) : 1)};

  :hover {
    opacity: ${(props) =>
      props['data-disabled']
        ? 0.5
        : props.hoverOpacity
          ? props.hoverOpacity
          : 0.9};
    cursor: ${(props) => (props['data-disabled'] ? 'not-allowed' : 'pointer')};
  }
`;

export default forwardRef((props, ref) => {
  const {children, disabled, onClick, hoverOpacity, onMouseDown, className} = props;
  const that = useRef({disabledFlag: false}).current;

  const onClickPointer = (e) => {
    if (disabled || that.disabledFlag) {
      e.stopPropagation();
    } else {
      that.disabledFlag = true;
      const res = onClick?.(e);
      if (res && res.finally) {
        res.finally(() => {
          that.disabledFlag = false;
        });
      } else {
        that.disabledFlag = false;
      }
    }
  };
  return (
    <Pointer
      {...props}
      ref={ref}
      className={className}
      data-disabled={disabled}
      disabled={undefined}
      onClick={onClickPointer}
      onMouseDown={(e) => {
        if (disabled) {
          e.stopPropagation();
        } else {
          onMouseDown?.(e);
        }
      }}
    >
      {children}
    </Pointer>
  );
});
