import React from "react";
import {IconStyled} from "@/pages/ResumeDetail/Chat/styled";

export const icon1 = <IconStyled style={{width: 12, height: 12, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="4" transform="translate(-175.000000, -133.000000)" fill="#F89D9D" fill-rule="nonzero">
            <g id="秒出助手备份-4" transform="translate(175.000000, 133.000000)">
                <path d="M3.63200726,1.29779735 C3.84266351,1.50830472 3.84278419,1.8497253 3.63227682,2.06038155 L2.89836748,2.79429087 L2.95097604,2.99825562 L7.2817947,3.02770559 C9.44575973,3.02770559 11.2,4.78194585 11.2,6.94591089 C11.2,9.10987592 9.44575973,10.8641162 7.2817947,10.8641162 L1.87521274,10.8641162 C1.57748068,10.8641162 1.33612117,10.6227567 1.33612117,10.3250246 C1.33612117,10.0272926 1.57748068,9.78593304 1.87521274,9.78593304 L7.2817947,9.78593304 C8.85029563,9.78593304 10.1218169,8.51441181 10.1218169,6.94591089 C10.1218169,5.37740996 8.85029563,4.10588873 7.2817947,4.10588873 L2.94706761,4.10588873 L2.84004262,4.28731345 L3.63231099,5.12123036 C3.77384246,5.25814856 3.83008255,5.45857404 3.78056652,5.64719881 C3.7310976,5.83564413 3.58379886,5.98273466 3.39528377,6.03193699 C3.20676867,6.08113932 3.00637397,6.02479657 2.87112025,5.88456376 L0.958145246,3.97223301 C0.856882246,3.87108259 0.8,3.73387286 0.8,3.59078886 C0.8,3.44770898 0.856879343,3.31049805 0.958108364,3.20938158 L2.86938888,1.29810109 C3.05653412,1.11082352 3.34709244,1.08991455 3.55730335,1.23542845 L3.63200726,1.29779735 Z" id="路径"></path>
            </g>
        </g>
    </g>
</svg>`
}}/>
export const icon2 = <IconStyled style={{width: 12, height: 12, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="4" transform="translate(-195.000000, -133.000000)" fill="#58CFC0">
            <g id="秒出助手备份-2" transform="translate(195.000000, 133.000000)">
                <path d="M4.53059901,9.83600402 L0.487073363,6.39138189 C0.232496025,6.17451106 0.201928855,5.79232682 0.418799677,5.53774948 C0.426011124,5.52928421 0.433454397,5.52101919 0.441121026,5.51296384 C0.688580444,5.25295751 1.09608234,5.23163484 1.36932593,5.46439523 L5.13283901,8.67031399 L5.13283901,8.67031399 L10.5466946,2.00185246 C10.7751572,1.72044595 11.1838355,1.6669184 11.4770642,1.87999524 C11.7508068,2.07891233 11.8114648,2.46207866 11.6125477,2.73582119 C11.6061178,2.74466974 11.5994522,2.75334452 11.5925579,2.7618362 L5.95542721,9.70507958 C5.6073188,10.1338441 4.97753889,10.1992287 4.54877441,9.85112032 C4.54265659,9.84615334 4.5365977,9.84111422 4.53059901,9.83600402 Z" id="路径"></path>
            </g>
        </g>
    </g>
</svg>`
}}/>


export const undoIcon = <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} dangerouslySetInnerHTML={{ __html:`
<?xml version="1.0" encoding="UTF-8"?>
<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="内容识别后样式优化" transform="translate(-1108.000000, -836.000000)" fill="#777777" fill-rule="nonzero">
            <g id="编组-4备份-9" transform="translate(892.000000, 742.000000)">
                <g id="文本备份-23" transform="translate(216.000000, 94.000000)">
                    <path d="M5.62071884,3.76653182 C5.64140497,3.77566689 5.65222871,3.7914489 5.65225998,3.80615404 L5.65225965,5.47518349 L9.63486374,5.47518349 C11.6500657,5.47127851 13.2923452,6.94749122 13.3325801,8.78737561 C13.372806,10.6268518 11.7969953,12.1619298 9.78390717,12.2324225 L9.63243345,12.2349175 L4.05452142,12.2349175 C3.93997463,12.2349175 3.85186877,12.1539224 3.85186877,12.0604728 L3.85186877,11.4193863 C3.85186877,11.3259367 3.93997463,11.2449618 4.05452142,11.2449618 L9.63486374,11.2449618 C11.0348474,11.2468192 12.1861613,10.2188188 12.224922,8.92096978 C12.2636993,7.6225672 11.174664,6.53726169 9.7759666,6.46850064 L9.63835994,6.4651608 L5.65225965,6.46511896 L5.65225965,8.13445918 C5.65222871,8.14885355 5.64140497,8.16463556 5.62071884,8.17377063 C5.59589908,8.18473112 5.56545848,8.18156924 5.5450306,8.16660312 L2.73762139,6.10245183 C2.69205824,6.06885576 2.66666667,6.02021477 2.66666667,5.97015122 C2.66666667,5.92008768 2.69205824,5.87144669 2.73778138,5.83773282 L5.54523113,3.77355215 C5.55871603,3.76367285 5.57674016,3.75897447 5.59461192,3.76018748 L5.62071884,3.76653182 Z" id="路径备份-3"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

export const redoIcon = <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} dangerouslySetInnerHTML={{ __html:`
<?xml version="1.0" encoding="UTF-8"?>
<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="内容识别后样式优化" transform="translate(-1132.000000, -836.000000)" fill="#11BBA6">
            <g id="编组-4备份-9" transform="translate(892.000000, 742.000000)">
                <g id="秒出助手备份-7" transform="translate(240.000000, 94.000000)">
                    <polygon id="路径" points="7.38670353 12 2.66666667 7.97906755 3.45088182 7.15509039 7.22919023 10.3736124 12.4036697 4 13.3333333 4.6755471"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />
export const whiteRedoIcon = <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} dangerouslySetInnerHTML={{ __html:`
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="填充内容-内容识别" transform="translate(-744.000000, -422.000000)" fill="#FFFFFF">
            <g id="编组备份" transform="translate(440.000000, 292.000000)">
                <g id="编组-30" transform="translate(190.000000, 120.000000)">
                    <g id="编组-5" transform="translate(98.000000, 0.000000)">
                        <g id="文本备份-32" transform="translate(16.000000, 10.000000)">
                            <polygon id="路径" points="9.23337941 15 3.33333333 9.97383444 4.31360228 8.94386299 9.03648779 12.9670155 15.5045872 5 16.6666667 5.84443387"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />