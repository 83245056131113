import Resume from "@/components/resume";
import * as React from "react";
import {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {createStore} from "@/components/resume/redux/store";
import {getResumeDataById} from "@/http/api";
import config from "@/const";
import useQuery from "@/hooks/useQuery";
import {message} from "antd";
import dispatchResumeData from "@/common/resume/dispatchResumeData";

export default () => {
    const [status, setStatus] = useState();
    const [width, setWidth] = useState(0);
    const query = useQuery();
    const {resumeId} = query;
    const store = useMemo(() => createStore({
        devTools: __PRO__ ? false : {name: "简历"},
    }), []);
    const fetchData = async () => {
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, 1000)
        })
        const resumeData = await getResumeDataById(resumeId).catch((err) => {
            message.error(err.msg)
        })
        if (!resumeData) return;
        dispatchResumeData(store.dispatch, resumeData);
    };
    const fetch = () => {
        fetchData()
            .then(() => {
                setStatus("success");
            })
            .catch((e) => {
                setStatus("error");
            })
            .finally(() => {
                setWidth(config.resume.width)
            })
    }
    const onInit = () => {
        setStatus("down");
    }
    useEffect(() => {
        fetch()
    }, []);


    return <div id="down-master" data-status={status}>
        {!!width && <Resume canEdit={false} isDown={true} width={width} onInit={onInit} store={store}/>}
    </div>
}
