
import { Module, getModuleList } from "@/common/module/module";
import { useEffect, useMemo, useState } from "react";
import useResumeData from "../../hooks/useResumeData";
import { useSelector } from "react-redux";
import getTitleTextByModule from "@/common/module/getTitleTextByModule";
import getTextByModule from "@/common/module/getTextByModule";
import getModuleVisible from "@/common/config/getModuleVisible";
import { cloneDeep, get } from 'lodash'
import useUpdateResumeData from "../../hooks/useUpdateResumeData";
import { CUSTOM_MODULE_SLOT_ENUM } from "@/common/slot/slot";
import {v4} from "uuid";

const useModuleConfig = () => {
    const { data: resumeData } = useResumeData()
    const { onChangeByPath } = useUpdateResumeData()
    const template = useSelector(state => state.mobileEdit.data.template)
    const moduleConfig = useMemo(() => getModuleList().filter((module) => {
        return template?.moduleConfig?.[module] && module !== Module.CUSTOM_MODULE
        
    }), [template?.moduleConfig]);
    const customModuleConfig = !resumeData ? [] : Object.keys(resumeData).filter((key) => resumeData[key].type === Module.CUSTOM_MODULE);
    
    
    const config = useMemo(() => {
        const config1 = moduleConfig.map((id, index) => ({
            id: id,
            blockName: id,
            index,
            title: getTitleTextByModule(resumeData, id) || getTextByModule(id),
            require: id === Module.BASE,
            visible: getModuleVisible(resumeData, id),
            sort: get(resumeData, `${id}.sort`)
        }));
        const config2 = customModuleConfig.map((id, index) => {
            return {
                id: id,
                index,
                blockName: Module.CUSTOM_MODULE,
                // title: "自定义模块",
                title: getTitleTextByModule(resumeData, id) || "自定义模块",
                visible: getModuleVisible(resumeData, id),
                sort: get(resumeData, `${id}.sort`)
            }
        });
        return [...config1, ...config2]
    }, [resumeData, moduleConfig, customModuleConfig])

    const visibleConfig = useMemo(() => config.filter((a) => a.visible).sort((a, b) => a.sort - b.sort), [resumeData]);
    const unVisibleConfig = useMemo(() => config.filter((a) => !a.visible).sort((a, b) => a.sort - b.sort), [resumeData]);

    const onChange = (obj, isSave = true) => {
        // 更新sort和visible
        const newResumeData = cloneDeep(resumeData)
        Object.keys(obj).forEach(key => {
            newResumeData[key].sort = obj[key].sort
            newResumeData[key].visible = obj[key].visible
        })

        onChangeByPath(null, newResumeData, isSave)
    }

    const onSave = () => {
        onChangeByPath(null, resumeData)
    }

    const onAdd = () => {
        const newResumeData = cloneDeep(resumeData)
        const id = v4();
        newResumeData[id] = {
            "data": {
                title_text: "自定义标题", 
                [CUSTOM_MODULE_SLOT_ENUM.DETAILS]: "这里是自定义描述。",
            }, 
            visible: true,
            id,
            type: Module.CUSTOM_MODULE
        }
        onChangeByPath(null, newResumeData)
    }

    return [
        {
            visibleConfig,
            unVisibleConfig
        },
        {
            onChange,
            onSave,
            onAdd
        }
    ]
}

export default useModuleConfig