import styled from "styled-components";
import Select from "@/components/resume/components/Select";
import {Popover} from "antd";
import {useCallback, useState} from "react";

const ContainerStyled = styled.div`
  width: 333px;
  height: 84px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 0.5);
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  padding: 0 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`
const ItemBoxStyled = styled.div`
  width: 130px;
  flex-shrink: 0;
  height: 42px;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 8px;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
`
const ItemTextStyled = styled.div`
  height: 16px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
`
const Text1Styled = styled.div`
  /* width: 12px; */
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  line-height: 17px;
  padding: 0 9px;
`
const icon = <div style={{width: 16, height: 16}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="求职意向状态" transform="translate(-1598.000000, -740.000000)" fill="#999999" fill-rule="nonzero">
            <g id="编组-21" transform="translate(1481.000000, 706.000000)">
                <g id="更多-绿备份" transform="translate(117.000000, 34.000000)">
                    <path d="M10.6287151,13.3333333 C10.4824264,13.3333333 10.3361172,13.2775932 10.2245011,13.1657434 L4.96743947,8.40517069 C4.74418684,8.18184055 4.74418684,7.8191394 4.96743947,7.59541932 L10.2245011,2.83445673 C10.4477538,2.61073665 10.8093079,2.61073665 11.0325605,2.83445673 C11.2558132,3.05778687 11.2558132,3.42048802 11.0325605,3.6442081 L6.17971289,8.00008979 L11.0325605,12.3556021 C11.2558131,12.5793221 11.2558131,12.9416333 11.0325605,13.1653534 C10.9213131,13.2775932 10.7750039,13.3333333 10.6287151,13.3333333 Z" id="-" transform="translate(8.000000, 8.000000) scale(-1, 1) rotate(-90.000000) translate(-8.000000, -8.000000) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>


export const DIMISSION_SEARCH_JOB = "DIMISSION_SEARCH_JOB";
const ON_JOb_SEARCH_JOB = "ON_JOb_SEARCH_JOB";
const ON_JOB_LOOK_FOR_CHANCE = "ON_JOB_LOOK_FOR_CHANCE";
export const NO_SEARCH_JOB = "NO_SEARCH_JOB";

const ONE_WEEK_TO_WORK = "ONE_WEEK_TO_WORK";
const ONE_MONTH_TO_WORK = "ONE_MONTH_TO_WORK";
const THREE_MONTH_TO_WORK = "THREE_MONTH_TO_WORK";
const LATER = "LATER";


export const getTextByJobStatus = (jobStatus) => {
    switch (jobStatus) {
        case DIMISSION_SEARCH_JOB: {
            return "离职找工作"
        }
        case ON_JOb_SEARCH_JOB: {
            return "在职找工作"
        }
        case ON_JOB_LOOK_FOR_CHANCE: {
            return "在职看机会"
        }
        case NO_SEARCH_JOB: {
            return "暂时不找工作"
        }

    }
}
export const getTextByJobArrivalTime = (jobArrivalTime) => {
    switch (jobArrivalTime) {
        case ONE_WEEK_TO_WORK: {
            return "一周内到岗"
        }
        case ONE_MONTH_TO_WORK: {
            return "一个月内到岗"
        }
        case THREE_MONTH_TO_WORK: {
            return "三个月内到岗"
        }
        case LATER: {
            return "到岗时间另议"
        }

    }
}


const list = [
    {
        id: "DIMISSION_SEARCH_JOB",
        name: "离职找工作",
    },
    {
        id: "ON_JOb_SEARCH_JOB",
        name: "在职找工作",
    },
    {
        id: "ON_JOB_LOOK_FOR_CHANCE",
        name: "在职看机会",
    },
    {
        id: "NO_SEARCH_JOB",
        name: "暂时不找工作",
    },
]

const list2 = [
    {
        id: "ONE_WEEK_TO_WORK",
        name: "一周内到岗",
    },
    {
        id: "ONE_MONTH_TO_WORK",
        name: "一个月内到岗",
    },
    {
        id: "THREE_MONTH_TO_WORK",
        name: "三个月内到岗",
    },
    {
        id: "LATER",
        name: "到岗时间另议",
    },
]

export default (props) => {
    const {defaultJobStatus, defaultJobArrivalTime} = props;
    const {onChangeJobStatus, onChangeJobArrivalTime} = props;

    const [jobStatus, setJobStatus] = useState(defaultJobStatus);
    const [jobArrivalTime, setJobArrivalTime] = useState(defaultJobArrivalTime);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverOpen2, setPopoverOpen2] = useState(false);

    const onClickMin = useCallback((item) => {
        setJobStatus(item.id);
        onChangeJobStatus?.(item.id);
        if (item.id === NO_SEARCH_JOB) {
            setJobArrivalTime(undefined);
            onChangeJobArrivalTime?.(undefined)
        }
        setPopoverOpen(false);
    }, []);
    const onClickMax = useCallback((item) => {
        setJobArrivalTime(item.id)
        onChangeJobArrivalTime?.(item.id)
        setPopoverOpen2(false);
    }, []);

    return <ContainerStyled>
        <Popover content={<Select width={140} onClickItem={onClickMin} list={list}/>}
                 destroyTooltipOnHide
                 overlayClassName="ant-popover-custom-field-menu"
                 autoAdjustOverflow={false}
                 trigger="click"
                 transitionName=""
                 open={popoverOpen}
                 placement="bottom"
                 onOpenChange={setPopoverOpen}>
            <ItemBoxStyled>
                <ItemTextStyled
                    style={{color: jobStatus !== undefined ? 'black' : undefined}}>{jobStatus ? getTextByJobStatus(jobStatus) : "请选择"}</ItemTextStyled>
                <div>{icon}</div>
            </ItemBoxStyled>
        </Popover>
        {
            jobStatus !== 'NO_SEARCH_JOB' && <>
                <Text1Styled>至</Text1Styled>
                <Popover content={<Select width={140} onClickItem={onClickMax} list={list2}/>}
                         destroyTooltipOnHide
                         overlayClassName="ant-popover-custom-field-menu"
                         autoAdjustOverflow={false}
                         trigger="click"
                         transitionName=""
                         open={popoverOpen2}
                         placement="bottom"
                         onOpenChange={setPopoverOpen2}>
                    <ItemBoxStyled>
                        <ItemTextStyled
                            style={{color: jobArrivalTime !== undefined ? 'black' : undefined}}>{jobArrivalTime ? getTextByJobArrivalTime(jobArrivalTime) : '请选择'}</ItemTextStyled>
                        <div>{icon}</div>
                    </ItemBoxStyled>
                </Popover>
            </>
        }
    </ContainerStyled>
}
