import moment from "moment";
import { getFieldConfigByModuleName } from "./config";
import { isArray } from "lodash";
import { cityConfig, jobInterestRoleConfig } from "@/components/resume/const/getMenuConfigByField";
import { JOB_INTEREST_FIELD_ENUM } from "@/common/field/field";
import { JOB_INTEREST_SLOT_ENUM } from "@/common/slot/slot";


const findPath = (cityConfig, cityName, path = []) => {
    for (const item of cityConfig) {
      // 如果找到匹配的城市名，返回包含父级名称和城市名的数组
      if (item.name === cityName) {
        return [...path, cityName];
      }
      // 如果当前项有子项，递归搜索子项
      if (item.children) {
        const foundPath = findPath(item.children, cityName, [...path, item.name]);
        // 如果在子项中找到匹配的城市名，返回找到的路径
        if (foundPath) {
          return foundPath;
        }
      }
    }
    // 如果没有找到匹配的城市名，返回null
    return [cityName];
};

// 处理输入层
// 做两件事
// 1. 将选择器模块字段value从 'value' => ['value']
export const processBasicInfo = (basicInfoData, moduleName) => {
    const fieldList = getFieldConfigByModuleName(moduleName)
    const pickerConfig = fieldList.reduce((pre, cur) => {
        const key = cur.value
        if(cur.type === 'picker') {
            pre[key] = [basicInfoData[key]]
        }
        return pre
    }, {})

    const startDate = basicInfoData['start_date']
    const endDate = basicInfoData['end_date']
    return {
        ...basicInfoData,
        ...pickerConfig,
        date: [moment(startDate).toDate(), moment(endDate).toDate()]
    }
}


// 处理输出层
export const processBasicInfoExport = (values) => {
    const newValues = {
        ...values,
    }

    let dateFlag = false

    Object.keys(newValues).forEach(key => {
        if (isArray(newValues[key])) {
            if (key === 'date') {
                const [startDate, endDate] = newValues[key]
                newValues['start_date'] = moment(startDate).toISOString()
                newValues['end_date'] = moment(endDate).toISOString()
                dateFlag = true
            } else {
                newValues[key] = newValues[key][newValues[key].length - 1]
            }
        }
    })

    if (dateFlag) {
        delete newValues['date']
    }
    return newValues
}