import withComponent from "@/hoc/withComponent"
import { forwardRef, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { uploadResumeByWord } from "@/http/mobileApi"
import { clearToast, showToast } from "@/pages/Mobile/utils/toast"
import { pdfSvg, wordSvg } from "@/svg"
import JzImage from "@/components/JzImage"
import VideoModal from "./VideoModal"
import { ProgressBar } from "antd-mobile"
import isLogin from '@/common/isLogin'
import errorSlice from "@/redux/services/errorSlice"
import store from "@/redux/store"

const TabItemImportStyled = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    .tab-import {
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 0 12px 0px rgba(0,0,0,0.05);
        border-radius: 8px;
    }
    .video-tip {
        font-size: 10px;
        color: #B4B4B4;
        text-decoration-line: underline;
    }
    input {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        top: 0;
    }
`

const imgList = [
    {
        imgUrl: "iojsiofjoi3.png",
    },
    {
        imgUrl: "ncvxknvk1.png",
    },
    {
        imgUrl: "fsnjkfn1399.png",
    },
    {
        imgUrl: "kjdshfk123.png",
    },
]

const UploadLoading = forwardRef(({ filename = '', loading = false }, ref) => {
    const [percent, setPercent] = useState(0)
    const intervalRef = useRef(null);
    const svg = useMemo(() => {
        return filename.includes('.doc') ? wordSvg : pdfSvg
    }, [filename])

    useEffect(() => {
        if (intervalRef.current) {
            setPercent(100)
            clearInterval(intervalRef.current);
        }
        if (!loading) return 

        setPercent(0);
        intervalRef.current = setInterval(() => {
            setPercent((v) => {
                return v + 0.02 * (100 - v);
            });
        }, 100);
    }, [loading])

    return <div className="flex flex-center flex-col mb-12" style={{
        fontWeight: 400,
        fontSize: 12,
        color: '#2C2C2C',
        width: '100%',
        padding: '0 40px',
        boxSizing: 'border-box'
    }}>
        <div className="mb-6">{svg}</div>
        <div className="mb-6 flex justify-between">
            <div>{filename || '简历文件'}</div>
            <div style={{ color: '#B3B3B3' }} className="ml-18">{Math.floor(percent)}%</div>
        </div>
        <ProgressBar style={{ 
            width: '100%',
            '--fill-color': '#11BBA6'
        }} percent={percent} />
    </div>
})


const TabItemImport = ({ style }) => {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [filename, setFilename] = useState('')
    const uploadRef = useRef()

    const handleUpload = async (e) => {
        if(!isLogin()){
            store.dispatch(errorSlice.actions.setCode(1009))
            return
        }
        const file = e.target.files[0]
        const isLt50M = file.size / 1024 / 1024 < 50;
        // 定义允许的MIME类型
        const allowedTypes = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf',
        ];

        // 校验文件大小和MIME类型
        if (!allowedTypes.includes(file.type)) {
            showToast('请上传Word或者PDF格式的文件', 'fail', 2000);
            return false;
        }
        if (!isLt50M) {
            showToast('简历文件最大为50M！', 'fail', 2000);
            return false;
        }
        const formData = new FormData();
        console.log('file', file)
        formData.append('wordFile', file);
        try {
            // showToast('上传中...')
            setLoading(true)
            setFilename(file.name)
            const result = await uploadResumeByWord({
                body: formData
            })
            clearToast()
            if (result) {
                navigate(`/mobile/resume/preview?id=${result}`)
            }
        } catch (error) {
            console.log('erroer', error)
            // showToast(error?.msg || '上传失败', 'fail', 2000)
        } finally {
            // clearToast()
            setLoading(false)
            e.target.value = null;
        }
        return false;
    }

    return <TabItemImportStyled style={style}>
        <div className="tab-import flex-center flex-col" style={{ height: 115 }}>
            {
                loading ? <UploadLoading loading={loading} filename={filename} />
            :<div>
                <div className="flex-center mb-4">
                    {wordSvg}
                    <div className="ml-6">
                        {pdfSvg}
                    </div>
                    <span style={{ fontSize: 14, color: '#666' }} className="pl-6">点击上传简历</span>
                </div>
                <div style={{ fontSize: 12, color: '#999' }} 
                // 先注释
                // className="mb-24"
                >
                    支持格式：Word、PDF，文件大小＜50M
                </div>
            </div>
            }
            

            {/* <div onClick={() => setVisible(true)} className="flex-center" style={{ position: 'relative', zIndex: 2}}>
                <div className="video-tip">如何从其他平台导入？</div>
                <div className="flex-center">
                    {imgList.map(item => {
                        return <JzImage className="mr-6" src={item.imgUrl} style={{ width: 14, height: 14 }} />
                    })}
                </div>
            </div> */}
            {
                !loading && <input accept=".pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" type="file" onChange={handleUpload} />
            }
        </div>
        
        <VideoModal visible={visible} onClose={() => setVisible(false)} />
    </TabItemImportStyled>
}

export default withComponent(TabItemImport)