import FlexStyled from '@/styled/FlexStyled';
import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import Pointer from '@/styled/Pointer';
import config from '@/const/config';
import withComponent from '@/hoc/withComponent';
import {color} from "@/const/themeConfig";

const themeColor = config.themeColor


export const CancelButtonStyled = styled.div`
  padding: 0 27px;
  height: 28px;
  background: #ECEFF1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5C5C5C;
  line-height: 12px;
  cursor: pointer;
`;
export const SuccessButtonStyled = styled.div`
  padding: 0 27px;
  height: 28px;
  background: ${color};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 12px;
  cursor: pointer;

  :hover {
    background: ${color};
  }

  :active {
    background: ${color};
  }
`;
export const HeaderContainerStyled = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 20px;
  border-bottom: 1px solid rgb(224, 224, 224);
  position: relative;
`;


export const closeIcon = <div style={{ height: 16, width: 16, position: 'relative' }} dangerouslySetInnerHTML={{
  __html: `<svg width='100%' height='100%' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='position: absolute;top:0:left:0'>
    <g id='添加新页' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='添加新页--输入内容状态' transform='translate(-1870.000000, -98.000000)' fill='#999999' fill-rule='nonzero'>
            <g id='叉-关闭' transform='translate(1870.000000, 98.000000)'>
                <g id='编组-11' transform='translate(2.000000, 2.000000)'>
                    <polygon id='路径' points='0.0458891013 10.9363474 10.9110163 0 11.9541109 1.06365259 1.08810119 12'></polygon>
                    <polygon id='路径' points='10.9674952 11.9527067 0 1.12076145 1.03250478 0.0472932778 12 10.8783462'></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;

export const Footer = withComponent((props) => {
  const { showFooterLine, onCancel, showCancel, onSuccess, footerSuccessText = '确定' } = props;
  return <FlexStyled $flexShrink={0} $alignItems='center' $justifyContent='flex-end' style={{ height: 54, padding: '0 20px', borderTop: showFooterLine ? '1px solid #E0E0E0' : undefined }}>
    <FlexStyled $alignItems='center'>
      {showCancel && <CancelButtonStyled onClick={onCancel} style={{ marginRight: 8 }}>取消</CancelButtonStyled>}
      <SuccessButtonStyled onClick={onSuccess}>{footerSuccessText}</SuccessButtonStyled>
    </FlexStyled>
  </FlexStyled>;
});

const Box = (props) => {
  const { footerVisible, headerVisible } = props;
  const { leftTitle, title, onCancel, onSuccess, children, style, render, footerSuccessText = '确定', titleCenter = false, showFooterLine = false, showCancel = true } = props;
  return <FlexStyled onWheel={(e) => e.stopPropagation()} flexDirection='column' style={{ ...style, borderRadius: 10, pointerEvents: 'auto', background: 'white' }}>
    {headerVisible && <HeaderContainerStyled>
      <FlexStyled $alignItems='center' $justifyContent={titleCenter ? 'center' : undefined} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, fontSize: 14, fontWeight: 'bold', color: ' #2C2C2C', padding: '0 20px' }}>{title}</FlexStyled>
      <FlexStyled $alignItems='center' style={{ position: 'absolute', top: 0, bottom: 0, right: 20 }}>
        <Pointer onClick={onCancel}>{closeIcon}</Pointer>
      </FlexStyled>
      {
        !!leftTitle && titleCenter && <FlexStyled $alignItems='center' style={{ position: 'absolute', top: 0, bottom: 0, left: 20 }}>
          {leftTitle}
        </FlexStyled>
      }
    </HeaderContainerStyled>}
    <FlexStyled $flexGrow={1} style={{ height: 0, overflow: 'hidden', width: '100%' }}>
      <div style={{ height: '100%', overflow: 'hidden', width: '100%' }}>
        {render ? render() : children}
      </div>
    </FlexStyled>
    {
      footerVisible && <Footer showFooterLine={showFooterLine} onCancel={onCancel} showCancel={showCancel} onSuccess={onSuccess} footerSuccessText={footerSuccessText} />
    }
  </FlexStyled>;
};

export default (props) => {
  const { onCancel, visible, getContainer, zIndex, centered = true, onKeyDown, destroyOnClose = true, mask = true, footerVisible = true, maskClosable = true, headerVisible = true } = props;
  return <Modal width={'auto'}
                open={visible}
                footer={false}
                transitionName=''
                maskTransitionName=''
                getContainer={getContainer}
                destroyOnClose={destroyOnClose}
                mask={mask}
                zIndex={zIndex}
                maskClosable={maskClosable}
                centered={centered}
                onCancel={onCancel}
                onKeyDown={onKeyDown}
                onWheel={(e) => e.stopPropagation()}
                modalRender={() => <Box {...props} footerVisible={footerVisible} headerVisible={headerVisible} />}
  />;
}
