import ErrorModal from "@/components/ErrorModal"
import { Outlet } from "react-router-dom"

const GlobalLayout = () => {

    return <>
        <Outlet />
        <ErrorModal />
    </>
}

export default GlobalLayout