import withComponent from "@/hoc/withComponent";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import {createElement, useCallback, useRef, useState} from "react";
import {Popover} from "antd";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import withField from "@/components/resume/hoc/withField";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import {EDUCATIONAL_BACKGROUND_SLOT_ENUM, JOB_INTEREST_SLOT_ENUM} from "@/common/slot/slot";
import SalaryController from "@/components/resume/components/SalaryController";
import {Module} from "@/common/module/module";
import JobStatusController, {
    DIMISSION_SEARCH_JOB,
    getTextByJobArrivalTime, getTextByJobStatus, NO_SEARCH_JOB
} from "@/components/resume/components/JobStatusController";
import useSelectedField from "@/components/resume/hooks/useSelectedField";


export default withComponent(withField((props) => {
    const {tagName, style, className, children = [],fieldRef} = props;
    const [visible, setVisible] = useState(false);
    useSelectedField({ visible, ref: fieldRef, type: 'select' })
    const dispatch = useDispatch();
    const jobStatus = useSelector(state => state.resumeData[Module.JOB_INTEREST].data[JOB_INTEREST_SLOT_ENUM.JOB_STATUS] || DIMISSION_SEARCH_JOB);
    const jobArrivalTime = useSelector(state => state.resumeData[Module.JOB_INTEREST].data.jobArrivalTime);
    const onChangeJobStatus = useCallback((v) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: `${[Module.JOB_INTEREST]}.data.${[JOB_INTEREST_SLOT_ENUM.JOB_STATUS]}`, value: v
        }))
    }, []);
    const onChangeJobArrivalTime = useCallback((v) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: `${[Module.JOB_INTEREST]}.data.jobArrivalTime`, value: v
        }))
    }, [])
    let text;
    if (jobStatus === undefined) {
        text = "求职状态"
    } else if (jobStatus) {
        text = getTextByJobStatus(jobStatus)
    }
    if (!jobStatus && jobArrivalTime) {
        text = getTextByJobArrivalTime(jobArrivalTime)
    }
    if (jobStatus && jobArrivalTime && jobStatus !== NO_SEARCH_JOB) {
        text = getTextByJobStatus(jobStatus) + ' - ' + getTextByJobArrivalTime(jobArrivalTime)
    }

    return <Popover
        content={<JobStatusController defaultJobStatus={jobStatus} defaultJobArrivalTime={jobArrivalTime}
                                      onChangeJobArrivalTime={onChangeJobArrivalTime}
                                      onChangeJobStatus={onChangeJobStatus}/>}
        destroyTooltipOnHide
        overlayClassName="ant-popover-custom"
        autoAdjustOverflow={false}
        trigger="click"
        transitionName=""
        onOpenChange={setVisible}
        getPopupContainer={null}
        placement="bottom"
        open={visible}>
        {createElement(tagName, {style,ref:fieldRef}, text)}
    </Popover>
}))
