import {createSlice} from '@reduxjs/toolkit';
import initState from '../initState';
import setNestedProperty from "@/utils/setNestedProperty";
import moment from "moment";


export default createSlice({
    name: 'resume',
    initialState: initState.resume,
    reducers: {
        set(state, {payload}) {
            return payload
        },
        clear() {
            return null
        },
        changeName(state, {payload}) {
            const {value} = payload;
            state.name = value;
        },
        changeUpdateDate(state, {payload = {}}) {
            const {value = moment.utc(new Date().toString())} = payload;
            state.updateDate = value;
        },
        setThemeIndex(state, {payload}){
            state.themeIndex = payload
        },
        isChangeColor(state, {payload}) {
            state.isChangeColor = payload
        },
        setFontSize(state, {payload}) {
            state.fontSize = payload
        },
        setFontFamily(state, {payload}) {
            state.fontFamily = payload
        },
        setPageMargin(state, {payload}) {
            state.pageMargin = payload
        },
        setModuleMargin(state, {payload}) {
            state.moduleMargin = payload
        },
    }
});
