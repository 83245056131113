import styled from "styled-components"


export const ContentItem = styled.div`
    border-bottom: 1px solid #F4F4F4;
    padding-bottom: 20px;
    padding-top: 20px;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border: none;
        padding-bottom: 0;
    }
`

export const TargetItem = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: #555555;
    margin-bottom: 14px;

`

export const TargetItemDate = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`

export const TargetItemDetail = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: #555555;
`