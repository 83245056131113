import withComponent from "@/hoc/withComponent"
import styled from "styled-components"
import { headerFeat1Svg, headerFeat2Svg, headerFeat3Svg, headerFeat4Svg, headerInput1Svg, headerInput2Svg } from "../svg"
import JzImage from "@/components/JzImage"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setConfigParams } from "../../TemplateList/indexSlice"
import { useState } from "react"

const HeaderStyled = styled.div`
    background: linear-gradient(180deg, #27DFD2 0%, #11BBA6 100%);
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    padding-top: 68px;
    position: relative;
    .logo-img {
        position: absolute;
        width: 94px;
        height: 24px;
        top: 12px;
        left: 17px;
    }
    .header-h1 {
        font-size: 23px;
        color: #FFFFFF;
        margin-bottom: 22px;
        text-align: center;
    }
    .header-p {
        font-size: 12px;
        color: #FFFFFF;
        margin-bottom: 30px;
        text-align: center;
    }
    .header-search {
        overflow: hidden;
        &-left {
            border-top-left-radius: 23px;
            border-bottom-left-radius: 23px;
            height: 100%;
            background-color: #FFFFFF;
            input {
                height: 100%;
                border: none;
                padding: 0;
                outline: none;
                background-color: transparent;
            }
        }
        &-right {
            border-top-right-radius: 23px;
            border-bottom-right-radius: 23px;
            background: #01B6A1;
            width: 100%;
            height: 100%;
            border: 2px solid #A2E4DC;
            border-left: none;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 14px;
            box-sizing: border-box;
        }
    }
    .header-hot {
        display: flex;
        margin-left: 27px;
        margin-bottom: 25px;
        span {
            font-size: 12px;
            color: rgba(255,255,255,0.8);
            margin-right: 12px;
        }
    }
    .header-feat {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        font-size: 14px;
        color: #FFFFFF;
        background: #009B89;
        padding: 18px 8px;
    }
    .header-feat-p {
        color: rgba(255,255,255,0.8); 
        font-size: 12;
        transform: scale(0.75);
        transform-origin: center;
        text-align: center;
    }
`

const featList = [
    {
        svg: headerFeat1Svg,
        title: '自动排版',
        p: '输入内容、自动排版生成简历',
    },
    {
        svg: headerFeat2Svg,
        title: '专业规范',
        p: '知名HR推荐的简历',
    },
    {
        svg: headerFeat3Svg,
        title: '模板切换',
        p: '海量模板任意切换',
    },
    {
        svg: headerFeat4Svg,
        title: '云端存储',
        p: 'PC、小程序等随时下载',
    },
]

const Header = () => {
    const [value, setValue] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onSearch = (value) => {
        dispatch(setConfigParams({ type: 'searchWord', value }))
        navigate(`/mobile/template?jz_origin=promo`)
    }

    const handleClickWord = (word) => {
        onSearch(word)
    }

    const handleSearch = () => {
        if (value) {
            onSearch(value)
        }
    }

    const handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            handleSearch()
        }
    }

    return <HeaderStyled>
        <JzImage className="logo-img" src="sldknfijxcbnvjhbu.png" />

        <div className="header-h1">高效智能的在线简历编辑神器</div>
        <div className="header-p">一键应用专业模版，轻松斩获高薪offer</div>

        <div className="px-14 pb-14"> 
            <div className="flex-center header-search" style={{ height: 42 }}>
                <div className="flex items-center header-search-left" style={{ flex: 10, paddingLeft: 12 }}>
                    {headerInput1Svg}
                    <input 
                        value={value} 
                        onChange={e => setValue(e.target.value)}
                        onKeyUp={handleKeyUp}
                        className="ml-4" 
                        placeholder="需要什么样的模板？"
                    />
                </div>
                <div onClick={handleSearch} className="flex items-center header-search-right" style={{ flex: 7, paddingLeft: 14 }}>
                    {headerInput2Svg}
                    <span style={{ paddingLeft: 7 }}>免费简历制作</span>
                </div>
            </div>
        </div>

        <div className="header-hot">
            <span style={{ color: '#FFFFFF'}}>热门搜索</span>
            {
                ["Java","Python","实习简历",].map(item => {
                    return <span key={item} onClick={() => handleClickWord(item)}>{item}</span>
                })
            }
        </div>

        <div className="header-feat">
            {featList.map((item, index) => {
                return <div className="flex-center justify-start flex-col" key={index}>
                    <div className="flex-center mb-8" >
                        {item.svg}
                        <div className="pl-4">{item.title}</div>
                    </div>
                    <div className="header-feat-p" style={{ color: 'rgba(255,255,255,0.8)', fontSize: 12, textAlign: 'center' }}>
                        {item.p}
                    </div>
                </div>
            })}
        </div>
    </HeaderStyled>
}

export default withComponent(Header)