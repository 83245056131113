import isLogin from "@/common/isLogin"
import { Button } from "antd"
import styled from "styled-components"


const LoginTipStyled = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 71px;
    background: rgba(0,0,0,0.6);
    z-index: 100;


    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
`
// 未登录时提示用户登录
const LoginTip = ({ onLogin }) => {

    const handleLogin = () => {
        onLogin && onLogin()
    }

    return !isLogin() ? <LoginTipStyled>
        <p>当前处于预览状态，简历数据不会被保存，请登录后制作</p>
        <Button style={{ marginLeft: 30 }} type="primary" onClick={handleLogin}>立即登录</Button>
    </LoginTipStyled> : null
    
}

export default LoginTip