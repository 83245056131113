import {getDataFromTemplate, getJobTypesAll, getResumeDataById} from "@/http/api";
import useQuery from "@/hooks/useQuery";
import styled from "styled-components"
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Resume from "@/components/resume/index";
import {createStore} from "@/components/resume/redux/store";
import useResumeEdit from "@/hooks/useResumeEdit";
import Version from "@/common/resume/version";
import dispatchResumeData from "@/common/resume/dispatchResumeData";
import strictStringify from "@/common/strictStringify";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const ResumeScreenShot = () => {
    const query = useQuery();
    const {id, create_flag, test_data_flag} = query;
    const resumeRef = useRef();
    const instance = useRef({
        templateId: undefined,
        name: undefined,
        resumeRef,
        version: new Version(),
    }).current;
    const [width, setWidth] = useState(undefined);
    const resumeStore = useMemo(() => createStore({
        devTools: __PRO__ ? false : {name: "简历"},
    }), []);
    const {onChange, onInit} = useResumeEdit(resumeStore);
    const navigate = useNavigate();

    const fetchData = async () => {
        let resumeId = id;
        if (create_flag === "1") {
            const {id: resId} = await getDataFromTemplate({query: {resumeId: id}}).catch((err) => {
                // message.error(err.msg)
                // setTimeout(() => {
                //     navigate("/workspace")
                //     message.destroy()
                // }, 2000)
            });
            if (!resId) {
                return
            }
            resumeId = resId;
            const query = {id: resumeId}
            navigate(location.pathname + strictStringify(query), {replace: true});
        }
        const resumeData = await getResumeDataById(resumeId).catch((err) => {
            message.error(err.msg)
        })
        if (!resumeData) return;
        dispatchResumeData(resumeStore.dispatch, resumeData);
        instance.templateId = resumeData.template.id;
        instance.name = resumeData.resume.name;
        setWidth(720);
        console.log('resumeData', resumeData)
    };

    useEffect(() => {
        fetchData()
    }, [])
    
    return <div>
        {
            width && 
            <div className="ll-cv-screenshot">
                <Resume resumeRef={resumeRef}
                    version={instance.version}
                    // getModuleContentToolContainer={() => bodyRef.current}
                    onInit={onInit}
                    openEdit 
                    width={width} 
                    store={resumeStore}
                    onChange={onChange} />
            </div>
        }
    </div>
}

export default ResumeScreenShot

