import withComponent from "@/hoc/withComponent";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import {createElement, useCallback, useRef, useState} from "react";
import {Popover} from "antd";
import Date from "@/components/resume/components/Date";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import getBaeInfoPathBySlot from "@/common/config/getBaeInfoPathBySlot";
import getNestedProperty from "@/utils/getNestedProperty";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import withField from "@/components/resume/hoc/withField";


export default withComponent(withField((props) => {
    const {tagName, style, className, children = []} = props;
    const [visible, setVisible] = useState(false);
    const ref = useRef();
    const dispatch = useDispatch();
    const birthdayYear = useSelector(state => getNestedProperty(state.resumeData, getBaeInfoPathBySlot("生日年份")));
    const birthdayMonth = useSelector(state => getNestedProperty(state.resumeData, getBaeInfoPathBySlot("生日月份")));
    const birthdayDay = useSelector(state => getNestedProperty(state.resumeData, getBaeInfoPathBySlot("生日日期")));
    const getPluginContainer = useCallback(() => {
        return ref.current
    }, []);
    const configRender = useConfigRender();
    const element = createElement(tagName, {style, className}, children.map((config) => configRender(config)));
    const onChange = useCallback((year, month) => {
        setVisible(false);
        dispatch(resumeDataSlice.actions.changeValueByPath({path: getBaeInfoPathBySlot('生日年份'), value: year}));
        dispatch(resumeDataSlice.actions.changeValueByPath({path: getBaeInfoPathBySlot('生日月份'), value: month}));
    }, []);

    return <Popover content={<Date year={birthdayYear} month={birthdayMonth} data openMonth onChange={onChange}/>}
                    destroyTooltipOnHide
                    overlayClassName="ant-popover-custom"
                    autoAdjustOverflow={false}
                    trigger="click"
                    transitionName=""
                    onOpenChange={setVisible}
                    getPopupContainer={getPluginContainer}
                    placement="bottom"
                    open={visible}
    >
        {element}
    </Popover>
}))
