import Modal from "@/components/Modal";
import {Module} from "@/common/module/module";
import {BASE_FIELD_ENUM} from "@/common/field/field";
import {BASE_Slot} from "@/common/slot/slot";
import {autoOnePage2, autoOnePageLevels} from "@/const/config";

const initState = {
    resume: null,
    resumeData: null,
    template: null,
    version: {
        current: null
    },
    focus: {
        moduleId: null,
        moduleContentIndex: null,
        filedId: null
    },
    loading: false,
    hover: {
        moduleId: null
    },
    pagination: {
        pageCount:undefined,
        openAutoOnePage: false,
        openAutoPagination: true,
        openHintPagination: false,
        hintPaginationLineVisible: false,
        // onePageLevel: autoOnePageLevels.autoOnePage1,
        onePageLevel: null,
        offsets: null,
        hidePageIndex: -1,
    },
    interview: {
        list: [],
        jdList: [],
        jdListWithResume: [],
        selectJdId: '',
        isContentModified: false,
        pageStatus: {
            status: 1,
            id: null,
            jdId: null,
            // 练面试 页数
            pageIndex:0,
            // 模板生成
            isTemplate: false
        }  // 1->代表面试题生成前状态 2->代表面试题生成后状态
    },
    aiDecision: [],
    theme: null,
    myResume:{}
};
export default initState;
