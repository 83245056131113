import styled from "styled-components"
import { copyResumeOfUser, deleteResumeOfUser, editResumeName, listResumeUser } from "@/http/api";
import { icon1, icon2, icon3 } from "./icon"
import {
    IconPointerStyled,
    PreviewFooterStyled,
    PreviewFooterNameStyled,
    PreviewFooterDateStyled,
    PreviewFooterTextBoxStyled,
    PreviewFooterIconBoxStyled,
} from "./styled"
import { useCallback, useEffect, useRef, useState } from "react"
import { Tooltip, message } from "antd";

const InputStyled = styled.input`
    border: none;
    outline: none;
    &:disabled {
        background-color: transparent;
        color: inherit;
    }
`

const PreviewFooter = ({ item, onChange }) => {
    const [disabled, setDisabled] = useState(true)
    const [value, setValue] = useState(item.name)
    const ref = useRef(null)

    useEffect(() => {
        if (!disabled) {
            ref.current.focus()
        }
    }, [disabled])

    const onClickDelete = (item) => {
        deleteResumeOfUser({
            resumePageId: item.id
        }).then(res => {
            message.success({ content: "删除成功" })
            onChange && onChange()
        }).catch(err => {
            message.error({ content: "删除失败" })
        })
    }
    const onClickCopy = (item) => {
        copyResumeOfUser({
            resumePageId: item.id
        }).then(res => {
            message.success({ content: "复制成功" })
            onChange && onChange()
        }).catch(err => {
            message.error({ content: "复制失败" })
        })
    }
    
    const onClickEditName = (item) => {
        setDisabled(false)
    }

    const handleEditName = (e, item) => {
        const value = e.target.value
        editResumeName({
            resumePageId: item.id,
            name: value
        }).then(res => {
            message.success({ content: "修改成功" })
            setValue(value)
            setDisabled(true)
        }).catch(err => {
            message.error({ content: "修改失败" })
        })
    }

    return <PreviewFooterStyled>
        <PreviewFooterTextBoxStyled>
            <PreviewFooterNameStyled>
                {
                    disabled ? <span>
                        {value}
                    </span> :
                        <InputStyled
                            ref={ref}
                            defaultValue={value}
                            disabled={disabled}
                            onBlur={(e) => handleEditName(e, item)}
                        />
                }
            </PreviewFooterNameStyled>
            <PreviewFooterDateStyled>{item.updateTime}</PreviewFooterDateStyled>
        </PreviewFooterTextBoxStyled>
        <PreviewFooterIconBoxStyled>
            <Tooltip title="删除">
                <IconPointerStyled $delete onClick={() => onClickDelete(item)}>{icon1}</IconPointerStyled>
            </Tooltip>
            <Tooltip title="复制">
                <IconPointerStyled onClick={() => onClickCopy(item)}>{icon2}</IconPointerStyled>
            </Tooltip>
            <Tooltip title="重命名">
                <IconPointerStyled onClick={() => onClickEditName(item)}>{icon3}</IconPointerStyled>
            </Tooltip>
        </PreviewFooterIconBoxStyled>
    </PreviewFooterStyled>
}

export default PreviewFooter