import {Module} from "@/common/module/module";
import {
    CAMPUS_ACTIVITIES_SLOT_ENUM,
    EDUCATIONAL_BACKGROUND_SLOT_ENUM,
    INTERNSHIP_EXPERIENCE_SLOT_ENUM,
    PROJECT_EXPERIENCE_SLOT_ENUM,
    WORK_EXPERIENCE_SLOT_ENUM
} from "@/common/slot/slot";


export default (block_name, option) => {
    switch (block_name) {
        case Module.EDUCATIONAL_BACKGROUND: {
            return {
            }
        }
        case Module.WORK_EXPERIENCE: {
            return {
            }
        }
        case Module.PORTFOLIO: {
            if (option.type === "link") {
                return {title: "", link: ""}
            } else if (option.type === "image") {
                return {src: ""}
            }
            return
        }
        case Module.PROJECT_EXPERIENCE: {
            return {
            }
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            return {
            }
        }
        case Module.CAMPUS_ACTIVITIES: {
            return {
            }
        }
    }
}
