import withComponent from "@/hoc/withComponent";
import styled from "styled-components"
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import useForceUpdate from "@/hooks/useForceUpdate";
import classNames from "classnames";


const ContainerStyled = styled.div`
  width: 210px;
  height: 190px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  overflow: hidden;
`
const TagBoxStyled = styled.div`
  display: flex;
`
const TagItemStyled = styled.div`
  width: 105px;
  height: 34px;
  border-radius: 4px 0px 0px 0px;
  line-height: 34px;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #292929;
  cursor: pointer;
  background: ${props => !props.active ? "#F6F6F6" : undefined};
  color: ${props => props.active ? '#11BBA6' : '#333333'} ;
  &:hover {
    color: #11BBA6;
  }
`;
const LeftIconStyled = styled.div`
  position: absolute;
  top: 16px;
  left: 12px;
  cursor: pointer;
`;
const RightIconStyled = styled.div`
  position: absolute;
  top: 16px;
  right: 12px;
  cursor: pointer;
`;
const DateBoxStyled = styled.div`
  padding-top: 4px;
`
const MonthItemBoxStyled = styled.div`
  margin-top: 10px;
  width: 25%;
  padding: 0 6px;
  height: 24px;
  display: inline-block;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
  .month-item {
    background: ${props => props.$isActive ? '#11BBA6' : 'transparent'};
    color: ${props => props.$isActive ? 'white' : '#333333'};
  }
  

  .month-item:hover {
    cursor: pointer;
    background: #11BBA6;
    color: white;
    transition: all 100ms;
  }
`
const MonthItemTextStyled = styled.div`

`
const ContentContainerStyled = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 210px;
`
const TitleStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 12px;
  text-align: center;
  margin-top: 20px;
`
const ContentStyled = styled.div`
  display: flex;
  transition: transform 0.1s;
  transform: ${props => props.$current === "start" ? 'translateX(0%)' : 'translateX(-100%)'};
`


const icon = <div dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="教育背景、工作经历、校园经历、项目经历、实习经历" transform="translate(-231.000000, -1142.000000)" fill="#999999" fill-rule="nonzero">
            <g id="编组-11备份-2" transform="translate(219.000000, 1094.000000)">
                <g id="更多-绿备份-3" transform="translate(12.000000, 48.000000)">
                    <path d="M8.46442045,11 C8.32727476,11 8.19010987,10.9477436 8.0854698,10.8428844 L3.1569745,6.37984753 C2.94767517,6.17047551 2.94767517,5.83044319 3.1569745,5.62070561 L8.08546981,1.15730318 C8.29476915,0.947565605 8.63372617,0.947565605 8.8430255,1.15730318 C9.05232483,1.3666752 9.05232483,1.70670752 8.8430255,1.9164451 L4.29348083,6.00008418 L8.84302549,10.0833769 C9.05232482,10.2931145 9.05232482,10.6327813 8.84302549,10.8425188 C8.73873103,10.9477436 8.60156614,11 8.46442045,11 Z" id="-"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`
}} />
const icon2 = <div dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: rotate(180deg)">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="教育背景、工作经历、校园经历、项目经历、实习经历" transform="translate(-231.000000, -1142.000000)" fill="#999999" fill-rule="nonzero">
            <g id="编组-11备份-2" transform="translate(219.000000, 1094.000000)">
                <g id="更多-绿备份-3" transform="translate(12.000000, 48.000000)">
                    <path d="M8.46442045,11 C8.32727476,11 8.19010987,10.9477436 8.0854698,10.8428844 L3.1569745,6.37984753 C2.94767517,6.17047551 2.94767517,5.83044319 3.1569745,5.62070561 L8.08546981,1.15730318 C8.29476915,0.947565605 8.63372617,0.947565605 8.8430255,1.15730318 C9.05232483,1.3666752 9.05232483,1.70670752 8.8430255,1.9164451 L4.29348083,6.00008418 L8.84302549,10.0833769 C9.05232482,10.2931145 9.05232482,10.6327813 8.84302549,10.8425188 C8.73873103,10.9477436 8.60156614,11 8.46442045,11 Z" id="-"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`
}} />

export const endYear = Number(new Date().getFullYear()) + 3;
const startYear = endYear - 59;
const baseLength = 10;
const yearRangeList = Array.from({ length: (endYear - startYear) / baseLength + 1 }).map((item, index) => {
    return Array.from({ length: baseLength }).map((ite, inde) => startYear + index * baseLength + inde)
});
const monthRangeList = [
    { key: 1, value: '一月' },
    { key: 2, value: '二月' },
    { key: 3, value: '三月' },
    { key: 4, value: '四月' },
    { key: 5, value: '五月' },
    { key: 6, value: '六月' },
    { key: 7, value: '七月' },
    { key: 8, value: '八月' },
    { key: 9, value: '九月' },
    { key: 10, value: '十月' },
    { key: 11, value: '十一月' },
    { key: 12, value: '十二月' }
];


const Content = (props) => {
    const { year, month, contentVisibleType, yearIndex, type } = props;
    const { onClickLeft, onClickRight, onClickTitle, onClickYearItem, onClickMonthItem } = props;
    const leftButtonVisible = useMemo(() => {
        if (contentVisibleType === "month") {
            return false
        } else if (contentVisibleType === "year") {
            if (yearIndex > 0) {
                return true
            }
        }
    }, [yearIndex, contentVisibleType])
    const rightButtonVisible = useMemo(() => {
        if (contentVisibleType === "month") {
            return false
        } else {
            return yearIndex < yearRangeList.length - 1;
        }
    }, [yearIndex, contentVisibleType])
    const title = useMemo(() => {
        if (contentVisibleType === "year") {
            return `${yearRangeList[yearIndex][0]} - ${yearRangeList[yearIndex][yearRangeList[yearIndex].length - 1]}`
        } else if (contentVisibleType === "month") {
            return year
        }
    }, [contentVisibleType, yearIndex, year]);

    return <ContentContainerStyled>
        {
            leftButtonVisible && <LeftIconStyled onClick={onClickLeft}>{icon}</LeftIconStyled>
        }
        {
            rightButtonVisible && <RightIconStyled onClick={onClickRight}>{icon2}</RightIconStyled>
        }
        <TitleStyled onClick={onClickTitle}
            style={{ cursor: contentVisibleType === "month" ? 'pointer' : undefined }}>{title}</TitleStyled>
        {
            contentVisibleType === "year" && <DateBoxStyled>
                {
                    yearRangeList[yearIndex].map((yearItem) => <MonthItemBoxStyled $isActive={year === yearItem}>
                        <MonthItemTextStyled className="month-item" onClick={() => onClickYearItem(yearItem)}>{(yearItem === endYear && type === 'end') ? '至今' : yearItem}</MonthItemTextStyled>
                    </MonthItemBoxStyled>)
                }
            </DateBoxStyled>
        }
        {
            contentVisibleType === "month" && <DateBoxStyled>
                {
                    monthRangeList.map((monthItem) => <MonthItemBoxStyled $isActive={monthItem.key === month}>
                        <MonthItemTextStyled className="month-item"
                            onClick={() => onClickMonthItem(monthItem.key)}>{monthItem.value}</MonthItemTextStyled>
                    </MonthItemBoxStyled>)
                }
            </DateBoxStyled>
        }


    </ContentContainerStyled>
}
export default withComponent((props) => {
    const { startDate = "2019.02.30", endDate = "2023.02.20", onSuccess } = props;
    const [current, setCurrent] = useState("start"); //start end
    const that = useRef({
        contentVisibleType: "month",// year month
        startMoment: moment(startDate),
        startYear: moment(startDate).get("year"),
        startMonth: moment(startDate).get("month") + 1,
        endMoment: moment(endDate),
        endYear: moment(endDate).get("year"),
        endMonth: moment(endDate).get("month") + 1,
        yearIndex: 0
    }).current;
    const [, update] = useForceUpdate();
    const setYearIndex = useCallback((index) => {
        that.yearIndex = index;
        update()
    }, []);
    useEffect(() => {
        // 如果年份是最后一年，判断为是“至今”
        if (moment(endDate).get("year") === endYear && current === 'end') {
            that.contentVisibleType = "year";
            update();
            updateCurrentYearIndex()
        }
    }, [endDate, current])
    const updateCurrentYearIndex = () => {
        let currentYear = that.endYear
        if (current === "start") {
            currentYear = that.startYear
        }
        const index = yearRangeList.findIndex((arr) => !!arr.find((year) => year === currentYear))
        if (index !== -1) {
            that.yearIndex = index;
            update()
        }
    }
    const onClickTagItem = (s) => {
        that.contentVisibleType = "month";
        setCurrent(s);
    }
    const onClickTitle = () => {
        if (that.contentVisibleType === "month") {
            that.contentVisibleType = "year";
            update();
            updateCurrentYearIndex()
        }
    };
    const onClickRight = () => {
        if (that.contentVisibleType === "year") {
            setYearIndex(that.yearIndex + 1);
        }
    }
    const onClickLeft = () => {
        if (that.contentVisibleType === "year") {
            setYearIndex(that.yearIndex - 1);
        }
    };
    const onClickYearItem = (year, type) => {
        if (type === "start") {
            that.startYear = year;
            that.startMoment.set("year", year)
        } else {
            that.endYear = year
            that.endMoment.set("year", year)
        }
        // 
        if (year === endYear) {
            onSuccess?.({
                start: that.startMoment.utc().toJSON(),
                end: that.endMoment.utc().toJSON(),
            })
        } else {
            that.contentVisibleType = "month";    
        }
        update();
    };
    const onClickMonthItem = (month, type) => {
        if (type === "start") {
            that.startMonth = month - 1;
            that.startMoment.set("month", month - 1)
        } else {
            that.endMonth = month - 1;
            that.endMoment.set("month", month - 1)
        }
        onSuccess?.({
            start: that.startMoment.utc().toJSON(),
            end: that.endMoment.utc().toJSON(),
        })
        update();
    }

    return <ContainerStyled>
        <TagBoxStyled>
            <TagItemStyled
                onClick={() => onClickTagItem("start")}
                active={current === "start"}
            >
                开始
            </TagItemStyled>
            <TagItemStyled
                onClick={() => onClickTagItem("end")}
                active={current === "end"}
            >
                结束
            </TagItemStyled>
        </TagBoxStyled>
        <ContentStyled $current={current}>
            <Content
                type="start"
                year={that.startYear}
                month={that.startMonth}
                onClickYearItem={(e) => onClickYearItem(e, 'start')}
                onClickMonthItem={(e) => onClickMonthItem(e, 'start')}
                onClickLeft={onClickLeft}
                onClickRight={onClickRight}
                onClickTitle={onClickTitle}
                yearIndex={that.yearIndex}
                setYearIndex={setYearIndex}
                contentVisibleType={that.contentVisibleType}
            />
            <Content
                type="end"
                year={that.endYear}
                month={that.endMonth}
                onClickYearItem={(e) => onClickYearItem(e, 'end')}
                onClickMonthItem={(e) => onClickMonthItem(e, 'end')}
                onClickLeft={onClickLeft}
                onClickRight={onClickRight}
                onClickTitle={onClickTitle}
                yearIndex={that.yearIndex}
                setYearIndex={setYearIndex}
                contentVisibleType={that.contentVisibleType}
            />
        </ContentStyled>
    </ContainerStyled>
})
