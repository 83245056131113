export default async function downloadBlob(blob, fileName) {
  // MS IE specific blob saving procedure
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    // For other browsers: create a link and trigger a download
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = url;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up the DOM
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  }
}