import { useMemo } from 'react'
import { useLocation } from 'react-router-dom';

function useQueryParam(param) {
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    
    return query.get(param);
}

export default useQueryParam