import HeaderBoard from "@/pages/Home/Header/HeaderBoard";
import Filter from "@/pages/Home/Filter"
import {useCallback, useEffect, useRef, useState} from "react";
import useForceUpdate from "../../hooks/useForceUpdate";
import ResumePreview from "./ResumePreview"
import styled from "styled-components"
import {fetchResumeSearch} from "@/http/api";
import HeaderFixed from "@/pages/Home/Header/HeaderFixed";
import Pagination_c from "@/pages/Home/Pagination_c";
import useQuery from "@/hooks/useQuery";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import ListLoading from "@/pages/Home/ListLoading";
import {message} from "antd"
import getQuery from "@/utils/getQuery";
import strictStringify from "@/common/strictStringify";
import Footer from "@/components/Footer";
import { getConfigNameByTypeAndIndex } from "@/pages/Home/config";
import FilterListBar from "@/pages/Home/FilterListBar";
import AdminModal from "./AdminTemplateModal";

const listBoxWidth = 1504;
const minListBoxWidth = 1180;
const templateContainerMarginRight = 18;

const PageContainerStyled = styled.div`
  min-height: 100vh;
  background: #F7F7F7;
`
const ListContainerPositionStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`
const ListContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > :not(:nth-child(${props => props.containerCount}n)) {
    margin-right: ${templateContainerMarginRight}px;
  }
`

export const filterCurrentToName = (filterCurrent) => {
    const [styleIndex = '全部', languageIndex = '全部', professionIndex = '全部', useIndex = '全部', positionIndex = '全部'] = filterCurrent
    const style = getConfigNameByTypeAndIndex('风格', styleIndex)
    const language = getConfigNameByTypeAndIndex('语言', languageIndex)
    const profession = getConfigNameByTypeAndIndex('行业', professionIndex)
    const use = getConfigNameByTypeAndIndex('用途', useIndex)
    const position = getConfigNameByTypeAndIndex('职位', positionIndex)
    return {
        style,
        profession,
        language,
        use,
        position,
    }
}


export const Admin = () => {
    const mainContentRef = useRef();
    const query = useQuery();
    const that = useRef({
        initListLoading: false,
        searchValue: '',
        filterCurrent: [],
        list: [],
        fixedHeaderVisible: false,
        current: undefined,  //当前页数
        defaultCurrent: 1,   //默认当前页
        defaultPageSize: 20,//默认单页大小
        pageSize: 20, //单页大小
        total: undefined,    //总数
        listCancelToken: undefined,
        listLoading: false,
    }).current;
    const navigate = useNavigate();
    const location = useLocation();
    const [, update] = useForceUpdate()
    const fetchList = useCallback(async (current) => {
        if (that.listCancelToken) {
            that.listCancelToken.cancel()
        }
        let source = axios.CancelToken.source();
        that.listCancelToken = source;
        that.listLoading = true;
        update()
        const { 
            style,
            language,
            profession,
            use,
            position,
        } = filterCurrentToName(that.filterCurrent)
        const res = await fetchResumeSearch({
            query: {
                page: current, 
                limit: that.pageSize,
                position, // 职位
                language,
                profession,
                searchWord: that.searchValue, // 用户搜索词
                style, // 风格
                use, // 用途
            },
            cancelToken: source
        }).finally(() => {
            that.listCancelToken = undefined;
            that.listLoading = false;
        });
        const {dataList, currPage, count} = res;
        that.current = currPage;
        that.total = count;
        that.list = dataList;
        update()
    }, []);
    const [visible, setVisible] = useState()
    const [selectItem, setSelectItem] = useState()
    const getFixedElement = useCallback(() => {
        return mainContentRef.current
    }, []);
    const fetchListByQuery = useCallback(async () => {
        let hide;
        if (that.list?.length > 0) {
            hide = message.loading('', 0);
        } else {
            that.initListLoading = true;
            update();
        }
        // 确保用户切页的时候，滚动到搜索区，初次加载时滚动到顶部
        if (that.list?.length > 0) {
            window.scrollTo({top: 303})
        } else {
            setTimeout(() => {
                if (location?.state?.scroll) {
                    window.scrollTo({top: 303})
                } else {
                    window.scrollTo({top: 0})    
                }
            }, 100)
        }
        await fetchList(query.current || that.defaultCurrent).finally(() => {
            that.initListLoading = false;
            hide?.()
            update();
        });
        
    }, [query])
    const onChangeCurrent = useCallback((current) => {
        const query = getQuery();
        query.current = current;
        navigate(location.pathname + strictStringify(query), {replace: true})
    }, [])
    const [subText,setSubText] = useState('')
    const changePosition = async(v)=>{
        
        if (that.listCancelToken) {
            that.listCancelToken.cancel()
        }
        let source = axios.CancelToken.source();
        that.listCancelToken = source;
        that.listLoading = true;
        setSubText(v)
        const { 
            style,
            language,
            profession,
            use,
            position,
        } = filterCurrentToName(that.filterCurrent)
        const res = await fetchResumeSearch({
            query: {
                page: that.current, 
                limit: that.pageSize,
                position:v, // 职位
                language,
                profession,
                searchWord: that.searchValue, // 用户搜索词
                style, // 风格
                use, // 用途
            },
            cancelToken: source
        }).finally(() => {
            that.listCancelToken = undefined;
            that.listLoading = false;
        });
        const {dataList, count} = res;
        that.total = count;
        that.list = dataList;
        update()
    }
    const changeFilterCurrent = useCallback((groundIndex, index) => {
        setSubText()
        that.filterCurrent[groundIndex] = index;
        that.filterCurrent = [...that.filterCurrent];
        update()
    }, []);
    const handleChangeSearchValue = (e) => {
        const value = e.target.value
        that.searchValue = value
        update()
    }
    const handleSearch = () => {
        fetchListByQuery()
    }
    useEffect(() => {
        fetchListByQuery()
    }, [query.current, that.filterCurrent])
    useEffect(() => {
        const handler = () => {
            const element = getFixedElement?.();
            if (document.documentElement.scrollTop >= element?.offsetTop - 60) {
                if (!that.show) {
                    that.fixedHeaderVisible = true;
                    update();
                }
            } else {
                if (that.fixedHeaderVisible) {
                    that.fixedHeaderVisible = false;
                    update();
                }
            }
        };
        window?.addEventListener('scroll', handler);
        return () => {
            window?.removeEventListener('scroll', handler);
        };
    }, []);

    const handleClick = (item) => {
        setSelectItem(item)
        setVisible(true)
    }
    const handleSuccess = () => {
        setVisible(false)
        fetchListByQuery()
    }
    const handleCancel = () => {
        setVisible(false)
    }

    const isMin = window.innerWidth < 1600;
    const listBoxWidth$1 = isMin ? minListBoxWidth : listBoxWidth;
    const containerCount = isMin ? 4 : 5;
    const containerWidth = (listBoxWidth$1 - (containerCount - 1) * templateContainerMarginRight) / containerCount;
    return <PageContainerStyled>
        {
            that.fixedHeaderVisible && <HeaderFixed value={that.searchValue} onChange={handleChangeSearchValue} onSearch={handleSearch} />
        }
        <HeaderBoard value={that.searchValue} onChange={handleChangeSearchValue} onSearch={handleSearch} />
        <div ref={mainContentRef} style={{paddingTop: 20, paddingBottom: 40}}>
            <Filter width={listBoxWidth$1} filterCurrent={that.filterCurrent} changeFilterCurrent={changeFilterCurrent} changePosition={changePosition}/>
            <FilterListBar 
                width={listBoxWidth$1} 
                filterCurrent={that.filterCurrent} 
                changeFilterCurrent={changeFilterCurrent}
                value={that.searchValue}
                onChangeValue={handleChangeSearchValue}
                subText={subText}
            />
            {
                that.initListLoading && <ListLoading/>
            }
            <ListContainerPositionStyled>
                <ListContainerStyled containerCount={containerCount} style={{width: listBoxWidth$1}}>
                    {
                        that.list && that.list.map((item, index) => {
                            const marginTop = 20;
                            return <ResumePreview key={item.id} item={item} width={containerWidth} style={{marginTop}} text="编辑模板" onClick={() => handleClick(item)}/>
                        })
                    }
                </ListContainerStyled>
            </ListContainerPositionStyled>
            <Pagination_c
                defaultCurrent={that.defaultCurrent}
                defaultPageSize={that.defaultPageSize}
                current={that.current}
                pageSize={that.pageSize}
                total={that.total}
                onChange={onChangeCurrent}
            />
            <AdminModal 
                resumeData={selectItem}
                visible={visible}
                onSuccess={handleSuccess}
                onCancel={handleCancel}
            />
        </div>
        <Footer />
    </PageContainerStyled>
}
export default Admin
