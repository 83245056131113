import {Module} from "@/common/module/module";
import { getJzImageUrl } from "@/utils/image";

export const getIconByModule = (module) => {
    switch (module) {
        case Module.BASE: {
            return "https://oss.jianzeppt.com/user/4556/7e4f2df2-4e31-4c1d-a163-98a29cd84d9c.png"
        }
        case Module.AVATAR: {
            return "https://oss.jianzeppt.com/user/4556/8d7adabf-d654-4570-adf4-dfb1cf1ae1f8.png"
        }
        case Module.JOB_INTEREST: {
            return "https://oss.jianzeppt.com/user/3029469/3134651c-de5a-4fb9-baf9-ae7812416865.png"
        }
        case Module.WORK_EXPERIENCE: {
            return "https://oss.jianzeppt.com/user/3029469/7cfae6f1-200f-41df-83c6-fd85e0328145.png"
        }
        case Module.SKILLS: {
            return "https://oss.jianzeppt.com/user/3029469/31dab84e-95f2-4546-8f50-12bd596c5919.png"
        }
        case Module.PORTFOLIO: {
            return "https://oss.jianzeppt.com/user/3029469/4fd8c289-5b82-49ea-af1b-6247a3abefaa.png"
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            return "https://oss.jianzeppt.com/user/3029469/d7b0225f-a390-4186-8339-e042e01a86d9.png"
        }
        case Module.CAMPUS_ACTIVITIES: {
            return "https://oss.jianzeppt.com/user/3029469/ec75f017-cf5c-4da3-82e7-748fd4819bab.png"
        }
        case Module.PROJECT_EXPERIENCE: {
            return "https://oss.jianzeppt.com/user/3029469/35cc3816-7eb5-4a23-8c56-b6da5922c553.png"
        }
        case Module.LANGUAGE_PROFICIENCY: {
            return "https://oss.jianzeppt.com/user/3029469/efae3d24-d47d-40f1-9794-c31f1cf1d25a.png"
        }
        case Module.SELF_EVALUATION: {
            return "https://oss.jianzeppt.com/user/3029469/15d6d620-058d-42be-8b78-8cdfe714896d.png"
        }

        case Module.INTERNSHIP_EXPERIENCE: {
            return "https://jianzeppt-backend-shanghai.oss-cn-shanghai.aliyuncs.com/jz-ppt-img/88gjhb2.png"
        }
        case Module.INTEREST: {
            return "https://oss.jianzeppt.com/user/3029469/57e96bb0-ebe6-4a64-825a-730214d0e389.png"
        }
        case Module.CUSTOM_MODULE: {
            return "https://oss.jianzeppt.com/user/4556/0a2753ef-3c7e-45c1-91b0-f25b0f27a89c.png"
        }
        case Module.HONORS_AND_AWARDS: {
            return 'https://jianzeppt-backend-shanghai.oss-cn-shanghai.aliyuncs.com/jz-ppt-img/i98fjgnjk1dsnj.png'
        }
        case Module.APPLICATION_INFO: {
            return getJzImageUrl('gkjdfngkjdfkg.png')
        }
        default: {
            return "https://oss.jianzeppt.com/user/4556/0a2753ef-3c7e-45c1-91b0-f25b0f27a89c.png"
        }
    }
}
