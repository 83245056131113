import { getDataFromTemplate } from "@/http/api"
import http from "@/http/http"
import { Button, Input } from "antd"
import { useEffect, useState } from "react"
import styled from "styled-components"


const VerifyStyleStyled = styled.div`
    padding: 50px;

    .header{
        display: flex;
        justify-content: flex-end;

        .input{
            width: 200px;
            margin-left: 20px;
        }
    }

    .content{
        display: flex;
        column-gap: 20px;
        margin: 30px 0;
        justify-content: space-between;
    }
`

const VerifyStyle = () => {

    const [inputValue, setInputValue] = useState('');
    const [page, setPage] = useState(1); 
    const [data, setData] = useState({})

    console.log(data, 'data');
    

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const res = await http.get(`resume/develop/listOriginResumeTemplate?page=${page}&limit=${1}`)
        const { id: resId } = await getDataFromTemplate({
            query: { resumeId: res.dataList[0].id },
          })
          setData({...res.dataList[0], resumeId: resId})
    }

    const handlePress = () => {
        console.log(data, 'data');
    }

    return (
        <VerifyStyleStyled>

            <div className="header">
                <Button className="btn">更换</Button>
                <Input className="input" onChange={(e) => setInputValue(e.target.value)} onPressEnter={handlePress}/>
            </div>

            <div className="content">

            <div style={{ flex:1 }}>
                <div>简历来源：{data.origin}</div>
            <img src={data.cover} style={{width:'100%'}}/>
            </div>
            <div style={{ flex:1 }}>
                <div>ID：{data.resumeId}</div>
            <iframe
                style={{ overflow: 'auto' }}
                width="100%"
                height="1630"
                className="iframe"
                loading="lazy"
                id={data?.id}
                src={`/resume/container?id=${data.resumeId}`} />
            </div>

            </div>

        </VerifyStyleStyled>
    )
}

export default VerifyStyle