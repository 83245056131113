import withComponent from "@/hoc/withComponent";
import {Popover} from "antd";
import {createElement, useCallback, useMemo, useState} from "react";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import getBaeInfoPathBySlot from "@/common/config/getBaeInfoPathBySlot";
import {useDispatch} from "@/components/resume/redux/hooks";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import withField from "@/components/resume/hoc/withField";
import getCityList from "@/components/resume/const/getCityList";
import Select from "@/components/resume/components/Select";

export default withComponent(withField((props) => {
    const {tagName, style, className, children = [], field_name,fieldRef} = props;
    const configRender = useConfigRender();
    const element = createElement(tagName, {style, className,ref:fieldRef}, children.map((config) => configRender(config)));
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const onClickCity = useCallback((item) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({path: getBaeInfoPathBySlot(field_name), value: item.name}));
        setVisible(false)
    }, [])
    const list = useMemo(() => getCityList(), []);
    // return  element
    return <Popover content={<Select onClickItem={onClickCity} list={list}/>}
                    destroyTooltipOnHide
                    overlayClassName="ant-popover-custom-field-menu"
                    autoAdjustOverflow={false}
                    trigger="click"
                    transitionName=""
                    open={visible}
                    placement="bottom"
                    onOpenChange={setVisible}>
        {element}
    </Popover>
}))
