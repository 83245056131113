import styled from "styled-components";

export const DateBoxStyled = styled.div`
  .ant-popover-inner {
    padding: 0 !important;
  }

  .ant-popover-arrow {
    visibility: hidden !important;
  }
`

export const ContainerStyled = styled.div`
  position: relative;
  width: 300px;
  height: 280px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 0.5);
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  user-select: none;
`
export const TitleStyled = styled.div`
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
`
export const RightIconStyled = styled.div`
  position: absolute;
  top: 17px;
  right: 14px;
  cursor: pointer;
  padding: 6px;

  &:hover {
    background: #F4F4F4;
  }
`
export const LeftIconStyled = styled.div`
  position: absolute;
  top: 17px;
  left: 14px;
  cursor: pointer;
  padding: 6px;

  &:hover {
    background: #F4F4F4;
  }
`
export const BackIconStyled = styled.div`
  position: absolute;
  top: 17px;
  left: 20px;
  cursor: pointer;

  &:hover {
    background: #F4F4F4;
  }
`

export const DateContentContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-top: 80px;
`
export const DateItemBoxStyled = styled.div`
  width: 25%;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
`
export const DateItemStyled = styled.div`
  width: 48px;
  height: 48px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 48px;
  text-align: center;
  cursor: pointer;

  ${props => props.$isActive ? 'background: #11BBA6 !important;color: #FFFFFF !important;' : undefined}
  &:hover {
    background: #F4F4F4;
  }
`
