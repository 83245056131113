import logo from "./logo"
import productName from "./productName";

export const autoOnePage1 = "1";
export const autoOnePage2 = "2";
export const autoOnePage3 = "3";
export const autoOnePage4 = "4";
export const autoOnePage5 = "5";
export const autoOnePageLevels = {autoOnePage1, autoOnePage2, autoOnePage3, autoOnePage4, autoOnePage5}

const hostname = new URL(window.location.href).hostname

const getCompanyName = () => {
    const hostname = new URL(window.location.href).hostname
    if (hostname === 'cv.xlyb05.cn') {
        return '温州加宏电子商务有限公司重庆市渝中区分公司'
    }
    if (hostname.indexOf('xals77.cn')!==-1) {
        return '温州嘉临电子商务有限公司'
    }
    if (hostname.indexOf('jlmb.haochuang11.cn') !== -1) {
        return '蚌埠好创信息科技有限公司'
    }
    if (hostname.indexOf('cv.qianli1.cn') !== -1) {
        return '杭州浅研网络科技有限公司阳江分公司'
    }
    return '杭州天资差异科技有限公司'
}

const config = {
    logo,
    productName,
    companyName: getCompanyName(),
    record: '浙ICP备2022036190号-2',
    themeColor: {
        color: '#11BBA6',
        activeColor: '#0D9584',
        hoverColor: '#0FA795',
    },
    resume: {
        width: 820,
        height: 1160,
        designScale: 1,
        autoOnePage: {
            [autoOnePage1]: {},
            [autoOnePage2]: {},
            [autoOnePage3]: {},
            [autoOnePage4]: {},
            [autoOnePage5]: {},
        }
    }
}


if (hostname.indexOf('xlyb05.cn')!==-1) {
    config.record = '浙ICP备2023017390号-8'
} else if (hostname.indexOf('xals77.cn')!==-1 ) {
    config.record = '浙ICP备2023017518号-6'
} else if (hostname.indexOf('cv.qianli1.cn') !== -1) {
    config.record = '浙ICP备2024064841号'
}


export default config
