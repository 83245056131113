export const vipOrderConfig = [
    { id: 13, text: '终身VIP' },
    { id: 12, text: '年VIP' },
    { id: 11, text: '月VIP' },
    // { id: 30, text: '七天VIP' },
];


export const sVipOrderConfig = [
    { id: 22, text: '终身VIP' },
    { id: 21, text: '年VIP' },
    { id: 20, text: '月VIP' },
]
