import { useEffect, useState } from "react";
import { formatDate, setPageTitle } from "../../utils";
import "./index.scss";
import { getDownHistory, getUsedPointHistory } from "@/http/api";
import useQueryParam from "@/hooks/useQueryParam";
import classNames from "classnames";
import useDownload from "../../hooks/useDownload";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js";

const History = () => {
  const type = useQueryParam("type");
  const [dataSource, setDataSource] = useState([]);
  const isHistory = type === "gpt";
  const { down } = useDownload();
  useEffect(() => {
    const title = isHistory ? "生成记录" : "下载记录";
    setPageTitle(title);

    const request = isHistory ? getUsedPointHistory : getDownHistory;
    const body = isHistory
      ? { limit: 999, page: 1 }
      : {
          query: { limit: 999, page: 1 },
        };
    request(body).then((res) => {
      const { dataList } = res;
      const newData = dataList.map((item) => ({
        ...item,
        text: item.fileName,
        time: formatDate(item.createTime),
      }));
      setDataSource(newData);
    });
  }, [isHistory]);

  const handleClick = (item) => {
    down({
      resumeName: item.filename,
      resumeId: item.id,
    });
  };

  const cls = classNames({
    download: true,
    "download-gpt": isHistory,
  });
  return (
    <div className={cls}>
      <NavigationHeader
        style={{ width: "100vw", left: "-18px", top: "-25px" }}
        title={document.title}
      ></NavigationHeader>

      {isHistory ? (
        <div className="table-header table-header-gpt">
          <span>时间</span>
          <span>关键字</span>
          <span>生成类型</span>
          <span>消耗量/页</span>
        </div>
      ) : (
        <div className="table-header">
          <span>时间</span>
          <span>PPT名称</span>
          <span>操作</span>
        </div>
      )}
      <div className="table-content">
        {dataSource.map((item, index) => {
          const { text, time, type, describe, usePoint } = item;
          return isHistory ? (
            <div key={index} className="table-cell table-cell-gpt">
              <span>{time}</span>
              <span>{describe}</span>
              <span>{type}</span>
              <span>{usePoint}</span>
            </div>
          ) : (
            <div
              key={index}
              className="table-cell"
              onClick={() => handleClick(item)}
            >
              <span>{time}</span>
              <span>{text}</span>
              <div className="jz-resume-btn">下载</div>
            </div>
          );
        })}
        {dataSource.length <= 0 && <p className="null">暂无记录</p>}
      </div>
      {/* {modal} */}
    </div>
  );
};

export default History;
