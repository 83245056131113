import withComponent from "@/hoc/withComponent";
import {IconContainerStyled} from "@/components/resume/moduleManage";


const icon = <div style={{height: 16, width: 16, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="作品展示、自定义展示" transform="translate(-1085.000000, -142.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-2" transform="translate(1081.000000, 138.000000)">
                <g id="设置备份" transform="translate(4.000000, 4.000000)">
                    <g id="tuodong" transform="translate(1.142857, 1.485714)">
                        <path d="M13.2094941,3.81181593 C13.2094941,3.81181593 13.2219081,2.70984067 12.3137811,2.65636527 C11.838232,2.62867265 11.734146,2.90464392 11.5546214,3.70677496 L11.0437403,5.1219633 L11.4123386,2.43864399 C11.512605,1.57062361 11.0370559,1.06165237 10.3838921,0.989078611 C9.72977329,0.917459769 9.16732664,1.24786136 9.04605207,2.17890632 L8.62684311,4.81543465 L8.62493327,1.33093922 C8.56859312,0.594697518 8.1407899,0.0370254622 7.42364655,0.0016934998 C6.70554829,-0.0336384626 6.1249582,0.487746712 6.06002379,1.20393514 L6.03519592,4.73426663 L4.97714689,1.45030396 C4.65151988,0.685414718 4.03750767,0.415172952 3.38720858,0.674910621 C2.73690949,0.934648291 2.52491771,1.61836951 2.68056933,2.322144 L3.98212243,7.28294251 L2.3377538,5.58987307 C1.90804075,5.11814362 1.13455724,4.89660267 0.56733601,5.30721737 C0.00106969323,5.71783207 -0.12497947,6.41874181 0.116614759,7.05089746 C0.116614759,7.05089746 0.193008192,7.33546299 1.04956955,8.4183399 C1.95483172,9.56233154 4.20175355,11.6717452 4.58085596,13.011495 L11.1745641,13.0124499 C11.1745641,13.0124499 11.5775394,11.6354583 12.2555311,9.89273314 C13.0089614,7.95138504 13.3995228,5.08185674 13.2094941,3.81181593 Z" id="路径"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`
}}/>

export default withComponent((props) => {
    return <IconContainerStyled {...props} onClick={props.onClick} className="module-drag-button" style={{marginRight: 4}}>
        {props.text}
    </IconContainerStyled>
})
