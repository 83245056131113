import styled from "styled-components";


function HighlightText({ text, highlight }) {
    // 使用正则表达式来分割文本
    console.log(text)
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
        <span>
            {parts.map((part, index) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <span key={index} className="t-highlight">{part}</span>
                ) : (
                    part // 不是高亮的文本部分
                )
            )}
        </span>
    );
}


const MaskStyled = styled.div`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const TDropDownStyled = styled.div`
    font-size: 16px;
    .t-dropdown {
        position: absolute;
        z-index: 30;
        left: 11px;
        right: -2px;
        top: 63px;
        padding: 6px 0;
        max-height: 291px;
        background: #FFFFFF;
        box-shadow: 0px 0 4px 0px rgba(0,0,0,0.2);
        border-radius: 6px;
        overflow-y: scroll;
        &-item {
            height: 42px;
            margin-bottom: 4px;
            padding-left: 14px;
        }
        &-item:hover {
            background-color: rgba(0,0,0,0.05);
            cursor: pointer;
        }
    }
    .t-highlight {
        color: #11BBA6;
    }
`

const TDropDown = ({ visible, options, onClick, onClose, highlight='' }) => {


    return <TDropDownStyled>
        {
            visible && <div className="t-dropdown">
                {options.map(item => {
                    return <div key={item.id} onClick={() => onClick(item)} className="flex-center justify-start t-dropdown-item">
                        <HighlightText text={item.name} highlight={highlight} />
                    </div>
                })}
            </div>
        }
        {
            visible && <MaskStyled onClick={onClose} />
        }
    </TDropDownStyled>
}

export default TDropDown