import { createSlice } from '@reduxjs/toolkit';
import initState from '../initState';

// 函数用于重命名对象中的键
function renameObjectKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

// 通用的重命名键并处理子数组的函数
function renameKeysAndHandleSubTypes(data, renameMap, subTypeKey, newSubTypeKey) {
  return data.map(item => {
    // 重命名对象键
    const newItem = renameObjectKeys(item, renameMap);

    // 如果存在子类型属性，递归调用自身函数处理子数组
    if (newItem[subTypeKey]) {
      newItem[newSubTypeKey] = renameKeysAndHandleSubTypes(newItem[subTypeKey], renameMap, subTypeKey, newSubTypeKey);
      delete newItem[subTypeKey];
    }

    // 返回处理过的新对象
    return newItem;
  });
}

export default createSlice({
  name: 'interview',
  initialState: initState.interview,
  reducers: {
    setList(state, { payload }) {
      state.list = payload;
    },
    setJdList(state, { payload }) {
      // 创建映射，定义如何重命名键
      const renameMap = {
        key: 'value',
        name: 'label',
      };

      // 创建映射，定义如何重命名键，用于jdListWithResume
      const renameMapForJdListWithResume = {
        key: 'id',
      };

      state.jdList = renameKeysAndHandleSubTypes(payload, renameMap, 'subTypes', 'children');
      state.jdListWithResume = renameKeysAndHandleSubTypes(payload, renameMapForJdListWithResume, 'subTypes', 'children');
    },
    setJdId(state, { payload }) {
      state.selectJdId = payload
    },
    setIsContentModified(state, { payload }) {
      state.isContentModified = payload
    },
    setChooseName(state,{ payload }){
      state.chooseName = payload
    },
    setPageStatus(state, {payload}){
      console.log(payload, 'pay');
      
      state.pageStatus = payload
    },
    setPageIndex(state, {payload}){
      state.pageStatus.pageIndex = payload
    }
  }
});