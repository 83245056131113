import styled from "styled-components"
import Select from "@/components/resume/components/Select";
import {Button, message, Popover} from "antd";
import {useMemo, useState} from "react";
import FlexStyled from "@/styled/FlexStyled";
import {useStore} from "react-redux";
import {useResumeSelector, useResumeStore} from "@/components/resume/redux/hooks";
import isVip2 from "@/common/isVip";
import {downPdf} from "@/http/api";
import fetchFile from "@/common/fetchFile";
import downFile from "@/common/downFile";

const ContainerStyled = styled.div`
`
const TitleStyled = styled.div`
  margin-top: 24px;
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2C2C2C;
  line-height: 22px;

`
const SelectCurrentContainerStyled = styled.div`
  position: relative;
  width: 240px;
  height: 56px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #C9C9C9;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
`
const SelectContainerStyled = styled.div`
  position: relative;
  height: 56px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin:8px 20px;
  background : ${props => props.$active ?   '#FFFFFF' : '#F7F7F7'};
  ${(props) => props.$active
        && `
        box-shadow: 0px 0px 10px 0px rgba(0,188,166,0.13);
        border-radius: 4px;
        border: 1px solid #11BBA6;
    `}

  &:hover {
    background: #F7F7F7;
    cursor: pointer;
  }
`


const SelectIconContainerStyled = styled.div`
  width: 36px;
  height: 36px;
  margin-left: 20px;
  margin-right: 10px;
`
const SelectLabelContainerStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2C2C2C;
  line-height: 20px;

`
const SelectDesContainerStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
`
const SelectRightIconContainerStyled = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width:20px;
  height:20px;
  display: flex;
    justify-content: center;
    align-items: center;
    background: #11BBA6;
`
const SelectMenuContainerStyled = styled.div`
  padding: 8px 0;
  top: -13px;
  position: relative;
  width: 240px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.18);
  border-radius: 4px;
  border: 1px solid #E0E0E0;
`

const icon1 = <div style={{height: 36, width: 36, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0;left: 0;">
    <g id="下载提示" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="选择格式下拉列表" transform="translate(-909.000000, -154.000000)" fill-rule="nonzero">
            <g id="word" transform="translate(909.000000, 154.000000)">
                <g id="pdf" transform="translate(6.000000, 3.600000)">
                    <path d="M24,27.7714286 C24,28.3395086 23.5395086,28.8 22.9714286,28.8 L1.02857143,28.8 C0.460491429,28.8 0,28.3395086 0,27.7714286 L0,1.02857143 C0,0.460491429 0.460491429,0 1.02857143,0 L17.3485714,0 L24,6.65142857 L24,27.7714286 Z" id="路径" fill="#FF6359"></path>
                    <path d="M24,6.65142857 L18.3771429,6.65142857 C17.8090629,6.65142857 17.3485714,6.19093714 17.3485714,5.62285714 L17.3485714,0" id="路径" fill-opacity="0.296" fill="#FFFFFF"></path>
                    <path d="M2.49051429,18.8228571 L4.15817143,18.8228571 L4.15817143,16.4749714 L5.09074286,16.4749714 C7.24114286,16.4749714 8.08594286,15.7837714 8.08594286,13.7979429 C8.08594286,11.9657143 7.3728,11.2086857 5.09074286,11.2086857 L2.49051429,11.2086857 L2.49051429,18.8228571 Z M4.15817143,15.1364571 L4.15817143,12.5252571 L4.87131429,12.5252571 C5.9904,12.5252571 6.38537143,12.8544 6.38537143,13.8308571 C6.38537143,14.8402286 6.00137143,15.1364571 4.87131429,15.1364571 L4.15817143,15.1364571 L4.15817143,15.1364571 Z M9.4464,11.2086857 L9.4464,18.8228571 L12.0576,18.8228571 C14.2957714,18.8228571 15.7659429,17.9561143 15.7659429,15.0157714 C15.7659429,11.8450286 14.2957714,11.2086857 12.0576,11.2086857 L9.4464,11.2086857 Z M11.1140571,17.3965714 L11.1140571,12.6130286 L11.9698286,12.6130286 C13.3412571,12.6130286 14.0434286,13.0518857 14.0434286,15.0157714 C14.0434286,16.8699429 13.3741714,17.3965714 11.9698286,17.3965714 L11.1140571,17.3965714 L11.1140571,17.3965714 Z M21.9209143,12.6569143 L21.9209143,11.2086857 L17.2690286,11.2086857 L17.2690286,18.8228571 L18.9366857,18.8228571 L18.9366857,15.8605714 L21.7014857,15.8605714 L21.7014857,14.4233143 L18.9366857,14.4233143 L18.9366857,12.6569143 L21.9209143,12.6569143 Z" id="形状" fill="#FFFFFF"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const icon2 = <div style={{height: 36, width: 36, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0;left: 0;">
    <g id="下载提示" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="选择格式下拉列表" transform="translate(-909.000000, -274.000000)" fill-rule="nonzero">
            <g id="编组-6" transform="translate(895.000000, 200.000000)">
                <g id="word备份-2" transform="translate(14.000000, 74.000000)">
                    <g id="img" transform="translate(6.000000, 3.600000)">
                        <path d="M24,27.7714286 C24,28.3395086 23.5395086,28.8 22.9714286,28.8 L1.02857143,28.8 C0.460491429,28.8 0,28.3395086 0,27.7714286 L0,1.02857143 C0,0.460491429 0.460491429,0 1.02857143,0 L17.3485714,0 L24,6.65142857 L24,27.7714286 Z" id="路径" fill="#FFAB4E"></path>
                        <path d="M24,6.65142857 L18.3771429,6.65142857 C17.8090629,6.65142857 17.3485714,6.19093714 17.3485714,5.62285714 L17.3485714,0" id="路径" fill-opacity="0.296" fill="#FFFFFF"></path>
                        <path d="M9.54034286,10.4789486 L14.7761486,19.7186057 C14.9441829,20.0151429 14.8400229,20.3917371 14.5434857,20.5597714 C14.4507041,20.6123532 14.3458799,20.64 14.2392343,20.64 L3.76762286,20.64 C3.42678857,20.64 3.15048,20.3636914 3.15048,20.0228571 C3.15048,19.9162116 3.17812678,19.8113874 3.23070857,19.7186057 L8.46648,10.4789486 C8.63454857,10.1824114 9.01114286,10.0782514 9.30768,10.2462857 C9.40486053,10.3013556 9.48527299,10.381768 9.54034286,10.4789486 L9.54034286,10.4789486 Z" id="路径" fill="#FFFFFF"></path>
                        <path d="M16.1499429,13.8908571 L19.4523429,19.7186057 C19.6203429,20.0151429 19.5162171,20.3917371 19.21968,20.5597714 C19.1268882,20.6123589 19.0220515,20.64 18.9153943,20.64 L12.3106629,20.64 C11.9697943,20.64 11.69352,20.3636914 11.69352,20.0228571 C11.69352,19.9162116 11.7211668,19.8113874 11.7737486,19.7186057 L15.0761143,13.8908571 C15.2441143,13.59432 15.6207429,13.49016 15.91728,13.6581943 C16.0144605,13.7132642 16.094873,13.7936766 16.1499429,13.8908571 L16.1499429,13.8908571 Z" id="路径" fill="#FFFFFF"></path>
                        <path d="M16.56,11.2457143 C16.56,12.1924881 17.3275119,12.96 18.2742857,12.96 C19.2210596,12.96 19.9885714,12.1924881 19.9885714,11.2457143 C19.9885714,10.2989404 19.2210596,9.53142857 18.2742857,9.53142857 C17.3275119,9.53142857 16.56,10.2989404 16.56,11.2457143 Z" id="路径" fill="#FFFFFF"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const icon3 = <div style={{height: 36, width: 36, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0;left: 0;">
    <g id="下载提示" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="选择格式下拉列表" transform="translate(-909.000000, -330.000000)" fill-rule="nonzero">
            <g id="编组-6" transform="translate(895.000000, 200.000000)">
                <g id="word备份-3" transform="translate(14.000000, 130.000000)">
                    <g id="编组" transform="translate(6.000000, 3.600000)">
                        <path d="M24,27.7714286 C24,28.3395086 23.5395086,28.8 22.9714286,28.8 L1.02857143,28.8 C0.460491429,28.8 0,28.3395086 0,27.7714286 L0,1.02857143 C0,0.460491429 0.460491429,0 1.02857143,0 L17.3485714,0 L24,6.65142857 L24,27.7714286 Z" id="路径" fill="#4895FF"></path>
                        <path d="M24,6.65142857 L18.3771429,6.65142857 C17.8090629,6.65142857 17.3485714,6.19093714 17.3485714,5.62285714 L17.3485714,0" id="路径" fill-opacity="0.296" fill="#FFFFFF"></path>
                        <polygon id="路径" fill="#FFFFFF" points="6.85062857 20.9485714 10.0426286 20.9485714 11.8738286 13.5565714 11.9746286 13.5565714 13.7722286 20.9485714 16.9642286 20.9485714 19.6354286 9.28937143 16.9978286 9.28937143 15.2842286 17.9077714 15.1834286 17.9077714 13.5034286 10.4149714 10.5466286 10.4149714 8.76582857 17.9077714 8.66502857 17.9077714 6.98502857 9.28937143 4.31382857 9.28937143"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const icon4 = <div style={{height: 16, width: 16, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="下载提示" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="选择格式下拉列表" transform="translate(-1163.000000, -164.000000)" fill="#BFBFBF" fill-rule="nonzero">
            <g id="向上备份-10" transform="translate(1171.000000, 172.000000) scale(1, -1) translate(-1171.000000, -172.000000) translate(1163.000000, 164.000000)">
                <g id="编组" transform="translate(8.008678, 8.666667) rotate(-270.000000) translate(-8.008678, -8.666667) translate(4.675345, 2.791322)">
                    <path d="M-2.12226538,3.70734312 L2.95898709,8.7885956 C3.05811155,8.88783039 3.19261941,8.9435892 3.3328806,8.9435892 C3.4731418,8.9435892 3.60764966,8.88783039 3.70677411,8.7885956 L8.7880266,3.70734312 C8.93823445,3.5562426 8.9830164,3.32972104 8.90160424,3.13283064 C8.82019208,2.93594025 8.62851051,2.80719392 8.41545426,2.80629939 L-1.74969305,2.80629939 C-1.9627493,2.80719393 -2.15443087,2.93594025 -2.23584303,3.13283065 C-2.31725518,3.32972104 -2.27247324,3.5562426 -2.12226538,3.70734312 L-2.12226538,3.70734312 Z" id="路径" transform="translate(3.332881, 5.874944) rotate(-90.000000) translate(-3.332881, -5.874944) "></path>
                    <path d="M3.33505528,9.2086784 C3.12488712,9.20849338 2.92339441,9.1248549 2.7748756,8.97615099 L-2.30637688,3.89489851 C-2.53788279,3.67070036 -2.60629389,3.32653991 -2.47813008,3.03084778 C-2.35570429,2.73586705 -2.06821452,2.54316557 -1.74883955,2.54201173 L8.41630776,2.54201173 C8.736645,2.54210039 9.0254447,2.73498105 9.14824064,3.03084778 C9.27103284,3.32709178 9.20324115,3.66813611 8.97648744,3.89489851 L3.89523496,8.97615099 C3.74671616,9.1248549 3.54522344,9.20849338 3.33505528,9.2086784 Z M-1.74883955,3.07048313 C-1.85570812,3.07093122 -1.9517846,3.13570899 -1.99227142,3.23461251 C-2.03275824,3.33351604 -2.00968283,3.44706944 -1.93380454,3.52232617 L3.14744793,8.60357865 C3.19706257,8.65360182 3.26460012,8.68173913 3.33505528,8.68173913 C3.40551044,8.68173913 3.47304799,8.65360182 3.52266263,8.60357865 L8.60391511,3.52232617 C8.67889055,3.44634237 8.70078305,3.33270987 8.6594046,3.23430927 C8.61886989,3.13563692 8.52298021,3.07101561 8.41630776,3.07048313 L-1.74883955,3.07048313 Z" id="形状" transform="translate(3.333333, 5.875345) rotate(-90.000000) translate(-3.333333, -5.875345) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const icon5 = <div style={{height: 16, width: 16, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="下载提示" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="点击下载显示预览状态" transform="translate(-1163.000000, -164.000000)" fill="#BFBFBF" fill-rule="nonzero">
            <g id="向上备份-10" transform="translate(1163.000000, 164.000000)">
                <g id="编组" transform="translate(8.008678, 8.666667) rotate(-270.000000) translate(-8.008678, -8.666667) translate(4.675345, 2.791322)">
                    <path d="M-2.12226538,3.70734312 L2.95898709,8.7885956 C3.05811155,8.88783039 3.19261941,8.9435892 3.3328806,8.9435892 C3.4731418,8.9435892 3.60764966,8.88783039 3.70677411,8.7885956 L8.7880266,3.70734312 C8.93823445,3.5562426 8.9830164,3.32972104 8.90160424,3.13283064 C8.82019208,2.93594025 8.62851051,2.80719392 8.41545426,2.80629939 L-1.74969305,2.80629939 C-1.9627493,2.80719393 -2.15443087,2.93594025 -2.23584303,3.13283065 C-2.31725518,3.32972104 -2.27247324,3.5562426 -2.12226538,3.70734312 L-2.12226538,3.70734312 Z" id="路径" transform="translate(3.332881, 5.874944) rotate(-90.000000) translate(-3.332881, -5.874944) "></path>
                    <path d="M3.33505528,9.2086784 C3.12488712,9.20849338 2.92339441,9.1248549 2.7748756,8.97615099 L-2.30637688,3.89489851 C-2.53788279,3.67070036 -2.60629389,3.32653991 -2.47813008,3.03084778 C-2.35570429,2.73586705 -2.06821452,2.54316557 -1.74883955,2.54201173 L8.41630776,2.54201173 C8.736645,2.54210039 9.0254447,2.73498105 9.14824064,3.03084778 C9.27103284,3.32709178 9.20324115,3.66813611 8.97648744,3.89489851 L3.89523496,8.97615099 C3.74671616,9.1248549 3.54522344,9.20849338 3.33505528,9.2086784 Z M-1.74883955,3.07048313 C-1.85570812,3.07093122 -1.9517846,3.13570899 -1.99227142,3.23461251 C-2.03275824,3.33351604 -2.00968283,3.44706944 -1.93380454,3.52232617 L3.14744793,8.60357865 C3.19706257,8.65360182 3.26460012,8.68173913 3.33505528,8.68173913 C3.40551044,8.68173913 3.47304799,8.65360182 3.52266263,8.60357865 L8.60391511,3.52232617 C8.67889055,3.44634237 8.70078305,3.33270987 8.6594046,3.23430927 C8.61886989,3.13563692 8.52298021,3.07101561 8.41630776,3.07048313 L-1.74883955,3.07048313 Z" id="形状" transform="translate(3.333333, 5.875345) rotate(-90.000000) translate(-3.333333, -5.875345) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const icon6 = <div style={{height: 16, width: 16, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="下载提示" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="选择格式下拉列表" transform="translate(-1163.000000, -228.000000)" fill="#FFFFFF">
            <g id="编组-6" transform="translate(895.000000, 200.000000)">
                <g id="向上备份-11" transform="translate(268.000000, 28.000000)">
                    <polygon id="路径" points="7.0800553 14 0 7.96860133 1.17632273 6.73263559 6.84378535 11.5604186 14.6055046 2 16 3.01332065"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const icon7 = <div
    style={{height: 24, width: 24, position: "relative", display: "inline-block", marginRight: 6, top: 6}}
    dangerouslySetInnerHTML={{
        __html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="下载提示" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="点击下载显示预览状态" transform="translate(-998.000000, -227.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="下载" transform="translate(998.000000, 227.000000)">
                <path d="M3,19.1 L3,21.1 L21,21.1 L21,19.1 L3,19.1 Z M5.45,9.79999999 L9.85000001,9.79999999 L9.85000001,2.9 L13.9,2.9 L13.9,9.79999999 L18.3,9.79999999 L11.9,17.2 L5.45,9.79999999 Z" id="形状"></path>
            </g>
        </g>
    </g>
</svg>`
    }}/>


const config = [{
    icon: icon1, label: "PDF格式", des: "适合打印，投递更安全", id: "pdf"
}, 
{
    icon: icon2, label: "图片格式", des: "适合预览，邮件投递", id: "img"
}, {
    icon: icon3, label: "Word格式", des: "导出可能会有排版误差", id: "word"
}
]


const SelectMenu = (props) => {
    const {current, onClick} = props;
    return <SelectMenuContainerStyled>
        {config.map((item, index) => {
            return <SelectContainerStyled  key={item.id} onClick={() => onClick(item.id)}>
                <SelectIconContainerStyled>{item.icon}</SelectIconContainerStyled>
                <div>
                    <SelectLabelContainerStyled>{item.label}</SelectLabelContainerStyled>
                    <SelectDesContainerStyled>{item.des}</SelectDesContainerStyled>
                </div>
                <SelectRightIconContainerStyled>
                    {current === item.id && icon6}
                </SelectRightIconContainerStyled>
            </SelectContainerStyled>
        })}
    </SelectMenuContainerStyled>
}
export default (props) => {
    const {setLoginVisible,setPayModalVisible} = props;
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [current, setCurrent] = useState(config[0].id);
    const currentConfig = useMemo(() => config.find((item) => item.id === current), [current]);


    const onClick = (id) => {
        setCurrent(id);
        setPopoverOpen(false)
    }

    const handleDownload = () => {
        props.down(currentConfig.id)
    }

    return <ContainerStyled>
        <TitleStyled>
            简历下载格式
        </TitleStyled>
        <div style={{marginTop: 24}}>
         

                {config.map((item, index) => {
                    console.log(current === item.id)
            return <SelectContainerStyled $active={current === item.id} key={item.id} onClick={() => onClick(item.id)}>
                <SelectIconContainerStyled>{item.icon}</SelectIconContainerStyled>
                <div>
                    <SelectLabelContainerStyled>{item.label}</SelectLabelContainerStyled>
                    <SelectDesContainerStyled>{item.des}</SelectDesContainerStyled>
                </div>
                {current === item.id && <SelectRightIconContainerStyled>
                    {icon6}
                </SelectRightIconContainerStyled>}
            </SelectContainerStyled>
        })}
        </div>
        <FlexStyled $justifyContent="center" style={{marginTop: 14}}>
            <Button type="primary"
                    onClick={handleDownload}
                    style={{width: 240, height: 50, padding: 0, lineHeight: "50px", fontSize: 16}}>{icon7}下载简历</Button>
        </FlexStyled>
    </ContainerStyled>
}
