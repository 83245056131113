import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    // 首页search参数
    filter: {
        searchWord: '',
        position: '全部', // 行业
        profession: '全部', // 职业
        style: '全部', // 风格
        use: '全部', // 用途
        color:'全部'
    },
    // list数据
    list: new Array(6).fill({})
}

export const indexSlice = createSlice({
    name: 'mobileIndex',
    initialState,
    reducers: {
        setConfigParams: (state, action) => {
            console.log(action)
            const { type, value } = action.payload
            state.filter[type] = value
        },
        clearFilter: (state, action) => {
            state.filter = {
                searchWord: '',
                position: '全部', // 行业
                profession: '全部', // 职业
                style: '全部', // 风格
                use: '全部', // 用途
             color:'全部'
            }
        },
        clearFilterWithoutSearchWord:(state,action)=>{
            const {  value } = action.payload
            state.filter ={
                searchWord:value,
                position: '全部', // 行业
                profession: '全部', // 职业
                style: '全部', // 风格
                use: '全部', // 用途
                 color:'全部'
            }
        },
        setList: (state, action) => {
            const list = action.payload
            state.list = list
        }
    }
})

// Action creators are generated for each case reducer function
export const { setConfigParams, setList, clearFilter,clearFilterWithoutSearchWord } = indexSlice.actions

export default indexSlice.reducer
