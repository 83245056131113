import ModuleBox from "../ModuleBox"
import useQuerySlot from "@/pages/Mobile/hooks/useQuerySlot";
import { TargetItemDetail } from '../../styled'
import { Ellipsis } from "antd-mobile";

const SingleField = ({ data = {}, moduleName, customId }) => {
    const title = useQuerySlot({
        customId,
        moduleName,
        slotName: "title_text"
    })

    return <ModuleBox name={moduleName} title={title || "求职意向"} customId={customId}>
        <TargetItemDetail>
            <Ellipsis direction='end' rows={4} content={data.details} />
        </TargetItemDetail>

    </ModuleBox>
}

export default SingleField
