import styled from "styled-components";
import MobileLayout from "@/pages/Mobile/layout/index.js";
import { listResumeUser } from "@/http/api";
import moment from "moment";
import { useEffect, useState } from "react";
import { InfiniteScroll } from "antd-mobile";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js";
import { useNavigate } from "react-router-dom";
import ResumePage from "@/pages/Mobile/pages/Resume/index.js";

const MyStyled = styled.div`
  background-color: rgba(247, 248, 249, 1);
  /* min-height: 100vh; */
  box-sizing: border-box;
  .my-title {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-bottom: 15px;
  }
`;

const BoxStyled = styled.div`
  position: absolute;
  left: calc(50% - 132px);
  top: calc(50% - 67px);
  width: 265px;
  height: 134px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  & > div:nth-child(1) {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #353336;
    margin: 36px 0 0;
  }
  .t-btn {
    margin: 23px 0 0;
    width: 124px;
    height: 36px;
    background: #11bba6;
    border-radius: 9px;

    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
  }
`;

const List = () => {
  const [mutation, setMutation] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  
  useEffect(() => {
    if (mutation > 0) {
      setCurrent(1);
      setDataList([]);
      setHasMore(true);
    }
  }, [mutation]);
  const navigator = useNavigate();
  const handleClickOne = () => {
    navigator("/mobile/index");
  };
  let resumeId = 0

  const fetchList = async () => {
    if (loading) return;
    setLoading(true);
    const res = await listResumeUser({
      query: {
        page: 1,
        pageSize: 6,
      },
    });
    const dataList = res?.dataList?.map((item) => {
      let data = JSON.parse(item.pagesData?.[0]?.userData);
      return {
        data,
        resume: {
          id: item.id,
          resumeId: item.id,
        },
        resumeData: data,
        name: item.name,
        updateTime: moment(item.updateTime).format("YYYY-MM-DD HH:mm"),
        id: item.id,
      };
    });
    if (dataList&&dataList.length>0) {
      const arr = dataList[0];
      resumeId = arr.id
      navigator(`/mobile/resume?id=${arr.id}`, {
        state: { navigatorUrl: "/mobile/index" },
      });

      setCurrent((current) => current + 1);
      setDataList((list) => [...list, ...dataList]);
      setHasMore(dataList.length > 0);
    } else {
      setHasMore(false);
    }
    setTotal(res?.count || 0);
    setLoading(false);
  };
  useEffect(()=>{

    fetchList();
  },[])
  const handleMutation = () => {
    setMutation((m) => m + 1);
  };

  const showImg = total > 0;
  console.log(loading)
  return (
    <>
      {!showImg && <MobileLayout>
        <MyStyled>
          <NavigationHeader
            title={document.title}
            style={{ width: "100vw" }}
          ></NavigationHeader>
          <img
            style={{ width: "100%", height: "100%" }}
            src="https://pub.jianzeppt.cn/image/%E6%94%B9%E7%AE%80%E5%8E%86%E5%A4%87%E4%BB%BD.png"
          ></img>

          {!loading && (
            <BoxStyled>
              <div>暂无简历，请新建简历</div>
              <div onClick={handleClickOne} className="flex-center t-btn">
                新建简历
              </div>
            </BoxStyled>
          )}
        </MyStyled>
      </MobileLayout>}
    </>
  );
};

export default List;
