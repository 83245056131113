import styled from 'styled-components';
import FlexStyled from '@/styled/FlexStyled';
import Remind from '@/pages/PersonalCenter/component/Remind';
import { Cell1Styled, Cell3Styled, Cell4Styled, Header2Styled } from '@/pages/PersonalCenter/styled';
import { getUsedPointHistory } from '@/http/api';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import useForceUpdate from '@/hooks/useForceUpdate';
import { useSelector } from 'react-redux';


const BoxStyled = styled.div`
  width: 100%;
  padding: 0 30px;
`;


const ButtonStyled = styled.div`
  width: 56px;
  height: 24px;
  line-height: 24px;
  background: #00BCA6;
  border-radius: 4px;

  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  display: inline-block;
  cursor: pointer;
`;
export default () => {
  const that = useRef({
    loading: false,
  }).current;
  const userRemainingDetail = useSelector((state) => state.user?.userRemainingDetail);
  const [, update] = useForceUpdate();
  const coList = [{ id: '1', text: '时间' }, { id: 'keyword', text: '对话内容' }, { id: '2', text: '生成类型' }, { id: '3', text: '消耗量/次' }];
  const [list, setList] = useState([]);
  const fetchData = async () => {
    that.loading = true;
    update();
    try {
      const { dataList } = await getUsedPointHistory( { limit: 999, page: 1 });
      const list = dataList.map((item) => {
        return [
          { id: 'time' + item.id, text: moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') },
          { id: 'describe' + item.id, text: item.describe },
          { id: 'text' + item.id, text: item.type },
          { id: '3', text: item.usePoint }];
      });
      setList(list);
    } catch (e) {

    } finally {

    }

  };
  useEffect(() => {
    fetchData();
  }, []);


  return <BoxStyled>
    <Remind text1={userRemainingDetail ? `本月剩余量：本月还剩 ${userRemainingDetail?.remainingPagePoint} 页 (${Number((userRemainingDetail?.remainingPagePoint / userRemainingDetail.pagePointSum * 100)?.toFixed?.(2))}%)` : ''}
            text2={userRemainingDetail ? `你的 ${userRemainingDetail.pagePointSum} 页AI ChatGPT生成将于${30 - userRemainingDetail.curMonthUsedDay}天后恢复` : ''}
    />
    <Header2Styled>
      {
        coList.map((item, index) => {
          let C;
          if (index === 0) {
            C = Cell1Styled;
          } else if (index === 1) {
            C = Cell4Styled;
          } else if (index === 2) {
            C = Cell4Styled;
          } else if (index === 3) {
            C = Cell3Styled;
          }
          return <C>{item.text}</C>;
        })
      }
    </Header2Styled>
    <div>
      {
        list.map((row, index) => {
          return <FlexStyled>
            {row.map((r, i) => {
              let C;
              if (i === 0) {
                C = Cell1Styled;
              } else if (i === 1) {
                C = Cell4Styled;
              } else if (i === 2) {
                C = Cell4Styled;
              } else if (i === 3) {
                C = Cell3Styled;
              }
              return <C>{r.text}</C>;
            })}
          </FlexStyled>;
        })
      }
    </div>
  </BoxStyled>;
}

