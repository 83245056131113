import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { create_order_edit, pay_alipay as payAlipay, pay_order_query } from '@/http/api';
import PaySuccessModal from '@/pages/Mobile/components/Modal/PaySuccessModal';
import { isIOSMiniProgram, isMiniProgram, isWechat, isWechatBrowser, wxLogin } from '@/pages/Mobile/utils';
// import { toast } from 'react-hot-toast';
import './index.scss';
import { vipOrderConfig, sVipOrderConfig } from './priceConfig';
import { payMiniProgramPay, payWxJsApiPay, payWxPay, sendWeiXingLog } from '@/http/mobileApi';
import { isSupportWxPay, wxJsApiPay, wxSdkPay } from '@/pages/Mobile/utils/pay';
import { Modal, Toast } from 'antd-mobile';
import isLogin from '@/common/isLogin';
import useUpgrade from '../../components/Modal/PayModal/useUpgrade';
import { isB } from '@/common/AOrB';
// import { svip_life } from '@/components/PayModal/config';
import store from 'store'
import { useNavigate } from 'react-router-dom';
import { svip_life } from '../../components/Modal/PayModal/config';
import { isMobileLogin } from '../../utils/login';
import { useDispatch } from 'react-redux';
import errorSlice from '@/redux/services/errorSlice';
import { isSVIP as isUserSvip } from '@/common/isVip';

const icon1 = <div style={{height: 18, width: 152, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="152px" height="18px" viewBox="0 0 152 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="支付页面" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="开通会员页面" transform="translate(-110.000000, -335.000000)">
            <g id="编组-11" transform="translate(12.000000, 326.000000)">
                <g id="编组-7备份" transform="translate(99.000000, 9.000000)">
                    <text id="会员权益" font-family="PingFangSC-Medium, PingFang SC" font-size="13" font-weight="400" fill="#00BCA6">
                        <tspan x="49.5" y="14">会员权益</tspan>
                    </text>
                    <rect id="矩形" fill="#00BCA6" transform="translate(114.828427, 8.828427) rotate(-315.000000) translate(-114.828427, -8.828427) " x="112.828427" y="6.82842712" width="4" height="4"></rect>
                    <rect id="矩形备份" fill="#00BCA6" transform="translate(36.828427, 8.828427) rotate(-315.000000) translate(-36.828427, -8.828427) " x="34.8284271" y="6.82842712" width="4" height="4"></rect>
                    <line x1="120" y1="9" x2="150" y2="9" id="路径-2" stroke="#00BCA6" stroke-width="0.5" stroke-linecap="round"></line>
                    <line x1="0" y1="9" x2="30" y2="9" id="路径-2备份" stroke="#00BCA6" stroke-width="0.5" stroke-linecap="round"></line>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const icon2 = <div style={{height: 24, width: 24, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="100%" y1="4.65105603%" x2="0%" y2="95.348944%" id="linearGradient-1">
            <stop stop-color="#FFFFFF" stop-opacity="0.701048951" offset="0%"></stop>
            <stop stop-color="#FFFFFF" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="支付页面" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="开通会员页面" transform="translate(-211.000000, -372.000000)" fill="url(#linearGradient-1)" fill-rule="nonzero">
            <g id="编组-11" transform="translate(12.000000, 326.000000)">
                <g id="a-zhineng4备份-2" transform="translate(199.000000, 46.000000)">
                    <g id="AIduihua" transform="translate(0.000000, 0.600000)">
                        <path d="M21,0 C22.656,0 24,1.27885714 24,2.85771429 L24,16.1897143 C24,17.7668571 22.6577143,19.0457143 21,19.0457143 L15.3017143,19.0457143 L9.55371429,22.6971429 C9.26824528,22.8762294 8.91380519,22.9062451 8.60228571,22.7777143 C8.29631486,22.655574 8.07434668,22.3850705 8.01428571,22.0611429 L8.00057143,21.9034286 L8.00057143,19.0457143 L3,19.0457143 C1.45714286,19.0457143 0.162857143,17.928 0.0154285714,16.464 L0,16.1897143 L0,2.85771429 C0,1.27885714 1.34228571,0 3,0 L21,0 Z M10.1931429,4.97828571 C9.432,4.97828571 9.024,5.33142857 8.736,6.17828571 L6.44228571,12.7628571 C6.32914286,13.104 6.28114286,13.3114286 6.28114286,13.4914286 C6.28114286,13.944 6.624,14.2457143 7.11771429,14.2457143 C7.59771429,14.2457143 7.86514286,13.9971429 8.05028571,13.3714286 L8.55942857,11.8217143 L11.7565714,11.8217143 L12.2708571,13.3714286 C12.4508571,13.992 12.7302857,14.2457143 13.2188571,14.2457143 C13.7331429,14.2457143 14.0725714,13.9371429 14.0725714,13.4571429 C14.0725714,13.2685714 14.0262857,13.0508571 13.9337143,12.7628571 L11.6365714,6.18 C11.3502857,5.32628571 10.9474286,4.98 10.1948571,4.98 L10.1931429,4.97828571 Z M16.2531429,4.98514286 C15.6805714,4.98514286 15.3514286,5.34514286 15.3514286,5.95885714 L15.3514286,13.2565714 C15.3514286,13.8702857 15.6874286,14.2302857 16.26,14.2302857 C16.8291429,14.2302857 17.1565714,13.8702857 17.1565714,13.2531429 L17.1565714,5.96228571 C17.1565714,5.34857143 16.8222857,4.98514286 16.2531429,4.98514286 Z M10.2085714,6.79371429 L11.364,10.4794286 L8.95714286,10.4794286 L10.1177143,6.79371429 L10.2085714,6.79371429 L10.2085714,6.79371429 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const icon3 = <div style={{height: 24, width: 24, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="linearGradient-1">
            <stop stop-color="#FFFFFF" stop-opacity="0.701048951" offset="0%"></stop>
            <stop stop-color="#FFFFFF" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="支付页面" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="开通会员页面" transform="translate(-38.000000, -372.000000)" fill="url(#linearGradient-1)" fill-rule="nonzero">
            <g id="编组-11" transform="translate(12.000000, 326.000000)">
                <g id="a-zhineng4备份-3" transform="translate(26.000000, 46.000000)">
                    <g id="daochu" transform="translate(1.000000, 1.000000)">
                        <path d="M9.28125,0 L12.71875,0 C13.2902344,0 13.75,0.459765625 13.75,1.03125 L13.75,8.25 L17.5183594,8.25 C18.2832031,8.25 18.665625,9.17382813 18.1242187,9.71523437 L11.5886719,16.2550781 C11.2664062,16.5773438 10.7378906,16.5773438 10.415625,16.2550781 L3.87148437,9.71523437 C3.33007812,9.17382813 3.7125,8.25 4.47734375,8.25 L8.25,8.25 L8.25,1.03125 C8.25,0.459765625 8.70976562,0 9.28125,0 Z M22,16.15625 L22,20.96875 C22,21.5402344 21.5402344,22 20.96875,22 L1.03125,22 C0.459765625,22 0,21.5402344 0,20.96875 L0,16.15625 C0,15.5847656 0.459765625,15.125 1.03125,15.125 L7.33476562,15.125 L9.44023437,17.2304688 C10.3039062,18.0941406 11.6960937,18.0941406 12.5597656,17.2304688 L14.6652344,15.125 L20.96875,15.125 C21.5402344,15.125 22,15.5847656 22,16.15625 Z M16.671875,19.9375 C16.671875,19.4648438 16.2851562,19.078125 15.8125,19.078125 C15.3398438,19.078125 14.953125,19.4648438 14.953125,19.9375 C14.953125,20.4101562 15.3398438,20.796875 15.8125,20.796875 C16.2851562,20.796875 16.671875,20.4101562 16.671875,19.9375 Z M19.421875,19.9375 C19.421875,19.4648438 19.0351562,19.078125 18.5625,19.078125 C18.0898438,19.078125 17.703125,19.4648438 17.703125,19.9375 C17.703125,20.4101562 18.0898438,20.796875 18.5625,20.796875 C19.0351562,20.796875 19.421875,20.4101562 19.421875,19.9375 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const icon4 = <div style={{height: 18, width: 152, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="168px" height="18px" viewBox="0 0 168 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="支付页面" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="SVIP会员" transform="translate(-104.000000, -335.000000)">
            <g id="编组-12" transform="translate(12.000000, 326.000000)">
                <g id="编组-7" transform="translate(93.000000, 9.000000)">
                    <text id="SVIP会员权益" font-family="PingFangSC-Medium, PingFang SC" font-size="13" font-weight="400" fill="#6F4B23">
                        <tspan x="43" y="14">SVIP会员权益</tspan>
                    </text>
                    <rect id="矩形" fill="#6F4B23" transform="translate(130.828427, 8.828427) rotate(-315.000000) translate(-130.828427, -8.828427) " x="128.828427" y="6.82842712" width="4" height="4"></rect>
                    <rect id="矩形备份" fill="#6F4B23" transform="translate(36.828427, 8.828427) rotate(-315.000000) translate(-36.828427, -8.828427) " x="34.8284271" y="6.82842712" width="4" height="4"></rect>
                    <line x1="136" y1="9" x2="166" y2="9" id="路径-2" stroke="#6F4B23" stroke-width="0.5" stroke-linecap="round"></line>
                    <line x1="0" y1="9" x2="30" y2="9" id="路径-2备份" stroke="#6F4B23" stroke-width="0.5" stroke-linecap="round"></line>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />


const icon5 = <div style={{height: 24, width: 24, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="100%" y1="4.65105603%" x2="0%" y2="95.348944%" id="linearGradient-1">
            <stop stop-color="#A88249" offset="0%"></stop>
            <stop stop-color="#6F4B23" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="支付页面" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="升级svip会员" transform="translate(-211.000000, -279.000000)" fill="url(#linearGradient-1)" fill-rule="nonzero">
            <g id="编组-12" transform="translate(12.000000, 233.000000)">
                <g id="a-zhineng4" transform="translate(199.000000, 46.000000)">
                    <path d="M21,0.6 C22.656,0.6 24,1.87885714 24,3.45771429 L24,16.7897143 C24,18.3668571 22.6577143,19.6457143 21,19.6457143 L15.3017143,19.6457143 L9.55371429,23.2971429 C9.26824528,23.4762294 8.91380519,23.5062451 8.60228571,23.3777143 C8.29631486,23.255574 8.07434668,22.9850705 8.01428571,22.6611429 L8.00057143,22.5034286 L8.00057143,19.6457143 L3,19.6457143 C1.45714286,19.6457143 0.162857143,18.528 0.0154285714,17.064 L0,16.7897143 L0,3.45771429 C0,1.87885714 1.34228571,0.6 3,0.6 L21,0.6 Z M10.1931429,5.57828571 C9.432,5.57828571 9.024,5.93142857 8.736,6.77828571 L6.44228571,13.3628571 C6.32914286,13.704 6.28114286,13.9114286 6.28114286,14.0914286 C6.28114286,14.544 6.624,14.8457143 7.11771429,14.8457143 C7.59771429,14.8457143 7.86514286,14.5971429 8.05028571,13.9714286 L8.55942857,12.4217143 L11.7565714,12.4217143 L12.2708571,13.9714286 C12.4508571,14.592 12.7302857,14.8457143 13.2188571,14.8457143 C13.7331429,14.8457143 14.0725714,14.5371429 14.0725714,14.0571429 C14.0725714,13.8685714 14.0262857,13.6508571 13.9337143,13.3628571 L11.6365714,6.78 C11.3502857,5.92628571 10.9474286,5.58 10.1948571,5.58 L10.1931429,5.57828571 Z M16.2531429,5.58514286 C15.6805714,5.58514286 15.3514286,5.94514286 15.3514286,6.55885714 L15.3514286,13.8565714 C15.3514286,14.4702857 15.6874286,14.8302857 16.26,14.8302857 C16.8291429,14.8302857 17.1565714,14.4702857 17.1565714,13.8531429 L17.1565714,6.56228571 C17.1565714,5.94857143 16.8222857,5.58514286 16.2531429,5.58514286 Z M10.2085714,7.39371429 L11.364,11.0794286 L8.95714286,11.0794286 L10.1177143,7.39371429 L10.2085714,7.39371429 L10.2085714,7.39371429 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const icon6 = <div style={{height: 24, width: 24, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="linearGradient-1">
            <stop stop-color="#A88249" offset="0%"></stop>
            <stop stop-color="#6F4B23" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="支付页面" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="升级svip会员" transform="translate(-38.000000, -279.000000)" fill="url(#linearGradient-1)" fill-rule="nonzero">
            <g id="编组-12" transform="translate(12.000000, 233.000000)">
                <g id="a-zhineng4备份" transform="translate(26.000000, 46.000000)">
                    <path d="M10.28125,1 L13.71875,1 C14.2902344,1 14.75,1.45976562 14.75,2.03125 L14.75,9.25 L18.5183594,9.25 C19.2832031,9.25 19.665625,10.1738281 19.1242187,10.7152344 L12.5886719,17.2550781 C12.2664062,17.5773438 11.7378906,17.5773438 11.415625,17.2550781 L4.87148437,10.7152344 C4.33007812,10.1738281 4.7125,9.25 5.47734375,9.25 L9.25,9.25 L9.25,2.03125 C9.25,1.45976562 9.70976562,1 10.28125,1 Z M23,17.15625 L23,21.96875 C23,22.5402344 22.5402344,23 21.96875,23 L2.03125,23 C1.45976562,23 1,22.5402344 1,21.96875 L1,17.15625 C1,16.5847656 1.45976562,16.125 2.03125,16.125 L8.33476562,16.125 L10.4402344,18.2304688 C11.3039062,19.0941406 12.6960937,19.0941406 13.5597656,18.2304688 L15.6652344,16.125 L21.96875,16.125 C22.5402344,16.125 23,16.5847656 23,17.15625 Z M17.671875,20.9375 C17.671875,20.4648438 17.2851562,20.078125 16.8125,20.078125 C16.3398438,20.078125 15.953125,20.4648438 15.953125,20.9375 C15.953125,21.4101562 16.3398438,21.796875 16.8125,21.796875 C17.2851562,21.796875 17.671875,21.4101562 17.671875,20.9375 Z M20.421875,20.9375 C20.421875,20.4648438 20.0351562,20.078125 19.5625,20.078125 C19.0898438,20.078125 18.703125,20.4648438 18.703125,20.9375 C18.703125,21.4101562 19.0898438,21.796875 19.5625,21.796875 C20.0351562,21.796875 20.421875,21.4101562 20.421875,20.9375 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />


const initVipList = [
    {
        id: 13,
        title: '终身VIP',
        price: '',
        mPrice: '',
        oPrice: '',
        tag: '超值特惠',
        sTag: '限时1折',
    },
    {
        id: 12,
        title: '年VIP',
        price: '',
        mPrice: '',
        oPrice: '',
        tag: '限时5折',
        sTag: '限时3折',
    },
    {
        id: 11,
        title: '月VIP',
        price: '',
        mPrice: '',
        oPrice: '',
        tag: '',
        sTag: '体验首选',
    },
    {
        id: 30,
        title: '七天VIP',
        price: '',
        mPrice: '',
        oPrice: '',
        tag: '',
        sTag: '体验首选',
    },
]

const mapVipToInfo = {
    vip: {
        icon: 'https://oss.jianzeppt.com/user/573972/aa217ee8-b703-4e8a-b6a7-f04d1183c023.png',
        icon2: 'https://oss.jianzeppt.com/user/253595/07ce629c-5bb7-4714-bc0b-67eb4fb9b5b2.png',
        icon3: 'https://oss.jianzeppt.com/user/2370869/f3d1e9f6-573a-418e-8dbe-49dbbe6cafd0.png',
        icon4: 'https://oss.jianzeppt.com/user/2370869/2fb69147-f406-4872-9033-05b284425ab8.png',
        text1: '10次/月<span>PPT下载限制</span>',
        text2: '50页/月<span>AI ChatGPT生成 </span>'
    },
    svip: {
        icon: 'https://oss.jianzeppt.com/user/573972/50ec1a5f-937b-4d2a-9a5c-20f793848c33.png',
        icon2: 'https://oss.jianzeppt.com/user/2370869/8ec21a5b-a033-44b0-9097-63064127ac48.png',
        icon3: 'https://oss.jianzeppt.com/user/2370869/89a37482-b752-4048-82af-d14382aacdaf.png',
        icon4: 'https://oss.jianzeppt.com/user/2370869/1750d9ce-82b3-4ec2-a014-df70779fcb23.png',
        text1: '100次/月<span>PPT下载限制</span>',
        text2: '500页/月<span>AI ChatGPT生成 </span>'
    }
}

const IOSPay = ({ visible, onSuccess, onClose, children }) => {
    const timerRef = useRef(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [vipList, setVipList] = useState(initVipList)
    const [priceConfig, setPriceConfig] = useState(vipOrderConfig)
    // { orderId: itemData }
    const [orderDataMap, setOrderDataMap] = useState({});
    // orderConfig == priceConfig
    const [orderConfig, setOrderConfig] = useState({});
    const [selectIndex, setSelectIndex] = useState(0)
    const [payVisible, setPayVisible] = useState(false)
    const [vipType, setVipType] = useState('vip')
    const [isBind, setIsBind] = useState(false)
    // 引入升级功能
    const { isUpgrade, Deduction, isVipLift } = useUpgrade({ visible: true })
    const [createLoading, setCreateLoading] = useState(false)
    useEffect(() => {
        if (!isMobileLogin()) {
            dispatch(errorSlice.actions.setCode(10004))
        }
    }, [])
    
    useEffect(() => {
        init()
    }, [priceConfig])

    useEffect(() => {
        const user = store.get('user')
        if (user?.vipType === svip_life) {
            navigate('/mobile/my')
        }
    }, [])

    useEffect(() => {
        const outTradeNo = localStorage.getItem('outTradeNo')
        if (outTradeNo) {
            checkPayStaus(outTradeNo)
        }
    }, [])

    const init = async () => {
        try {
            setCreateLoading(true)
            const { orderDataMap, orderConfig } = await fetchConfig()
            setOrderConfig(orderConfig)
            setOrderDataMap(orderDataMap)
            setCreateLoading(false)
        } catch (error) {
            Toast.show({
                icon: 'fail',
                content: '出错了，请联系管理员'
            })
            setCreateLoading(false)
        }
    }

    const fetchConfig = async () => {
        // 用户必须登录否则创建订单的用户和发起订单的用户不是同一个人
        // 为什么要把三个不同的付费类型都发送请求创建订单？
        const dataPromiseArr = priceConfig.map((item) => {
            return create_order_edit("", {
                abType: isB() ? 1 : 0,
                isUpgrade: isUpgrade,
                orderType: item.id,
                pptId: "-2",
            })
        });
        // 使用 Promise.allSettled 而不是 Promise.all
        const results = await Promise.allSettled(dataPromiseArr);

        const orderDataMap = new Map();
        let newOrderConfig = [];

        // 使用 results 数组而不是 dataArr
        results.forEach((result, index) => {
            if (result.status === 'fulfilled' && result.value) {
                newOrderConfig.push(priceConfig[index]);
                orderDataMap.set(priceConfig[index].id, { ...result.value });
            }
        });

        // 根据成功创建的订单更新 vipList
        const newVipList = newOrderConfig.map((config, index) => {
            const item = orderDataMap.get(config.id);
            let baseMonth = 12;
            if (config.id === 2) baseMonth = 1; // 假设 config.id 与 index 的对应关系
            if (config.id === 0) baseMonth = 100 * 12; // 假设 config.id 与 index 的对应关系
            const price = isUpgrade ? item.actualPrice : item.baseMoney
            return {
                ...initVipList[index],
                price,
                oPrice: item.originPrice,
                mPrice: (price / baseMonth).toFixed(2)
            };
        });

        setVipList(newVipList);
        return { orderDataMap, orderConfig: newOrderConfig };
    }

    const checkPayStaus = (outTradeNo) => {
        clearInterval(timerRef.current)
        const checkFn = () => {
            const payload = {
                out_trade_no: outTradeNo
            }
            pay_order_query(payload).then(res => {
                if (res?.isPaid === 1) {
                    // 支付成功
                    showPaySuccessModal()
                    clearInterval(timerRef.current)
                    localStorage.removeItem('outTradeNo')
                    // 关闭支付弹窗
                    handleClose()
                }
            }).catch(err => {
                console.log(err)
            })
        }
        checkFn()
        timerRef.current = setInterval(() => {
            checkFn()
        }, 1000)
    }
    window.checkPayStaus = checkPayStaus
    const pay = async (type) => {
        if (createLoading) {
            Toast.show({
                icon: 'loading',
                content: '请稍后'
            })
            return 
        }
        // 未登录强制要求登录
        if (!isLogin()) {
            Toast.show({
                icon: 'loading',
                content: '登录中…',
            })
            wxLogin(() => {
                // 登录成功后重新创建订单
                console.log('登录成功后创建订单')
                init()
            }).finally(() => {
                Toast.clear()
            })
            return
        }
        const first = orderDataMap.get(orderConfig[selectIndex].id);
        let code_url;
        if (type === "wechat") {
            let data = {}
            if (isWechatBrowser()) {
                try {
                    data = await payWxJsApiPay({
                        "outTradeNo": first.outTradeNo
                    })
                    const { prepay_id, sign, nonceStr, timeStamp, signType } = data
                    wxJsApiPay({
                        data: { prepay_id, sign, nonceStr, timeStamp, signType },
                        onSuccess: () => {
                            window.location.reload()
                        }
                    })
                } catch (error) {
                    // // window.localStorage.clear()
                    // Toast.show({
                    //     icon: 'loading',
                    //     content: '登录中…',
                    // })
                    // wxLogin().finally(() => {
                    //     Toast.clear()
                    // })
                }
            } else if (isMiniProgram()) {
                data = await payMiniProgramPay({
                    "outTradeNo": first.outTradeNo
                })
                // 拉起微信小程序支付
                const { prepay_id, sign, nonceStr, timeStamp, signType } = data
                wxSdkPay({
                    data: { prepay_id, sign, nonceStr, timeStamp, signType },
                })
            } else {
                data = await payWxPay({
                    "outTradeNo": first.outTradeNo
                })
            }


            code_url = data.code_url
        } else {
            const data = await payAlipay({
                "outTradeNo": first.outTradeNo
            })
            code_url = data.code_url
        }
        let url = new URL(window.location.href)
        // 添加支付成功标识
        url.searchParams.set('outTradeNo', first.outTradeNo)
        localStorage.setItem('outTradeNo', first.outTradeNo)
        // 开始轮询
        checkPayStaus(first.outTradeNo)

        // 非微信内支付
        if (!isWechat()) {
            window.location.href = code_url + (code_url.indexOf("?") !== -1 ? '&' : '?') + "redirect_url=" + window.encodeURIComponent(url)
        }
    }

    const handleClose = () => {
        onClose && onClose()
    }

    const handleClickTip = () => {
        showPaySuccessModal()
        setIsBind(true)
    }

    const handleClickVip = (type) => {
        setVipType(type)
        if (type === 'vip') {
            setPriceConfig(vipOrderConfig)
        } else if (type === 'svip') {
            setPriceConfig(sVipOrderConfig)
        }
    }

    const showPaySuccessModal = () => {
        // 非微信环境才显示弹窗，微信环境内显示支付成功即可
        if (!isWechat()) {
            setPayVisible(true)
        } else {
            Toast.show({
                icon: 'success',
                content: '支付成功',
            })
        }
    }

    const handleClickIOSWxApp = () => {
        Modal.show({
            bodyClassName: 'ios-wxapp-modal',
            title: '提示',
            content: '点击“查看详情”，了解更多详细信息。',
            closeOnAction: true,
            actions: [
                {
                    key: 'download',
                    text: '关闭',
                    primary: true,
                },
                {
                    key: 'online',
                    text: '查看详情',
                    primary: true,
                    onClick: () => {
                        // 跳转到微信公众号文章
                        window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzkxMDUwOTAyNA==&mid=2247483663&idx=1&sn=161527a21586501624c2de18f44d64d6&chksm=c12b1780f65c9e96c37dc68d02611222d851f32a8ef44295ae1a517fdda7ac14cf6f1f657854#rd'
                    }
                },
            ],
        })
    }

    const handleClickAgreement = () => {
        navigate('/mobile/agreement')
        handleClose()
    }


    const cls = classNames({
        'pay-wrap': true,
        [vipType]: true
    })

    const clsVip = classNames({
        'tab-item': true,
        'active': vipType === 'vip'
    })

    const clsSvip = classNames({
        'tab-item': true,
        'active': vipType === 'svip'
    })

    useEffect(() => {
        handleClickVip('svip')
    }, [])

    window.isSupportWxPay = isSupportWxPay

    const rightsEle = useMemo(() => {
        const isSvip = vipType === 'svip'
        return (
            <div className='rights' style={{ background : isSvip ? '#FCF9EF' : '#F5FBFA' }}>
                <div className='head'>
                    {isSvip ? icon4 : icon1}
                </div>
                <div className='content'>
                    <div className='item'>
                        <div className='left' style={{ background : isSvip ? '#F8D183' : '#00BCA6' }}>
                            {isSvip ? icon6 : icon3}
                        </div>
                        <div className='right'>
                            <div className='title' style={{ color : isSvip ? '#EDB769' : '#00BCA6' }}>简历下载</div>
                            <div className='time'>{isSvip ? '60次/月' : '30次/月'}</div>
                        </div>
                    </div>
                    <div className='item item-2'>
                        <div className='left' style={{ background : isSvip ? '#F8D183' : '#00BCA6' }}>
                            {isSvip ? icon5 : icon2}
                        </div>
                        <div className='right'>
                            <div className='title' style={{ color : isSvip ? '#EDB769' : '#00BCA6' }}>AI对话额度</div>
                            <div className='time'>{isSvip ? '600次/月' : '300次/月'}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }, [vipType])

    return (
        <div className='pay-wrap'>
            <div className={cls}>
                <div className="tab">
                    {
                        !isUserSvip() && <div onClick={() => handleClickVip('vip')} className={clsVip}>
                            {
                                isUpgrade ? '升级VIP会员' : 'VIP会员'
                            }
                        </div>
                    }
                    <div onClick={() => handleClickVip('svip')} className={clsSvip}>
                        {
                            isUpgrade ? '升级SVIP会员' : 'SVIP会员'
                        }
                    </div>
                </div>
                {/* <div className="vip-info">
                    <div className="vip-title">
                        <img src={mapVipToInfo[vipType].icon2} />
                    </div>
                    <div className='info'>
                        <div className="info-item">
                            <div className="img"><img src={mapVipToInfo[vipType].icon3} /></div>
                            <div className="content" dangerouslySetInnerHTML={{ __html: mapVipToInfo[vipType].text1 }} />
                        </div>
                        <div className="info-item">
                            <div className="img"><img src={mapVipToInfo[vipType].icon4} /></div>
                            <div className="content" dangerouslySetInnerHTML={{ __html: mapVipToInfo[vipType].text2 }} />
                        </div>
                    </div>
                </div> */}
                {rightsEle}
                {
                    <div className="vip-list">
                        {
                            vipList.map((item, index) => {
                                //特殊处理下 7天不要
                                if(index === 3) return
                                const { title, price, mPrice, oPrice, tag, sTag } = item
                                const isSvip = vipType === 'svip'
                                const cls = classNames({
                                    'vip-item': true,
                                    'active': index === selectIndex,
                                    's-active': isSvip
                                })
                                return <div key={index} className={cls} onClick={() => setSelectIndex(index)}>
                                    {
                                        isSvip ? sTag && <div className='tag'>{sTag}</div> : tag && <div className='tag'>{tag}</div>
                                    }
                                    <p className='title'>{title}</p>
                                    <p className='price'>¥<span>{price}</span></p>
                                    <div className="divide" />
                                    <p className='m-price'>¥{mPrice}/月</p>
                                    <p className='o-price'>原价 ¥ {oPrice}</p>
                                </div>
                            })
                        }
                    </div>
                }
                {Deduction}
                <div className='divider' />
                {
                    isIOSMiniProgram() && <div onClick={handleClickIOSWxApp} className="ios-miniprogram-btn" >
                        解锁秒出会员权益
                    </div>
                }
                {
                    isSupportWxPay() && <div onClick={() => pay('wechat')} className="wx-btn jz-resume-btn">
                        <img src={'https://oss.jianzeppt.com/user/573972/caad3e75-b041-4416-86f8-16a477d43e50.png'} alt='wx' />
                        微信支付
                    </div>
                }
                {
                    !isWechat() && <div onClick={() => pay('ali')} className="ali-btn jz-resume-btn">
                        <img src={'https://oss.jianzeppt.com/user/573972/c362590a-82ac-4354-99e0-100d5b4e05cf.png'} alt='ali' />
                        支付宝支付
                    </div>
                }

                {
                    !isWechat() && <p onClick={handleClickTip} className='tip'>我已是PC端会员，<span>绑定微信</span></p>
                }
                {
                    <p onClick={handleClickAgreement} className='agreement'>支付即代表您同意<span>会员协议</span></p>
                }
            </div>
            <PaySuccessModal isBind={isBind} visible={payVisible} onClose={() => setPayVisible(false)} />
        </div>
    )
}

export default IOSPay;
