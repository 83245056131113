import withComponent from "@/hoc/withComponent";
import getModuleDataListBySort from "@/common/resume/getModuleDataListBySort";
import {createElement, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import {blockHandler} from "@/common/config/configRender";
import {Module} from "@/common/module/module";
import useConfigRender from "@/components/resume/hooks/useConfigRender";




export default withComponent((props) => {
    const ref = useRef();
    const resumeData = useSelector((state) => state.resumeData);
    const template = useSelector((state) => state.template);
    const list = useMemo(() => getModuleDataListBySort(resumeData), [resumeData])
    const moduleConfig = template.moduleConfig;
    const configRender = useConfigRender();
    return createElement(props.tagName, {
        className: props.className,
        style: props.style,
        ['data-layout-type']: props["data-layout-type"],
        ref
    }, [
        ...props.children?.map?.((config) => configRender(config)), ...list.map((item) => {
            let targetConfig = moduleConfig[item.id];
            let key = item.id;
            if (item.type === Module.CUSTOM_MODULE) {
                targetConfig = moduleConfig[Module.CUSTOM_MODULE];
            }
            if (item.type === Module.APPLICATION_INFO) {
                targetConfig = moduleConfig[Module.CUSTOM_MODULE];
            }
            if (targetConfig && targetConfig.for_layout === props["data-layout-type"]) {
                return blockHandler({...targetConfig, key, data: item})
            }
        })
    ]);
})
