const data = [
    {
        "id": 1054,
        "key": "1054",
        "name": "Java",
        "type": null,
        "description": null
    },
    {
        "id": 1055,
        "key": "1055",
        "name": "C/C++",
        "type": null,
        "description": null
    },
    {
        "id": 1056,
        "key": "1056",
        "name": "PHP",
        "type": null,
        "description": null
    },
    {
        "id": 1057,
        "key": "1057",
        "name": "Python",
        "type": null,
        "description": null
    },
    {
        "id": 1058,
        "key": "1058",
        "name": "C#",
        "type": null,
        "description": null
    },
    {
        "id": 1059,
        "key": "1059",
        "name": ".NET",
        "type": null,
        "description": null
    },
    {
        "id": 1060,
        "key": "1060",
        "name": "Golang",
        "type": null,
        "description": null
    },
    {
        "id": 1061,
        "key": "1061",
        "name": "Node.js",
        "type": null,
        "description": null
    },
    {
        "id": 1062,
        "key": "1062",
        "name": "Hadoop",
        "type": null,
        "description": null
    },
    {
        "id": 1063,
        "key": "1063",
        "name": "语音/视频/图形开发",
        "type": null,
        "description": null
    },
    {
        "id": 1064,
        "key": "1064",
        "name": "GIS工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1065,
        "key": "1065",
        "name": "区块链工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1066,
        "key": "1066",
        "name": "全栈工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1067,
        "key": "1067",
        "name": "其他后端开发",
        "type": null,
        "description": null
    },
    {
        "id": 1068,
        "key": "1068",
        "name": "前端开发工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1069,
        "key": "1069",
        "name": "Android",
        "type": null,
        "description": null
    },
    {
        "id": 1070,
        "key": "1070",
        "name": "iOS",
        "type": null,
        "description": null
    },
    {
        "id": 1071,
        "key": "1071",
        "name": "U3D",
        "type": null,
        "description": null
    },
    {
        "id": 1072,
        "key": "1072",
        "name": "UE4",
        "type": null,
        "description": null
    },
    {
        "id": 1073,
        "key": "1073",
        "name": "Cocos",
        "type": null,
        "description": null
    },
    {
        "id": 1074,
        "key": "1074",
        "name": "技术美术",
        "type": null,
        "description": null
    },
    {
        "id": 1075,
        "key": "1075",
        "name": "JavaScript",
        "type": null,
        "description": null
    },
    {
        "id": 1076,
        "key": "1076",
        "name": "测试工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1077,
        "key": "1077",
        "name": "软件测试",
        "type": null,
        "description": null
    },
    {
        "id": 1078,
        "key": "1078",
        "name": "自动化测试",
        "type": null,
        "description": null
    },
    {
        "id": 1079,
        "key": "1079",
        "name": "功能测试",
        "type": null,
        "description": null
    },
    {
        "id": 1080,
        "key": "1080",
        "name": "测试开发",
        "type": null,
        "description": null
    },
    {
        "id": 1081,
        "key": "1081",
        "name": "硬件测试",
        "type": null,
        "description": null
    },
    {
        "id": 1082,
        "key": "1082",
        "name": "游戏测试",
        "type": null,
        "description": null
    },
    {
        "id": 1083,
        "key": "1083",
        "name": "性能测试",
        "type": null,
        "description": null
    },
    {
        "id": 1084,
        "key": "1084",
        "name": "渗透测试",
        "type": null,
        "description": null
    },
    {
        "id": 1085,
        "key": "1085",
        "name": "移动端测试",
        "type": null,
        "description": null
    },
    {
        "id": 1086,
        "key": "1086",
        "name": "运维工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1087,
        "key": "1087",
        "name": "IT技术支持",
        "type": null,
        "description": null
    },
    {
        "id": 1088,
        "key": "1088",
        "name": "网络工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1089,
        "key": "1089",
        "name": "网络安全",
        "type": null,
        "description": null
    },
    {
        "id": 1090,
        "key": "1090",
        "name": "系统工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1091,
        "key": "1091",
        "name": "运维开发工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1092,
        "key": "1092",
        "name": "系统管理员",
        "type": null,
        "description": null
    },
    {
        "id": 1093,
        "key": "1093",
        "name": "DBA",
        "type": null,
        "description": null
    },
    {
        "id": 1094,
        "key": "1094",
        "name": "系统安全",
        "type": null,
        "description": null
    },
    {
        "id": 1095,
        "key": "1095",
        "name": "技术文档工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1096,
        "key": "1096",
        "name": "图像算法",
        "type": null,
        "description": null
    },
    {
        "id": 1097,
        "key": "1097",
        "name": "自然语言处理算法",
        "type": null,
        "description": null
    },
    {
        "id": 1098,
        "key": "1098",
        "name": "大模型算法",
        "type": null,
        "description": null
    },
    {
        "id": 1099,
        "key": "1099",
        "name": "数据挖掘",
        "type": null,
        "description": null
    },
    {
        "id": 1100,
        "key": "1100",
        "name": "规控算法",
        "type": null,
        "description": null
    },
    {
        "id": 1101,
        "key": "1101",
        "name": "SLAM算法",
        "type": null,
        "description": null
    },
    {
        "id": 1102,
        "key": "1102",
        "name": "推荐算法",
        "type": null,
        "description": null
    },
    {
        "id": 1103,
        "key": "1103",
        "name": "搜索算法",
        "type": null,
        "description": null
    },
    {
        "id": 1104,
        "key": "1104",
        "name": "语音算法",
        "type": null,
        "description": null
    },
    {
        "id": 1105,
        "key": "1105",
        "name": "风控算法",
        "type": null,
        "description": null
    },
    {
        "id": 1106,
        "key": "1106",
        "name": "算法研究员",
        "type": null,
        "description": null
    },
    {
        "id": 1107,
        "key": "1107",
        "name": "算法工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1108,
        "key": "1108",
        "name": "机器学习",
        "type": null,
        "description": null
    },
    {
        "id": 1109,
        "key": "1109",
        "name": "深度学习",
        "type": null,
        "description": null
    },
    {
        "id": 1110,
        "key": "1110",
        "name": "自动驾驶系统工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1111,
        "key": "1111",
        "name": "数据标注/AI训练师",
        "type": null,
        "description": null
    },
    {
        "id": 1112,
        "key": "1112",
        "name": "售前技术支持",
        "type": null,
        "description": null
    },
    {
        "id": 1113,
        "key": "1113",
        "name": "售后技术支持",
        "type": null,
        "description": null
    },
    {
        "id": 1114,
        "key": "1114",
        "name": "销售技术支持",
        "type": null,
        "description": null
    },
    {
        "id": 1115,
        "key": "1115",
        "name": "客户成功",
        "type": null,
        "description": null
    },
    {
        "id": 1116,
        "key": "1116",
        "name": "数据分析师",
        "type": null,
        "description": null
    },
    {
        "id": 1117,
        "key": "1117",
        "name": "数据开发",
        "type": null,
        "description": null
    },
    {
        "id": 1118,
        "key": "1118",
        "name": "数据仓库",
        "type": null,
        "description": null
    },
    {
        "id": 1119,
        "key": "1119",
        "name": "ETL工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1121,
        "key": "1121",
        "name": "数据架构师",
        "type": null,
        "description": null
    },
    {
        "id": 1122,
        "key": "1122",
        "name": "爬虫工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1123,
        "key": "1123",
        "name": "数据采集",
        "type": null,
        "description": null
    },
    {
        "id": 1124,
        "key": "1124",
        "name": "项目经理/主管",
        "type": null,
        "description": null
    },
    {
        "id": 1125,
        "key": "1125",
        "name": "项目助理",
        "type": null,
        "description": null
    },
    {
        "id": 1126,
        "key": "1126",
        "name": "项目专员",
        "type": null,
        "description": null
    },
    {
        "id": 1128,
        "key": "1128",
        "name": "实施顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1129,
        "key": "1129",
        "name": "需求分析工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1130,
        "key": "1130",
        "name": "硬件项目经理",
        "type": null,
        "description": null
    },
    {
        "id": 1131,
        "key": "1131",
        "name": "技术经理",
        "type": null,
        "description": null
    },
    {
        "id": 1132,
        "key": "1132",
        "name": "架构师",
        "type": null,
        "description": null
    },
    {
        "id": 1133,
        "key": "1133",
        "name": "技术总监",
        "type": null,
        "description": null
    },
    {
        "id": 1134,
        "key": "1134",
        "name": "CTO",
        "type": null,
        "description": null
    },
    {
        "id": 1135,
        "key": "1135",
        "name": "测试经理",
        "type": null,
        "description": null
    },
    {
        "id": 1136,
        "key": "1136",
        "name": "技术合伙人",
        "type": null,
        "description": null
    },
    {
        "id": 1137,
        "key": "1137",
        "name": "运维总监",
        "type": null,
        "description": null
    },
    {
        "id": 1138,
        "key": "1138",
        "name": "其他技术职位",
        "type": null,
        "description": null
    },
    {
        "id": 1146,
        "key": "1146",
        "name": "集成电路IC设计",
        "type": null,
        "description": null
    },
    {
        "id": 1147,
        "key": "1147",
        "name": "数字IC验证工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1148,
        "key": "1148",
        "name": "模拟版图设计工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1149,
        "key": "1149",
        "name": "FAE",
        "type": null,
        "description": null
    },
    {
        "id": 1150,
        "key": "1150",
        "name": "电子工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1151,
        "key": "1151",
        "name": "硬件工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1152,
        "key": "1152",
        "name": "嵌入式软件工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1153,
        "key": "1153",
        "name": "FPGA开发",
        "type": null,
        "description": null
    },
    {
        "id": 1154,
        "key": "1154",
        "name": "单片机",
        "type": null,
        "description": null
    },
    {
        "id": 1156,
        "key": "1156",
        "name": "PCB工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1157,
        "key": "1157",
        "name": "电子维修技术员",
        "type": null,
        "description": null
    },
    {
        "id": 1158,
        "key": "1158",
        "name": "射频工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1159,
        "key": "1159",
        "name": "电路设计",
        "type": null,
        "description": null
    },
    {
        "id": 1160,
        "key": "1160",
        "name": "系统集成",
        "type": null,
        "description": null
    },
    {
        "id": 1161,
        "key": "1161",
        "name": "光学工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1162,
        "key": "1162",
        "name": "DSP开发",
        "type": null,
        "description": null
    },
    {
        "id": 1163,
        "key": "1163",
        "name": "ARM开发",
        "type": null,
        "description": null
    },
    {
        "id": 1164,
        "key": "1164",
        "name": "通信项目专员",
        "type": null,
        "description": null
    },
    {
        "id": 1166,
        "key": "1166",
        "name": "通信技术工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1167,
        "key": "1167",
        "name": "通信研发工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1168,
        "key": "1168",
        "name": "无线/射频通信工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1169,
        "key": "1169",
        "name": "移动通信工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1170,
        "key": "1170",
        "name": "电信网络工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1171,
        "key": "1171",
        "name": "数据通信工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1172,
        "key": "1172",
        "name": "通信测试工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1173,
        "key": "1173",
        "name": "光通信工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1174,
        "key": "1174",
        "name": "光传输工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1175,
        "key": "1175",
        "name": "光网络工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1176,
        "key": "1176",
        "name": "通信电源工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1177,
        "key": "1177",
        "name": "有线传输工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1178,
        "key": "1178",
        "name": "通信设备工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1179,
        "key": "1179",
        "name": "核心网工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1180,
        "key": "1180",
        "name": "通信标准化工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1181,
        "key": "1181",
        "name": "电信交换工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1182,
        "key": "1182",
        "name": "电气工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1183,
        "key": "1183",
        "name": "电气设计工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1185,
        "key": "1185",
        "name": "机电工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1186,
        "key": "1186",
        "name": "建筑机电工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1193,
        "key": "1193",
        "name": "产品经理",
        "type": null,
        "description": null
    },
    {
        "id": 1194,
        "key": "1194",
        "name": "产品专员/助理",
        "type": null,
        "description": null
    },
    {
        "id": 1195,
        "key": "1195",
        "name": "高级产品管理岗",
        "type": null,
        "description": null
    },
    {
        "id": 1196,
        "key": "1196",
        "name": "电商产品经理",
        "type": null,
        "description": null
    },
    {
        "id": 1197,
        "key": "1197",
        "name": "AI产品经理",
        "type": null,
        "description": null
    },
    {
        "id": 1198,
        "key": "1198",
        "name": "数据产品经理",
        "type": null,
        "description": null
    },
    {
        "id": 1199,
        "key": "1199",
        "name": "移动产品经理",
        "type": null,
        "description": null
    },
    {
        "id": 1200,
        "key": "1200",
        "name": "金融产品经理",
        "type": null,
        "description": null
    },
    {
        "id": 1202,
        "key": "1202",
        "name": "化妆品产品经理",
        "type": null,
        "description": null
    },
    {
        "id": 1203,
        "key": "1203",
        "name": "硬件产品经理",
        "type": null,
        "description": null
    },
    {
        "id": 1204,
        "key": "1204",
        "name": "其他产品职位",
        "type": null,
        "description": null
    },
    {
        "id": 1205,
        "key": "1205",
        "name": "游戏策划",
        "type": null,
        "description": null
    },
    {
        "id": 1206,
        "key": "1206",
        "name": "系统策划",
        "type": null,
        "description": null
    },
    {
        "id": 1207,
        "key": "1207",
        "name": "游戏数值策划",
        "type": null,
        "description": null
    },
    {
        "id": 1208,
        "key": "1208",
        "name": "游戏制作人",
        "type": null,
        "description": null
    },
    {
        "id": 1209,
        "key": "1209",
        "name": "用户研究员",
        "type": null,
        "description": null
    },
    {
        "id": 1210,
        "key": "1210",
        "name": "用户研究经理",
        "type": null,
        "description": null
    },
    {
        "id": 1211,
        "key": "1211",
        "name": "用户研究总监",
        "type": null,
        "description": null
    },
    {
        "id": 1212,
        "key": "1212",
        "name": "客服专员",
        "type": null,
        "description": null
    },
    {
        "id": 1213,
        "key": "1213",
        "name": "客服主管",
        "type": null,
        "description": null
    },
    {
        "id": 1214,
        "key": "1214",
        "name": "客服经理",
        "type": null,
        "description": null
    },
    {
        "id": 1215,
        "key": "1215",
        "name": "网络客服",
        "type": null,
        "description": null
    },
    {
        "id": 1216,
        "key": "1216",
        "name": "电话客服",
        "type": null,
        "description": null
    },
    {
        "id": 1217,
        "key": "1217",
        "name": "售后客服",
        "type": null,
        "description": null
    },
    {
        "id": 1218,
        "key": "1218",
        "name": "售前客服",
        "type": null,
        "description": null
    },
    {
        "id": 1219,
        "key": "1219",
        "name": "新媒体运营",
        "type": null,
        "description": null
    },
    {
        "id": 1221,
        "key": "1221",
        "name": "视频运营",
        "type": null,
        "description": null
    },
    {
        "id": 1222,
        "key": "1222",
        "name": "内容运营",
        "type": null,
        "description": null
    },
    {
        "id": 1223,
        "key": "1223",
        "name": "微信运营",
        "type": null,
        "description": null
    },
    {
        "id": 1225,
        "key": "1225",
        "name": "跨境电商运营",
        "type": null,
        "description": null
    },
    {
        "id": 1226,
        "key": "1226",
        "name": "品类运营",
        "type": null,
        "description": null
    },
    {
        "id": 1227,
        "key": "1227",
        "name": "淘宝运营",
        "type": null,
        "description": null
    },
    {
        "id": 1228,
        "key": "1228",
        "name": "天猫运营",
        "type": null,
        "description": null
    },
    {
        "id": 1229,
        "key": "1229",
        "name": "京东运营",
        "type": null,
        "description": null
    },
    {
        "id": 1230,
        "key": "1230",
        "name": "拼多多运营",
        "type": null,
        "description": null
    },
    {
        "id": 1231,
        "key": "1231",
        "name": "亚马逊运营",
        "type": null,
        "description": null
    },
    {
        "id": 1233,
        "key": "1233",
        "name": "阿里国际站运营",
        "type": null,
        "description": null
    },
    {
        "id": 1234,
        "key": "1234",
        "name": "速卖通运营",
        "type": null,
        "description": null
    },
    {
        "id": 1235,
        "key": "1235",
        "name": "运营助理/专员",
        "type": null,
        "description": null
    },
    {
        "id": 1236,
        "key": "1236",
        "name": "产品运营",
        "type": null,
        "description": null
    },
    {
        "id": 1237,
        "key": "1237",
        "name": "用户运营",
        "type": null,
        "description": null
    },
    {
        "id": 1238,
        "key": "1238",
        "name": "商家运营",
        "type": null,
        "description": null
    },
    {
        "id": 1239,
        "key": "1239",
        "name": "数据/策略运营",
        "type": null,
        "description": null
    },
    {
        "id": 1240,
        "key": "1240",
        "name": "社区运营",
        "type": null,
        "description": null
    },
    {
        "id": 1241,
        "key": "1241",
        "name": "游戏运营",
        "type": null,
        "description": null
    },
    {
        "id": 1242,
        "key": "1242",
        "name": "活动运营",
        "type": null,
        "description": null
    },
    {
        "id": 1244,
        "key": "1244",
        "name": "内容审核",
        "type": null,
        "description": null
    },
    {
        "id": 1246,
        "key": "1246",
        "name": "车辆运营",
        "type": null,
        "description": null
    },
    {
        "id": 1247,
        "key": "1247",
        "name": "线下拓展运营",
        "type": null,
        "description": null
    },
    {
        "id": 1248,
        "key": "1248",
        "name": "商场运营",
        "type": null,
        "description": null
    },
    {
        "id": 1249,
        "key": "1249",
        "name": "文案编辑",
        "type": null,
        "description": null
    },
    {
        "id": 1252,
        "key": "1252",
        "name": "主编/副主编",
        "type": null,
        "description": null
    },
    {
        "id": 1253,
        "key": "1253",
        "name": "运营经理/主管",
        "type": null,
        "description": null
    },
    {
        "id": 1254,
        "key": "1254",
        "name": "运营总监",
        "type": null,
        "description": null
    },
    {
        "id": 1255,
        "key": "1255",
        "name": "COO",
        "type": null,
        "description": null
    },
    {
        "id": 1256,
        "key": "1256",
        "name": "客服总监",
        "type": null,
        "description": null
    },
    {
        "id": 1258,
        "key": "1258",
        "name": "销售专员",
        "type": null,
        "description": null
    },
    {
        "id": 1259,
        "key": "1259",
        "name": "电话销售",
        "type": null,
        "description": null
    },
    {
        "id": 1260,
        "key": "1260",
        "name": "网络销售",
        "type": null,
        "description": null
    },
    {
        "id": 1262,
        "key": "1262",
        "name": "大客户代表",
        "type": null,
        "description": null
    },
    {
        "id": 1263,
        "key": "1263",
        "name": "客户经理",
        "type": null,
        "description": null
    },
    {
        "id": 1264,
        "key": "1264",
        "name": "BD经理",
        "type": null,
        "description": null
    },
    {
        "id": 1265,
        "key": "1265",
        "name": "客户代表",
        "type": null,
        "description": null
    },
    {
        "id": 1266,
        "key": "1266",
        "name": "销售工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1267,
        "key": "1267",
        "name": "销售经理",
        "type": null,
        "description": null
    },
    {
        "id": 1268,
        "key": "1268",
        "name": "销售总监",
        "type": null,
        "description": null
    },
    {
        "id": 1269,
        "key": "1269",
        "name": "销售VP",
        "type": null,
        "description": null
    },
    {
        "id": 1270,
        "key": "1270",
        "name": "团队经理",
        "type": null,
        "description": null
    },
    {
        "id": 1271,
        "key": "1271",
        "name": "城市经理",
        "type": null,
        "description": null
    },
    {
        "id": 1272,
        "key": "1272",
        "name": "区域总监",
        "type": null,
        "description": null
    },
    {
        "id": 1273,
        "key": "1273",
        "name": "销售助理",
        "type": null,
        "description": null
    },
    {
        "id": 1274,
        "key": "1274",
        "name": "销售运营",
        "type": null,
        "description": null
    },
    {
        "id": 1275,
        "key": "1275",
        "name": "商务专员",
        "type": null,
        "description": null
    },
    {
        "id": 1276,
        "key": "1276",
        "name": "商务经理",
        "type": null,
        "description": null
    },
    {
        "id": 1277,
        "key": "1277",
        "name": "商务总监",
        "type": null,
        "description": null
    },
    {
        "id": 1279,
        "key": "1279",
        "name": "外贸业务员",
        "type": null,
        "description": null
    },
    {
        "id": 1280,
        "key": "1280",
        "name": "外贸经理",
        "type": null,
        "description": null
    },
    {
        "id": 1281,
        "key": "1281",
        "name": "课程顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1282,
        "key": "1282",
        "name": "招生顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1283,
        "key": "1283",
        "name": "留学顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1284,
        "key": "1284",
        "name": "汽车销售",
        "type": null,
        "description": null
    },
    {
        "id": 1285,
        "key": "1285",
        "name": "汽车配件销售",
        "type": null,
        "description": null
    },
    {
        "id": 1286,
        "key": "1286",
        "name": "二手车评估师",
        "type": null,
        "description": null
    },
    {
        "id": 1287,
        "key": "1287",
        "name": "置业顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1288,
        "key": "1288",
        "name": "地产中介",
        "type": null,
        "description": null
    },
    {
        "id": 1289,
        "key": "1289",
        "name": "物业招商管理",
        "type": null,
        "description": null
    },
    {
        "id": 1290,
        "key": "1290",
        "name": "房地产销售总监",
        "type": null,
        "description": null
    },
    {
        "id": 1291,
        "key": "1291",
        "name": "服装导购",
        "type": null,
        "description": null
    },
    {
        "id": 1292,
        "key": "1292",
        "name": "珠宝销售",
        "type": null,
        "description": null
    },
    {
        "id": 1293,
        "key": "1293",
        "name": "美容顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1294,
        "key": "1294",
        "name": "化妆品导购",
        "type": null,
        "description": null
    },
    {
        "id": 1295,
        "key": "1295",
        "name": "会籍顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1296,
        "key": "1296",
        "name": "瘦身顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1297,
        "key": "1297",
        "name": "旅游顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1298,
        "key": "1298",
        "name": "医药代表",
        "type": null,
        "description": null
    },
    {
        "id": 1300,
        "key": "1300",
        "name": "药店店员",
        "type": null,
        "description": null
    },
    {
        "id": 1301,
        "key": "1301",
        "name": "药店店长",
        "type": null,
        "description": null
    },
    {
        "id": 1302,
        "key": "1302",
        "name": "医美咨询",
        "type": null,
        "description": null
    },
    {
        "id": 1304,
        "key": "1304",
        "name": "口腔咨询师",
        "type": null,
        "description": null
    },
    {
        "id": 1305,
        "key": "1305",
        "name": "广告销售",
        "type": null,
        "description": null
    },
    {
        "id": 1306,
        "key": "1306",
        "name": "会展活动销售",
        "type": null,
        "description": null
    },
    {
        "id": 1307,
        "key": "1307",
        "name": "会议活动销售",
        "type": null,
        "description": null
    },
    {
        "id": 1308,
        "key": "1308",
        "name": "理财顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1309,
        "key": "1309",
        "name": "保险顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1310,
        "key": "1310",
        "name": "信用卡销售",
        "type": null,
        "description": null
    },
    {
        "id": 1311,
        "key": "1311",
        "name": "证券经纪人",
        "type": null,
        "description": null
    },
    {
        "id": 1312,
        "key": "1312",
        "name": "其他销售职位",
        "type": null,
        "description": null
    },
    {
        "id": 1313,
        "key": "1313",
        "name": "行政专员/助理",
        "type": null,
        "description": null
    },
    {
        "id": 1314,
        "key": "1314",
        "name": "行政经理/主管",
        "type": null,
        "description": null
    },
    {
        "id": 1316,
        "key": "1316",
        "name": "前台",
        "type": null,
        "description": null
    },
    {
        "id": 1317,
        "key": "1317",
        "name": "后勤",
        "type": null,
        "description": null
    },
    {
        "id": 1318,
        "key": "1318",
        "name": "经理助理",
        "type": null,
        "description": null
    },
    {
        "id": 1319,
        "key": "1319",
        "name": "文员",
        "type": null,
        "description": null
    },
    {
        "id": 1320,
        "key": "1320",
        "name": "企业党建",
        "type": null,
        "description": null
    },
    {
        "id": 1321,
        "key": "1321",
        "name": "人力资源专员/助理",
        "type": null,
        "description": null
    },
    {
        "id": 1322,
        "key": "1322",
        "name": "人力资源经理/主管",
        "type": null,
        "description": null
    },
    {
        "id": 1323,
        "key": "1323",
        "name": "人力资源总监",
        "type": null,
        "description": null
    },
    {
        "id": 1325,
        "key": "1325",
        "name": "HRBP",
        "type": null,
        "description": null
    },
    {
        "id": 1326,
        "key": "1326",
        "name": "培训",
        "type": null,
        "description": null
    },
    {
        "id": 1327,
        "key": "1327",
        "name": "员工关系",
        "type": null,
        "description": null
    },
    {
        "id": 1328,
        "key": "1328",
        "name": "组织发展",
        "type": null,
        "description": null
    },
    {
        "id": 1329,
        "key": "1329",
        "name": "企业文化",
        "type": null,
        "description": null
    },
    {
        "id": 1330,
        "key": "1330",
        "name": "薪酬绩效",
        "type": null,
        "description": null
    },
    {
        "id": 1331,
        "key": "1331",
        "name": "法务专员/助理",
        "type": null,
        "description": null
    },
    {
        "id": 1332,
        "key": "1332",
        "name": "法务经理/主管",
        "type": null,
        "description": null
    },
    {
        "id": 1333,
        "key": "1333",
        "name": "法务总监",
        "type": null,
        "description": null
    },
    {
        "id": 1334,
        "key": "1334",
        "name": "法律顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1335,
        "key": "1335",
        "name": "律师",
        "type": null,
        "description": null
    },
    {
        "id": 1336,
        "key": "1336",
        "name": "其他职能职位",
        "type": null,
        "description": null
    },
    {
        "id": 1337,
        "key": "1337",
        "name": "会计",
        "type": null,
        "description": null
    },
    {
        "id": 1338,
        "key": "1338",
        "name": "总账会计",
        "type": null,
        "description": null
    },
    {
        "id": 1339,
        "key": "1339",
        "name": "成本会计",
        "type": null,
        "description": null
    },
    {
        "id": 1340,
        "key": "1340",
        "name": "结算会计",
        "type": null,
        "description": null
    },
    {
        "id": 1341,
        "key": "1341",
        "name": "税务外勤会计",
        "type": null,
        "description": null
    },
    {
        "id": 1342,
        "key": "1342",
        "name": "建筑/工程会计",
        "type": null,
        "description": null
    },
    {
        "id": 1343,
        "key": "1343",
        "name": "审计",
        "type": null,
        "description": null
    },
    {
        "id": 1344,
        "key": "1344",
        "name": "税务",
        "type": null,
        "description": null
    },
    {
        "id": 1345,
        "key": "1345",
        "name": "财务经理/主管",
        "type": null,
        "description": null
    },
    {
        "id": 1346,
        "key": "1346",
        "name": "财务总监/VP",
        "type": null,
        "description": null
    },
    {
        "id": 1347,
        "key": "1347",
        "name": "CFO",
        "type": null,
        "description": null
    },
    {
        "id": 1348,
        "key": "1348",
        "name": "出纳",
        "type": null,
        "description": null
    },
    {
        "id": 1349,
        "key": "1349",
        "name": "风控",
        "type": null,
        "description": null
    },
    {
        "id": 1350,
        "key": "1350",
        "name": "财务顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1351,
        "key": "1351",
        "name": "统计员",
        "type": null,
        "description": null
    },
    {
        "id": 1352,
        "key": "1352",
        "name": "财务分析/财务BP",
        "type": null,
        "description": null
    },
    {
        "id": 1353,
        "key": "1353",
        "name": "普工/操作工",
        "type": null,
        "description": null
    },
    {
        "id": 1354,
        "key": "1354",
        "name": "包装工",
        "type": null,
        "description": null
    },
    {
        "id": 1355,
        "key": "1355",
        "name": "学徒工",
        "type": null,
        "description": null
    },
    {
        "id": 1356,
        "key": "1356",
        "name": "搬运工/装卸工",
        "type": null,
        "description": null
    },
    {
        "id": 1357,
        "key": "1357",
        "name": "组装工",
        "type": null,
        "description": null
    },
    {
        "id": 1358,
        "key": "1358",
        "name": "焊工",
        "type": null,
        "description": null
    },
    {
        "id": 1359,
        "key": "1359",
        "name": "氩弧焊工",
        "type": null,
        "description": null
    },
    {
        "id": 1360,
        "key": "1360",
        "name": "车工",
        "type": null,
        "description": null
    },
    {
        "id": 1361,
        "key": "1361",
        "name": "钳工",
        "type": null,
        "description": null
    },
    {
        "id": 1362,
        "key": "1362",
        "name": "切割工",
        "type": null,
        "description": null
    },
    {
        "id": 1363,
        "key": "1363",
        "name": "钣金工",
        "type": null,
        "description": null
    },
    {
        "id": 1364,
        "key": "1364",
        "name": "注塑工",
        "type": null,
        "description": null
    },
    {
        "id": 1365,
        "key": "1365",
        "name": "折弯工",
        "type": null,
        "description": null
    },
    {
        "id": 1366,
        "key": "1366",
        "name": "磨工",
        "type": null,
        "description": null
    },
    {
        "id": 1367,
        "key": "1367",
        "name": "模具工",
        "type": null,
        "description": null
    },
    {
        "id": 1368,
        "key": "1368",
        "name": "铣工",
        "type": null,
        "description": null
    },
    {
        "id": 1369,
        "key": "1369",
        "name": "抛光工",
        "type": null,
        "description": null
    },
    {
        "id": 1370,
        "key": "1370",
        "name": "喷塑工",
        "type": null,
        "description": null
    },
    {
        "id": 1371,
        "key": "1371",
        "name": "钻工",
        "type": null,
        "description": null
    },
    {
        "id": 1372,
        "key": "1372",
        "name": "铆工",
        "type": null,
        "description": null
    },
    {
        "id": 1375,
        "key": "1375",
        "name": "弱电工",
        "type": null,
        "description": null
    },
    {
        "id": 1376,
        "key": "1376",
        "name": "机修工",
        "type": null,
        "description": null
    },
    {
        "id": 1377,
        "key": "1377",
        "name": "木工",
        "type": null,
        "description": null
    },
    {
        "id": 1378,
        "key": "1378",
        "name": "油漆工",
        "type": null,
        "description": null
    },
    {
        "id": 1379,
        "key": "1379",
        "name": "锅炉工",
        "type": null,
        "description": null
    },
    {
        "id": 1380,
        "key": "1380",
        "name": "叉车工",
        "type": null,
        "description": null
    },
    {
        "id": 1381,
        "key": "1381",
        "name": "铲车司机",
        "type": null,
        "description": null
    },
    {
        "id": 1383,
        "key": "1383",
        "name": "质量管理/测试",
        "type": null,
        "description": null
    },
    {
        "id": 1384,
        "key": "1384",
        "name": "质检员",
        "type": null,
        "description": null
    },
    {
        "id": 1385,
        "key": "1385",
        "name": "实验室技术员",
        "type": null,
        "description": null
    },
    {
        "id": 1386,
        "key": "1386",
        "name": "体系工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1387,
        "key": "1387",
        "name": "体系审核员",
        "type": null,
        "description": null
    },
    {
        "id": 1388,
        "key": "1388",
        "name": "产品认证工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1389,
        "key": "1389",
        "name": "失效分析工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1390,
        "key": "1390",
        "name": "可靠度工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1391,
        "key": "1391",
        "name": "供应商质量工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1392,
        "key": "1392",
        "name": "医疗器械生产/质量管理",
        "type": null,
        "description": null
    },
    {
        "id": 1393,
        "key": "1393",
        "name": "汽车质量工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1394,
        "key": "1394",
        "name": "计量工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1395,
        "key": "1395",
        "name": "CNC/数控",
        "type": null,
        "description": null
    },
    {
        "id": 1396,
        "key": "1396",
        "name": "机械工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1397,
        "key": "1397",
        "name": "机械结构工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1398,
        "key": "1398",
        "name": "工艺工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1399,
        "key": "1399",
        "name": "机械设备工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1400,
        "key": "1400",
        "name": "模具工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1401,
        "key": "1401",
        "name": "机械制图员",
        "type": null,
        "description": null
    },
    {
        "id": 1403,
        "key": "1403",
        "name": "注塑工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1404,
        "key": "1404",
        "name": "夹具工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1405,
        "key": "1405",
        "name": "冲压工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1406,
        "key": "1406",
        "name": "铸造/锻造工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1407,
        "key": "1407",
        "name": "机械维修/保养",
        "type": null,
        "description": null
    },
    {
        "id": 1408,
        "key": "1408",
        "name": "材料工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1409,
        "key": "1409",
        "name": "工业工程师(IE)",
        "type": null,
        "description": null
    },
    {
        "id": 1410,
        "key": "1410",
        "name": "焊接工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1411,
        "key": "1411",
        "name": "热设计工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1412,
        "key": "1412",
        "name": "液压工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1413,
        "key": "1413",
        "name": "车间主任",
        "type": null,
        "description": null
    },
    {
        "id": 1414,
        "key": "1414",
        "name": "生产组长/拉长",
        "type": null,
        "description": null
    },
    {
        "id": 1415,
        "key": "1415",
        "name": "生产总监",
        "type": null,
        "description": null
    },
    {
        "id": 1416,
        "key": "1416",
        "name": "厂长",
        "type": null,
        "description": null
    },
    {
        "id": 1417,
        "key": "1417",
        "name": "生产文员",
        "type": null,
        "description": null
    },
    {
        "id": 1418,
        "key": "1418",
        "name": "生产计划/物料管理(PMC)",
        "type": null,
        "description": null
    },
    {
        "id": 1419,
        "key": "1419",
        "name": "生产设备管理员",
        "type": null,
        "description": null
    },
    {
        "id": 1420,
        "key": "1420",
        "name": "厂务",
        "type": null,
        "description": null
    },
    {
        "id": 1421,
        "key": "1421",
        "name": "生产安全员",
        "type": null,
        "description": null
    },
    {
        "id": 1422,
        "key": "1422",
        "name": "施工安全员",
        "type": null,
        "description": null
    },
    {
        "id": 1423,
        "key": "1423",
        "name": "EHS工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1424,
        "key": "1424",
        "name": "安全评价师",
        "type": null,
        "description": null
    },
    {
        "id": 1426,
        "key": "1426",
        "name": "化工工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1427,
        "key": "1427",
        "name": "食品/饮料研发",
        "type": null,
        "description": null
    },
    {
        "id": 1428,
        "key": "1428",
        "name": "化妆品研发",
        "type": null,
        "description": null
    },
    {
        "id": 1430,
        "key": "1430",
        "name": "涂料研发",
        "type": null,
        "description": null
    },
    {
        "id": 1431,
        "key": "1431",
        "name": "化工项目经理",
        "type": null,
        "description": null
    },
    {
        "id": 1432,
        "key": "1432",
        "name": "服装/纺织/皮革跟单",
        "type": null,
        "description": null
    },
    {
        "id": 1433,
        "key": "1433",
        "name": "打样/制版",
        "type": null,
        "description": null
    },
    {
        "id": 1434,
        "key": "1434",
        "name": "服装/纺织设计",
        "type": null,
        "description": null
    },
    {
        "id": 1435,
        "key": "1435",
        "name": "面料辅料开发",
        "type": null,
        "description": null
    },
    {
        "id": 1436,
        "key": "1436",
        "name": "缝纫工",
        "type": null,
        "description": null
    },
    {
        "id": 1437,
        "key": "1437",
        "name": "样衣工",
        "type": null,
        "description": null
    },
    {
        "id": 1438,
        "key": "1438",
        "name": "量体师",
        "type": null,
        "description": null
    },
    {
        "id": 1439,
        "key": "1439",
        "name": "裁剪工",
        "type": null,
        "description": null
    },
    {
        "id": 1440,
        "key": "1440",
        "name": "电池工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1441,
        "key": "1441",
        "name": "电机工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1442,
        "key": "1442",
        "name": "车身/造型设计",
        "type": null,
        "description": null
    },
    {
        "id": 1443,
        "key": "1443",
        "name": "汽车电子工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1445,
        "key": "1445",
        "name": "线束设计",
        "type": null,
        "description": null
    },
    {
        "id": 1446,
        "key": "1446",
        "name": "内外饰设计工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1447,
        "key": "1447",
        "name": "动力系统工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1449,
        "key": "1449",
        "name": "汽车零部件设计",
        "type": null,
        "description": null
    },
    {
        "id": 1450,
        "key": "1450",
        "name": "汽车设计",
        "type": null,
        "description": null
    },
    {
        "id": 1452,
        "key": "1452",
        "name": "总装工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1453,
        "key": "1453",
        "name": "汽车项目管理",
        "type": null,
        "description": null
    },
    {
        "id": 1455,
        "key": "1455",
        "name": "环境检测员",
        "type": null,
        "description": null
    },
    {
        "id": 1456,
        "key": "1456",
        "name": "环评工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1457,
        "key": "1457",
        "name": "环保工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1458,
        "key": "1458",
        "name": "碳排放管理师",
        "type": null,
        "description": null
    },
    {
        "id": 1459,
        "key": "1459",
        "name": "其他生产制造职位",
        "type": null,
        "description": null
    },
    {
        "id": 1460,
        "key": "1460",
        "name": "店员/营业员",
        "type": null,
        "description": null
    },
    {
        "id": 1461,
        "key": "1461",
        "name": "收银",
        "type": null,
        "description": null
    },
    {
        "id": 1462,
        "key": "1462",
        "name": "导购",
        "type": null,
        "description": null
    },
    {
        "id": 1466,
        "key": "1466",
        "name": "理货员",
        "type": null,
        "description": null
    },
    {
        "id": 1467,
        "key": "1467",
        "name": "促销员",
        "type": null,
        "description": null
    },
    {
        "id": 1468,
        "key": "1468",
        "name": "陈列员",
        "type": null,
        "description": null
    },
    {
        "id": 1469,
        "key": "1469",
        "name": "防损员",
        "type": null,
        "description": null
    },
    {
        "id": 1470,
        "key": "1470",
        "name": "门店店长",
        "type": null,
        "description": null
    },
    {
        "id": 1471,
        "key": "1471",
        "name": "督导/巡店",
        "type": null,
        "description": null
    },
    {
        "id": 1472,
        "key": "1472",
        "name": "卖场经理",
        "type": null,
        "description": null
    },
    {
        "id": 1474,
        "key": "1474",
        "name": "美容师",
        "type": null,
        "description": null
    },
    {
        "id": 1475,
        "key": "1475",
        "name": "美容店长",
        "type": null,
        "description": null
    },
    {
        "id": 1476,
        "key": "1476",
        "name": "美体师",
        "type": null,
        "description": null
    },
    {
        "id": 1478,
        "key": "1478",
        "name": "美甲美睫师",
        "type": null,
        "description": null
    },
    {
        "id": 1479,
        "key": "1479",
        "name": "纹绣师",
        "type": null,
        "description": null
    },
    {
        "id": 1480,
        "key": "1480",
        "name": "发型师",
        "type": null,
        "description": null
    },
    {
        "id": 1481,
        "key": "1481",
        "name": "美发助理/学徒",
        "type": null,
        "description": null
    },
    {
        "id": 1482,
        "key": "1482",
        "name": "养发师",
        "type": null,
        "description": null
    },
    {
        "id": 1483,
        "key": "1483",
        "name": "化妆/造型师",
        "type": null,
        "description": null
    },
    {
        "id": 1485,
        "key": "1485",
        "name": "理疗师",
        "type": null,
        "description": null
    },
    {
        "id": 1486,
        "key": "1486",
        "name": "针灸推拿",
        "type": null,
        "description": null
    },
    {
        "id": 1487,
        "key": "1487",
        "name": "按摩师",
        "type": null,
        "description": null
    },
    {
        "id": 1488,
        "key": "1488",
        "name": "足疗师",
        "type": null,
        "description": null
    },
    {
        "id": 1489,
        "key": "1489",
        "name": "采耳师",
        "type": null,
        "description": null
    },
    {
        "id": 1490,
        "key": "1490",
        "name": "保安",
        "type": null,
        "description": null
    },
    {
        "id": 1491,
        "key": "1491",
        "name": "保安经理",
        "type": null,
        "description": null
    },
    {
        "id": 1492,
        "key": "1492",
        "name": "消防中控员",
        "type": null,
        "description": null
    },
    {
        "id": 1493,
        "key": "1493",
        "name": "押运员",
        "type": null,
        "description": null
    },
    {
        "id": 1494,
        "key": "1494",
        "name": "安检员",
        "type": null,
        "description": null
    },
    {
        "id": 1495,
        "key": "1495",
        "name": "消防维保员",
        "type": null,
        "description": null
    },
    {
        "id": 1496,
        "key": "1496",
        "name": "保洁",
        "type": null,
        "description": null
    },
    {
        "id": 1497,
        "key": "1497",
        "name": "保洁经理",
        "type": null,
        "description": null
    },
    {
        "id": 1498,
        "key": "1498",
        "name": "保姆",
        "type": null,
        "description": null
    },
    {
        "id": 1499,
        "key": "1499",
        "name": "月嫂",
        "type": null,
        "description": null
    },
    {
        "id": 1500,
        "key": "1500",
        "name": "产后康复师",
        "type": null,
        "description": null
    },
    {
        "id": 1501,
        "key": "1501",
        "name": "育婴师",
        "type": null,
        "description": null
    },
    {
        "id": 1502,
        "key": "1502",
        "name": "护工",
        "type": null,
        "description": null
    },
    {
        "id": 1503,
        "key": "1503",
        "name": "收纳师",
        "type": null,
        "description": null
    },
    {
        "id": 1504,
        "key": "1504",
        "name": "家电维修",
        "type": null,
        "description": null
    },
    {
        "id": 1506,
        "key": "1506",
        "name": "电脑维修",
        "type": null,
        "description": null
    },
    {
        "id": 1507,
        "key": "1507",
        "name": "汽车维修",
        "type": null,
        "description": null
    },
    {
        "id": 1508,
        "key": "1508",
        "name": "汽车美容",
        "type": null,
        "description": null
    },
    {
        "id": 1509,
        "key": "1509",
        "name": "洗车工",
        "type": null,
        "description": null
    },
    {
        "id": 1510,
        "key": "1510",
        "name": "汽车改装工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1511,
        "key": "1511",
        "name": "汽车服务顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1512,
        "key": "1512",
        "name": "4S店店长/维修站长",
        "type": null,
        "description": null
    },
    {
        "id": 1513,
        "key": "1513",
        "name": "汽车查勘定损",
        "type": null,
        "description": null
    },
    {
        "id": 1514,
        "key": "1514",
        "name": "加油员",
        "type": null,
        "description": null
    },
    {
        "id": 1515,
        "key": "1515",
        "name": "宠物美容",
        "type": null,
        "description": null
    },
    {
        "id": 1516,
        "key": "1516",
        "name": "宠物医生",
        "type": null,
        "description": null
    },
    {
        "id": 1517,
        "key": "1517",
        "name": "健身教练",
        "type": null,
        "description": null
    },
    {
        "id": 1518,
        "key": "1518",
        "name": "舞蹈老师",
        "type": null,
        "description": null
    },
    {
        "id": 1519,
        "key": "1519",
        "name": "体育教师/体育教练",
        "type": null,
        "description": null
    },
    {
        "id": 1520,
        "key": "1520",
        "name": "篮球教练",
        "type": null,
        "description": null
    },
    {
        "id": 1521,
        "key": "1521",
        "name": "瑜伽老师",
        "type": null,
        "description": null
    },
    {
        "id": 1522,
        "key": "1522",
        "name": "游泳教练",
        "type": null,
        "description": null
    },
    {
        "id": 1523,
        "key": "1523",
        "name": "跆拳道教练",
        "type": null,
        "description": null
    },
    {
        "id": 1524,
        "key": "1524",
        "name": "武术教练",
        "type": null,
        "description": null
    },
    {
        "id": 1525,
        "key": "1525",
        "name": "轮滑教练",
        "type": null,
        "description": null
    },
    {
        "id": 1526,
        "key": "1526",
        "name": "救生员",
        "type": null,
        "description": null
    },
    {
        "id": 1527,
        "key": "1527",
        "name": "乒乓球教练",
        "type": null,
        "description": null
    },
    {
        "id": 1528,
        "key": "1528",
        "name": "足球教练",
        "type": null,
        "description": null
    },
    {
        "id": 1529,
        "key": "1529",
        "name": "羽毛球教练",
        "type": null,
        "description": null
    },
    {
        "id": 1530,
        "key": "1530",
        "name": "拳击教练",
        "type": null,
        "description": null
    },
    {
        "id": 1531,
        "key": "1531",
        "name": "网约车司机",
        "type": null,
        "description": null
    },
    {
        "id": 1532,
        "key": "1532",
        "name": "代驾司机",
        "type": null,
        "description": null
    },
    {
        "id": 1533,
        "key": "1533",
        "name": "驾校教练",
        "type": null,
        "description": null
    },
    {
        "id": 1534,
        "key": "1534",
        "name": "商务司机",
        "type": null,
        "description": null
    },
    {
        "id": 1535,
        "key": "1535",
        "name": "货运司机",
        "type": null,
        "description": null
    },
    {
        "id": 1536,
        "key": "1536",
        "name": "客运司机",
        "type": null,
        "description": null
    },
    {
        "id": 1537,
        "key": "1537",
        "name": "无人机飞手",
        "type": null,
        "description": null
    },
    {
        "id": 1538,
        "key": "1538",
        "name": "花艺师",
        "type": null,
        "description": null
    },
    {
        "id": 1539,
        "key": "1539",
        "name": "婚礼策划",
        "type": null,
        "description": null
    },
    {
        "id": 1540,
        "key": "1540",
        "name": "网吧网管",
        "type": null,
        "description": null
    },
    {
        "id": 1543,
        "key": "1543",
        "name": "验光师",
        "type": null,
        "description": null
    },
    {
        "id": 1544,
        "key": "1544",
        "name": "摄影/摄像师",
        "type": null,
        "description": null
    },
    {
        "id": 1545,
        "key": "1545",
        "name": "剧本杀主持人",
        "type": null,
        "description": null
    },
    {
        "id": 1546,
        "key": "1546",
        "name": "儿童引导师",
        "type": null,
        "description": null
    },
    {
        "id": 1547,
        "key": "1547",
        "name": "放映员",
        "type": null,
        "description": null
    },
    {
        "id": 1548,
        "key": "1548",
        "name": "其他服务业职位",
        "type": null,
        "description": null
    },
    {
        "id": 1549,
        "key": "1549",
        "name": "服务员",
        "type": null,
        "description": null
    },
    {
        "id": 1551,
        "key": "1551",
        "name": "礼仪/迎宾/接待",
        "type": null,
        "description": null
    },
    {
        "id": 1552,
        "key": "1552",
        "name": "传菜员",
        "type": null,
        "description": null
    },
    {
        "id": 1553,
        "key": "1553",
        "name": "餐饮学徒",
        "type": null,
        "description": null
    },
    {
        "id": 1554,
        "key": "1554",
        "name": "厨师",
        "type": null,
        "description": null
    },
    {
        "id": 1555,
        "key": "1555",
        "name": "中餐厨师",
        "type": null,
        "description": null
    },
    {
        "id": 1556,
        "key": "1556",
        "name": "烧烤师傅",
        "type": null,
        "description": null
    },
    {
        "id": 1557,
        "key": "1557",
        "name": "西餐厨师",
        "type": null,
        "description": null
    },
    {
        "id": 1558,
        "key": "1558",
        "name": "日料厨师",
        "type": null,
        "description": null
    },
    {
        "id": 1559,
        "key": "1559",
        "name": "凉菜厨师",
        "type": null,
        "description": null
    },
    {
        "id": 1560,
        "key": "1560",
        "name": "面点师",
        "type": null,
        "description": null
    },
    {
        "id": 1561,
        "key": "1561",
        "name": "后厨",
        "type": null,
        "description": null
    },
    {
        "id": 1562,
        "key": "1562",
        "name": "配菜打荷",
        "type": null,
        "description": null
    },
    {
        "id": 1563,
        "key": "1563",
        "name": "洗碗工",
        "type": null,
        "description": null
    },
    {
        "id": 1564,
        "key": "1564",
        "name": "水台",
        "type": null,
        "description": null
    },
    {
        "id": 1565,
        "key": "1565",
        "name": "餐饮店长",
        "type": null,
        "description": null
    },
    {
        "id": 1566,
        "key": "1566",
        "name": "餐饮前厅经理/领班",
        "type": null,
        "description": null
    },
    {
        "id": 1567,
        "key": "1567",
        "name": "餐饮储备店长/干部",
        "type": null,
        "description": null
    },
    {
        "id": 1568,
        "key": "1568",
        "name": "厨师长",
        "type": null,
        "description": null
    },
    {
        "id": 1569,
        "key": "1569",
        "name": "行政总厨",
        "type": null,
        "description": null
    },
    {
        "id": 1570,
        "key": "1570",
        "name": "咖啡师",
        "type": null,
        "description": null
    },
    {
        "id": 1571,
        "key": "1571",
        "name": "茶艺师",
        "type": null,
        "description": null
    },
    {
        "id": 1573,
        "key": "1573",
        "name": "调酒师",
        "type": null,
        "description": null
    },
    {
        "id": 1574,
        "key": "1574",
        "name": "面包/烘焙师",
        "type": null,
        "description": null
    },
    {
        "id": 1575,
        "key": "1575",
        "name": "蛋糕/裱花师",
        "type": null,
        "description": null
    },
    {
        "id": 1576,
        "key": "1576",
        "name": "送餐员",
        "type": null,
        "description": null
    },
    {
        "id": 1577,
        "key": "1577",
        "name": "酒店前台",
        "type": null,
        "description": null
    },
    {
        "id": 1579,
        "key": "1579",
        "name": "客房服务员",
        "type": null,
        "description": null
    },
    {
        "id": 1580,
        "key": "1580",
        "name": "酒店经理",
        "type": null,
        "description": null
    },
    {
        "id": 1581,
        "key": "1581",
        "name": "酒店前厅经理",
        "type": null,
        "description": null
    },
    {
        "id": 1582,
        "key": "1582",
        "name": "客房经理",
        "type": null,
        "description": null
    },
    {
        "id": 1583,
        "key": "1583",
        "name": "民宿管家",
        "type": null,
        "description": null
    },
    {
        "id": 1585,
        "key": "1585",
        "name": "导游",
        "type": null,
        "description": null
    },
    {
        "id": 1587,
        "key": "1587",
        "name": "计调",
        "type": null,
        "description": null
    },
    {
        "id": 1588,
        "key": "1588",
        "name": "票务员",
        "type": null,
        "description": null
    },
    {
        "id": 1589,
        "key": "1589",
        "name": "签证专员",
        "type": null,
        "description": null
    },
    {
        "id": 1590,
        "key": "1590",
        "name": "旅游产品经理",
        "type": null,
        "description": null
    },
    {
        "id": 1591,
        "key": "1591",
        "name": "旅游策划师",
        "type": null,
        "description": null
    },
    {
        "id": 1592,
        "key": "1592",
        "name": "其他旅游职位",
        "type": null,
        "description": null
    },
    {
        "id": 1594,
        "key": "1594",
        "name": "英语教师",
        "type": null,
        "description": null
    },
    {
        "id": 1595,
        "key": "1595",
        "name": "助教",
        "type": null,
        "description": null
    },
    {
        "id": 1596,
        "key": "1596",
        "name": "数学教师",
        "type": null,
        "description": null
    },
    {
        "id": 1597,
        "key": "1597",
        "name": "语文教师",
        "type": null,
        "description": null
    },
    {
        "id": 1598,
        "key": "1598",
        "name": "高中教师",
        "type": null,
        "description": null
    },
    {
        "id": 1599,
        "key": "1599",
        "name": "初中教师",
        "type": null,
        "description": null
    },
    {
        "id": 1600,
        "key": "1600",
        "name": "小学教师",
        "type": null,
        "description": null
    },
    {
        "id": 1601,
        "key": "1601",
        "name": "家教",
        "type": null,
        "description": null
    },
    {
        "id": 1602,
        "key": "1602",
        "name": "物理教师",
        "type": null,
        "description": null
    },
    {
        "id": 1603,
        "key": "1603",
        "name": "化学教师",
        "type": null,
        "description": null
    },
    {
        "id": 1604,
        "key": "1604",
        "name": "理科教师",
        "type": null,
        "description": null
    },
    {
        "id": 1605,
        "key": "1605",
        "name": "日语教师",
        "type": null,
        "description": null
    },
    {
        "id": 1606,
        "key": "1606",
        "name": "生物教师",
        "type": null,
        "description": null
    },
    {
        "id": 1607,
        "key": "1607",
        "name": "文科教师",
        "type": null,
        "description": null
    },
    {
        "id": 1608,
        "key": "1608",
        "name": "地理教师",
        "type": null,
        "description": null
    },
    {
        "id": 1609,
        "key": "1609",
        "name": "其他外语教师",
        "type": null,
        "description": null
    },
    {
        "id": 1610,
        "key": "1610",
        "name": "幼教",
        "type": null,
        "description": null
    },
    {
        "id": 1611,
        "key": "1611",
        "name": "托管老师",
        "type": null,
        "description": null
    },
    {
        "id": 1612,
        "key": "1612",
        "name": "早教老师",
        "type": null,
        "description": null
    },
    {
        "id": 1613,
        "key": "1613",
        "name": "保育员",
        "type": null,
        "description": null
    },
    {
        "id": 1614,
        "key": "1614",
        "name": "感统训练教师",
        "type": null,
        "description": null
    },
    {
        "id": 1615,
        "key": "1615",
        "name": "教务管理",
        "type": null,
        "description": null
    },
    {
        "id": 1616,
        "key": "1616",
        "name": "校长/副校长",
        "type": null,
        "description": null
    },
    {
        "id": 1617,
        "key": "1617",
        "name": "班主任/辅导员",
        "type": null,
        "description": null
    },
    {
        "id": 1618,
        "key": "1618",
        "name": "教学管理",
        "type": null,
        "description": null
    },
    {
        "id": 1619,
        "key": "1619",
        "name": "园长/副园长",
        "type": null,
        "description": null
    },
    {
        "id": 1620,
        "key": "1620",
        "name": "就业老师",
        "type": null,
        "description": null
    },
    {
        "id": 1633,
        "key": "1633",
        "name": "美术教师",
        "type": null,
        "description": null
    },
    {
        "id": 1635,
        "key": "1635",
        "name": "书法教师",
        "type": null,
        "description": null
    },
    {
        "id": 1636,
        "key": "1636",
        "name": "音乐教师",
        "type": null,
        "description": null
    },
    {
        "id": 1637,
        "key": "1637",
        "name": "播音主持教师",
        "type": null,
        "description": null
    },
    {
        "id": 1638,
        "key": "1638",
        "name": "钢琴教师",
        "type": null,
        "description": null
    },
    {
        "id": 1639,
        "key": "1639",
        "name": "古筝教师",
        "type": null,
        "description": null
    },
    {
        "id": 1641,
        "key": "1641",
        "name": "表演教师",
        "type": null,
        "description": null
    },
    {
        "id": 1642,
        "key": "1642",
        "name": "架子鼓老师",
        "type": null,
        "description": null
    },
    {
        "id": 1643,
        "key": "1643",
        "name": "围棋老师",
        "type": null,
        "description": null
    },
    {
        "id": 1644,
        "key": "1644",
        "name": "乐高教师",
        "type": null,
        "description": null
    },
    {
        "id": 1645,
        "key": "1645",
        "name": "机器人教师",
        "type": null,
        "description": null
    },
    {
        "id": 1647,
        "key": "1647",
        "name": "培训师",
        "type": null,
        "description": null
    },
    {
        "id": 1648,
        "key": "1648",
        "name": "拓展培训",
        "type": null,
        "description": null
    },
    {
        "id": 1649,
        "key": "1649",
        "name": "财会培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1650,
        "key": "1650",
        "name": "PHP培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1651,
        "key": "1651",
        "name": "JAVA培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1652,
        "key": "1652",
        "name": "C++培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1653,
        "key": "1653",
        "name": "UI设计培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1654,
        "key": "1654",
        "name": "Web前端培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1655,
        "key": "1655",
        "name": "软件测试培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1656,
        "key": "1656",
        "name": "动漫培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1657,
        "key": "1657",
        "name": "Unity 3D培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1658,
        "key": "1658",
        "name": "Android培训讲师",
        "type": null,
        "description": null
    },
    {
        "id": 1666,
        "key": "1666",
        "name": "课程设计",
        "type": null,
        "description": null
    },
    {
        "id": 1667,
        "key": "1667",
        "name": "课程编辑",
        "type": null,
        "description": null
    },
    {
        "id": 1669,
        "key": "1669",
        "name": "培训研究",
        "type": null,
        "description": null
    },
    {
        "id": 1670,
        "key": "1670",
        "name": "其他教育产品研发职位",
        "type": null,
        "description": null
    },
    {
        "id": 1671,
        "key": "1671",
        "name": "其他教育培训职位",
        "type": null,
        "description": null
    },
    {
        "id": 1672,
        "key": "1672",
        "name": "平面设计",
        "type": null,
        "description": null
    },
    {
        "id": 1673,
        "key": "1673",
        "name": "美工",
        "type": null,
        "description": null
    },
    {
        "id": 1674,
        "key": "1674",
        "name": "设计师助理",
        "type": null,
        "description": null
    },
    {
        "id": 1675,
        "key": "1675",
        "name": "CAD设计/制图",
        "type": null,
        "description": null
    },
    {
        "id": 1676,
        "key": "1676",
        "name": "UI设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1677,
        "key": "1677",
        "name": "视觉设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1678,
        "key": "1678",
        "name": "广告设计",
        "type": null,
        "description": null
    },
    {
        "id": 1679,
        "key": "1679",
        "name": "交互设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1680,
        "key": "1680",
        "name": "网页设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1681,
        "key": "1681",
        "name": "多媒体设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1682,
        "key": "1682",
        "name": "室内设计",
        "type": null,
        "description": null
    },
    {
        "id": 1683,
        "key": "1683",
        "name": "家具设计",
        "type": null,
        "description": null
    },
    {
        "id": 1684,
        "key": "1684",
        "name": "家居设计",
        "type": null,
        "description": null
    },
    {
        "id": 1685,
        "key": "1685",
        "name": "橱柜设计",
        "type": null,
        "description": null
    },
    {
        "id": 1686,
        "key": "1686",
        "name": "工业设计",
        "type": null,
        "description": null
    },
    {
        "id": 1687,
        "key": "1687",
        "name": "包装设计",
        "type": null,
        "description": null
    },
    {
        "id": 1688,
        "key": "1688",
        "name": "珠宝设计",
        "type": null,
        "description": null
    },
    {
        "id": 1689,
        "key": "1689",
        "name": "家具拆单员",
        "type": null,
        "description": null
    },
    {
        "id": 1692,
        "key": "1692",
        "name": "3D设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1693,
        "key": "1693",
        "name": "插画师",
        "type": null,
        "description": null
    },
    {
        "id": 1694,
        "key": "1694",
        "name": "动画设计",
        "type": null,
        "description": null
    },
    {
        "id": 1695,
        "key": "1695",
        "name": "原画师",
        "type": null,
        "description": null
    },
    {
        "id": 1696,
        "key": "1696",
        "name": "漫画师",
        "type": null,
        "description": null
    },
    {
        "id": 1697,
        "key": "1697",
        "name": "修图师",
        "type": null,
        "description": null
    },
    {
        "id": 1698,
        "key": "1698",
        "name": "游戏场景",
        "type": null,
        "description": null
    },
    {
        "id": 1699,
        "key": "1699",
        "name": "游戏角色",
        "type": null,
        "description": null
    },
    {
        "id": 1700,
        "key": "1700",
        "name": "游戏界面设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1703,
        "key": "1703",
        "name": "游戏特效",
        "type": null,
        "description": null
    },
    {
        "id": 1706,
        "key": "1706",
        "name": "游戏主美术",
        "type": null,
        "description": null
    },
    {
        "id": 1707,
        "key": "1707",
        "name": "陈列设计",
        "type": null,
        "description": null
    },
    {
        "id": 1708,
        "key": "1708",
        "name": "展览/展示设计",
        "type": null,
        "description": null
    },
    {
        "id": 1709,
        "key": "1709",
        "name": "照明设计",
        "type": null,
        "description": null
    },
    {
        "id": 1713,
        "key": "1713",
        "name": "UX设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1714,
        "key": "1714",
        "name": "设计经理/主管",
        "type": null,
        "description": null
    },
    {
        "id": 1715,
        "key": "1715",
        "name": "设计总监",
        "type": null,
        "description": null
    },
    {
        "id": 1716,
        "key": "1716",
        "name": "视觉设计总监",
        "type": null,
        "description": null
    },
    {
        "id": 1717,
        "key": "1717",
        "name": "其他设计职位",
        "type": null,
        "description": null
    },
    {
        "id": 1718,
        "key": "1718",
        "name": "建筑施工项目经理",
        "type": null,
        "description": null
    },
    {
        "id": 1719,
        "key": "1719",
        "name": "工程造价",
        "type": null,
        "description": null
    },
    {
        "id": 1720,
        "key": "1720",
        "name": "工程监理",
        "type": null,
        "description": null
    },
    {
        "id": 1721,
        "key": "1721",
        "name": "工程预算",
        "type": null,
        "description": null
    },
    {
        "id": 1722,
        "key": "1722",
        "name": "施工员",
        "type": null,
        "description": null
    },
    {
        "id": 1723,
        "key": "1723",
        "name": "资料员",
        "type": null,
        "description": null
    },
    {
        "id": 1724,
        "key": "1724",
        "name": "材料员",
        "type": null,
        "description": null
    },
    {
        "id": 1725,
        "key": "1725",
        "name": "测绘/测量",
        "type": null,
        "description": null
    },
    {
        "id": 1728,
        "key": "1728",
        "name": "软装设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1729,
        "key": "1729",
        "name": "装修项目经理",
        "type": null,
        "description": null
    },
    {
        "id": 1730,
        "key": "1730",
        "name": "物业经理",
        "type": null,
        "description": null
    },
    {
        "id": 1731,
        "key": "1731",
        "name": "物业管理员",
        "type": null,
        "description": null
    },
    {
        "id": 1732,
        "key": "1732",
        "name": "综合维修工",
        "type": null,
        "description": null
    },
    {
        "id": 1733,
        "key": "1733",
        "name": "物业工程主管",
        "type": null,
        "description": null
    },
    {
        "id": 1737,
        "key": "1737",
        "name": "绿化工",
        "type": null,
        "description": null
    },
    {
        "id": 1742,
        "key": "1742",
        "name": "水电工",
        "type": null,
        "description": null
    },
    {
        "id": 1743,
        "key": "1743",
        "name": "土木/土建/结构工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1744,
        "key": "1744",
        "name": "园林/景观设计",
        "type": null,
        "description": null
    },
    {
        "id": 1745,
        "key": "1745",
        "name": "建筑设计师",
        "type": null,
        "description": null
    },
    {
        "id": 1746,
        "key": "1746",
        "name": "建筑工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1747,
        "key": "1747",
        "name": "城市规划设计",
        "type": null,
        "description": null
    },
    {
        "id": 1748,
        "key": "1748",
        "name": "弱电工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1750,
        "key": "1750",
        "name": "给排水工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1752,
        "key": "1752",
        "name": "幕墙工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1753,
        "key": "1753",
        "name": "BIM工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1754,
        "key": "1754",
        "name": "消防工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1755,
        "key": "1755",
        "name": "地产招投标",
        "type": null,
        "description": null
    },
    {
        "id": 1756,
        "key": "1756",
        "name": "地产项目管理",
        "type": null,
        "description": null
    },
    {
        "id": 1757,
        "key": "1757",
        "name": "房地产策划",
        "type": null,
        "description": null
    },
    {
        "id": 1758,
        "key": "1758",
        "name": "房产评估师",
        "type": null,
        "description": null
    },
    {
        "id": 1759,
        "key": "1759",
        "name": "地产项目总监",
        "type": null,
        "description": null
    },
    {
        "id": 1760,
        "key": "1760",
        "name": "地产策划总监",
        "type": null,
        "description": null
    },
    {
        "id": 1761,
        "key": "1761",
        "name": "地产招投标总监",
        "type": null,
        "description": null
    },
    {
        "id": 1768,
        "key": "1768",
        "name": "空调工",
        "type": null,
        "description": null
    },
    {
        "id": 1770,
        "key": "1770",
        "name": "电梯工",
        "type": null,
        "description": null
    },
    {
        "id": 1771,
        "key": "1771",
        "name": "其他房地产职位",
        "type": null,
        "description": null
    },
    {
        "id": 1772,
        "key": "1772",
        "name": "主播",
        "type": null,
        "description": null
    },
    {
        "id": 1773,
        "key": "1773",
        "name": "带货主播",
        "type": null,
        "description": null
    },
    {
        "id": 1775,
        "key": "1775",
        "name": "游戏主播",
        "type": null,
        "description": null
    },
    {
        "id": 1776,
        "key": "1776",
        "name": "中控/场控/助播",
        "type": null,
        "description": null
    },
    {
        "id": 1778,
        "key": "1778",
        "name": "演员/配音员",
        "type": null,
        "description": null
    },
    {
        "id": 1779,
        "key": "1779",
        "name": "艺人助理",
        "type": null,
        "description": null
    },
    {
        "id": 1780,
        "key": "1780",
        "name": "主持人/DJ",
        "type": null,
        "description": null
    },
    {
        "id": 1781,
        "key": "1781",
        "name": "模特",
        "type": null,
        "description": null
    },
    {
        "id": 1783,
        "key": "1783",
        "name": "导演/编导",
        "type": null,
        "description": null
    },
    {
        "id": 1784,
        "key": "1784",
        "name": "经纪人/星探",
        "type": null,
        "description": null
    },
    {
        "id": 1785,
        "key": "1785",
        "name": "编剧",
        "type": null,
        "description": null
    },
    {
        "id": 1786,
        "key": "1786",
        "name": "制片人",
        "type": null,
        "description": null
    },
    {
        "id": 1787,
        "key": "1787",
        "name": "影视策划",
        "type": null,
        "description": null
    },
    {
        "id": 1788,
        "key": "1788",
        "name": "影视发行",
        "type": null,
        "description": null
    },
    {
        "id": 1789,
        "key": "1789",
        "name": "剪辑师",
        "type": null,
        "description": null
    },
    {
        "id": 1790,
        "key": "1790",
        "name": "视频编辑",
        "type": null,
        "description": null
    },
    {
        "id": 1791,
        "key": "1791",
        "name": "后期制作",
        "type": null,
        "description": null
    },
    {
        "id": 1794,
        "key": "1794",
        "name": "灯光师",
        "type": null,
        "description": null
    },
    {
        "id": 1795,
        "key": "1795",
        "name": "录音/音效",
        "type": null,
        "description": null
    },
    {
        "id": 1796,
        "key": "1796",
        "name": "音频编辑",
        "type": null,
        "description": null
    },
    {
        "id": 1797,
        "key": "1797",
        "name": "影视特效",
        "type": null,
        "description": null
    },
    {
        "id": 1801,
        "key": "1801",
        "name": "剧本杀编剧",
        "type": null,
        "description": null
    },
    {
        "id": 1802,
        "key": "1802",
        "name": "媒介投放",
        "type": null,
        "description": null
    },
    {
        "id": 1803,
        "key": "1803",
        "name": "媒介商务BD",
        "type": null,
        "description": null
    },
    {
        "id": 1804,
        "key": "1804",
        "name": "策划经理",
        "type": null,
        "description": null
    },
    {
        "id": 1805,
        "key": "1805",
        "name": "广告文案",
        "type": null,
        "description": null
    },
    {
        "id": 1806,
        "key": "1806",
        "name": "广告客户执行",
        "type": null,
        "description": null
    },
    {
        "id": 1807,
        "key": "1807",
        "name": "广告/会展项目经理",
        "type": null,
        "description": null
    },
    {
        "id": 1808,
        "key": "1808",
        "name": "广告创意策划",
        "type": null,
        "description": null
    },
    {
        "id": 1810,
        "key": "1810",
        "name": "美术指导",
        "type": null,
        "description": null
    },
    {
        "id": 1811,
        "key": "1811",
        "name": "广告制作",
        "type": null,
        "description": null
    },
    {
        "id": 1812,
        "key": "1812",
        "name": "广告审核",
        "type": null,
        "description": null
    },
    {
        "id": 1813,
        "key": "1813",
        "name": "品牌公关",
        "type": null,
        "description": null
    },
    {
        "id": 1814,
        "key": "1814",
        "name": "媒介专员",
        "type": null,
        "description": null
    },
    {
        "id": 1815,
        "key": "1815",
        "name": "媒介经理/总监",
        "type": null,
        "description": null
    },
    {
        "id": 1816,
        "key": "1816",
        "name": "编辑",
        "type": null,
        "description": null
    },
    {
        "id": 1818,
        "key": "1818",
        "name": "记者/采编",
        "type": null,
        "description": null
    },
    {
        "id": 1819,
        "key": "1819",
        "name": "作者/撰稿人",
        "type": null,
        "description": null
    },
    {
        "id": 1820,
        "key": "1820",
        "name": "校对录入",
        "type": null,
        "description": null
    },
    {
        "id": 1821,
        "key": "1821",
        "name": "印刷排版",
        "type": null,
        "description": null
    },
    {
        "id": 1822,
        "key": "1822",
        "name": "出版发行",
        "type": null,
        "description": null
    },
    {
        "id": 1824,
        "key": "1824",
        "name": "其他传媒职位",
        "type": null,
        "description": null
    },
    {
        "id": 1825,
        "key": "1825",
        "name": "市场营销",
        "type": null,
        "description": null
    },
    {
        "id": 1826,
        "key": "1826",
        "name": "市场推广",
        "type": null,
        "description": null
    },
    {
        "id": 1827,
        "key": "1827",
        "name": "网络推广",
        "type": null,
        "description": null
    },
    {
        "id": 1828,
        "key": "1828",
        "name": "商务渠道",
        "type": null,
        "description": null
    },
    {
        "id": 1829,
        "key": "1829",
        "name": "市场策划",
        "type": null,
        "description": null
    },
    {
        "id": 1830,
        "key": "1830",
        "name": "活动策划执行",
        "type": null,
        "description": null
    },
    {
        "id": 1831,
        "key": "1831",
        "name": "营销主管",
        "type": null,
        "description": null
    },
    {
        "id": 1832,
        "key": "1832",
        "name": "海外市场",
        "type": null,
        "description": null
    },
    {
        "id": 1833,
        "key": "1833",
        "name": "市场顾问",
        "type": null,
        "description": null
    },
    {
        "id": 1834,
        "key": "1834",
        "name": "信息流优化师",
        "type": null,
        "description": null
    },
    {
        "id": 1835,
        "key": "1835",
        "name": "SEO",
        "type": null,
        "description": null
    },
    {
        "id": 1836,
        "key": "1836",
        "name": "SEM",
        "type": null,
        "description": null
    },
    {
        "id": 1838,
        "key": "1838",
        "name": "游戏推广",
        "type": null,
        "description": null
    },
    {
        "id": 1839,
        "key": "1839",
        "name": "网络营销",
        "type": null,
        "description": null
    },
    {
        "id": 1840,
        "key": "1840",
        "name": "选址开发",
        "type": null,
        "description": null
    },
    {
        "id": 1841,
        "key": "1841",
        "name": "商业数据分析",
        "type": null,
        "description": null
    },
    {
        "id": 1845,
        "key": "1845",
        "name": "政府关系",
        "type": null,
        "description": null
    },
    {
        "id": 1846,
        "key": "1846",
        "name": "政策研究",
        "type": null,
        "description": null
    },
    {
        "id": 1847,
        "key": "1847",
        "name": "社工",
        "type": null,
        "description": null
    },
    {
        "id": 1860,
        "key": "1860",
        "name": "会展活动执行",
        "type": null,
        "description": null
    },
    {
        "id": 1861,
        "key": "1861",
        "name": "会议活动执行",
        "type": null,
        "description": null
    },
    {
        "id": 1862,
        "key": "1862",
        "name": "会展活动策划",
        "type": null,
        "description": null
    },
    {
        "id": 1863,
        "key": "1863",
        "name": "会议活动策划",
        "type": null,
        "description": null
    },
    {
        "id": 1870,
        "key": "1870",
        "name": "市场总监",
        "type": null,
        "description": null
    },
    {
        "id": 1871,
        "key": "1871",
        "name": "创意总监",
        "type": null,
        "description": null
    },
    {
        "id": 1872,
        "key": "1872",
        "name": "公关总监",
        "type": null,
        "description": null
    },
    {
        "id": 1873,
        "key": "1873",
        "name": "CMO",
        "type": null,
        "description": null
    },
    {
        "id": 1874,
        "key": "1874",
        "name": "其他市场职位",
        "type": null,
        "description": null
    },
    {
        "id": 1875,
        "key": "1875",
        "name": "物流专员",
        "type": null,
        "description": null
    },
    {
        "id": 1876,
        "key": "1876",
        "name": "物流经理",
        "type": null,
        "description": null
    },
    {
        "id": 1877,
        "key": "1877",
        "name": "物流总监",
        "type": null,
        "description": null
    },
    {
        "id": 1878,
        "key": "1878",
        "name": "物流运营",
        "type": null,
        "description": null
    },
    {
        "id": 1879,
        "key": "1879",
        "name": "物流跟单",
        "type": null,
        "description": null
    },
    {
        "id": 1880,
        "key": "1880",
        "name": "调度员",
        "type": null,
        "description": null
    },
    {
        "id": 1881,
        "key": "1881",
        "name": "运输经理/主管",
        "type": null,
        "description": null
    },
    {
        "id": 1884,
        "key": "1884",
        "name": "货运代理专员",
        "type": null,
        "description": null
    },
    {
        "id": 1885,
        "key": "1885",
        "name": "货运代理经理",
        "type": null,
        "description": null
    },
    {
        "id": 1887,
        "key": "1887",
        "name": "物流/仓储项目经理",
        "type": null,
        "description": null
    },
    {
        "id": 1888,
        "key": "1888",
        "name": "配/理/拣/发货",
        "type": null,
        "description": null
    },
    {
        "id": 1889,
        "key": "1889",
        "name": "配送员",
        "type": null,
        "description": null
    },
    {
        "id": 1890,
        "key": "1890",
        "name": "快递员",
        "type": null,
        "description": null
    },
    {
        "id": 1892,
        "key": "1892",
        "name": "配送站长",
        "type": null,
        "description": null
    },
    {
        "id": 1900,
        "key": "1900",
        "name": "仓库管理员",
        "type": null,
        "description": null
    },
    {
        "id": 1901,
        "key": "1901",
        "name": "仓库文员",
        "type": null,
        "description": null
    },
    {
        "id": 1902,
        "key": "1902",
        "name": "仓库主管/经理",
        "type": null,
        "description": null
    },
    {
        "id": 1903,
        "key": "1903",
        "name": "供应链专员",
        "type": null,
        "description": null
    },
    {
        "id": 1904,
        "key": "1904",
        "name": "供应链经理",
        "type": null,
        "description": null
    },
    {
        "id": 1905,
        "key": "1905",
        "name": "供应链总监",
        "type": null,
        "description": null
    },
    {
        "id": 1907,
        "key": "1907",
        "name": "采购专员/助理",
        "type": null,
        "description": null
    },
    {
        "id": 1908,
        "key": "1908",
        "name": "采购经理/主管",
        "type": null,
        "description": null
    },
    {
        "id": 1909,
        "key": "1909",
        "name": "采购工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1910,
        "key": "1910",
        "name": "买手",
        "type": null,
        "description": null
    },
    {
        "id": 1912,
        "key": "1912",
        "name": "商品专员/助理",
        "type": null,
        "description": null
    },
    {
        "id": 1914,
        "key": "1914",
        "name": "采购总监",
        "type": null,
        "description": null
    },
    {
        "id": 1915,
        "key": "1915",
        "name": "招标专员",
        "type": null,
        "description": null
    },
    {
        "id": 1916,
        "key": "1916",
        "name": "投标专员",
        "type": null,
        "description": null
    },
    {
        "id": 1919,
        "key": "1919",
        "name": "贸易跟单",
        "type": null,
        "description": null
    },
    {
        "id": 1920,
        "key": "1920",
        "name": "单证员",
        "type": null,
        "description": null
    },
    {
        "id": 1922,
        "key": "1922",
        "name": "其他采购/贸易类职位",
        "type": null,
        "description": null
    },
    {
        "id": 1948,
        "key": "1948",
        "name": "护士",
        "type": null,
        "description": null
    },
    {
        "id": 1949,
        "key": "1949",
        "name": "导医",
        "type": null,
        "description": null
    },
    {
        "id": 1950,
        "key": "1950",
        "name": "护士长",
        "type": null,
        "description": null
    },
    {
        "id": 1952,
        "key": "1952",
        "name": "外科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1953,
        "key": "1953",
        "name": "内科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1954,
        "key": "1954",
        "name": "皮肤科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1955,
        "key": "1955",
        "name": "妇产科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1956,
        "key": "1956",
        "name": "儿科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1957,
        "key": "1957",
        "name": "眼科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1958,
        "key": "1958",
        "name": "精神心理科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1959,
        "key": "1959",
        "name": "整形医生",
        "type": null,
        "description": null
    },
    {
        "id": 1960,
        "key": "1960",
        "name": "全科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1961,
        "key": "1961",
        "name": "耳鼻喉科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1962,
        "key": "1962",
        "name": "检验科医师",
        "type": null,
        "description": null
    },
    {
        "id": 1964,
        "key": "1964",
        "name": "超声科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1965,
        "key": "1965",
        "name": "麻醉科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1966,
        "key": "1966",
        "name": "病理科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1967,
        "key": "1967",
        "name": "医生助理",
        "type": null,
        "description": null
    },
    {
        "id": 1968,
        "key": "1968",
        "name": "中医",
        "type": null,
        "description": null
    },
    {
        "id": 1969,
        "key": "1969",
        "name": "口腔科医生",
        "type": null,
        "description": null
    },
    {
        "id": 1970,
        "key": "1970",
        "name": "幼儿园保健医",
        "type": null,
        "description": null
    },
    {
        "id": 1971,
        "key": "1971",
        "name": "药剂师",
        "type": null,
        "description": null
    },
    {
        "id": 1973,
        "key": "1973",
        "name": "医务管理",
        "type": null,
        "description": null
    },
    {
        "id": 1974,
        "key": "1974",
        "name": "其他医生职位",
        "type": null,
        "description": null
    },
    {
        "id": 1976,
        "key": "1976",
        "name": "营养师/健康管理师",
        "type": null,
        "description": null
    },
    {
        "id": 1981,
        "key": "1981",
        "name": "执业药师/驻店药师",
        "type": null,
        "description": null
    },
    {
        "id": 1983,
        "key": "1983",
        "name": "生物学研究人员",
        "type": null,
        "description": null
    },
    {
        "id": 1984,
        "key": "1984",
        "name": "医药研发",
        "type": null,
        "description": null
    },
    {
        "id": 1985,
        "key": "1985",
        "name": "生物信息工程师",
        "type": null,
        "description": null
    },
    {
        "id": 1986,
        "key": "1986",
        "name": "药品生产",
        "type": null,
        "description": null
    },
    {
        "id": 1987,
        "key": "1987",
        "name": "药品注册",
        "type": null,
        "description": null
    },
    {
        "id": 1988,
        "key": "1988",
        "name": "医药项目经理",
        "type": null,
        "description": null
    },
    {
        "id": 1989,
        "key": "1989",
        "name": "细胞培养员",
        "type": null,
        "description": null
    },
    {
        "id": 1990,
        "key": "1990",
        "name": "药物分析",
        "type": null,
        "description": null
    },
    {
        "id": 1991,
        "key": "1991",
        "name": "制剂研发",
        "type": null,
        "description": null
    },
    {
        "id": 1992,
        "key": "1992",
        "name": "药物合成",
        "type": null,
        "description": null
    },
    {
        "id": 1993,
        "key": "1993",
        "name": "医疗产品技术支持",
        "type": null,
        "description": null
    },
    {
        "id": 1994,
        "key": "1994",
        "name": "临床协调员",
        "type": null,
        "description": null
    },
    {
        "id": 1995,
        "key": "1995",
        "name": "临床监查员",
        "type": null,
        "description": null
    },
    {
        "id": 1996,
        "key": "1996",
        "name": "临床项目经理",
        "type": null,
        "description": null
    },
    {
        "id": 1997,
        "key": "1997",
        "name": "临床数据分析",
        "type": null,
        "description": null
    },
    {
        "id": 1998,
        "key": "1998",
        "name": "临床医学经理/专员",
        "type": null,
        "description": null
    },
    {
        "id": 1999,
        "key": "1999",
        "name": "临床医学总监",
        "type": null,
        "description": null
    },
    {
        "id": 2001,
        "key": "2001",
        "name": "医疗器械研发",
        "type": null,
        "description": null
    },
    {
        "id": 2003,
        "key": "2003",
        "name": "试剂研发",
        "type": null,
        "description": null
    },
    {
        "id": 2012,
        "key": "2012",
        "name": "其他医疗健康职位",
        "type": null,
        "description": null
    },
    {
        "id": 2013,
        "key": "2013",
        "name": "柜员",
        "type": null,
        "description": null
    },
    {
        "id": 2014,
        "key": "2014",
        "name": "银行大堂经理",
        "type": null,
        "description": null
    },
    {
        "id": 2015,
        "key": "2015",
        "name": "客户经理",
        "type": null,
        "description": null
    },
    {
        "id": 2016,
        "key": "2016",
        "name": "信贷专员",
        "type": null,
        "description": null
    },
    {
        "id": 2017,
        "key": "2017",
        "name": "证券交易员",
        "type": null,
        "description": null
    },
    {
        "id": 2018,
        "key": "2018",
        "name": "卖方分析师",
        "type": null,
        "description": null
    },
    {
        "id": 2019,
        "key": "2019",
        "name": "买方分析师",
        "type": null,
        "description": null
    },
    {
        "id": 2020,
        "key": "2020",
        "name": "投资银行业务",
        "type": null,
        "description": null
    },
    {
        "id": 2023,
        "key": "2023",
        "name": "量化研究员",
        "type": null,
        "description": null
    },
    {
        "id": 2025,
        "key": "2025",
        "name": "合规稽查",
        "type": null,
        "description": null
    },
    {
        "id": 2026,
        "key": "2026",
        "name": "法务",
        "type": null,
        "description": null
    },
    {
        "id": 2027,
        "key": "2027",
        "name": "资信评估",
        "type": null,
        "description": null
    },
    {
        "id": 2028,
        "key": "2028",
        "name": "清算",
        "type": null,
        "description": null
    },
    {
        "id": 2029,
        "key": "2029",
        "name": "资产评估",
        "type": null,
        "description": null
    },
    {
        "id": 2031,
        "key": "2031",
        "name": "催收员",
        "type": null,
        "description": null
    },
    {
        "id": 2032,
        "key": "2032",
        "name": "投资经理",
        "type": null,
        "description": null
    },
    {
        "id": 2033,
        "key": "2033",
        "name": "投资助理",
        "type": null,
        "description": null
    },
    {
        "id": 2034,
        "key": "2034",
        "name": "行业研究",
        "type": null,
        "description": null
    },
    {
        "id": 2035,
        "key": "2035",
        "name": "融资",
        "type": null,
        "description": null
    },
    {
        "id": 2036,
        "key": "2036",
        "name": "投后管理",
        "type": null,
        "description": null
    },
    {
        "id": 2039,
        "key": "2039",
        "name": "投资者关系/证券事务代表",
        "type": null,
        "description": null
    },
    {
        "id": 2040,
        "key": "2040",
        "name": "其他投融资职位",
        "type": null,
        "description": null
    },
    {
        "id": 2045,
        "key": "2045",
        "name": "保险理赔",
        "type": null,
        "description": null
    },
    {
        "id": 2046,
        "key": "2046",
        "name": "保险精算师",
        "type": null,
        "description": null
    },
    {
        "id": 2047,
        "key": "2047",
        "name": "其他金融职位",
        "type": null,
        "description": null
    },
    {
        "id": 2048,
        "key": "2048",
        "name": "知识产权/专利/商标代理人",
        "type": null,
        "description": null
    },
    {
        "id": 2050,
        "key": "2050",
        "name": "咨询项目管理",
        "type": null,
        "description": null
    },
    {
        "id": 2052,
        "key": "2052",
        "name": "战略咨询",
        "type": null,
        "description": null
    },
    {
        "id": 2054,
        "key": "2054",
        "name": "IT咨询顾问",
        "type": null,
        "description": null
    },
    {
        "id": 2055,
        "key": "2055",
        "name": "咨询经理",
        "type": null,
        "description": null
    },
    {
        "id": 2056,
        "key": "2056",
        "name": "人力资源咨询顾问",
        "type": null,
        "description": null
    },
    {
        "id": 2057,
        "key": "2057",
        "name": "猎头顾问",
        "type": null,
        "description": null
    },
    {
        "id": 2058,
        "key": "2058",
        "name": "咨询总监",
        "type": null,
        "description": null
    },
    {
        "id": 2059,
        "key": "2059",
        "name": "市场调研",
        "type": null,
        "description": null
    },
    {
        "id": 2060,
        "key": "2060",
        "name": "数据分析师",
        "type": null,
        "description": null
    },
    {
        "id": 2061,
        "key": "2061",
        "name": "心理咨询师",
        "type": null,
        "description": null
    },
    {
        "id": 2062,
        "key": "2062",
        "name": "婚恋咨询师",
        "type": null,
        "description": null
    },
    {
        "id": 2063,
        "key": "2063",
        "name": "其他咨询顾问",
        "type": null,
        "description": null
    },
    {
        "id": 2064,
        "key": "2064",
        "name": "英语翻译",
        "type": null,
        "description": null
    },
    {
        "id": 2065,
        "key": "2065",
        "name": "日语翻译",
        "type": null,
        "description": null
    },
    {
        "id": 2066,
        "key": "2066",
        "name": "韩语/朝鲜语翻译",
        "type": null,
        "description": null
    },
    {
        "id": 2067,
        "key": "2067",
        "name": "俄语翻译",
        "type": null,
        "description": null
    },
    {
        "id": 2068,
        "key": "2068",
        "name": "西班牙语翻译",
        "type": null,
        "description": null
    },
    {
        "id": 2069,
        "key": "2069",
        "name": "德语翻译",
        "type": null,
        "description": null
    },
    {
        "id": 2070,
        "key": "2070",
        "name": "法语翻译",
        "type": null,
        "description": null
    },
    {
        "id": 2071,
        "key": "2071",
        "name": "其他语种翻译",
        "type": null,
        "description": null
    },
    {
        "id": 2072,
        "key": "2072",
        "name": "事务所律师",
        "type": null,
        "description": null
    },
    {
        "id": 2073,
        "key": "2073",
        "name": "律师助理",
        "type": null,
        "description": null
    },
    {
        "id": 2074,
        "key": "2074",
        "name": "专利律师",
        "type": null,
        "description": null
    },
    {
        "id": 2075,
        "key": "2075",
        "name": "知识产权律师",
        "type": null,
        "description": null
    },
    {
        "id": 2078,
        "key": "2078",
        "name": "光伏系统工程师",
        "type": null,
        "description": null
    },
    {
        "id": 2079,
        "key": "2079",
        "name": "风电运维工程师",
        "type": null,
        "description": null
    },
    {
        "id": 2080,
        "key": "2080",
        "name": "水利工程师",
        "type": null,
        "description": null
    },
    {
        "id": 2081,
        "key": "2081",
        "name": "地质工程师",
        "type": null,
        "description": null
    },
    {
        "id": 2087,
        "key": "2087",
        "name": "农业/林业技术员",
        "type": null,
        "description": null
    },
    {
        "id": 2088,
        "key": "2088",
        "name": "饲养员",
        "type": null,
        "description": null
    },
    {
        "id": 2089,
        "key": "2089",
        "name": "禽畜/水产养殖技术员",
        "type": null,
        "description": null
    },
    {
        "id": 2090,
        "key": "2090",
        "name": "畜牧兽医",
        "type": null,
        "description": null
    },
    {
        "id": 2091,
        "key": "2091",
        "name": "总裁/总经理/CEO",
        "type": null,
        "description": null
    },
    {
        "id": 2092,
        "key": "2092",
        "name": "副总裁/副总经理/VP",
        "type": null,
        "description": null
    },
    {
        "id": 2093,
        "key": "2093",
        "name": "总助/CEO助理/董事长助理",
        "type": null,
        "description": null
    },
    {
        "id": 2094,
        "key": "2094",
        "name": "区域负责人(辖多个分公司)",
        "type": null,
        "description": null
    },
    {
        "id": 2095,
        "key": "2095",
        "name": "分公司/代表处负责人",
        "type": null,
        "description": null
    },
    {
        "id": 2096,
        "key": "2096",
        "name": "董事会秘书",
        "type": null,
        "description": null
    },
    {
        "id": 2097,
        "key": "2097",
        "name": "联合创始人",
        "type": null,
        "description": null
    },
    {
        "id": 2098,
        "key": "2098",
        "name": "高级管理职位",
        "type": null,
        "description": null
    }
]

export default data