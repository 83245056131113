import React from 'react';
import styled from 'styled-components';
import {Tooltip} from "antd";

const ContainerStyled = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${props => props.$fill ? `path{fill: ${props.$fill};};` : ""}
`

export default ((props) => {
    const {value, style, className, onClick, width, height, fill, title, placement, openTooltip} = props;
    const viewElement = <ContainerStyled className={className}
                                         onClick={onClick}
                                         $fill={fill}
                                         style={{...style, height, width}}
                                         dangerouslySetInnerHTML={{__html: value}}/>;


    return openTooltip ? <Tooltip title={title} placement={placement} overlayClassName="tooltipPointerEventsNone">
        {viewElement}
    </Tooltip>:viewElement

});

