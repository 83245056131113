import withComponent from '../../hoc/withComponent';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const LayoutBoxStyled = styled.div`
  position: relative;
  transition: transform 0.5s;
  ${props => props.$tran ? '' : 'transform: translateX(-20px);'}
  user-select: text;
`;

export default withComponent((props) => {
  const { id, initAnimation = true, onTransitionEnd, onInit, delayTime = 500 } = props;
  const [show, setShow] = useState(false);
  const [tran, setTran] = useState(!initAnimation);

  useEffect(() => {
    setShow(true);
    if (initAnimation) {
      setTimeout(() => {
        setTran(true);
      }, delayTime);
    } else {
      onTransitionEnd?.(id);
    }
    onInit?.();
  }, []);
  return <LayoutBoxStyled id={id} $tran={tran} onTransitionEnd={() => onTransitionEnd?.(id)} style={{ visibility: show ? undefined : 'hidden' }}>
    {props.children}
  </LayoutBoxStyled>;
});
