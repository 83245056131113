import JzImage from "@/components/JzImage"
import { Modal } from "antd-mobile"
import classNames from "classnames"
import { useMemo, useState } from "react"
import styled from "styled-components"


const VideoModalStyled = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #4C4C4C;
    .vd-h2 {
        
    }
    .vd-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 8px;
        row-gap: 8px;
    }
    .vd-item {
        padding: 5px 8px;
        border-radius: 4px;

        font-weight: 400;
        font-size: 12px;
        color: #3C3C3C;
    }
    .vd-item-active {
        background: #F4F4F4;
        color: #11BBA6;
    }
`

const list = [
    {
        id: 1,
        imgUrl: "iojsiofjoi3.png",
        url: '',
        text: 'BOSS直聘',
    },
    {
        id: 2,
        imgUrl: "ncvxknvk1.png",
        url: '',
        text: '拉勾招聘',
    },
    {
        id: 3,
        imgUrl: "fsnjkfn1399.png",
        url: '',
        text: '智联招聘',
    },
    {
        id: 4,
        imgUrl: "kjdshfk123.png",
        url: '',
        text: '超级简历',
    },
]

const VideoModal = ({ visible, onClose }) => {
    const [selected, setSelected] = useState('')
    const videoUrl = useMemo(() => {
        return list.find(item => item.id === selected)?.url
    }, [selected])

    return <Modal closeOnMaskClick={true} visible={visible} onClose={onClose} content={
        <VideoModalStyled>
            <div className="vd-h2 mb-14">查看操作视频</div>
            <video className="mb-20" controls style={{
                width: '100%',
                height: 172,
            }} loop muted autoplay="autoplay">
                <source src={videoUrl} />
            </video>
            <div className="vd-list">

                {list.map(item => {
                    const cls = classNames('vd-item flex-center justify-start', {
                        'vd-item-active': item.id === selected
                    })
                    return <div className={cls} onClick={() => setSelected(item.id)}>
                        <JzImage className="pr-12" src={item.imgUrl} style={{ width: 20, height: 20 }} />
                        <span>{item.text}</span>
                    </div>
                })}
            </div>
        </VideoModalStyled>
    }>
    </Modal>
}

export default VideoModal