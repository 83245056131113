import {saveResumeData} from "@/redux/thunk/edit";
import {useCallback, useRef} from "react";
import {useDispatch} from "react-redux";
import {editResumeInfo} from "@/http/api";
import getNestedProperty from "@/utils/getNestedProperty";


const savePath = [
    'resume',
    'resumeData',
    'pagination.openAutoOnePage',
    'pagination.openAutoPagination',
    'pagination.onePageLevel'
]


export default (store) => {
    const dispatch = useDispatch()
    const that = useRef({
        currentState: undefined
    }).current;
    const save = useCallback(() => {
        // if (__DEV__) return
        if (!that.currentState) return
        const state = store.getState();
        const {resume, resumeData, pagination} = state;
        let flag = false;
        try {
            savePath.forEach((path) => {
                if (getNestedProperty(that.currentState, path) !== getNestedProperty(state, path)) {
                    flag = true;
                }
            })
            if (flag) {
                const body = {
                    defaultThemeIndex: resume.defaultThemeIndex,
                    themeIndex: resume.themeIndex,
                    fontSize: resume?.fontSize,
                    fontFamily: resume?.fontFamily,
                    pageMargin: resume?.pageMargin,
                    moduleMargin: resume?.moduleMargin,
                    openAutoOnePage: pagination.openAutoOnePage,
                    openAutoPagination: pagination.openAutoPagination,
                    onePageLevel: pagination.onePageLevel
                }
                editResumeInfo({
                    body: JSON.stringify(body), query: {
                        resumeId: resume.resumeId,
                    }
                })
                dispatch(saveResumeData({pageId: resume.pageId, resumeData}));
            }
        } catch (e) {

        }
        that.currentState = state;
    }, [])
    const onInit = useCallback(() => {
        that.currentState = store.getState();
    }, [])

    return {onChange: save, onInit}
}
