// 页面跳转后，再返回时，恢复到原来的位置

const { useLayoutEffect } = require("react")

const usePreScroll = () => {
    const pathname = window.location.pathname

    useLayoutEffect(() => {
        const top = window.localStorage.getItem(window.location.pathname)
        if (top) {
            window.scrollTo(0, Number(top))
        }

        return () => {
            const top = document.documentElement.scrollTop || document.body.scrollTop 
            window.localStorage.setItem(pathname, top)
        }
    }, [])
}

export default usePreScroll