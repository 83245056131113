import {useCallback, useEffect} from "react";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";

export default () => {
    const resumeInstance = useResumeInstance();
    const listenDownHandler = useCallback((e) => {
        const isCtrlKey = !!e.ctrlKey;
        const isCommandKey = !!e.metaKey;
        const isCtrlCodeOrCommand = isCommandKey || isCtrlKey;
        if (isCtrlCodeOrCommand && e.keyCode === 90) {
            resumeInstance.versionPre?.()
        }
    }, []);
    const listenCopy = () => {
        document?.addEventListener?.('keydown', listenDownHandler);
    };
    const unListenCopy = () => {
        document?.removeEventListener?.('keydown', listenDownHandler);
    };

    useEffect(() => {
        listenCopy();
        return () => {
            unListenCopy();
        };
    }, []);
}
