import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import ThemeButton from "@/styled/ThemeButton";
import { useCallback, useEffect, useRef,useState } from "react";
import useForceUpdate from "@/hooks/useForceUpdate";
import withComponent from "@/hoc/withComponent";
import FlexStyled from "@/styled/FlexStyled";
import { bindPhonePc, getUserInfo, sendCode } from "@/http/api";
import { message } from "antd";
import store from "store";
import { useDispatch, useSelector, useStore } from "react-redux";
import loginHandler from "@/common/user/loginHandler";


const Text1Styled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
`;
const Text2Styled = styled.div`
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #2C2C2C;
    font-weight:bold;
    margin:12px 0 ;
`;
const Text3Styled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #90969e;
  line-height: 17px;
`;





const Button2Styled = styled(ThemeButton)`
  width: 120px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: white;
  ${(props) => (props.isDisable ? "opacity:0.3 !important;" : "")}
  ${(props) => (props.isDisable ? "cursor:not-allowed !important;" : "")}
`;

const Content = (props) => {
  const {phone} = props

  const [telephone,setTelephone] = useState('**')
  useEffect(()=>{
    setTelephone(maskPhoneNumber(phone)||'**')
  },[phone])
  const onClick = ()=>{
    window.open('https://jzmbti.com/pages/enterprise/start/e-start?pwd=RDMyekQ0ekU7OzI=')
    props.onCancel()
}
  function maskPhoneNumber(phoneNumber) {
    if(!phoneNumber){
      return ''
    }
    let number = phoneNumber + '' 
    let start = number.substr(0, 3);
    let end = number.substr(-4);
    let masked = start + "****" + end;
    return masked;
  }

  return (
    <Modal
        width='400px'
        height='474px'
        open={props.open}
        footer={null}
        onCancel={props.onCancel}
        zIndex={99999991}
        destroyOnClose
      //   modalRender={() => <Content {...props} />}
    >
      <div style={{height:442}}>
        {
          <>
            <FlexStyled $justifyContent='center'>
                <img src="https://pub.jianzeppt.cn/image/report.png" style={{paddingTop:'90px',width:'212px',height:'150px'}}>
                </img>
            </FlexStyled>
            <FlexStyled  $justifyContent='center' alignItems="center">
              <Text2Styled>已获取免费职业测评机会</Text2Styled>
            </FlexStyled>
            <FlexStyled  $justifyContent='center' alignItems="center">
              <Text1Styled>发放至手机号{telephone}中</Text1Styled>
            </FlexStyled>
            <FlexStyled
                $justifyContent='center'
            >
                <Button2Styled
              onClick={onClick}
              style={{ marginTop: 64,marginBottom:'28px' }}
            >
              立即测验
            </Button2Styled>
            </FlexStyled>
          </>
        }
      </div>
      
    </Modal>
  );
};

export default Content;
