import styled from "styled-components";
import Select from "@/components/resume/components/Select";
import {Popover} from "antd";
import {useCallback, useState} from "react";

const ContainerStyled = styled.div`
  width: 313px;
  height: 84px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 0.5);
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  padding: 0 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`
const ItemBoxStyled = styled.div`
  width: 120px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 8px;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
`
const ItemTextStyled = styled.div`
  width: 56px;
  height: 16px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
`
const Text1Styled = styled.div`
  /* width: 12px; */
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  line-height: 17px;
  padding: 0 9px;
`
const icon = <div style={{width: 16, height: 16}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="求职意向状态" transform="translate(-1598.000000, -740.000000)" fill="#999999" fill-rule="nonzero">
            <g id="编组-21" transform="translate(1481.000000, 706.000000)">
                <g id="更多-绿备份" transform="translate(117.000000, 34.000000)">
                    <path d="M10.6287151,13.3333333 C10.4824264,13.3333333 10.3361172,13.2775932 10.2245011,13.1657434 L4.96743947,8.40517069 C4.74418684,8.18184055 4.74418684,7.8191394 4.96743947,7.59541932 L10.2245011,2.83445673 C10.4477538,2.61073665 10.8093079,2.61073665 11.0325605,2.83445673 C11.2558132,3.05778687 11.2558132,3.42048802 11.0325605,3.6442081 L6.17971289,8.00008979 L11.0325605,12.3556021 C11.2558131,12.5793221 11.2558131,12.9416333 11.0325605,13.1653534 C10.9213131,13.2775932 10.7750039,13.3333333 10.6287151,13.3333333 Z" id="-" transform="translate(8.000000, 8.000000) scale(-1, 1) rotate(-90.000000) translate(-8.000000, -8.000000) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>


const maxIndex = 101;
const getList = (maxIndex) => {
    return Array.from({length: maxIndex}).map((i, index) => ({id: index, name: index === 0 ? '面议' : index + 'k'}))
}
const getTextById = (index) => {
    if (index === undefined) {
        return "请选择"
    }
    if (!index) {
        return "面议"
    }
    if (index === -1) {
        return '不填写'
    }
    return index + "k"
}

const list = Array.from({length: 101}).map((i, index) => ({id: index, name: index === 0 ? '面议' : index + 'k'}))


export default (props) => {
    const {defaultMinSalary, defaultMaxSalary} = props;
    const {onChangeMin, onChangeMax} = props;
    const [minSalary, setMinSalary] = useState(defaultMinSalary);
    const [maxSalary, setMaxSalary] = useState(defaultMaxSalary);
    const [minSalaryOpen, setMinSalaryOpen] = useState(false);
    const [minSalaryOpen2, setMinSalaryOpen2] = useState(false);
    const list2 = [{id: -1, name: '不填写'}, ...list.slice(minSalary + 1, minSalary + 1 + 2)];

    const onClickMin = useCallback((item) => {
        setMinSalary(item.id);
        onChangeMax(undefined);
        setMaxSalary(undefined);
        setMinSalaryOpen(false);
        onChangeMin(item.id);
    }, [])
    const onClickMax = useCallback((item) => {
        setMaxSalary(item.id)
        setMinSalaryOpen2(false);
        onChangeMax(item.id);
    }, [])
    return <ContainerStyled>
        <Popover content={<Select width={120} onClickItem={onClickMin} list={list}/>}
                 destroyTooltipOnHide
                 overlayClassName="ant-popover-custom-field-menu"
                 autoAdjustOverflow={false}
                 trigger="click"
                 transitionName=""
                 open={minSalaryOpen}
                 placement="bottom"
                 onOpenChange={setMinSalaryOpen}>
            <ItemBoxStyled>
                <ItemTextStyled
                    style={{color: minSalary !== undefined ? 'black' : undefined}}>{getTextById(minSalary)}</ItemTextStyled>
                <div>{icon}</div>
            </ItemBoxStyled>
        </Popover>
        {
            (minSalary !== 0 && (minSalary < 100 || maxSalary === undefined)) && <>
                <Text1Styled>至</Text1Styled>
                <Popover content={<Select width={120} onClickItem={onClickMax} list={list2}/>}
                         destroyTooltipOnHide
                         overlayClassName="ant-popover-custom-field-menu"
                         autoAdjustOverflow={false}
                         trigger="click"
                         transitionName=""
                         open={minSalaryOpen2}
                         placement="bottom"
                         onOpenChange={setMinSalaryOpen2}>
                    <ItemBoxStyled>
                        <ItemTextStyled
                            style={{color: maxSalary !== undefined ? 'black' : undefined}}>{getTextById(maxSalary)}</ItemTextStyled>
                        <div>{icon}</div>
                    </ItemBoxStyled>
                </Popover>
            </>
        }
    </ContainerStyled>
}
