import styled from "styled-components"
import useResumeData from "@/pages/Mobile/hooks/useResumeData"
import { Input, TextArea } from 'antd-mobile'
import { useEffect, useMemo, useState } from "react";
import { getFieldConfigByModuleName } from "./config";
import { isEmpty } from 'lodash'
import JzButton from "@/components/JzButton";
import useResumeFieldVisible from "@/pages/Mobile/hooks/useResumeFieldVisible";
import { Module } from "@/common/module/module";
import useUpdateResumeData from "@/pages/Mobile/hooks/useUpdateResumeData";
import useQuery from "@/hooks/useQuery";
import getPlaceholder from "@/common/config/getPlaceholder";
import { PORTFOLIO_FIELD_ENUM } from "@/common/field/field";
import useQueryParam from "@/hooks/useQueryParam";
import { RightOutline } from 'antd-mobile-icons'
import QueryString from "qs";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js"


const ModuleEditStyled = styled.div`
    padding: 20px;
    margin-bottom: 52px;

    .adm-form-item-label {
        font-size: 12px !important;
    }
    .adm-input-element, .adm-form-item-child-inner {
        font-size: 15px !important;
    }
    .adm-list-default .adm-list-body {
        border: none;
    }
    .resume-wrap-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 15px;
        padding-bottom: 24px;
        background-color: #FFFFFF;
    }
    .long-title {
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #161619;
        margin-bottom: 24px;
    }

    textarea {
        height: calc(100vh - 206px);
    }
    .adm-text-area-count {
        font-size: 13px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #B3B3B3;
    }
`



const moduleName = Module.PORTFOLIO

const PortfolioTextEdit = () => {
    const { contentIndex } = useQuery()
    const { data: resumeData } = useResumeData()
    const { onChangeByPath } = useUpdateResumeData()
    const basicInfoData = useMemo(() => {
        return resumeData?.[moduleName]?.data?.links?.[contentIndex] || {}
    }, [resumeData, moduleName, contentIndex])
    const onFinish = () => {
        let path = `${moduleName}.data.links[${contentIndex}]`
        onChangeByPath(path, {
            title: basicInfoData.title,
            link: basicInfoData.link
        })
    }

    const handleInputChange = (type, value) => {
        let path = `${moduleName}.data.links[${contentIndex}]`
        onChangeByPath(path, {
            ...basicInfoData,
            [type]: value
        }, false)
    }

    return <ModuleEditStyled>
        <NavigationHeader
            style={{ width: "100vw", left: "-20px", top: "-20px" }}
        title={document.title}></NavigationHeader>

        <div>
            <div className="long-title">作品介绍</div>
            <div>
                <TextArea style={{
                    '--font-size': 15,
                }} onChange={v => handleInputChange('title', v)} value={basicInfoData.title} placeholder={"在这里添加自定义描述（5000字以内）"} maxLength={5000} showCount />
            </div>
        </div>
        <div className="resume-wrap-footer flex">
            <JzButton onClick={() => onFinish()} className="flex-1" $type="primary">保存</JzButton>
        </div>

    </ModuleEditStyled>
}

export default PortfolioTextEdit