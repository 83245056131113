import {useEffect} from "react";
import './useSelectedFieldCss.css'

const mapTypeToClassName = {
    'select': 'resume-field-select-focus',
    'input': 'resume-field-focus',
}

const useSelectedField = (props) => {
    const { type = 'input', visible, ref } = props
    const className = mapTypeToClassName[type]

    useEffect(() => {
        const element = ref.current
        if (element?.parentElement) {
            if (visible) {
                element?.parentElement.classList.add(className)
            } else {
                element?.parentElement.classList.remove(className)
            }
        }
        element?.parentElement.classList.add('resume-field-border')
        element.classList.add('result-filed-border-none')
    }, [visible, className])
}

export default useSelectedField