import styled from "styled-components"
import {
    DownOutlined
} from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import Menu from "../Menu";
import { Upload, message } from "antd";
import {v4} from "uuid";
import { modifyResume, uploadResumeAttachment } from "@/http/api";
import { getParam } from "@/pages/Mobile/utils";
import FlexStyled from "@/styled/FlexStyled";
import { useDispatch, useSelector } from "@/components/resume/redux/hooks";
import JzThemeButton from "@/components/JzThemeButton";

const TargetPositionStyled = styled.div`
    /* margin-bottom: -26px; */
    margin-bottom: 8px;
    .wrap {
        display: flex;
        .target-title {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 84px;
            height: 84px;
            padding: 10px;
            box-sizing: border-box;
            /* border-radius: 4px; */
            background-color: #fff;
            flex-shrink: 0;
            color: #11BBA6;
            font-size: 14px;
            border-radius: 4px;
            border-top-right-radius: 0;
        }
        .target-content {
            align-self: flex-start;
            flex: 1;
            padding: 10px;
            background-color: #fff;
            margin-right: 12px;
            border-radius: 4px;
            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .name {
                    font-size: 14px;
                    font-weight: bold;
                }
                .right {
                    display: flex;
                    .dropdown {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        height: 32px;
                        border-radius: 8px;
                        border: 1px solid #ECECEC;
                        color: #999;
                    }
                }
            }
        }
    }
    .custom-upload {
        line-height: 1;
    }
`

const ButtonStyled = styled.div`
  width: 96px;
  height: 30px;
  background: #11BBA6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;


  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
`

const Button2Styled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #00BCA6;
    background-color: #ffffff;
    font-size: 14px;

    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #11BBA6;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`


const icon = <div style={{ width: 20, height: 20, position: "relative" }} dangerouslySetInnerHTML={{
    __html: `<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0">
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="简历编辑页" transform="translate(-1836.000000, -15.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-6备份" transform="translate(1814.000000, 10.000000)">
                <g id="加协作团队备份-11" transform="translate(22.000000, 5.000000)">
                    <g id="xiazai-15" transform="translate(3.500000, 3.000000)">
                        <path d="M12.3400236,12.6622222 L0.659976387,12.6622222 C0.291617473,12.6622222 0,12.9577778 0,13.3311111 C0,13.7044444 0.291617473,14 0.659976387,14 L12.3400236,14 C12.7083825,14 13,13.7044444 13,13.3311111 C13,12.9733333 12.7083825,12.6622222 12.3400236,12.6622222 Z M6.06257379,11.5111111 C6.18536009,11.6355556 6.35419126,11.6977778 6.50767414,11.6822222 C6.66115702,11.6822222 6.82998819,11.6355556 6.9527745,11.5111111 L10.9433294,7.46666667 C11.1735537,7.23333333 11.1735537,6.84444444 10.9433294,6.61111111 C10.7131051,6.37777778 10.3293979,6.37777778 10.0991736,6.61111111 L7.0755608,9.67555556 L7.0755608,0.606666667 C7.0755608,0.28 6.81463991,0 6.47697757,0 C6.13931523,0 5.87839433,0.264444444 5.87839433,0.606666667 L5.87839433,9.61333333 L2.91617473,6.61111111 C2.68595041,6.37777778 2.30224321,6.37777778 2.07201889,6.61111111 C1.84179457,6.84444444 1.84179457,7.23333333 2.07201889,7.46666667 L6.06257379,11.5111111 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}} />

const icon2 = <div dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="完善简历" transform="translate(-79.000000, -159.000000)" fill="#11BBA6" fill-rule="nonzero">
            <g id="编组-6" transform="translate(48.000000, 66.000000)">
                <g id="模块管理" transform="translate(31.000000, 93.000000)">
                    <g id="01_neirongguanli" transform="translate(2.983750, 2.750000)">
                        <path d="M12.5827338,8.5467626 L12.5827338,16.0251799 C12.5827338,16.381295 12.4640288,16.5 12.1079137,16.5 L1.18705036,16.5 C0.356115115,16.5 0,16.0251798 0,15.3129496 L0,1.18705034 C0,0.356115094 0.474820153,0 1.30575538,0 L11.3956835,0 C12.2266187,0 12.5827338,0.356115115 12.5827338,1.30575538 L12.5827338,8.5467626 Z M6.29136691,4.74820143 L9.97122301,4.74820143 C10.3273381,4.74820143 10.5647482,4.39208632 10.5647482,4.03597122 C10.5647482,3.67985613 10.3273381,3.32374099 9.97122301,3.32374099 L2.61151078,3.32374099 C2.3741007,3.32374099 2.13669065,3.56115107 2.13669065,3.91726618 C2.13669065,4.2733813 2.25539569,4.51079135 2.61151078,4.62949639 C2.73021582,4.74820143 2.9676259,4.74820143 3.20503597,4.74820143 L6.29136691,4.74820143 L6.29136691,4.74820143 Z M5.57913668,8.90287769 L8.19064748,8.90287769 C8.66546763,8.90287769 9.02158273,8.66546761 9.02158273,8.19064748 C9.02158273,7.71582733 8.66546761,7.47841727 8.19064748,7.47841727 L2.84892086,7.47841727 C2.3741007,7.47841727 2.01798561,7.71582735 2.01798561,8.19064748 C2.01798561,8.66546763 2.37410073,8.90287769 2.84892086,8.90287769 L5.57913668,8.90287769 Z M5.69784172,11.6330935 L2.84892086,11.6330935 C2.49280574,11.6330935 2.25539569,11.9892086 2.25539569,12.3453237 C2.25539569,12.7014388 2.49280576,12.9388489 2.84892086,13.0575539 L8.78417265,13.0575539 C9.14028777,12.9388489 9.37769782,12.7014388 9.25899281,12.3453237 C9.25899281,11.9892086 8.90287769,11.7517986 8.42805756,11.7517986 C7.47841727,11.6330935 6.52877699,11.6330935 5.69784172,11.6330935 L5.69784172,11.6330935 Z M14.0071943,9.85251798 L14.0071943,3.67985611 C14.0071943,3.32374099 14.1258993,3.20503595 14.3633094,3.32374099 L15.0755396,3.32374099 C15.6690648,3.44244603 16.0251799,3.79856114 16.0251799,4.39208632 L16.0251799,15.3129496 C16.0251799,15.9064748 15.5503597,16.381295 14.9568345,16.381295 C14.0071943,16.381295 14.0071943,16.381295 14.0071943,15.4316547 L14.0071943,9.85251798 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const icon3 = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="未展开样式" transform="translate(-1138.000000, -15.000000)" fill="#11BBA6">
            <g id="编组-6备份-2" transform="translate(1128.000000, 10.000000)">
                <g id="加协作团队备份-11" transform="translate(10.000000, 5.000000)">
                    <g id="daoru-4" transform="translate(2.200000, 3.000000)">
                        <path d="M3.18494272,0 C2.83360611,0 2.55100927,0.282596836 2.55100927,0.633933442 L2.55100927,3.18494272 L3.81887616,3.18494272 L3.81887616,1.27550464 L13.3660666,1.27550464 L13.3660666,12.7321331 L3.81887616,12.7321331 L3.81887616,10.822695 L2.54337152,10.822695 L2.54337152,13.3660666 C2.54337152,13.7174032 2.82596836,14 3.17730496,14 L14,14 C14.3513366,14 14.6339334,13.7174032 14.6339334,13.3660666 L14.6339334,0.641571195 C14.6339334,0.290234588 14.3513366,0.00763775232 14,0.00763775232 C14,0 3.18494272,0 3.18494272,0 Z M6.04909984,4.7735952 C5.87343153,4.7735952 5.72831424,4.91871249 5.72831424,5.0943808 L5.72831424,6.36988543 L0.320785597,6.36988543 C0.145117294,6.36988543 0,6.51500273 0,6.69067103 L0,7.32460447 C0,7.50027278 0.145117294,7.64539007 0.320785597,7.64539007 L5.72831424,7.64539007 L5.72831424,8.91325696 L5.73595199,8.96672122 C5.74358974,9.03546099 5.78177851,9.08892526 5.82760502,9.13475177 C5.94980906,9.25695581 6.15602837,9.25695581 6.27823241,9.13475177 L8.18767049,7.22531369 L8.22585925,7.17948718 C8.30987452,7.04964539 8.29459902,6.88161484 8.18767049,6.77468631 L6.27823241,4.86524823 L6.24004364,4.82705947 C6.17894163,4.79650846 6.11020185,4.7735952 6.04909984,4.7735952 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />


const MyResume = (props) => {
    const {
        store,
        onClickDown,
        resumeRef,
        setResumeLoading,
        setThemeVisible,
        setImportModalVisible,
        setChangeTemplateVisible,
        onClickModule
    } = props;
    const resumeData = useSelector(state => state.resumeData);
    const ref = useRef(null)

    const handleUploadVideo = async (file) => {
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
            message.error('单个简历文件最大为50M！');
            return false;
        }
        const requestId = v4()
        const formData = new FormData();
        formData.append('file', file);
        // formData.append('resumeBody', resumeData);
        try {
            setResumeLoading(true,'import');
            await modifyResume({body: {
                id: parseInt(getParam('id')),
                requestId,
                resumeBody: {
                    userData: resumeData
                }
            }})
            const res = await uploadResumeAttachment({
                query: { requestId },
                body: formData
            })
            window.processModifyResume(res)
        } catch (error) {
            console.log('erroer', error)
        } finally {
            setResumeLoading(false,'import')
        }
        return false;
    }
    window.triggerUploadFile = () => {
        // console.log('ref', window.ref = ref.current)
        const {upload} = ref.current || {};
        upload.uploader.onClick(); 
    }



    return <TargetPositionStyled>
    <div className="wrap">
        {/* <div className="target-title">
            {icon2}
            <span>我的简历</span>
        </div> */}
        <div className="target-content">
            <div className="header">
                <Menu 
                    resumeRef={resumeRef} 
                    setThemeVisible={setThemeVisible}
                    setImportModalVisible={setImportModalVisible}
                    setChangeTemplateVisible={setChangeTemplateVisible}
                    onClickModule={onClickModule}/>
                <FlexStyled $alignItems="center" $justifyContent="center" >
                    {
                        <Upload
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            ref={ref}
                            beforeUpload={handleUploadVideo}
                            showUploadList={false}
                            multiple={false}
                            className="custom-upload"
                        >
                            <JzThemeButton.Type3 styles={{width: 104, height: 30}}>
                                {icon3}
                                导入简历
                            </JzThemeButton.Type3>
                        </Upload>
                    }
                    <JzThemeButton.Type1 onClick={onClickDown} styles={{width: 96, height: 30, marginLeft: 8}}>
                        <div>{icon}</div>
                            下载
                    </JzThemeButton.Type1>
                </FlexStyled>
            </div>
        </div>
    </div>
</TargetPositionStyled>
}

export default MyResume