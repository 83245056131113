import classNames from "classnames"
import React from "react"
import styled from "styled-components"

const TabsStyled = styled.div`

    .custom-tab-panel-hidden {
        display: none;
    }
    .custom-tab-panel{
      position: relative;
      z-index: 2;
    }
    .custom-tab-list {
        position: relative;
        width: 100%;
        height: 64px;
    }
    .custom-tab-list-item {
        width: 177px;
        height: 64px;
        position: absolute;

        border-radius: 8px 8px 0px 0px;
        color: #333;
        font-size: 14px;
        color: #213130;
        font-weight: bold;
        &{
          cursor: pointer;
        }
        /* 
        &-active {
            background: #ffffff;
        } */
    }
`

const TabItemStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({active}) => active ? '#ffffff' : '#15252B'};
  background: ${({ active, text, url, activeUrl }) => {
    if (active && text === '搜索模板') {
      return `url(${'https://oss.jianzeppt.com/jz-ppt-img/' + activeUrl}) no-repeat center center`;
    } else if (active && text === '导入简历') {
      return `url(${'https://oss.jianzeppt.com/jz-ppt-img/' + activeUrl}) no-repeat center center`;
    }else if(text === '一键生成' && active){
      return `url(https://pub.jianzeppt.cn/image/%E4%B8%80%E9%94%AE%E7%94%9F%E6%88%90.png) no-repeat center center`;
    }
  }};
  background-size: 100% 100%;
  /* left: ${({text, active}) => text === '搜索模板' ? active ? '-4px' : '0' : '140px'}; */
  left:  ${({text, active}) => {
    if (active && text === '搜索模板') {
      return '-5px';
    } else if (active && text === '导入简历') {
      return `300px`;
    } else if(text === '导入简历') {
      return `300px`;
    } else if(text === '搜索模板'){
      return `0`;
    }else if(text === '一键生成'){
      return `140px`;
    }
  }};
  z-index: ${({active}) => active ? '9' : '8'};
  bottom: ${({text, active}) => {
    if (active && text === '搜索模板') {
      return '-10px';
    } else if (active && text === '导入简历') {
      return `-10px`;
    } else if(text === '导入简历') {
      return `-13px`;
    } else if(text === '搜索模板'){
      return `-13px`;
    }else if(active && text === '一键生成'){
      return `-8px`;
    }else if(text === '一键生成'){
      return `-13px`;
    }
  }};
  padding-bottom: ${({text, active}) => !active && '10px'};
  padding-right: ${({text, active}) => {
    if (active && text === '搜索模板') {
      return '10px';
    } else if(text === '搜索模板'){
      return `13px`;
    }
  }};
  padding-left: ${({text, active}) => {
    if (active && text === '导入简历') {
      return '20px';
    } else if(text === '导入简历'){
      return `13px`;
    }
  }};
  box-sizing: border-box;
`;

// Tabs 组件
const Tabs = ({ children, value, onChange, className, style }) => {
    const passProps = { activeTab: value, onChange }
    return (
        <TabsStyled className={className} style={style}>
            {React.Children.map(children, (child) =>
                React.cloneElement(child, passProps)
            )}
        </TabsStyled>
    )
}

// TabList 组件
const TabList = ({ children, activeTab, onChange }) => {
    const passProps = { activeTab, onChange }
    return <div className="flex custom-tab-list">
      <div style={{
        width: '473px',
        borderRadius: '8px 8px 0px 0px',
        height:'54px',
        background: 'rgba(234,249,250)',
        marginTop: '13px'
      }} ></div>
        {React.Children.map(children, (child) =>
            React.cloneElement(child, passProps)
        )}
    </div>
}

// Tab 组件
const Tab = ({ children, value, activeTab, onChange, style }) => {
    const cls = classNames('flex-center custom-tab-list-item', {'custom-tab-list-item-active': activeTab === value})
    return (
        <div
            className={cls}
            onClick={() => onChange && onChange(value)}
            style={style}
        >
            {children}
        </div>
    )
}

// TabPanel 组件
const TabPanel = ({ children, value, activeTab }) => {
    const cls = classNames('custom-tab-panel', {'custom-tab-panel-hidden': activeTab !== value})
    return <div className={cls}>{children}</div>
}

export const TabItem = ({ active, text, url, activeUrl }) => {
    return <TabItemStyled url={url} active={active} text={text} activeUrl={activeUrl}>
        <span>{text}</span>
    </TabItemStyled>
  }

Tabs.List = TabList;
Tabs.Tab = Tab;
Tabs.Panel = TabPanel;

export default Tabs