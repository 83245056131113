import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Pointer from "@/styled/Pointer";
import FlexStyled from "@/styled/FlexStyled";
import withComponent from "@/hoc/withComponent";
import { Dropdown, Menu } from "antd";
import { fetchUserThunk } from "@/components/resume/redux/thunk/user";
import UserMenu, { svipIcon, vipIcon } from "@/components/user/UserMenu";
import PayModal from "@/components/PayModal";
import { isDVIP, isSVIP, hasReport } from "@/common/isVip";
import GetReportSuccessModal from "@/components/PayModal/GetReportSuccessModal.js";
import GetReportModal from "@/components/PayModal/GetReportModal";

const CellStyled = styled.div`
  cursor: pointer;
  height: 36px;
  padding: 0 16px;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 14px;

  :hover {
    background: #eceff1;
  }
`;
const Text1Styled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 14px;
  white-space: nowrap;
`;
const Text2Styled = styled.div`
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 14px;
  background: linear-gradient(
    169deg,
    #fa4d4f 0%,
    #fb7d61 53%,
    #fcad73 85%,
    #fdd682 100%
  );
  border-radius: 9px 9px 9px 0px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
  text-align: center;
  right: -38px;
`;

export default withComponent((props) => {
  const { show, style = {}, zIndex = 99 } = props;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [menuVisible, setMenuVisible] = useState(false);
  const [payModalVisible, setPayModalVisible] = useState(false);
  const { enterpriseMbtiMobile, enterpriseMbtiMobileState } = user;

  const that = useRef({}).current;
  const [, update] = useReducer((s) => s + 1, 0);
  const onMouseEnter = () => {
    clearTimeout(that.timer);
    that.in = true;
    update();
  };
  const onMouseLeave = () => {
    that.timer = setTimeout(() => {
      that.in = false;
      update();
    }, 250);
  };
  const onClickGetReport = () => {};
  const boxStyle = {
    width: 44,
    height: 44,
    position: "relative",
    color: "black",
    lineHeight: "normal",
    zIndex,
    ...style,
  };
  const onClickPay = () => {
    setPayModalVisible(true);
    setMenuVisible(false);
  };
  const [getModalOpen, setGetModalOpen] = useState(false);

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const onClickReport = () => {
    if (enterpriseMbtiMobileState === 2) {
      setSuccessModalOpen(true);
    } else if (enterpriseMbtiMobileState === 1) {
      setGetModalOpen(true);
    }
  };
  let [usePhone, setUsePhone] = useState();

  const onGetReportSuccess = (v) => {
    setUsePhone(v.phone);
    setGetModalOpen(false);
    setSuccessModalOpen(true);
  };
  const cancelSuccessModal = () => {
    setSuccessModalOpen(false);
    if(enterpriseMbtiMobileState === 1){

      window.location.reload();
    }
  };
  useEffect(() => {
    if (that.in && show) {
      dispatch(fetchUserThunk());
    }
  }, [that.in, show]);
  window.setPayModalVisible = setPayModalVisible;
  return (
    <>
      <div style={boxStyle}>
        <PayModal
          id={1}
          visible={payModalVisible}
          onSuccess={() => setPayModalVisible(false)}
          onCancel={() => setPayModalVisible(false)}
        />
        <GetReportModal
          onSuccess={onGetReportSuccess}
          open={getModalOpen}
          onCancel={() => setGetModalOpen(false)}
        ></GetReportModal>
        <GetReportSuccessModal
          phone={enterpriseMbtiMobile || usePhone}
          open={successModalOpen}
          onCancel={cancelSuccessModal}
          
        ></GetReportSuccessModal>

        <Dropdown
          trigger="hover"
          open={menuVisible}
          // open
          onOpenChange={setMenuVisible}
          placement="bottom"
          transitionName=""
          maskTransitionName=""
          overlay={
            <UserMenu
              onClickPay={onClickPay}
              onClickReport={onClickReport}
              menuVisible={menuVisible}
            />
          }
        >
          <Pointer
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            style={{
              position: "relative",
              borderRadius: "50%",
              width: "100%",
              height: "100%",
            }}
          >
            <div style={{ position: "absolute", top: 24, left: 15, zIndex: 2 }}>
              {isDVIP() && vipIcon}
              {isSVIP() && svipIcon}
            </div>
            {hasReport() && (
              <Text2Styled onClick={onClickGetReport}>待领取</Text2Styled>
            )}
            <div
              style={{
                width: boxStyle.width,
                height: boxStyle.height,
                borderRadius: "50%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              {user.icon ? (
                <img
                  src={user.icon}
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                  }}
                />
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  version="1.1"
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                  }}
                >
                  <g
                    id="页面-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g id="User" transform="translate(-240.000000, -48.000000)">
                      <g
                        id="user_4_fill"
                        transform="translate(240.000000, 48.000000)"
                      >
                        <rect
                          id="矩形"
                          fillRule="nonzero"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        ></rect>
                        <path
                          d="M12,2 C6.47715,2 2,6.47715 2,12 C2,17.5228 6.47715,22 12,22 C17.5228,22 22,17.5228 22,12 C22,6.47715 17.5228,2 12,2 Z M8.5,8.5 C8.5,6.567 10.067,5 12,5 C13.933,5 15.5,6.567 15.5,8.5 C15.5,10.433 13.933,12 12,12 C10.067,12 8.5,10.433 8.5,8.5 Z M5.2782,16.3396 C6.92693,14.9918 9.33472,14 12,14 C14.6653,14 17.0731,14.9918 18.7218,16.3396 C17.2969,18.5423 14.8187,20 12,20 C9.18136,20 6.70318,18.5423 5.2782,16.3396 Z"
                          id="形状"
                          fill="#cccccc"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </div>
          </Pointer>
        </Dropdown>
      </div>
    </>
  );
});
