import withComponent from "@/hoc/withComponent";
import {Popover} from "antd";
import {createElement, useCallback, useState} from "react";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import HeightAndWeightInput from "@/components/resume/components/HeightAndWeightInput";
import {useDispatch} from "@/components/resume/redux/hooks";
import {debounce} from "lodash";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import withField from "@/components/resume/hoc/withField";
import getResumePath from "@/common/resume/getResumePath";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import {BASE_Slot} from "@/common/slot/slot";

export default withComponent(withField((props) => {
    const {tagName, style, className, children = [], fieldRef} = props;
    const configRender = useConfigRender();
    const element = createElement(tagName, {style, className,ref:fieldRef}, children.map((config) => configRender(config)));
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const fieldInstance = useFieldInstance();
    const onChangeHeight = useCallback(debounce((value) => {
        const path = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, BASE_Slot.HEIGHT);
        dispatch(resumeDataSlice.actions.changeValueByPath({path:path, value}));
    }, 500), [])
    const onChangeWeight = useCallback(debounce((value) => {
        const path = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, BASE_Slot.WEIGHT);
        dispatch(resumeDataSlice.actions.changeValueByPath({path: path, value}));
    }, 500), [])

    return <Popover content={<HeightAndWeightInput onChangeHeight={onChangeHeight} onChangeWeight={onChangeWeight}/>}
                    destroyTooltipOnHide
                    overlayClassName="ant-popover-custom-field-menu"
                    autoAdjustOverflow={false}
                    trigger="click"
                    transitionName=""
                    open={visible}
                    placement="bottom"
                    onOpenChange={setVisible}>
        {element}
    </Popover>
}))
