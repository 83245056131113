import styled from 'styled-components';


export default styled.div`
  ${(props) => props.$displayFlex === false ? '' : 'display: flex;'}
  ${(props) => props.$justifyContent ? ('justify-content:' + props.$justifyContent + ';') : ''}
  ${(props) => props.$alignItems ? ('align-items:' + props.$alignItems + ';') : ''}
  ${(props) => props.$alignContent ? ('align-content:' + props.$alignContent + ';') : ''}
  ${(props) => props.$flexDirection ? ('flex-direction:' + props.$flexDirection + ';') : ''}
  ${(props) => props.$alignSelf ? ('align-self:' + props.$alignSelf + ';') : ''}
  ${(props) => props.$flex ? ('flex:' + props.$flex + ';') : ''}
  ${(props) => props.$flexWrap ? ('flex-wrap:' + props.$flexWrap + ';') : ''}
  ${(props) => props.$flexGrow ? ('flex-grow:' + props.$flexGrow + ';') : ''}
  ${(props) => props.$flexShrink ? ('flex-shrink:' + props.$flexShrink + ';') : ''}
`;
