export function checkWebpFeature() {
    return new Promise(resolve => {
        const image = new Image();
        image.addEventListener('error', () => resolve(false));
        image.addEventListener('load', () => {
            window.isSupportWebp = true
            resolve(image.width === 1)
        });
        image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
    }).catch(() => false)
}

export const isSupportWebp = () => window.isSupportWebp