import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "antd-mobile";
import "./DropdownMenu.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setConfigParams } from "@/pages/Mobile/pages/TemplateList/indexSlice.js";
import { getFilterConfigByType } from "@/pages/Home/config";
import styled from "styled-components";
import { getSearchTagsJson } from "@/http/api";
import FlexStyled from "@/styled/FlexStyled";
/**
 *
 *
 * config {
 *   color: [],
 *   场景: [],
 *   风格: [],
 * }
 */
const  CancelButton = styled.div`
  border-radius: 4px;
  border: 1px solid #00BCA6;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #11BBA6;
  font-size: 14px;
  cursor: pointer;
  flex:1;
  margin:14px 9px 14px 14px;
`;
const OkButton = styled.div`
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  background: #24c6b2;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  flex:1;
  margin:14px 9px 14px 0px;
`;
const config = {
  语言: getFilterConfigByType("语言"),
  用途: getFilterConfigByType("用途"),
  风格: getFilterConfigByType("风格"),
  颜色: getFilterConfigByType("颜色"),
  职位: getFilterConfigByType("职位"),
};
const DropdownMenu = (props) => {
  const { onChange, onOk } = props;
  const dispatch = useDispatch();
  const filter = useSelector((state) => state?.mobileIndex?.filter);
  const [filterState,setFilterState] = useState({...filter});
  const handleChange = (type, value) => {
    setFilterState((v)=>{return {...v,[type]:value}})
    console.log(filterState)
  };
  const [tags, setTags] = useState([]);
  useEffect(() => {
    getSearchTagsJson().then((res) => {
      setTags(res);
    });
  }, []);
  const getNumber = (item, type) => {

    if (type) {
      return tags[type]?.find((v) => v.key === item)?.doc_count;
    }
  };

  const buttonContent = () => {
    return (
      <FlexStyled>
        <CancelButton onClick={() => {dropRef.current.close();setUpdateKey(n=>n+1);setFilterState({style:'全部',use:'全部'})}}>取消</CancelButton>
        <OkButton  onClick={()=>{
          console.log(filterState)
          const {style,use} = filterState;
          dispatch(setConfigParams({type:"style",value:style}))
          dispatch(setConfigParams({type:"use",value:use}))
          onOk();
        }} >确定</OkButton>
      </FlexStyled>
    );
  };

  const [updateKey,setUpdateKey] = useState(0);
  const dropRef = useRef();
  return (
    <Dropdown ref={dropRef} key={updateKey}>
      {/* 不展示先隐藏 */}
      {/* <Dropdown.Item key="color" title={`颜色：${filter.color}`}>
        <div className="dropdown-tag-list1">
          {config["颜色"] &&
            config["颜色"].map((item) => {
              const cls = classNames({
                tag: true,
                active: item === filter.color,
              });
              return (
                <div
                  key={item}
                  className={cls}
                  onClick={() => handleChange("color", item)}
                >
                  {item}{" "}
                  <div
                    className={{
                      active: item === filter.use,
                      "option-number": true,
                    }}
                  >
                    {getNumber(item, "color")}
                  </div>
                </div>
              );
            })}
        </div>
        {buttonContent()}
      </Dropdown.Item> */}
      <Dropdown.Item key="abc" title={`风格：${filterState.style}`}>
        <div className="dropdown-tag-list1">
          {config["风格"] &&
            config["风格"].map((item) => {
              const cls = classNames({
                tag: true,
                active: item === filterState.style,
              });
              return (
                <div
                  key={item}
                  className={cls}
                  onClick={() => handleChange("style", item)}
                >
                  {item}
                  <div
                    className={classNames({
                      active: item === filterState.style,
                      "option-number": true,
                    })}
                  >
                    {getNumber(item, "style")}
                  </div>
                </div>
              );
            })}
        </div>
        {buttonContent()}
      </Dropdown.Item>
      <Dropdown.Item key="bizop" title={`用途：${filterState.use}`}>
        <div className="dropdown-tag-list1">
          {config["用途"] &&
            config["用途"].map((item) => {
              const cls = classNames({
                tag: true,
                active: item === filterState.use,
              });
              return (
                <div
                  key={item}
                  className={cls}
                  onClick={() => handleChange("use", item)}
                >
                  {item}
                  <div
                    className={classNames({
                      active: item === filterState.use,
                      "option-number": true,
                    })}
                  >
                    {getNumber(item, "use")}
                  </div>
                </div>
              );
            })}
        </div>
        {buttonContent()}
      </Dropdown.Item>
      {/* 有问题 先注释 */}
      {/* <Dropdown.Item key='more' title={`职位：${filter.profession}`}>
                    <div className='dropdown-tag-list1'>
                        {config['职位'] && config['职位'].map(item => {
                            const cls = classNames({
                                'tag': true,
                                active: item === filter.profession
                            })
                            return <div key={item} className={cls} onClick={() => handleChange('profession', item)} >{item}</div>
                        })}
                    </div>
                </Dropdown.Item> */}
    </Dropdown>
  );
};

export default DropdownMenu;
