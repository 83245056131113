import styled from "styled-components"

const InfoModalStyled = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);

    .icon-close{
        position: absolute;
        top: 12px;
        right: 12px;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    .container{
        width: 400px;
        height: 184px;
        border-radius: 10px;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff;

        padding: 24px 10px;
        box-sizing: border-box;

        .title{
            color: #2C2C2C;
            font-size: 18px;
            line-height: 1;

            display: flex;
            align-items: center;
        }

        .content{
            margin-top: 12px;
            margin-left: 10px;
            color: #999999;
            font-size: 14px;
        }

        .btn-section{
            display: flex;
            justify-content: flex-end;
            .btn{
                width: 112px;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                border-radius: 6px;
                text-align: center;
                margin-top: 43px;
                cursor: pointer;
                &.cancel{
                    color: #A3A3A3;
                    border: 1px solid #C8C8C8;
                }

                &.confirm{
                    color: #ffffff;
                    border: 1px solid #11BBA6;
                    background: #11BBA6;
                    margin-left: 9px;
                }
            }
        }
    }
`

export const icon1 = <div style={{ height: 16, width: 16}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="面试问题页面流程优化" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="升级会员弹窗" transform="translate(-772.000000, -294.000000)" fill="#D8D8D8" fill-rule="nonzero">
            <g id="编组" transform="translate(400.000000, 282.000000)">
                <g id="新建一项备份-10" transform="translate(372.000000, 12.000000)">
                    <g id="编组-11" transform="translate(2.000000, 2.000000)">
                        <polygon id="路径" points="0.0458891013 10.9363474 10.9110163 0 11.9541109 1.06365259 1.08810119 12"></polygon>
                        <polygon id="路径" points="10.9674952 11.9527067 0 1.12076145 1.03250478 0.0472932778 12 10.8783462"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;

export const icon2 = <div style={{ height: 20, width: 20, margin: '2px 6px 0 10px' }} dangerouslySetInnerHTML={{
    __html: `<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="面试问题页面流程优化" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="升级会员弹窗" transform="translate(-420.000000, -308.000000)" fill="#FA9C17">
            <g id="编组" transform="translate(400.000000, 282.000000)">
                <g id="删除提示" transform="translate(20.000000, 26.000000)">
                    <path d="M10,1 C14.9707,1 19,5.0293 19,10 C19,14.9707 14.9707,19 10,19 C5.0293,19 1,14.9707 1,10 C1,5.0293 5.0293,1 10,1 Z M9.2,5.50000001 L9.2,12.7 C9.2,13.1970563 9.5581722,13.6 10,13.6 C10.4418278,13.6 10.8,13.1970563 10.8,12.7 L10.8,5.50000001 C10.8,5.00294373 10.4418278,4.6 10,4.6 C9.5581722,4.6 9.2,5.00294373 9.2,5.50000001 Z M10,16.3 C10.4970563,16.3 10.9,15.8970563 10.9,15.4 C10.9,14.9029437 10.4970563,14.5 10,14.5 C9.50294372,14.5 9.09999999,14.9029437 9.09999999,15.4 C9.09999999,15.8970563 9.50294372,16.3 10,16.3 L10,16.3 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;



const InfoModal = ({title, onCancel, onConfirm, visible}) => {
    return (
        <>
            {
                visible ? <InfoModalStyled>
                <div className="container">
                    <div onClick={onCancel} className="icon-close">{icon1}</div>
                    <div className="title">{icon2}提示</div>
                    <div className="content">{title}</div>
                    
                    <div className="btn-section">
                        <div className="btn cancel" onClick={onCancel}>取消</div>
                        <div className="btn confirm" onClick={onConfirm}>升级</div>
                    </div>
                </div>
            </InfoModalStyled> : <></>
            }
        </>
    )
}

export default InfoModal