import { useEffect, useRef, useState } from 'react';
import './index.scss'
import { SearchBar } from 'antd-mobile'
import DropdownMenu from './components/DropdownMenu';
import { useSelector,useDispatch } from 'react-redux';
import ResumeList from '../Home/components/ResumeList';
import { useNavigate,useLocation } from 'react-router-dom';
import useQueryParam from '@/hooks/useQueryParam';
import NavigationHeader from '../../components/NaviGationHeader';
import {clearFilter} from '../TemplateList/indexSlice';

const TemplateList = () => {
    const filter = useSelector(state => state?.mobileIndex?.filter)
    const navigate = useNavigate()
    const inputRef = useRef(null);

    const selectConfig = useSelector(state => state.mobileIndex.filter)

    const jzOrigin = useQueryParam('jz_origin')
    const fromHome = useQueryParam('fromHome')
    useEffect(() => {
        inputRef.current.nativeElement.readOnly = true
    }, []);
    const dispatch = useDispatch()

    const handleClickInput = () => {
        if (jzOrigin) {
            navigate(`/mobile/search_word?jz_origin=${jzOrigin}`)
        } else {
                navigate(`/mobile/search_word`,{replace:true})
        }
    }


  const [searchValue, setSearchValue] = useState("");
    const onBack = ()=>{
        console.log(fromHome)
        dispatch(clearFilter({}))

        if(fromHome){
            navigate('/mobile/index',{replace:true})
            return
        }
        if (selectConfig.searchWord) {
            navigate(`/mobile/search_word`,{replace:true})
            return
        }
        navigate('/mobile/index',{replace:true})
    }

    return (
        <div className='container'>
            <NavigationHeader title={document.title} onBack={onBack}></NavigationHeader>
            <div className='search-wrap flex'>
                <div className='search'>
                    <div className="input" onClick={handleClickInput} >
                        <SearchBar className='input' disabled value={filter.searchWord} ref={inputRef} placeholder='在100万的模板中搜索' />
                    </div>
                </div>
            </div>
            <div title='三列' padding={'0'}>
                <DropdownMenu />
            </div>
            <div className='content'>
                <ResumeList filter={filter} />
                {/* <Footer /> */}
            </div>
        </div>
    )
}


export default TemplateList