import moment from "moment";
import { pickerModuleList } from "./config";
import { isArray } from "lodash";

export function formatDate({ year, month, day }) {
    // 注意：moment 的月份是从 0 开始的，所以需要将 month 减去 1
    const date = moment({ year, month: month - 1, day });
    if (date.isValid()) {
        return date.toDate();
    } else {
        return new Date()
    }
}

export function parseDate(dateString) {
    const date = moment(dateString, 'YYYY年MM月DD日');
    return {
      生日年份: date.year(),
      生日月份: date.month() + 1,
      生日日期: date.date()
    };
}

// 处理输入层
// 做两件事
// 1. 将生日年月日转换为标准日期
// 2. 将选择器模块字段value从 'value' => ['value']
export const processBasicInfo = (basicInfoData) => {
    const year = String(basicInfoData['生日年份'])
    const month = String(basicInfoData['生日月份'])
    const day = String(basicInfoData['生日日期'])
    const pickerConfig = pickerModuleList.reduce((pre, cur) => {
        const key = cur.key
        if (key === '工作年限' && !isNaN(Number(basicInfoData[key]))) {
            pre[key] = [basicInfoData[key] + '年经验']
        } else {
            pre[key] = [basicInfoData[key]]
        }
        return pre
    }, {})
    return {
        ...basicInfoData,
        ...pickerConfig,
        '年龄': formatDate({ year, month, day}),
    }
}


// 处理输出层
export const processBasicInfoExport = (values) => {
    const newValues = {
        ...values,
        ...parseDate(values['年龄']),
        height: String(values['身高']),
        weight: String(values['体重']),
    }

    delete newValues['年龄']

    Object.keys(newValues).forEach(key => {
        if (isArray(newValues[key])) {
            newValues[key] = newValues[key][0]
        }
    })
    return newValues
}