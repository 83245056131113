import styled from "styled-components"

const EmptyResumeModalStyled = styled.div`

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 385px;
    height: 134px;
    border-radius: 8px;
    background: #ffffff;

    .title{
        color: #353336;
        text-align: center;
        font-weight: 500;
        margin-top: 30px;
        font-size: 14px;

    }

    .btn{
        width: 124px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background-color: #11BBA6;
        color: #ffffff;
        margin: 25px auto 0;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
    }
`

const EmptyResumeModal = ({
    title,
    btnText,
    onClick
}) => {
    return <EmptyResumeModalStyled>

        <div className="title">{title}</div>
        <div onClick={onClick} className="btn">{btnText}</div>

    </EmptyResumeModalStyled>
}

export default EmptyResumeModal