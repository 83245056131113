import useQueryParam from "@/hooks/useQueryParam"
import { useEffect, useState } from "react"
import { getResumeDataById, saveResumePageUserData } from "@/http/api"
import { useDispatch, useSelector } from "react-redux"
import { setData } from "../pages/ResumeEdit/editSlice"
import { Toast } from "antd-mobile"
import { cloneDeep, set } from "lodash"
import { useNavigate } from "react-router-dom"

const useResumeData = () => {
    const resumeId = useQueryParam('id')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let data = useSelector(state => state.mobileEdit.data)

    const fetchData = async () => {
        
        const data = await getResumeDataById(resumeId).catch((err) => {
            // message.error(err.msg)
        })
        dispatch(setData(data))
    }
    // console.log('data', data)
    const save = async (pageId, resumeData) => {
        try {
            await saveResumePageUserData({ query: { pageId }, body: resumeData });    
            navigate(-1)
            Toast.show({
                icon: 'success',
                content: '保存成功',
            })
        } catch (error) {
            Toast.clear()
        }
    }

    useEffect(() => {
        fetchData()
    }, [resumeId]);

    const onChange = (key, value) => {
        const newData = {
            ...data,
            resumeData: {
                ...data.resumeData,
                [key]: {
                    ...data.resumeData[key],
                    data: value
                }
            }
        }

        dispatch(setData(newData))
        
        // 保存
        const pageId = newData?.resume?.pageId
        if (pageId) {
            save(pageId, newData.resumeData)
        }
    }

    const onChangeByPath = (path, value) => {
        const newData = set(cloneDeep(data), path, value)
        
    }
    
    return {
        data: data?.resumeData || {},
        template: data?.template,
        onChange,
    }
}

export default useResumeData