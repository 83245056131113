import withComponent from "../hoc/withComponent";
import {BrowserRouter, createBrowserRouter,RouterProvider} from "react-router-dom";
import routes from "./routes"
import React, { Suspense } from "react";

const router = createBrowserRouter(routes);


export default withComponent(() => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <RouterProvider router={router} />
        </Suspense>
    )
})
