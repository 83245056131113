import styled from "styled-components";
import Header from "@/components/Modal/Header";
import React, {createElement, useEffect, useRef, useState} from "react";
import {changeResumeTemplate, fetchResumeSearch, getTemplate} from "@/http/api";
import useForceUpdate from "@/hooks/useForceUpdate";
import ScrollWithFetch from "@/components/ScrollWithFetch";
import {message, Modal} from "antd";
import {useSelector} from "@/components/resume/redux/hooks";
import useResumeDetailInstance from "@/pages/ResumeDetail/hooks/useResumeDetailInstance";

const ContainerStyled = styled.div`
  width: 990px;
  height: 600px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
`
const TagPositionStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
`
const TagBoxStyled = styled.div`
  display: flex;
  align-items: center;
`
const TagItemStyled = styled.div`
  width: 60px;
  height: 28px;
  border-radius: 6px;
  ${props => props.$isActive ? 'background: #11BBA6;' : ''}
  ${props => props.$isActive ? 'color:white;' : 'color: #666666;'}
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  opacity: 0.9;
  margin-right: 10px;
`
const TagTextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 14px;
`
const CardPositionStyled = styled.div`
  margin-left: 25px;
  flex-grow: 1;
  height: 0;
`
const CardBoxStyled = styled.div`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;

  & > :not(:nth-child(4n)) {
    margin-right: 10px;
  }

  & > div {
    margin-bottom: 16px;
  }
`
const CardContainerStyled = styled.div`
  width: 224px;
  height: 314px;
  position: relative;
  border: 1px solid #E9E9E9;
  transition: scale 0.3s ease;
  transform-origin: center center;

  .template-apply-button {
    visibility: hidden;
  }

  &:hover .template-apply-button {
    visibility: visible;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: scale(1.044);
  }

  &:hover::before {
    opacity: 0.3;
  }
`
const CardApplyButtonStyled = styled.div`
  width: 146px;
  height: 44px;
  background: #00BCA6;
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  top: 144px;
  left: 42px;

  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 44px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`


const tagConfigList = [
    {
        id: "1", label: "全部"
    }, {
        id: "2", label: "时尚"
    }, {
        id: "3", label: "创意"
    }, {
        id: "4", label: "简洁"
    }, {
        id: "5", label: "经典"
    },
];
const Component = (props) => {
    const resume = useSelector(state => state.resume);

    const instance = useRef({
        loading: false, total: undefined, current: undefined, limit: 20, templateList: []
    }).current;
    const {templateId} = props
    const [, update] = useForceUpdate();
    const resumeDetailInstance = useResumeDetailInstance();
    const [currentTag, setCurrentTag] = useState(tagConfigList[0]?.id);
    const fetchTemplateList = async ({current}) => {
        const tag = tagConfigList.find(item => item.id === currentTag)
        instance.loading = true;
        try {
            const res = await fetchResumeSearch({
                query: {page: current, limit: instance.limit, style: tag?.label || '全部',templateId },
            })
            const {dataList, currPage, count} = res;
            instance.current = currPage;
            instance.total = count;
            instance.templateList = current === 1 ? dataList : [...instance.templateList, ...dataList];
        } catch (e) {

        } finally {
            instance.loading = false;
        }
        update()
    };
    const onClickChange = async (item) => {
        let info = {}
        try {
            info = JSON.parse(item.info)
        } catch (error) {
            
        }
        let closeListen = resumeDetailInstance.resumeRef.current.closeListen;
        let openListen = resumeDetailInstance.resumeRef.current.openListen;
        let removeThemeStyle = resumeDetailInstance.resumeRef.current.removeThemeStyle;
        let removeTemplateStyle = resumeDetailInstance.resumeRef.current.removeTemplateStyle;
        let insetTemplateStyle = resumeDetailInstance.resumeRef.current.insetTemplateStyle;
        let changeTheme = resumeDetailInstance.resumeRef.current.changeTheme;
        let versionLog = resumeDetailInstance.resumeRef.current.versionLog;
        closeListen();
        const hide = message.loading("", 0);
        window.setShow(false);
        try {
            await changeResumeTemplate({query: {resumeId: resume.resumeId, templateId: item.id}});
            removeThemeStyle();
            removeTemplateStyle();
            await window.fetchData();
            await insetTemplateStyle();
            changeTheme(info.defaultThemeIndex)
            if (info.defaultThemeIndex) {
                resumeDetailInstance.resumeRef.current?.changeThemeWithSave?.(info.defaultThemeIndex)
            }
        } catch (e) {
            console.log(e);
        } finally {
            hide();
            openListen();
            props.onCancel();
            await versionLog();
            window.setShow(true);
        }
    }
    const onClickTag = (id) => {
        setCurrentTag(id)
    }
    const scrollFetchHandler = async (current) => {
        return fetchTemplateList({current: current + 1})
    }


    useEffect(() => {
        const hide = message.loading("", 0)
        fetchTemplateList({current: 1}).finally(() => {
            hide()
        })
    }, [currentTag]);

    return <ContainerStyled>
        <Header title="更换模板" onCancel={props.onCancel}/>
        <TagPositionStyled>
            <TagBoxStyled>
                <TagTextStyled style={{marginRight: 20}}>风格：</TagTextStyled>
                {tagConfigList.map((item, index) => {
                    return <TagItemStyled key={item.id} onClick={() => onClickTag(item.id)}
                                          $isActive={item.id === currentTag}>
                        {item.label}
                    </TagItemStyled>
                })}
            </TagBoxStyled>
        </TagPositionStyled>
        <CardPositionStyled>
            <ScrollWithFetch current={instance.current}
                             pageSize={instance.limit}
                             total={instance.total}
                             scrollFetchHandler={scrollFetchHandler}>
                <CardBoxStyled>
                    {instance.templateList.map((item, index) => {
                        return <CardContainerStyled key={item.id} onClick={() => onClickChange(item)}>
                            <img src={item.cover + `?x-oss-process=image/resize,,h_${314},w_${224}`}
                                 style={{width: "100%", height: "100%"}}/>
                            <CardApplyButtonStyled className="template-apply-button">
                                一键更换模板
                            </CardApplyButtonStyled>
                        </CardContainerStyled>
                    })}
                </CardBoxStyled>
            </ScrollWithFetch>
        </CardPositionStyled>
    </ContainerStyled>
}

export default (props) => {
    return <Modal width={'auto'}
                  open={props.visible}
                  footer={false}
                  transitionName=''
                  maskTransitionName=''
                  centered
                  getContainer={props.getContainer}
                  destroyOnClose={props.destroyOnClose}
                  mask={props.mask}
                  zIndex={props.zIndex}
                  maskClosable={props.onCancel}
                  modalRender={() => createElement(Component, props)}
    />;
}

