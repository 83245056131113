import {Button, Modal, Upload, message} from "antd";
import React, {createElement, useMemo, useRef, useState, useEffect} from "react";
import styled from "styled-components";
import Header from "@/components/Modal/Header";
import { pdfSvg, wordSvg } from "@/svg";
import JzImage from "./JzImage";
import classNames from "classnames";
import {v4} from "uuid";
import { ProgressBar } from "antd-mobile";
import { uploadResumeByWord } from "@/http/mobileApi";
import { useNavigate } from "react-router-dom";

const closeIcon = <div style={{height: 16, width: 16, position: 'relative'}} dangerouslySetInnerHTML={{
    __html: `<svg width='100%' height='100%' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='position: absolute;top:0:left:0'>
    <g id='添加新页' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='添加新页--输入内容状态' transform='translate(-1870.000000, -98.000000)' fill='#999999' fill-rule='nonzero'>
            <g id='叉-关闭' transform='translate(1870.000000, 98.000000)'>
                <g id='编组-11' transform='translate(2.000000, 2.000000)'>
                    <polygon id='路径' points='0.0458891013 10.9363474 10.9110163 0 11.9541109 1.06365259 1.08810119 12'></polygon>
                    <polygon id='路径' points='10.9674952 11.9527067 0 1.12076145 1.03250478 0.0472932778 12 10.8783462'></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;
const preSvg = <div className="flex-center" dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>返回</title>
    <g id="模板站-详情页优化" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="详情页-输入内容生成大纲（可返回）" transform="translate(-950.000000, -110.000000)" fill="#8B8B8B" fill-rule="nonzero">
            <g id="秒出助手备份-2" transform="translate(959.500000, 119.500000) scale(-1, 1) translate(-959.500000, -119.500000) translate(950.000000, 110.000000)">
                <polygon id="路径" points="10.8573884 12.4842626 11.8525697 13.4794439 15.8333333 9.49868029 11.8525697 5.51791667 10.8573499 6.5131172 13.1392617 8.79497124 3.16666667 8.79497124 3.16666667 10.2023893 13.1392617 10.2023893"></polygon>
            </g>
        </g>
    </g>
</svg>
`}} />

const ContainerStyled = styled.div`
    width: 600px;
    height: 348px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    pointer-events: auto;
    .vd-h2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #4C4C4C;
    }
    
    .vd-item {
        padding: 5px 9px;
        border-radius: 4px;
        padding-right: 43px;
        font-weight: 500;
        font-size: 12px;
        color: #3C3C3C;
    }
    .vd-item:hover {
        background: #F4F4F4;
        color: #11BBA6;
        cursor: pointer;
    }
    .vd-item-active {
        background: #F4F4F4;
        color: #11BBA6;
    }
    .im-svg {
        svg {
            width: 42px;
            height: 42px;
        }
    }
`
const AreaContainerPositionStyled = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`
const ImportTipStyled = styled.div`
    margin-right: 10px;
    right: 0;
    bottom: 10px;
    transform:translate(0,-30px);
    align-items: flex-end;
    justify-content: flex-end;
    .video-tip {
        font-size: 12px;
        color: #B4B4B4;
        text-decoration-line: underline;
        transition: all 200ms;
    }
    .video-tip:hover {
        color: #11BBA6;
    }
`

const AreaContainerStyled = styled.div`
  /* width: 552px; */
  height: 190px;
  /* border-radius: 8px;
  border: 2px dashed #EBEBEB;
  background-color: #FAFAFA; */
  position: relative;
`
const AreaContainerTitleStyled = styled.div`
  /* position: absolute;
  top: 80px;
  left: 0;
  right: 0; */
  pointer-events: none;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5F5F5F;
  line-height: 20px;
`
const AreaContainerDesStyled = styled.div`
  /* position: absolute;
  top: 108px;
  left: 0;
  right: 0; */
  font-size: 12px;
  text-align: center;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #B4B4B4;
  line-height: 12px;
`
const ButtonPositionStyled = styled.div`
  margin-top: 22px;
  text-align: center;
`

const UploadLoading = ({ filename = '', loading = false, style = {} }) => {
    const [percent, setPercent] = useState(0)
    const intervalRef = useRef(null);
    const svg = useMemo(() => {
        if (filename) {
            return filename.includes('.doc') ? wordSvg : pdfSvg
        } else {
            return wordSvg
        }
    }, [filename])

    useEffect(() => {
        if (intervalRef.current) {
            setPercent(100)
            clearInterval(intervalRef.current);
        }
        if (!loading) return 

        setPercent(0);
        intervalRef.current = setInterval(() => {
            setPercent((v) => {
                return v + 0.02 * (100 - v);
            });
        }, 100);
    }, [loading])
    return <div className="flex flex-center flex-col" style={{
        fontWeight: 400,
        height: 190,
        fontSize: 12,
        color: '#2C2C2C',
        width: '100%',
        padding: '0 40px',
        boxSizing: 'border-box',
        ...style
    }}>
        <div className="im-svg mb-18">{svg}</div>
        <div className="mb-10 flex justify-between">
            <div>{filename || '简历文件'}</div>
            <div style={{ color: '#B3B3B3' }} className="ml-18">{Math.floor(percent)}%</div>
        </div>
        <ProgressBar style={{ 
            width: '100%',
            '--fill-color': '#11BBA6'
        }} percent={percent} />
    </div>
}


const list = [
    {
        id: 1,
        imgUrl: "iojsiofjoi3.png",
        url: 'https://oss.jianzeppt.com/jz-ppt-img/hdckxhcbvjcx23.mp4',
        text: 'BOSS直聘',
    },
    // {
    //     id: 2,
    //     imgUrl: "ncvxknvk1.png",
    //     url: '',
    //     text: '拉勾招聘',
    // },
    // {
    //     id: 3,
    //     imgUrl: "fsnjkfn1399.png",
    //     url: '',
    //     text: '智联招聘',
    // },
    // {
    //     id: 4,
    //     imgUrl: "kjdshfk123.png",
    //     url: '',
    //     text: '超级简历',
    // },
]

export const ImportContentVideo = ({ onPre, onCancel, isHome }) => {
    const [selected, setSelected] = useState(1)
    const videoUrl = useMemo(() => {
        return list.find(item => item.id === selected)?.url
    }, [selected])


    return <div>
        <div className="flex-center justify-between p-16" style={{ paddingBottom: 0 }}>
            {
                <div className="flex-center" style={{ cursor: 'pointer' }} onClick={onPre}>
                    {preSvg}
                    <div style={{ fontSize: 12, color: '#8B8B8B'}} className="ml-3">返回</div>
                </div>
            }
            <div onClick={onCancel} style={{ cursor: 'pointer'}}>
                {closeIcon}
            </div>
        </div>
        <div className="flex px-24">
            <div className="vd-list mt-32">
                {list.map(item => {
                    const cls = classNames('vd-item flex-center justify-start mb-8', {
                        'vd-item-active': item.id === selected
                    })
                    return <div key={item.id} className={cls} onClick={() => setSelected(item.id)}>
                        <JzImage className="pr-12" src={item.imgUrl} style={{ width: 20, height: 20 }} />
                        <span>{item.text}</span>
                    </div>
                })}
            </div>
            <div className="flex-1 ml-14">
                <div className="vd-h2 mb-16">查看操作视频</div>
                <video className="mb-20" controls style={{
                    width: '100%',
                    height: 224,
                }} loop muted autoplay="autoplay">
                    <source src={videoUrl} />
                </video>
            </div>
        </div>
    </div>
}

export const ImportContent = ({ onClickVideo, style, contentStyle }) => {
    const [loading, setLoading] = useState(false)
    const [filename, setFilename] = useState('')
    const navigate = useNavigate()

    const handleUpload = async (file) => {
        const isLt50M = file.size / 1024 / 1024 < 50;
        // 定义允许的MIME类型
        const allowedTypes = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf',
        ];

        // 校验文件大小和MIME类型
        if (!allowedTypes.includes(file.type)) {
            message.error('文件格式不支持，请检查格式并重新上传');
            return false;
        }
        if (!isLt50M) {
            message.error('简历文件最大为50M！');
            return false;
        }
        const formData = new FormData();
        formData.append('wordFile', file);
        try {
            // showToast('上传中...')
            setLoading(true)
            setFilename(file.name)
            console.log('file', file)
            const result = await uploadResumeByWord({
                body: formData
            })

            if (result) {
                navigate(`/resume?id=${result}`)
            }
        } catch (error) {
            console.log('erroer', error)
            // showToast(error?.msg || '上传失败', 'fail', 2000)
        } finally {
            // clearToast()
            setLoading(false)
            e.target.value = null;
        }
        return false;
    }

    return <div>
        <AreaContainerPositionStyled className="mb-30" style={style}>
            <Upload.Dragger 
                disabled={loading}
                // accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                beforeUpload={handleUpload}
                showUploadList={false}
                multiple={false}
            >
                {
                    loading ? <UploadLoading style={contentStyle} loading={loading} filename={filename}  /> : 
                    <AreaContainerStyled className="flex-center flex-col" style={contentStyle}>
                        <div className="flex-center mb-6">
                            {wordSvg}
                            <div className="ml-8">{pdfSvg}</div>
                        </div>
                        <AreaContainerTitleStyled className="mb-8">将简历文件拖拽至此处</AreaContainerTitleStyled>
                        <AreaContainerDesStyled>支持格式：pdf、doc、docx，文件大小＜50M</AreaContainerDesStyled>
                    </AreaContainerStyled>
                }
             
            </Upload.Dragger>
            <ImportTipStyled 
                            onClick={onClickVideo} 
                            className="flex-center" 
                            style={{zIndex: 2, cursor: 'pointer', alignSelf: 'flex-end',display:loading? 'none' : 'flex'}}>
                    <div className="video-tip mr-10">如何从其他平台导入？</div>
                        <div className="flex-center">
                            {list.map(item => {
                                return <JzImage key={item.id} src={item.imgUrl} style={{ width: 20, height: 20 }} />
                            })}
                    </div>
        </ImportTipStyled>
        </AreaContainerPositionStyled>
        
    </div>
}


const Component = (props) => {
    const [step, setStep] = useState(1)

    return <ContainerStyled>
        {
            step === 1 && <>
                <Header title="导入简历" onCancel={props.onCancel} bottomBorderVisible={false}/>
                <ImportContent onClickVideo={() => setStep(2)} />
            </>
        }
        {
            step === 2 && <ImportContentVideo onPre={() => setStep(1)} onCancel={props.onCancel} />
        }
    </ContainerStyled>
}

export default (props) => {
    return <Modal width={'auto'}
                  open={props.visible}
                  footer={false}
                  transitionName=''
                  maskTransitionName=''
                  centered
                  getContainer={props.getContainer}
                  destroyOnClose={props.destroyOnClose}
                  mask={props.mask}
                  zIndex={props.zIndex}
                  maskClosable={props.onCancel}
                  modalRender={() => createElement(Component, props)}
    />;
}
