import config, {autoOnePage1, autoOnePage2, autoOnePage3, autoOnePage4, autoOnePage5} from "@/const/config";


export const getModuleContentStyle = (level) => {
    switch (level) {
        case autoOnePage1:
            return {
                marginTop: 2 + "px",
                marginBottom: 2 + "px",
                // paddingTop:2+ "px",
                // paddingBottom:2+ "px"
            }
        case autoOnePage2:
            return {
                marginTop: 10 + "px",
                marginBottom: 10 + "px",
                // paddingTop:2+ "px",
                // paddingBottom:2+ "px"
            }
        case autoOnePage3:
            return {
                marginTop: 20 + "px",
                marginBottom: 20 + "px",
                // paddingTop:2+ "px",
                // paddingBottom:2+ "px"
            }
        case autoOnePage4:
            return {
                marginTop: 35 + "px",
                marginBottom: 35 + "px",
                // paddingTop:2+ "px",
                // paddingBottom:2+ "px"
            }
        case autoOnePage5: {
            return {
                marginTop: 50 + "px",
                marginBottom: 50 + "px",
                // paddingTop:2+ "px",
                // paddingBottom:2+ "px"
            }
        }
    }

}
export const getDetailStyle = (level) => {
    switch (level) {
        case autoOnePage1:
            return {
                lineHeight: 1.2
            }
        case autoOnePage2:
            return {
                lineHeight: 1.4
            }
        case autoOnePage3:
            return {
                lineHeight: 1.6
            }
        case autoOnePage4:
            return {
                lineHeight: 1.8
            }
        case autoOnePage5: {
            return {
                lineHeight: 2.2

            }
        }
    }

}


export default (level, block_name, field_name, slot, key) => {
    if (block_name && !field_name && !slot) {
        switch (level) {
            case autoOnePage1:
                return {
                    paddingTop: 2 + "px",
                    paddingBottom: 2 + "px"
                }
            case autoOnePage2:
                return {
                    paddingTop: 10 + "px",
                    paddingBottom: 10 + "px"
                }
            case autoOnePage3:
                return {
                    paddingTop: 20 + "px",
                    paddingBottom: 20 + "px"
                }
            case autoOnePage4:
                return {
                    paddingTop: 30 + "px",
                    paddingBottom: 30 + "px"
                }
            case autoOnePage5: {
                return {
                    paddingTop: 40 + "px",
                    paddingBottom: 40 + "px"
                }
            }
        }
    }
    if (block_name && field_name) {
        switch (level) {
            case autoOnePage1:
                return {lineHeight: 30 + "px"}
            case autoOnePage2:
                return {lineHeight: 40 + "px"}
            case autoOnePage3:
                return {lineHeight: 50 + "px"}
            case autoOnePage4:
                return {lineHeight: 60 + "px"}
            case autoOnePage5: {
                return {lineHeight: 70 + "px"}
            }
        }
    }
}
