import styled from "styled-components"
import { compressAndResizeImage } from "@/utils/image";


const RatioImgStyled = styled.div`
    position: relative;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    width: 100%;
    padding-bottom: ${props => props.$ratioHeightPercentage}%;
    overflow: hidden;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const RatioImg = ({ src, ratio = [1, 1], ...other}) => {
    const ratioHeightPercentage = (ratio[1] / ratio[0]) * 100;

    return <RatioImgStyled $ratioHeightPercentage={ratioHeightPercentage} {...other}>
        <img src={compressAndResizeImage(src)} alt="img" />
    </RatioImgStyled>
}

export default RatioImg