// import Header from '@/components/TemplateStation/Header';
import React from "react";
import {
  ArticleContainerStyled,
  ArticleTitleStyled,
  ArticleParagraphStyled,
  ArticleTitle2Styled,
} from "@/pages/Agreement/styled";
// import FlexStyled from '@/styled/FlexStyled';
import config from "@/const";
import Footer from "@/components/Footer";
import styled from "styled-components";
import Header from "@/components/Header";

const FlexStyled = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  align-self: ${(props) => props.alignSelf};
  flex: ${(props) => props.flex};
  flex-wrap: ${(props) => props.flexWrap};
  flex-wrap: ${(props) => props.flexWrap};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
`;

export default () => {
  return (
    <>
      <FlexStyled
        flexDirection="column"
        style={{ minHeight: "100vh", background: "#eceff1" }}
      >
        <Header />
        <FlexStyled
          flexGrow={1}
          justifyContent="center"
          style={{ paddingTop: 40, paddingBottom: 40 }}
        >
          <ArticleContainerStyled>
            <ArticleTitleStyled>了了简历会员协议 </ArticleTitleStyled>
            <ArticleTitle2Styled>会员协议</ArticleTitle2Styled>

            <ArticleTitle2Styled>一、服务条款的接受</ArticleTitle2Styled>
            <ArticleParagraphStyled>
              (一)本协议是用户与杭州天资差异科技有限公司
              （下称“了了简历”）之间关于用户使用了了简历提供的了了简历产品平台(以下简称了了简历平台)中付费高级服务(会员专属功能及模板/素材)所订立的协议。“用户”或“您”是指享受了了简历提供的付费高级服务的个人或单一实体。本服务协议构成您(无论个人或者单位)使用了了简历所提供的付费高级服务之先决条件，除非您接受本协议条款，否则您无权使用本协议的相关服务。您的使用行为将视为同意接受本协议各项条款的约束。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (二) 接受本协议意味着用户接受了了简历“用户协议”及“隐私协议”
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (三)请您仔细审阅并决定接受或不接受本协议，未成年人应在法定监护人陪同下审阅和履行，未成年人行使和履行本协议项下的权利和义务视为已获得了法定监护人的认可，您在享受了了简历付费高级服务时必须完全、严格遵守本服务协议条款。
            </ArticleParagraphStyled>
            <ArticleTitle2Styled>二、服务说明</ArticleTitle2Styled>
            <ArticleParagraphStyled>
              (一)了了简历付费高级服务涉及的产品及服务的所有权以及相关知识产权归了了简历所有或了了简历已获得相关权利人的合法授权。了了简历所提供的服务将按照其服务条款和操作规则严格执行。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (二)了了简历享有对用户使用本服务过程中的一切活动的监督、提示、检查的权利，如用户的行为违反有关法律法规或违反本协议条款的约定，了了简历有权要求其纠正并追究其责任。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (三)了了简历向用户提供的全部付费高级服务，均仅限于用户在了了简历提供的平台使用，任何以恶意破解等手段将了了简历提供的服务内容与了了简历提供的平台分离的行为，如将了了简历会员素材中的模板等与了了简历提供的平台分离，均不属于本协议中约定的了了简历提供的服务。由此引起的一切法律后果由行为人负责，了了简历将依法追究行为人的法律责任。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (四)当用户使用了了简历各单项服务时。用户的使用行为视为其对各单项服务的服务条款以及了了简历在该单项服务中发出各类公告的同意。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (五)您明确了解并同意，了了简历用户的付费方式为第三方代收费的付款方式，您通过此种付费方式付费可能存在一定的商业风险，包括但不限于不法分子利用您账号或银行卡等有价卡等进行违法活动，该等风险均会给您造成相应的经济损失。您应自行承担向侵权方追究侵权责任和追究责任不能的后果。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (六)您应自行负责妥善且正确地保管、使用、维护您在了了简历申请取得的账号、账号信息及账号密码。您应对账号信息和账号密码采取必要和有效的保密措施、非了了简历原因致使您账号密码泄漏以及因您保管、使用、维护不当造成损失的，了了简历无须承担与此有关的任何责任。同时，为了保护您的安全和隐私，请勿与他人共享账号。请勿将您的账号和密码告诉他人，否则了了简历有权对异常使用的账号进行冻结。如遇此情况。请拔打客服电话解决以保障自身隐私安全。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (七)了了简历不对您因第三方的作为或不作为造成的损失承担任何责任，包括但不限于支付服务和网络接入服务、任意第三方的侵权行为。
            </ArticleParagraphStyled>
            <ArticleTitle2Styled>
              三、付费高级服务 (会员专属功能及模板/素材)的购买
            </ArticleTitle2Styled>
            <ArticleParagraphStyled>
              (一)在购买了了简历付费高级服务之前，您必须先根据真实、准确信息注册成为了了简历用户。用户所填写的内容与个人资料必须真实有效，否则了了简历有权拒绝申请或撤销服务，并不予任何赔偿或退还服务费。用户的个人资料发生变化，应及时修改注册的个人资料。否则由此造成的用户权利不能全面有效行使的责任由用户自己承担，了了简历有权因此取消服务，并不予退还相关服务费或其他任何形式的任何赔偿。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (二)用户同意了了简历仅可根据本协议服务目的使用用户个人信息。服务目的包括但不限于交易确认、交易支付、客户回访、政策更新、用户通知、发送促销信息、商业信息以及其他与交易和服务相关的辅助服务等。如用户认为了了简历超范围使用了其个人信息，请立即与客服中心联系，了了简历将核实处理。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (三)用户可通过各种已有和未来新增的支付渠道购买了了简历付费高级服务。在用户使用具体某种支付方式购买了了简历付费高级服务时，须阅读并确认接受相关的服务条款和使用方法。了了简历在此提醒用户注意：请用户从了了简历或了了简历授权渠道销售商处购买了了简历付费高级服务，任何未经了了简历授权而销售了了简历付费高级服务的行为均属于非法行为，了了简历有权追究其法律责任。
              </ArticleParagraphStyled><ArticleParagraphStyled>(四)购买了了简历付费高级服务后，用户有权利不接受了了简历的服务，但不获得会员服务费的退还。
              </ArticleParagraphStyled><ArticleParagraphStyled>(五)了了简历仅提供付费高级服务，与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均由用户自行负担。
              </ArticleParagraphStyled><ArticleParagraphStyled>(六)
              一旦您购买了了简历付费高级服务，即视为您认可该项服务标明之价格；购买后该项服务即时生效。
            </ArticleParagraphStyled>
            <ArticleTitle2Styled>四、付费高级服务用户限制</ArticleTitle2Styled>
            <ArticleParagraphStyled>(一) 限制</ArticleParagraphStyled>
            <ArticleParagraphStyled>
              1.付费高级服务仅限于申请账号者自行使用；止止赠与、出借、出租、转让或售卖，禁止使用机器人或者其他手段，批量非正常下载了了简历平台资源库中的素材相应内容。否则了了简历有权单方面取消该账号的服务资格，由此带来的损失由用户自行承担。了了简历禁止用户私下有偿或无偿转让账号，以免因账号产生纠纷，用户应当自行承担因违反此要求而导致的任何损失，同时了了简历保留追究上述行为人法律责任的权利。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              2、若用户的行为违反本协议或违反国家相关法律法规。或了了简历认为用户行为有损了了简历或他人的声誉及利益，了了简历有权取消该用户的资格而无须给与任何补偿，并有权追究用户该等行为给了了简历造成的任何损失。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              3.用户不得以盗窃、利用系统漏洞等非法途径获取或购买付费高级服务，也不得在未获了了简历授权的销售平台购买付费高级服务，否则了了简历有权取消用户的服务资格。由此引发的问题由用户自行承担，了了简历不负任何责任，同时有权追究用户该等行为给了了简历造成的任何损失。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              5.被取消服务资格的用户，不能再参加由了了简历组织的活动并不可再享有由了了简历提供的各项优惠及增值服务，即不再享有用户权利。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              6.对了了简历平台资源库中的会员专属模板/素材的授权使用请遵守“版权声明”。7.用户有权根据本协议购买情况获得相应的付费高级服务，但应在本协议约定范围内进行合理使用，用户在使用付费高级服务时应当依照本协议第四条第一款规定《内容详见了了简历网站》行使权利，并受该条款中的授权使用"限制"及"重要提示"约束，不得超出本协议所约定和所允许的使用范用及使用方式。禁止用户以下行为：
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              1)通过非法手段对付费用户账号的服务期限、消费金额、交易状态进行修改，或以非法方式或为了非法目的使用已购买的付费高级服务(会员专属功能及模板/素材)。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              2)将您的了了简历账号有偿或无偿提供给任何第三人，并允许他人通过您的账号使用付费高级服务(会员专属功能及模板/素材)。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              3)将付费高级服务(会员专属功能及模板/素材)复制、出售、出租、授权给任何第三方，或将付费高级服务(会员专属功能及模板/素材)组合、编辑成简历进行出售、出租。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              4)主动对了了简历用于保护付费高级服务(会员专属功能及模板/素材)的任何安全措施技术进行破解、更改、反向编译、破坏或其他算改，或协助他人进行上述行为。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              5)通过对了了简历产品目标程序进行反编译或任何反向操作以获取程序源代码或从中截取部分源码；或将反向后获取的源码或部分源码自行使用或许可任何第三方使用。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              6)通过非了了简历认可的方式获得付费高级服务(会员专属功能及模板/素材)，或者删除付费高级服务(会员专属功能及模板/素材)上的任何所有权声明或标签。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              7)损害第三方利益，通过了了简历收集第三方资料信息，破坏或盗取第三方账号，发送诈骗邮件和垃圾邮件等非法信息，侵犯第三方合法权益，包括但不限于隐私权、知识产权、财产权等。
            </ArticleParagraphStyled>
            <ArticleTitle2Styled>五、协议的变更及免责</ArticleTitle2Styled>
            <ArticleParagraphStyled>
              (一)了了简历可能根据实际运营情况对本协议内容进行变更，如对付费高级服务费用收费模式、收费标准、方式等相关内容进行修改和变更，了了简历也可能会对本服务目前提供的免费部分开始收费。前述协议变更前，了了简历将在相应服务页面进行通知或公告，该等内容一经公布即视为通知会员。如您继续使用了了简历提供的服务，则视为您已经接受本协议的全部修改。如您不同意条款修改，可主动向了了简历提出终止付费高级服务，在您尚未使用已购买的付费高级服务内容的前提下，了了简历将退还相应费用。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (二)付费高级服务提供的使用期限中包含了了简历解决故障、服务器维修、调整、升级等所需用的合理时间，对上述情况所需用的时间了了简历不予补偿。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (三)用户在使用了了简历所提供的服务时，如因自身原因或第三方原因遭受任何人身或财务的损失、损害或伤害，了了简历均不负责任。由于用户将个人密码告知他人或与他人共享注册账号，由此导致的任何个人资料泄露，了了简历不负任何责任。
            </ArticleParagraphStyled>
            <ArticleTitle2Styled>六、服务的中断和终止</ArticleTitle2Styled>
            <ArticleParagraphStyled>
              (一)因发生了了简历不可抗拒的事由，如政府行为、不可抗力、网络服务提供商等问题，导致付费高级服务无法继续，了了简历将尽快通知用户，但不承担由此对用户造成的任何损失并不退还付费高级服务费。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (二)如用户违反或被视为违反本服务条款中的内容，了了简历有权在不通知用户的情况下立即终止您已购买的所有服务，并取消您的了了简历账号和使用权限，但不退还任何已缴纳的付费高级服务费用。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (三)在某些罕见情况下，由于权利授权问题可能致使某些服务无法使用。了了简历将根据不同情况用服务内容替换方式给您补偿。如有需要，请联系客服。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (四)了了简历末行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单一或部分行使其在本协议项下的任何权利并不排斥任何其他权利的行使。了了简历随时有权要求您继续履行义务并承担相应的法律责任。
            </ArticleParagraphStyled>
            <ArticleTitle2Styled>七、法律的适用和管辖</ArticleTitle2Styled>
            <ArticleParagraphStyled>
              (一)本服务条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，本服务条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。
            </ArticleParagraphStyled>
            <ArticleParagraphStyled>
              (二)如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，任何一方均可向法院提起诉讼。
            </ArticleParagraphStyled>
          </ArticleContainerStyled>
        </FlexStyled>
        <Footer />
      </FlexStyled>
    </>
  );
};
