import JzImage from "@/components/JzImage"
import config from "@/const"
import withComponent from "@/hoc/withComponent"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const FooterStyled = styled.div`
    background: #222222;
    color: #777777;
    .logo-img {
        width: 141px;
        height: 36px;
        margin-top: 16px;
        margin-bottom: 40px;
    }
`

const Footer = () => {
    const navigate = useNavigate()

    const handleClickAgreement = () => {
        navigate('/mobile/agreement')
    }

    return <FooterStyled>
        <div className="flex-center">
            <JzImage className="logo-img" src="sldknfijxcbnvjhbu.png" />
        </div>

        <div onClick={handleClickAgreement} className="flex-center mb-22" style={{ color: '#BBB'}}>
            用户协议
        </div>
        <div className="flex-center" style={{ paddingBottom: 30 }}>
            <div style={{ textAlign: 'center'}}>
                <div style={{ marginBottom: 8}}>Copyright © 2022 {'了了简历' || config.productName}</div>
                <div style={{ marginBottom: 8}}>All Rights Reserved·{config.companyName}</div>
                <div>
                    <a style={{ color: '#777'}} rel="nofollow"
                        target='_blank'
                        href='http://beian.miit.gov.cn/'>{config.record}</a>
                </div>
            </div>
        </div>

    </FooterStyled>
}

export default withComponent(Footer)