import {Modal} from 'antd';
import React, {useCallback, useRef, useState} from 'react';
import withComponent from '@/hoc/withComponent';
import useForceUpdate from '@/hooks/useForceUpdate';
import {useSelector} from 'react-redux';
import isLogin from '@/common/isLogin';
import LoginModal from '@/components/user/LoginModal';
import OriginPrice from '@/components/PayModal/OriginPrice';
import {vipTypeEnum} from '@/const/pay';

export const expiresTime = 30 * 60 * 1000;

export default withComponent((props) => {
    const {getContainer = null, visible = false, zIndex = 999, canSinglePage, onSuccess: onSuccess$1} = props;
    const that = useRef({ telephoneBindModalCancelCallback: undefined}).current;
    const user = useSelector((state) => state.user);
    const [, update] = useForceUpdate();

    const login = isLogin(user);

    const onCancel = () => {
        props.onCancel?.();
    };
    const onClickUserCancel = useCallback(() => {
        onCancel();
    }, [canSinglePage]);

    const onSuccess = useCallback((...param) => {
        onSuccess$1?.(...param);
        clearTimeout(that.checkPayTimer);
    }, []);

    if (!login && visible) {
        return <LoginModal zIndex={zIndex} getContainer={null} visible onSucessd={update}
                           onSuccess={update}
                           onCancel={props.onCancel}/>;
    }
    return <>

        <Modal open={visible}
               destroyOnClose
               centered
               width={'auto'}
               getContainer={getContainer}
               zIndex={zIndex}
               transitionName=''
               mask={true}
               maskTransitionName=''
               modalRender={() => <OriginPrice {...props}
                                               onSuccess={onSuccess}
                                               onClickUserCancel={onClickUserCancel}
                                               onCancel={onCancel}
                                               visible={visible}
               />}
        />
    </>;
});
