import { Alert, Button, Card, Col, Input, Modal, Radio, Row, Tag, message } from "antd"
import { useEffect, useState } from "react"
import styled from "styled-components"
import config from "../Home/config"
import { deleteResumeOrigin, updateResumeOrigin } from "@/http/api"
import ResumePreview from "./ResumePreview"



const WrapStyled = styled.div`
    margin-top: 30px;
    .form-item {
        margin-bottom: 20px;
    }
`

const AdminModal = ({ visible, onSuccess, onCancel, resumeData }) => {
    const [likes, setLikes] = useState('')
    const [style, setStyle] = useState('')

    useEffect(() => {
        if (resumeData) {
            const { likes, style } = resumeData
            setLikes(likes)
            setStyle(style)
        }
    }, [resumeData])

    const handleSumbit = () => {
        updateResumeOrigin({
            query: {
                resumeId: resumeData.id,
                likes,
                style
            }
        }).then(res => {
            message.success('修改成功')
            onSuccess && onSuccess()
        }).catch(err => {
            message.error('修改失败')
        })
    }

    const handleDelete = async () => {
        const res = await deleteResumeOrigin({
            checkCode: 'dhajkhdjkahdnjkan_dklajkldam2d13af24jkhkj6713gdcn?>,;,//; ',
            id: resumeData?.id
        })
        if(res){
            message.success('修改成功')
            onSuccess && onSuccess()
        }else{
            message.error('修改失败')
        }
    }

    const handleChangeStyle = (e) => {
        const value = e.target.value
        setStyle(value)
    }
    
    return  <Modal
        title="配置"
        centered
        open={visible}
        onOk={onSuccess}
        onCancel={onCancel}
        footer={[
            <Button onClick={onCancel}>取消</Button>,
            <Button onClick={handleSumbit} type="primary">修改</Button>,
            <Button danger onClick={handleDelete}>删除</Button>
        ]}
        width={1000}
    >
        <WrapStyled>
            <Row gutter={20}>
                <Col span={12}>
                    <Card title="模板配置">
                        <div className="form-item">模板ID：{resumeData?.id}</div>
                        <div className="form-item">点赞：<Input value={likes} onChange={(e) => setLikes(e.target.value)} style={{ width: 300 }} placeholder="点赞数" /></div>
                        <div className="form-item">风格：
                            <Radio.Group value={style} onChange={handleChangeStyle} buttonStyle="solid" style={{ marginTop: 16 }}>
                                {
                                    config[0].children.map(item => {
                                        return <Radio.Button value={item}>
                                            {item}
                                        </Radio.Button>
                                    })
                                }
                            </Radio.Group>
                        </div>
                    </Card>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center'}}>
                    <ResumePreview item={{
                        ...resumeData,
                        likes,
                        style
                    }} width={300} text="查看模板"/>
                </Col>
            </Row>

            
        </WrapStyled>
    </Modal>
}

export default AdminModal
