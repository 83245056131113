import {createElement, useMemo} from "react";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import withComponent from "@/hoc/withComponent";
import withField from "@/components/resume/hoc/withField";
import {getModuleContentStyle} from "@/common/getStyleByAutoOnePage";
import {parseMargin} from "@/utils/margin";
import {useSelector} from "@/components/resume/redux/hooks";
import useSelectedField from "../hooks/useSelectedField";


export default withComponent(withField((props) => {
    const {tagName, style, className, children = [], fieldRef} = props;
    const configRender = useConfigRender();
    const onePageLevel = useSelector((state) => state.pagination.onePageLevel);
    const styles = {...style}
    if (props['field_name'] === "details") {
        const autoOnePageStyle = getModuleContentStyle(onePageLevel);
        const margin = parseMargin(style?.margin);
        Object.assign(styles, {margin: undefined}, margin, autoOnePageStyle)
    }
    delete  styles.margin

    return createElement(tagName, {
        style: styles,
        className: className,
        tabIndex: 0,
        ref: fieldRef,
        'data-type': props['data-type'],
        'data-field_name': props['field_name'],
    }, children.map((config) => configRender(config)))
}))
