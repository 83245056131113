import React, {useEffect, useRef} from "react";
import md5 from "md5";
import useForceUpdate from "../../hooks/useForceUpdate";
import store from "store";
import {gzhQRCode, gzhState} from "../../http/api";
import FlexStyled from "../../styled/FlexStyled";
import {Button, Space, message} from "antd";
import LoadingContainer from "../LoadingContainer";
import loginHandler from "../../common/user/loginHandler";

export default (props) => {
    const {height = 200, width = 200} = props;
    const that = useRef({
        ticket: undefined,
        loading: false,
        checkSeconds: Date.now(),
        uuid: md5(String(Date.now())),
        checkStatusTimer: 0,
    }).current;
    const [, update] = useForceUpdate();
    const getId = () => {
        let id = that.uuid;
        if (store.get('user') && !store.get('user').isWXLogin) {
            id = id + `-user-id-${store.get('user').id}=`;
        }
        return id;
    };
    const isTimeout = () => that.checkSeconds <= 0;
    const fetchQR = () => {
        let uuid = getId();
        return gzhQRCode({
            state: uuid,
        })
            .then((resp) => {
                that.ticket = resp.ticket;
                that.checkSeconds = (+resp.expireSeconds || 7200) / 2;
                that.isExpire = false;
                that.error = false;
            })
            .catch(() => {
                that.error = true;
                message.error('网络异常，请刷新重试')
            })
            .finally(() => {
                that.loading = false;
                update();
            });
    };
    const checkStatus = async () => {
        if (isTimeout()) {
            that.isExpire = true;
            update();
            return;
        }
        that.checkSeconds = that.checkSeconds - 1;
        let uuid = getId();
        return gzhState({state: uuid}).then((user) => {
            if (user && user.id) {
                clearInterval(that.checkStatusTimer);
                loginHandler(user)
                if (props.reload) {
                    window.location.reload();
                }
                window.loginSuccessCallback && window.loginSuccessCallback()
                props.onSuccess(user);
            }
        }).catch(err => {
            if (window.tempCount) return 
            message.error('网络异常')
            window.tempCount = 1
        });
    };
    const init = async () => {
        that.loading = true;
        update();
        that.checkStatusTimer = setInterval(checkStatus, 1000);
        await fetchQR();
    };
    const onClickRefresh = () => {
        clearInterval(that.checkStatusTimer);
        that.isExpire = false;
        that.error = false;
        init();
    };
    useEffect(() => {
        init();
        return () => {
            clearInterval(that.checkStatusTimer);
        };
    }, []);

    return (
        <LoadingContainer loading={that.loading} style={{width: width, height: height, position: 'relative'}}>
            {that.isExpire || that.error ? (
                <FlexStyled
                    $flexDirection='column'
                    $alignItems='center'
                    $justifyContent='center'
                    style={{height: '100%', width: '100%'}}
                >
                    <Space direction='vertical'>
                        <FlexStyled $justifyContent='center'>
                            {that.error ? '未知错误' : '二维码已过期，请刷新网页。'}
                        </FlexStyled>
                        <Button type='primary' onClick={onClickRefresh}>
                            点击重试
                        </Button>
                    </Space>
                </FlexStyled>
            ) : (
                <img
                    key={1}
                    alt='二维码'
                    style={{height: '100%', width: '100%'}}
                    src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${that.ticket}`}
                />
            )}
        </LoadingContainer>
    );
};
