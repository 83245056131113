// 基于antd的modal进行的一些定制化改造

import FlexStyled from "@/styled/FlexStyled";
import { Button, Modal, ModalProps } from "antd"
import styled from "styled-components";

// 

interface DialogProps extends ModalProps {
    type?: "danger" | "warning";
    titleText?: string;
    descText?: string;
    onBtnCancel?: (e: any) => {}
}

interface SvgMap {
    [key: string]: React.ReactNode; // 如果您的 SVG 是 React 组件
}

const CustomDialogWrapStyled = styled.div`
    position: relative;
    display: flex;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 22px;
    pointer-events: auto;

    .close-btn {
        position: absolute;
        right: 12px;
        top: 12px;
    }
    .close-btn:hover {
        cursor: pointer;
        transition: all 100ms;
        svg g {
            fill: #333;
        }
    }
`

const IconStyled = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 6px;
`
const ContentStyled = styled.div<any>`
    width: 100%;
    h3 {
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #2C2C2C;
        margin: 0;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #999999;
        ${props => props.$type === 'error' && `color: #EC5B59;`}
    }
`

const FooterStyled = styled.div`
    margin-top: 46px;
    display: flex;
    justify-content: flex-end;
`

const CancelBtnStyled = styled.div`
    background: #F4F4F4;
    border-radius: 6px;

    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #5C5C5C;

    margin-right: 10px;

    width: 88px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`

const ConfirmBtnStyled = styled.div<any>`
    background: #EC5B59;
    ${props => props.$type === 'warning' && `background: #11BBA6;`}
    border-radius: 6px;

    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;

    width: 88px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`

const closeSvg = <FlexStyled dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="职位描述2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="职位描述-2-删除目标岗位提示" transform="translate(-819.000000, -352.000000)" fill="#D8D8D8" fill-rule="nonzero">
            <g id="编组" transform="translate(447.000000, 340.000000)">
                <g id="新建一项备份-10" transform="translate(372.000000, 12.000000)">
                    <g id="编组-11" transform="translate(2.000000, 2.000000)">
                        <polygon id="路径" points="0.0458891013 10.9363474 10.9110163 0 11.9541109 1.06365259 1.08810119 12"></polygon>
                        <polygon id="路径" points="10.9674952 11.9527067 0 1.12076145 1.03250478 0.0472932778 12 10.8783462"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>    
`}} />
const dangerSvg = <FlexStyled dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="职位描述2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="职位描述-2-删除目标岗位提示" transform="translate(-467.000000, -366.000000)" fill="#EC5B59">
            <g id="编组" transform="translate(447.000000, 340.000000)">
                <g id="删除提示" transform="translate(20.000000, 26.000000)">
                    <path d="M10,1 C14.9707,1 19,5.0293 19,10 C19,14.9707 14.9707,19 10,19 C5.0293,19 1,14.9707 1,10 C1,5.0293 5.0293,1 10,1 Z M10,4.6 C9.50294372,4.6 9.09999999,5.00294373 9.09999999,5.50000001 L9.09999999,12.7 C9.09999999,13.1970563 9.50294372,13.6 10,13.6 C10.4970563,13.6 10.9,13.1970563 10.9,12.7 L10.9,5.50000001 C10.9,5.00294373 10.4970563,4.6 10,4.6 L10,4.6 Z M10,16.3 C10.4970563,16.3 10.9,15.8970563 10.9,15.4 C10.9,14.9029437 10.4970563,14.5 10,14.5 C9.50294372,14.5 9.09999999,14.9029437 9.09999999,15.4 C9.09999999,15.8970563 9.50294372,16.3 10,16.3 L10,16.3 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>   
`}} />

const warningSvg = <FlexStyled dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="填充内容-内容识别" transform="translate(-460.000000, -318.000000)" fill="#FA9C17">
            <g id="编组备份" transform="translate(440.000000, 292.000000)">
                <g id="美化" transform="translate(20.000000, 26.000000)">
                    <path d="M10,1 C14.9707,1 19,5.0293 19,10 C19,14.9707 14.9707,19 10,19 C5.0293,19 1,14.9707 1,10 C1,5.0293 5.0293,1 10,1 Z M9.2,5.50000001 L9.2,12.7 C9.2,13.1970563 9.5581722,13.6 10,13.6 C10.4418278,13.6 10.8,13.1970563 10.8,12.7 L10.8,5.50000001 C10.8,5.00294373 10.4418278,4.6 10,4.6 C9.5581722,4.6 9.2,5.00294373 9.2,5.50000001 Z M10,16.3 C10.4970563,16.3 10.9,15.8970563 10.9,15.4 C10.9,14.9029437 10.4970563,14.5 10,14.5 C9.50294372,14.5 9.09999999,14.9029437 9.09999999,15.4 C9.09999999,15.8970563 9.50294372,16.3 10,16.3 L10,16.3 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />
const errorSvg = <FlexStyled dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="新版小助手🤖🤖🤖📠" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="终止提示弹窗" transform="translate(-460.000000, -318.000000)" fill="#EC5B59">
            <g id="编组备份-8" transform="translate(440.000000, 292.000000)">
                <g id="删除提示" transform="translate(20.000000, 26.000000)">
                    <path d="M10,1 C14.9707,1 19,5.0293 19,10 C19,14.9707 14.9707,19 10,19 C5.0293,19 1,14.9707 1,10 C1,5.0293 5.0293,1 10,1 Z M10,4.6 C9.50294372,4.6 9.09999999,5.00294373 9.09999999,5.50000001 L9.09999999,12.7 C9.09999999,13.1970563 9.50294372,13.6 10,13.6 C10.4970563,13.6 10.9,13.1970563 10.9,12.7 L10.9,5.50000001 C10.9,5.00294373 10.4970563,4.6 10,4.6 L10,4.6 Z M10,16.3 C10.4970563,16.3 10.9,15.8970563 10.9,15.4 C10.9,14.9029437 10.4970563,14.5 10,14.5 C9.50294372,14.5 9.09999999,14.9029437 9.09999999,15.4 C9.09999999,15.8970563 9.50294372,16.3 10,16.3 L10,16.3 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />


const Dialog = (props: DialogProps) => {
    const { type, ...otherProps } = props

    return <Modal
        centered
        maskClosable={false}
        getContainer={null}
        {...otherProps}
        modalRender={() => {
            return <CustomDialogContent {...props} />
        }}
    >

    </Modal>
}

const CustomDialogContent = (props: DialogProps) => {
    const { 
        type = 'danger', 
        titleText, 
        descText, 
        onCancel, 
        onOk,
        okText = '确定',
        cancelText = '取消',
        onBtnCancel,
    } = props

    const handleCancel = (e: any) => {
        onCancel && onCancel(e)
    }

    const handleBtnCancel = (e: any) => {
        onBtnCancel && onBtnCancel(e)
        onCancel && onCancel(e)
    }

    const handleOk = (e: any) => {
        onOk && onOk(e)
    }

    const mapTypeToSvg: SvgMap = {
        'danger': dangerSvg,
        'warning': warningSvg,
        'error': errorSvg,
    }

    return <CustomDialogWrapStyled>
        <div onClick={handleCancel} className="close-btn">{closeSvg}</div>

        <ContentStyled $type={type}>
            <h3>
                <IconStyled>
                    {mapTypeToSvg[type]}
                </IconStyled>
                {titleText}
            </h3>
            <p>{descText}</p>
            {/* {modal} */}
            <FooterStyled>
                <CancelBtnStyled onClick={handleBtnCancel}>{cancelText}</CancelBtnStyled>
                <ConfirmBtnStyled $type={type} onClick={handleOk}>{okText}</ConfirmBtnStyled>
            </FooterStyled>
        </ContentStyled>

        
    </CustomDialogWrapStyled>
}

export default Dialog