import withComponent from "@/hoc/withComponent"
import styled from "styled-components"
import { 
    feat1Svg,
    feat2Svg,
    feat3Svg,
    feat4Svg,
    feat5Svg,
    feat6Svg,
} from "../svg"

const FeatureListStyled = styled.div`
    font-family: PingFangSC, PingFang SC;
    padding-top: 70px;
    background: #FFFFFF;
    padding-bottom: 110px;
    .feature-h1 {
        font-size: 36px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 24px;
        text-align: center;
    }

    .feature-p {
        font-size: 18px;
        font-weight: 400;
        color: #555555;
        margin-bottom: 90px;
        text-align: center;
    }

    .feature-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 40px;
        column-gap: 100px;
    }
    .feature-item-svg {
        width: 80px;
        height: 80px;
        background: rgba(17,187,166,0.1);
        border-radius: 50%;

        svg {
            width: 52px;
            height: 52px;
        }
    }
`

const list = [
    {
        svg: feat1Svg,
        title: '自动排版',
        p: '只需填写内容，程序会自动排版出精美的简历',
    },
    {
        svg: feat2Svg,
        title: '模块定制',
        p: '所有简历模块皆可自由调整、隐藏或显示',
    },
    // {
    //     svg: feat3Svg,
    //     title: '皮肤色彩定制',
    //     p: '千万种皮肤随心定制，做出独一无二的简历',
    // },
    // {
    //     svg: feat4Svg,
    //     title: '支持中英文',
    //     p: '中英文一键切换，字体可修改',
    // },
    {
        svg: feat5Svg,
        title: '一键换模板',
        p: '填写一份简历，一键切换海量模板',
    },
    {
        svg: feat6Svg,
        title: '一键下载',
        p: '一键下载简历，打印、投递方便快捷',
    },
]

const FeatureList = () => {




    return <FeatureListStyled>
        <div className="feature-h1">简历神器特点</div>
        <div className="feature-p">更多功能等你探索</div>
        <div className="feature-list" style={{ width: 892, margin: '0 auto'}}>
            {list.map((item, index) => {
                return <div className="flex" key={index}>
                    <div className="feature-item-svg flex-center mr-30" >
                        {item.svg}
                    </div>
                    <div className="flex justify-center flex-col">
                        <div className="mb-12" style={{ fontSize: 20, color: '#555', fontWeight: 500}}>{item.title}</div>
                        <div style={{ color: '#555', fontSize: 14, textAlign: 'center',fontWeight: 400 }}>
                            {item.p}
                        </div>
                    </div>

                </div>
            })}
        </div>
    </FeatureListStyled>
}

export default withComponent(FeatureList)