import React, { useState } from 'react';
import FlexStyled from '@/styled/FlexStyled';

import {BtnStyleEl, BtnStyleEl2} from "@/components/ErrorModal";
import JzImage from '@/components/JzImage';
import { vipOrderConfig, vip_life } from '@/const/pay';
import store from 'store'



export default (props) => {
  const { vipType } = store.get('user')
  const vipItem = vipOrderConfig.find(item => item.id === vipType)
  // const count = vipType === vip_life ? 15 : 5
  return <FlexStyled $flexDirection='column' $alignItems='flex-start' $justifyContent='flex-start' style={{padding: '20px', width: '100%'}}>
    <FlexStyled $alignItems='center' style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1 }}>
      <JzImage src='delete-icon.png' style={{ width: 20, height: 20, marginRight: 6 }} />
      提示
    </FlexStyled>
    <FlexStyled $justifyContent='center' style={{ marginTop: 15, fontSize: 14, color: '#999999' }}>
      { vipType === vip_life ? "VIP用户只能创建 5 份简历，请往工作台删除。" : <>您已创建 5 份简历！{(vipItem?.text)?.slice(1) || 'VIP'}用户只能创建 5 份简历。</>}
    </FlexStyled>
    <FlexStyled $justifyContent='flex-end' style={{ marginTop: 42, fontSize: 16, color: '#007AFF', width: '100%' }}>
      {/* <Pointer onClick={props.onCancel}>升级会员</Pointer> */}
      <BtnStyleEl2 onClick={props.onNavigate}>查看我已创建的简历</BtnStyleEl2>
      {
        vipType !== 22 && <BtnStyleEl style={{ marginLeft: 10 }} onClick={props.onCancel}>升级</BtnStyleEl>
      }
    </FlexStyled>
  </FlexStyled>;
};
