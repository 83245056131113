import mobile from '../config'
import QueryString from "qs";
import setUserByLocal from "@/common/user/setUserByLocal";
import getUserByLocal from '@/common/user/getUserByLocal';
import { wxBrowserLogin } from '@/http/mobileApi';
import { isMobileLogin } from './login';
import { getUserInfo } from '@/http/api';
import loginHandler from '@/common/user/loginHandler';

export const getMobileImg = (imgName) => {
    return `https://oss.jianzeppt.com/jz-ppt-img/${imgName}`
}

export function setPageTitle(title) {
    document.title = title;
}

export function isEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const isPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length !== 11) {
        return false;
    }
    if (!/^\d+$/.test(phoneNumber)) {
        return false;
    }
    return true;
}

export const isCode = (code) => {
    if (code.length !== 6 && code.length !== 4) {
        return false;
    }
    if (!/^\d+$/.test(code)) {
        return false;
    }
    return true;
}

export var decode = function (str, decoder, charset) {
    var strWithoutPlus = str.replace(/\+/g, ' ');
    if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
    }
    // utf-8
    try {
        return decodeURIComponent(strWithoutPlus);
    } catch (e) {
        return strWithoutPlus;
    }
};


export function getParam(name) {
    const url = window.location.href
    const urlObj = new URL(url)
    return urlObj.searchParams.get(name) || ''
}

export function getAllParam(name) {
    const url = window.location.href;
    const urlObj = new URL(url);
    const values = urlObj.searchParams.getAll(name);
    return values.length ? values[values.length - 1] : '';
}


/* 'wechat-h5' */
export const getEnv = () => {
    var ua = navigator.userAgent.toLowerCase();
    var isMiniProgram = ua.indexOf('miniprogram') !== -1;
    var isWechatBrowser = ua.indexOf('micromessenger') !== -1;
    if (isMiniProgram) {
        return 'miniProgram'; // 小程序环境
    } else if (isWechatBrowser) {
        return 'wechatBrowser'; // 微信浏览器环境
    } else {
        return 'other'; // 非微信环境
    }
}

// 判断是否是微信环境，微信浏览器或者小程序
export const isWechat = () => {
    const env = getEnv()
    return env === 'wechatBrowser' || isMiniProgram()
}

export const isWechatBrowser = () => getEnv() === 'wechatBrowser'
export const isMiniProgram = () => window.__wxjs_environment === 'miniprogram'

export function isIOS() {
    return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
}

export const isIOSMiniProgram = () => (isMiniProgram() && isIOS())

export function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

// 判断是否是PPT插件环境
export function isPowerPoint() {
    return window.parent && window.parent.PowerPoint
}

// 判断是否是WPS插件环境
export function isWpsPowerPoint() {
    // window.wps.Presentations.OpenFromUrl(pptPath)
    return window.parent && window.parent.wps
}

const getOfficialAppId = () => {
    return mobile.appId;
};

const wxAuthorize = () => {
    const appid = getOfficialAppId();
    const scope = 'snsapi_userinfo';
    const stateNum = 123;
    const url = new URL(window.location.href);
    url.searchParams.delete('code')
    url.searchParams.delete('state')
    const redirectUrl = encodeURIComponent(url.href);
    console.log('redirectUrl', redirectUrl)
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${stateNum}#wechat_redirect`;
}

// wx js api方式登录
export async function wxLogin(callback) {
    if (isMobileLogin()) return
    const code = getAllParam('code')
    console.log('获取到的code', code)
    try {
        if (code) {
            const data = {
                code
            }
            await wxBrowserLogin(data).then(user => {
                if (user) {
                    console.log('微信浏览器登录成功', user)
                    callback && callback()
                    loginHandler(user)
                }
            })
        } else {
            wxAuthorize()
        }
    } catch (error) {
        console.log('微信浏览器登录失败', error)
        // code被用过了，重新授权
        wxAuthorize()
        return Promise.reject(error)
    }
}

export const wxAppLogin = () => {
    const url = new URL(window.location.href)
    const user = getUserByLocal()
    const userId = user?.id || ''
    const token = user?.token || user?.token || ''
    console.log(`/pages/login/index?jz_redirect_url=${encodeURIComponent(url)}&userId=${userId}&token=${token}`)
    openMiniGramPage(`/pages/login/index?jz_redirect_url=${encodeURIComponent(url)}&userId=${userId}&token=${token}`)
}

export const wxAppPay = (obj) => {
    const url = new URL(window.location.href)
    const path = `/pages/pay/index?${QueryString.stringify(obj)}&jz_redirect_url=${encodeURIComponent(url)}`
    openMiniGramPage(path)
}

function loadScript(url, callback, charset) {
    let script = document.createElement('script')
    script.src = url
    script.charset = charset || 'utf-8'
    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                callback && callback()
            }
        }
    } else {
        script.onload = function () {
            setTimeout(() => {
                callback && callback()
            }, 200)
        }
    }
    document.getElementsByTagName('head')[0].appendChild(script)
}

export const openMiniGramPage = url => {
    if (!isMiniProgram()) return
    const weiXinJs = 'https://res.wx.qq.com/open/js/jweixin-1.3.2.js'
    if (window.wx) {
        window.wx.miniProgram && window.wx.miniProgram.navigateTo({ url })
    } else {
        loadScript(weiXinJs, () => {
            window.wx.miniProgram && window.wx.miniProgram.navigateTo({ url })
        })
    }
}

export function formatDate(dateStr) {
    const date = new Date(dateStr)
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var now = new Date();
    var currentYear = now.getFullYear();

    if (year === currentYear) {
        return month + '-' + day;
    } else {
        return year + '-' + month + '-' + day;
    }
}