// 底部导航栏
import { useNavigate , useLocation } from 'react-router-dom'
import classNames from 'classnames'
import './NavigationHeader.scss'
import { LeftOutline } from 'antd-mobile-icons'
import { Ellipsis } from 'antd-mobile'
import { debounce, throttle } from 'lodash'

// 顶部导航栏
const NavigationHeader = (props) => {
    const { title, onBack, render,style={} } = props
    const location = useLocation()
    const history = useNavigate()
    const { children } = props

    const handleNavigation = (url) => {
        history.push(url)
    }


    const back = throttle(() => {
        if (onBack) {
            onBack()
        } else {
            history(-1)
        }
    }, 2000)

    const handleBack = () => {
        back()
    }

    return <div className="top-layout" style={{...style}}>
        <div onClick={handleBack} className="back">
            <LeftOutline fontSize={20} className='left-icon' />
        </div>
        <div className="top-title">
            <Ellipsis direction='end' content={title || '了了简历'} />
        </div>
        {render && render}
    </div>
}

export default NavigationHeader
