
// 中间出现的

import JzImage from "@/components/JzImage"
import { useState } from "react"
import styled from "styled-components"
import { SystemChatBoxStyled } from "../styled"

const SelectCardWrap = styled.div`
    width: 282px;
    background: #F7F7F8;
    border-radius: 12px 12px 12px 0px;
    padding: 8px;
    box-sizing: border-box;
`

const SelectBgStyled = styled.div`
    width: 266px;
    /* background: #FFFFFF; */
    /* box-shadow: 0px 0px 4px 0px rgba(210,210,210,0.5); */
    border-radius: 12px 12px 0px 12px;
    box-sizing: border-box;
    padding: 8px 13px;

    .desc {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        margin-bottom: 8px;
    }
`

const SelectCardStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    row-gap: 8px;
    box-sizing: border-box;
    width: 100%;
`

const SelectCardItemStyled = styled.div`
    box-sizing: border-box;
    background: #F7F7F8;
    border-radius: 8px;
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 12px;
    flex-direction: column;
    cursor: pointer;
    position: relative;

    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #5E658E;
    
    ${props => props.$active && `
        color: ${props.$color};
        font-weight: bold;
        .bg {
            display: block !important;
        }
    `}
    &:hover {
        /* box-shadow: 0px 2px 6px 0px rgba(230,230,230,1); */
        /* border: 2px solid ${props => props.$color}; */
        background-color: ${props => props.$bgColor};
        color: ${props => props.$color};
        svg path{
            fill: ${props => props.$color};
        }
        font-weight: bold;
        .bg {
            display: block;
        }
    }
    p {
        position: relative;
        margin-top: 8px;
        padding: 0 17px;
        text-align: center;
        z-index: 20;
    }
    .bg {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 10;
    }
    .icon {
        position: relative;
        width: 36px;
        height: 36px;
        z-index: 20;
    }
`

// beautify:美化 | edit: 完善简历 | interview: 面试建议

const SelectNextCard = ({ onChange, customOption = null }) => {
    const [active, setActive] = useState('');

    const handleChange = (selectedType) => {
        // if (selectedType === active) return
        onChange(selectedType);
        setActive(selectedType);
    };
    
    const options = customOption || [
        { type: 'edit', label: '完善简历', src: "airesume_custom1.png", bgSrc: "airesumebg3.png", color: '#5E658E'},
        { type: 'continue', label: '继续优化', src: "airesume5.png", bgSrc: "airesumebg5.png", color: '#5E658E'},
    ];
    return (
        <SelectCardWrap>
            <SelectBgStyled style={{ background: '#FFFFFF'}}>
                <div className="desc">选择你的操作：</div>
                <SelectCardStyled>
                    {options.map((option) => (
                        <SelectCardItemStyled
                            key={option.type}
                            $active={active === option.type}
                            // $bgColor={option.bgColor}
                            $color={option.color}
                            onClick={() => handleChange(option.type)}
                        >
                            <JzImage className="bg" src={option.bgSrc} />
                            <JzImage className="icon" src={option.src} />
                            {/* {option.icon} */}
                            <p>{option.label}</p>
                        </SelectCardItemStyled>
                    ))}
                </SelectCardStyled>
            </SelectBgStyled>
        </SelectCardWrap>
    );
};

export default SelectNextCard