import store from 'store';

const AOrB = (id) => {
  return !!(id % 2);

};

export const isA = (u) => {
  const user = u || store.get('user');
  return !AOrB(user?.id);


  // return user.isAb === 1;
};
export const isB = (u) => {
  if (__DEV__) {
    return true;
  }
  const user = u || store.get('user');
  return !!AOrB(user?.id);
};
//a 为false
//b 为 true
//a 测保留原始
//b 为新功能


export default AOrB;
