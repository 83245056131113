import styled from "styled-components";
import MobileLayout from "../../layout";
import { listResumeUser } from "@/http/api";
import moment from "moment";
import { useEffect, useState } from "react";
import UserResumeItem from "../Home/components/UserResumeItem";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js";
import {
  mobileSlice,
  fetchResumeUserList,
} from "@/pages/Mobile/mobileSlice.js";
import store from "@/redux/store";
import { useDispatch, useSelector } from "react-redux";
import FlexStyled from "@/styled/FlexStyled";
import { useNavigate } from "react-router-dom";
import LazyLoadComponent from "./LazyLoadComponent";
import Pagination_c from "@/pages/Home/Pagination_c";

const MyStyled = styled.div`
  background-color: rgba(247, 248, 249, 1);
  padding: 15px;
  min-height: 100vh;
  box-sizing: border-box;
  .my-title {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-bottom: 15px;
  }
  .img {
    width: 160px;
    height: 160px;
    margin-top: 150px;
  }
  .word {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #a0a7b1;
    margin: 12px 0;
  }
  .btn {
    margin-top: 13px;
    width: 124px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    background: #11bba6;
    border-radius: 6px;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
  }
`;

const List = () => {
  const [mutation, setMutation] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    defaultCurrent: 1,
    defaultPageSize: 4,
    current: 1,
    pageSize: 4,
    total: 0,
  });

  const onChangeCurrent = (current) => {
    setPagination({
      ...pagination,
      current,
    });
  };
  const fetchList = async () => {
    if (loading) return;
    setLoading(true);
    const res = await listResumeUser({
      query: {
        page: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
    const dataList = res?.dataList?.map((item) => {
      let data = JSON.parse(item.pagesData?.[0]?.userData);
      return {
        data,
        resume: {
          id: item.id,
          resumeId: item.id,
        },
        resumeData: data,
        name: item.name,
        updateTime: moment(item.updateTime).format("YYYY-MM-DD HH:mm"),
        id: item.id,
      };
    });
    if (dataList) {
      setDataList(() => [ ...dataList]);
    } else {
    }
    setPagination({
      ...pagination,
      total: res.count,
    });
    dispatch(fetchResumeUserList());
    setLoading(false);
  };

  useEffect(() => {
    fetchList();
  }, [pagination.current]);


  const handleMutation = () => {
    fetchList();
  };

  return (
    <MyStyled>
      <NavigationHeader
        title={document.title + "(" + pagination.total + ")"}
        style={{ left: "-15px", top: "-15px", width: "100vw" }}
      ></NavigationHeader>
      {pagination.total > 0 && (
        <div className="my-wrap">
          {dataList.map((item) => {
            return (
              <UserResumeItem
                key={item.id}
                id={item.id}
                title={item.name}
                time={item.updateTime}
                style={{ marginBottom: 15 }}
                onMutation={handleMutation}
              />
            );
          })}
          <FlexStyled style={{ position : 'fixed',bottom:12, width:'calc( 100% - 30px )',display:'flex',justifyContent:'center' }}>
          <Pagination_c
            defaultCurrent={pagination.defaultCurrent}
            defaultPageSize={pagination.defaultPageSize}
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={onChangeCurrent}
          />
          </FlexStyled>
         
        </div>
      )}
      {pagination.total === 0 && !loading &&  (
        <FlexStyled
          $flexDirection="column"
          $alignItems="center"
          $justifyContent="center"
          $alignContent="center"
        >
          <img
            className="img"
            src="https://pub.jianzeppt.cn/image/%E6%9A%82%E6%97%A0%E7%AE%80%E5%8E%86%402x.png"
          ></img>
          <div className="word">暂无简历，请先</div>
          <div onClick={() => navigate(`/mobile/index`)} className="btn">
            新建简历
          </div>
        </FlexStyled>
      )}
    </MyStyled>
  );
};

export default List;
