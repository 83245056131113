import SvgComponent from "@/components/resume/SvgComponent";
import {cloneElement, createElement} from "react";
import GenderValue from "@/components/resume/modules/base/GenderValue";
import Avatar from "@/components/resume/modules/Avatar";
import CommonSlotInputByPath from "@/components/resume/components/CommonSlotInputByPath";
import ModuleManage from "@/components/resume/moduleManage";
import FieldBox from "@/components/resume/components/FieldBox";
import DateFieldBox from "@/components/resume/modules/base/DateFieldBox"
import FieldSelect from "@/components/resume/components/FieldSelect";
import CommonSlotViewByPath from "@/components/resume/components/CommonSlotViewByPath";
import HeightAndWeightBox from "@/components/resume/modules/base/HeightAndWeightBox";
import Portfolio from "@/components/resume/modules/portfolio/index";
import Layout from "@/components/resume/layout/index";
import JOB_INTERESTSalary from "@/components/resume/modules/JOB_INTEREST/slot/Salary"
import JOB_INTEREST_JOB_STATUS from "@/components/resume/modules/JOB_INTEREST/slot/JobStatus"
import CitySelect from "@/components/resume/modules/base/CitySelect";
import {
    AVATAR_FIELD_ENUM,
    BASE_FIELD_ENUM,
    CAMPUS_ACTIVITIES_FIELD_ENUM,
    EDUCATIONAL_BACKGROUND_FIELD_ENUM,
    INTERNSHIP_EXPERIENCE_FIELD_ENUM,
    JOB_INTEREST_FIELD_ENUM,
    PROJECT_EXPERIENCE_FIELD_ENUM,
    WORK_EXPERIENCE_FIELD_ENUM
} from "@/common/field/field";
import {
    BASE_Slot,
    CAMPUS_ACTIVITIES_SLOT_ENUM, CONTACT_INFORMATION_SLOT_ENUM,
    CUSTOM_MODULE_SLOT_ENUM,
    EDUCATIONAL_BACKGROUND_SLOT_ENUM,
    HONORS_AND_AWARDS_SLOT_ENUM,
    INTEREST_SLOT_ENUM,
    INTERNSHIP_EXPERIENCE_SLOT_ENUM,
    JOB_INTEREST_SLOT_ENUM,
    LANGUAGE_PROFICIENCY_SLOT_ENUM,
    PORTFOLIO_SLOT_ENUM,
    PROJECT_EXPERIENCE_SLOT_ENUM,
    SELF_EVALUATION_SLOT_ENUM,
    SKILLS_SLOT_ENUM,
    WORK_EXPERIENCE_SLOT_ENUM
} from "@/common/slot/slot";
import {Module} from "@/common/module/module";
import ModuleContent from "@/components/resume/components/ModuleContent";
import Age from "@/components/resume/modules/base/Age";
import StartAndEndField from "@/components/resume/modules/common/field/StartAndEndField";
import StartAndEndSlot from "@/components/resume/modules/common/slot/StartAndEndSlot";
import CommonSlotDetailInputByPath from "@/components/resume/components/CommonSlotDetailInputByPath";
import SlotSelect from "@/components/resume/components/SlotSelect";
import isModuleMainList from "@/common/config/isModuleMainList";
import CommonSlotDate from "@/components/resume/components/CommonSlotDate";
import CommonIcon from "@/components/resume/components/CommonIcon";

const baseHandler = (config, options) => {
    if (config.tagName) {
        let style = config.style;
        if(config.className &&config.className.indexOf("resume-font")!==-1){
            return createElement(CommonIcon, config);
        }

        switch (config.tagName) {
            case "img" : {
                return createElement("img", config);
            }
            default: {
                let type = config.tagName
                let children;
                const props = {
                    ...config,
                    className: config.className, style: style,
                    field_name: undefined,
                    for_block_name: undefined,
                    tagName: undefined
                };
                if (config.children && config.children.length > 0) {
                    children = config.children?.map?.((config, index) => {
                        return  configRender(config, options);
                    })
                } else if (config.value !== undefined) {
                    props.dangerouslySetInnerHTML = {__html: config.value}
                }

                delete props.children
                return createElement(type, props, children);
            }
        }
    } else {
        return config.children?.map?.((config) => {
            return {...configRender(config, options)}
        })
    }
}
export const excludeModule = [Module.BASE, Module.AVATAR]
export const blockHandler = (config) => {
    switch (config.block_name) {
        case Module.AVATAR:
        case Module.BASE:
        case Module.EDUCATIONAL_BACKGROUND:
        case Module.SELF_EVALUATION:
        case Module.JOB_INTEREST:
        case Module.PORTFOLIO:
        case Module.PROJECT_EXPERIENCE:
        case Module.INTERNSHIP_EXPERIENCE:
        case Module.CAMPUS_ACTIVITIES:
        case Module.SKILLS:
        case Module.INTEREST:
        case Module.LANGUAGE_PROFICIENCY:
        case Module.CUSTOM_MODULE:
        case Module.APPLICATION_INFO:
        case Module.HONORS_AND_AWARDS:
        case Module.CONTACT_INFORMATION:
        case Module.WORK_EXPERIENCE: {
            return createElement(ModuleManage, config);
        }
    }
    console.log("未识别的模块", config.block_name);
}
const fieldHandler = (config, context) => {
    if (!context) {
        // console.log("未传递context", config.field_name);
        // return undefined
    }
    // console.log(context?.for_block_name,config.field_name);
    switch (config.field_name) {
        case JOB_INTEREST_FIELD_ENUM.TITLE:
        case JOB_INTEREST_FIELD_ENUM.JOB_TYPE:
        case JOB_INTEREST_FIELD_ENUM.JOB_STATUS:
        case JOB_INTEREST_FIELD_ENUM.ROLE:
        case JOB_INTEREST_FIELD_ENUM.CITY:
        case JOB_INTEREST_FIELD_ENUM.SALARY:
        case BASE_FIELD_ENUM.NAME: {
            return createElement(FieldBox, config);
        }
        case AVATAR_FIELD_ENUM.AVATAR:{
            return createElement(Avatar, config);
        }
    }
    switch (context?.for_block_name) {
        case Module.BASE: {
            switch (config.field_name) {
                case BASE_FIELD_ENUM.AGE:
                case BASE_FIELD_ENUM.BIRTHDAY: {
                    return createElement(DateFieldBox, config);
                }
                case BASE_FIELD_ENUM.HEIGHT_AND_WEIGHT: {
                    return createElement(HeightAndWeightBox, config);
                }
                case BASE_FIELD_ENUM.ADDRESS_AND_ORIGIN:
                case BASE_FIELD_ENUM.PRESENT_ADDRESS: {
                    return createElement(CitySelect, config);
                }
                case BASE_FIELD_ENUM.EDUCATION_BACKGROUND:
                case BASE_FIELD_ENUM.MARITAL_STATUS:
                case BASE_FIELD_ENUM.YEARS_OF_WORKING:
                case BASE_FIELD_ENUM.GENDER:
                case BASE_FIELD_ENUM.POLITICS_STATUS: {
                    return createElement(FieldSelect, config);
                }
                // case BASE_FIELD_ENUM.NAME:
                case BASE_FIELD_ENUM.NATION:
                case BASE_FIELD_ENUM.OTHER:
                case BASE_FIELD_ENUM.PHONE_NUMBER:
                case BASE_FIELD_ENUM.WECHAT_ID:
                case BASE_FIELD_ENUM.MAIL: {
                    return createElement(FieldBox, config);
                }
            }
            break
        }
        case Module.PROJECT_EXPERIENCE: {
            switch (config.field_name) {
                case PROJECT_EXPERIENCE_FIELD_ENUM.DATE: {
                    return createElement(StartAndEndField, config);
                }
            }
            return createElement(FieldBox, config);
        }
        case Module.CAMPUS_ACTIVITIES: {
            switch (config.field_name) {
                case CAMPUS_ACTIVITIES_FIELD_ENUM.DATE: {
                    return createElement(StartAndEndField, config);
                }
            }
            return createElement(FieldBox, config);
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            switch (config.field_name) {
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.DATE: {
                    return createElement(StartAndEndField, config);
                }
            }
            return createElement(FieldBox, config);
        }
        case Module.WORK_EXPERIENCE: {
            switch (config.field_name) {
                case WORK_EXPERIENCE_FIELD_ENUM.DATE: {
                    return createElement(StartAndEndField, config);
                }
            }
            return createElement(FieldBox, config);
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            switch (config.field_name) {
                case EDUCATIONAL_BACKGROUND_FIELD_ENUM.DATE: {
                    return createElement(StartAndEndField, config);
                }
                case EDUCATIONAL_BACKGROUND_FIELD_ENUM.TITLE:
                case EDUCATIONAL_BACKGROUND_FIELD_ENUM.SCHOOL:
                // case EDUCATIONAL_BACKGROUND_FIELD_ENUM.LOGO:
                case EDUCATIONAL_BACKGROUND_FIELD_ENUM.MAJOR:
                case EDUCATIONAL_BACKGROUND_FIELD_ENUM.DETAILS: {
                    return createElement(FieldBox, config);
                }
                case EDUCATIONAL_BACKGROUND_FIELD_ENUM.EDUCATION:
                    return createElement(FieldSelect, config);
            }
            break
        }
        case Module.JOB_INTEREST: {
            switch (config.field_name) {
                case JOB_INTEREST_FIELD_ENUM.SALARY:
                case JOB_INTEREST_FIELD_ENUM.JOB_TYPE:
                case JOB_INTEREST_FIELD_ENUM.ROLE:
                case JOB_INTEREST_FIELD_ENUM.CITY:
                case JOB_INTEREST_FIELD_ENUM.JOB_STATUS:
                case JOB_INTEREST_FIELD_ENUM.TITLE: {
                    return createElement(FieldBox, config);
                }
            }
            break
        }
        case Module.AVATAR: {
            return createElement(Avatar, config);
        }
        case Module.CONTACT_INFORMATION:
        case Module.SKILLS:
        case Module.PORTFOLIO:
        case Module.INTEREST:
        case Module.LANGUAGE_PROFICIENCY:
        case Module.SELF_EVALUATION:
        case Module.HONORS_AND_AWARDS:
        case Module.APPLICATION_INFO:
        case Module.CUSTOM_MODULE: {
            return createElement(FieldBox, config);
        }
    }


    // console.log("未识别的字段",context?.for_block_name,config.field_name);
    // return baseHandler(config);

}
const slotHandler = (config, context) => {
    if (!context) {
        // console.log("未传递context", config.slot_value);
        // return undefined
    }
    switch (context?.for_block_name) {
        case Module.BASE: {
            switch (config.slot_value) {
                case BASE_Slot.AGE: {
                    return createElement(Age, config);
                }
                case BASE_Slot.PHONE_NUMBER:
                case BASE_Slot.WECHAT_ID:
                case BASE_Slot.MAIL:
                case BASE_Slot.NATION:
                case BASE_Slot.ADDRESS_AND_ORIGIN:
                case BASE_Slot.JOB_INTENTION:
                case BASE_Slot.PRESENT_ADDRESS:
                case BASE_Slot.NAME: {
                    return createElement(CommonSlotInputByPath, config);
                }
                case BASE_Slot.GENDER:
                case BASE_Slot.MARITAL_STATUS:
                case BASE_Slot.YEARS_OF_WORKING:
                case BASE_Slot.POLITICS_STATUS:
                case BASE_Slot.BIRTHDAY_YEAR:
                case BASE_Slot.BIRTHDAY_MONTH:
                case BASE_Slot.BIRTHDAY_DAY:
                case BASE_Slot.HEIGHT:
                case BASE_Slot.EDUCATION_BACKGROUND:
                case BASE_Slot.WEIGHT: {
                    return createElement(CommonSlotViewByPath, config);
                }
            }
            break
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            switch (config.slot_value) {

                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }

                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.DATE:{
                    return createElement(CommonSlotDate, config);
                }
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.END_DATE:
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.START_DATE: {
                    return createElement(StartAndEndSlot, config);
                }
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.SCHOOL:
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.TITLE_TEXT:
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.MAJOR:
                    return createElement(CommonSlotInputByPath, config);
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.EDUCATION: {
                    return createElement(CommonSlotViewByPath, config);
                }
            }
            break
        }
        case Module.WORK_EXPERIENCE: {
            switch (config.slot_value) {
                case WORK_EXPERIENCE_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
                case WORK_EXPERIENCE_SLOT_ENUM.DATE:{
                    return createElement(CommonSlotDate, config);
                }
                case WORK_EXPERIENCE_SLOT_ENUM.START_DATE:
                case WORK_EXPERIENCE_SLOT_ENUM.END_DATE: {
                    return createElement(StartAndEndSlot, config);
                }
                case WORK_EXPERIENCE_SLOT_ENUM.TITLE_TEXT:
                case WORK_EXPERIENCE_SLOT_ENUM.POSITION:
                case WORK_EXPERIENCE_SLOT_ENUM.COMPANY:
                    return createElement(CommonSlotInputByPath, config);
            }
            break
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            switch (config.slot_value) {
                case INTERNSHIP_EXPERIENCE_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
                case INTERNSHIP_EXPERIENCE_SLOT_ENUM.DATE:{
                    return createElement(CommonSlotDate, config);
                }
                case INTERNSHIP_EXPERIENCE_SLOT_ENUM.START_DATE:
                case INTERNSHIP_EXPERIENCE_SLOT_ENUM.END_DATE: {
                    return createElement(StartAndEndSlot, config);
                }
                case INTERNSHIP_EXPERIENCE_SLOT_ENUM.TITLE_TEXT:
                case INTERNSHIP_EXPERIENCE_SLOT_ENUM.ACTIVITY_NAME:
                case INTERNSHIP_EXPERIENCE_SLOT_ENUM.ROLE:
                    return createElement(CommonSlotInputByPath, config);
            }
            break
        }
        case Module.CAMPUS_ACTIVITIES: {
            switch (config.slot_value) {
                case CAMPUS_ACTIVITIES_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
                case CAMPUS_ACTIVITIES_SLOT_ENUM.DATE:{
                    return createElement(CommonSlotDate, config);
                }
                case CAMPUS_ACTIVITIES_SLOT_ENUM.START_DATE:
                case CAMPUS_ACTIVITIES_SLOT_ENUM.END_DATE: {
                    return createElement(StartAndEndSlot, config);
                }
                case CAMPUS_ACTIVITIES_SLOT_ENUM.TITLE_TEXT:
                case CAMPUS_ACTIVITIES_SLOT_ENUM.ACTIVITY_NAME:
                case CAMPUS_ACTIVITIES_SLOT_ENUM.ROLE:
                    return createElement(CommonSlotInputByPath, config);
            }
            break
        }
        case Module.PROJECT_EXPERIENCE: {
            switch (config.slot_value) {
                case PROJECT_EXPERIENCE_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
                case PROJECT_EXPERIENCE_SLOT_ENUM.DATE:{
                    return createElement(CommonSlotDate, config);
                }
                case PROJECT_EXPERIENCE_SLOT_ENUM.START_DATE:
                case PROJECT_EXPERIENCE_SLOT_ENUM.END_DATE: {
                    return createElement(StartAndEndSlot, config);
                }
                case PROJECT_EXPERIENCE_SLOT_ENUM.TITLE_TEXT:
                case PROJECT_EXPERIENCE_SLOT_ENUM.ROLE:
                case PROJECT_EXPERIENCE_SLOT_ENUM.NAME:
                    return createElement(CommonSlotInputByPath, config);
            }
            break
        }
        case Module.HONORS_AND_AWARDS: {
            switch (config.slot_value) {
                case HONORS_AND_AWARDS_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
                case HONORS_AND_AWARDS_SLOT_ENUM.TITLE_TEXT:
                    return createElement(CommonSlotInputByPath, config);
            }
            break
        }
        case Module.SELF_EVALUATION: {
            switch (config.slot_value) {
                case SELF_EVALUATION_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
                case SELF_EVALUATION_SLOT_ENUM.TITLE_TEXT:
                    return createElement(CommonSlotInputByPath, config);
            }
            break
        }
        case Module.INTEREST: {
            switch (config.slot_value) {
                case INTEREST_SLOT_ENUM.TITLE_TEXT:
                    return createElement(CommonSlotInputByPath, config);
                case INTEREST_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
            }
            break
        }
        case Module.SKILLS: {
            switch (config.slot_value) {
                case SKILLS_SLOT_ENUM.TITLE_TEXT:
                    return createElement(CommonSlotInputByPath, config);
                case SKILLS_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
            }
            break
        }
        case Module.JOB_INTEREST: {
            switch (config.slot_value) {
                case JOB_INTEREST_SLOT_ENUM.TITLE_ICON:
                case JOB_INTEREST_SLOT_ENUM.JOB_TYPE_ICON:
                case JOB_INTEREST_SLOT_ENUM.ROLE_ICON:
                case JOB_INTEREST_SLOT_ENUM.CITY_ICON:
                case JOB_INTEREST_SLOT_ENUM.SALARY_ICON:
                case JOB_INTEREST_SLOT_ENUM.SALARY: {
                    return createElement(JOB_INTERESTSalary, config);
                }
                case JOB_INTEREST_SLOT_ENUM.JOB_STATUS: {
                    return createElement(JOB_INTEREST_JOB_STATUS, config);
                }
                case JOB_INTEREST_SLOT_ENUM.TITLE_TEXT: {
                    return createElement(CommonSlotInputByPath, config);
                }
                case JOB_INTEREST_SLOT_ENUM.CITY:
                case JOB_INTEREST_SLOT_ENUM.JOB_TYPE:
                case JOB_INTEREST_SLOT_ENUM.ROLE: {
                    return createElement(SlotSelect, {...config, canInputEdit: true});
                }
                default: {
                    return createElement(CommonSlotViewByPath, config);
                }
            }
        }
        case Module.LANGUAGE_PROFICIENCY: {
            switch (config.slot_value) {
                case LANGUAGE_PROFICIENCY_SLOT_ENUM.TITLE_TEXT: {
                    return createElement(CommonSlotInputByPath, config);
                }
                case LANGUAGE_PROFICIENCY_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
                default: {
                    return createElement(CommonSlotViewByPath, config);
                }
            }
        }
        // TODO: 这里插值
        case Module.CUSTOM_MODULE: {
            switch (config.slot_value) {
                case CUSTOM_MODULE_SLOT_ENUM.TITLE_TEXT: {
                    return createElement(CommonSlotInputByPath, config);
                }
                case CUSTOM_MODULE_SLOT_ENUM.DETAILS: {
                    return createElement(CommonSlotDetailInputByPath, config);
                }
                default: {
                    return createElement(CommonSlotViewByPath, config);
                }
            }
        }
        case Module.PORTFOLIO: {
            switch (config.slot_value) {
                case PORTFOLIO_SLOT_ENUM.TITLE_TEXT: {
                    return createElement(CommonSlotInputByPath, config);
                }
                default: {
                    return createElement(CommonSlotViewByPath, config);
                }
            }
        }
        case Module.CONTACT_INFORMATION: {
            switch (config.slot_value) {
                case CONTACT_INFORMATION_SLOT_ENUM.TITLE: {
                    return createElement(CommonSlotInputByPath, config);
                }
                case CONTACT_INFORMATION_SLOT_ENUM.PHONE_NUMBER:
                case CONTACT_INFORMATION_SLOT_ENUM.MAIL:
                default: {
                    return createElement(CommonSlotInputByPath, config);
                }
            }
        }
    }

    switch (config.slot_value) {
        case JOB_INTEREST_SLOT_ENUM.JOB_STATUS:
            return createElement(JOB_INTEREST_JOB_STATUS, config);
        case JOB_INTEREST_SLOT_ENUM.JOB_TYPE:
        case JOB_INTEREST_SLOT_ENUM.ROLE:
        case JOB_INTEREST_SLOT_ENUM.CITY: {
            return createElement(SlotSelect, {...config, canInputEdit: true});
        }
        case JOB_INTEREST_SLOT_ENUM.SALARY: {
            return createElement(JOB_INTERESTSalary, config);
        }
        case JOB_INTEREST_SLOT_ENUM.TITLE_TEXT:
        case BASE_Slot.DES:
        case BASE_Slot.NAME: {
            return createElement(CommonSlotInputByPath, config);
        }
    }

    // console.log("未识别的slot", config.slot_value);
    // return baseHandler(config);

}
const textHandler = (config) => {
    return config
}
const iconHandler = (config) => {
    return createElement(SvgComponent, config);
}
const moduleContentHandler = (config, context) => {
    if (config.type === "module_main" && isModuleMainList(context.for_block_name)) {
        switch (context?.for_block_name) {
            case Module.EDUCATIONAL_BACKGROUND:
            case Module.PROJECT_EXPERIENCE:
            case Module.INTERNSHIP_EXPERIENCE:
            case Module.CAMPUS_ACTIVITIES:
            case Module.WORK_EXPERIENCE: {
                return createElement(ModuleContent, config);
            }
            case Module.PORTFOLIO: {
                return createElement(Portfolio, config);
            }
        }
    }
}
const configRender = (config, options) => {
    if (!config) return;
    const {context} = options || {};
    if (typeof config === "string") {
        return textHandler(config);
    }
    const moduleContentRes = moduleContentHandler(config, context);
    if (moduleContentRes) {
        return moduleContentRes
    }
    if (config["data-layout-type"] === "left") {
        return createElement(Layout, config);
    }
    if (config["data-layout-type"] === "main") {
        return createElement(Layout, config);
    }

    if (config.block_name) {
        return blockHandler(config, context)
    }
    if (config.field_name) {
        return fieldHandler(config, context)
    }
    if (config.slot_value) {
        return slotHandler(config, context)
    }
    if (config.tagName === "icon") {
        return iconHandler(config);
    }
    return baseHandler(config, options);
}

export default configRender

