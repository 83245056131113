import React from 'react';
import FlexStyled from '@/styled/FlexStyled';
import Pointer from '@/styled/Pointer';

export default (props) => {
  return <FlexStyled $flexDirection='column' alignItems='flex-start' $justifyContent='flex-start' style={{padding: '20px', width: '100%'}}>
    <div style={{ fontSize: 18, fontWeight: 'bold' }}>下载超出限制</div>
    <FlexStyled $justifyContent='center' style={{ marginTop: 15, fontSize: 14, color: '#999999' }}>很抱歉，简历下载达到使用限制，请联系客服！</FlexStyled>
    {/*<div style={{color: '#11BBA6', textAlign: 'center', width: '100%', marginTop: 20}}>联系客服：19537581425</div>*/}
  </FlexStyled>;
};
