import withComponent from "@/hoc/withComponent";
import { useMemo, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { generateResume, uploadResumeByWord } from "@/http/mobileApi";
import { clearToast, showToast } from "@/pages/Mobile/utils/toast";
import { Picker, Toast, Cascader, CascaderView, Popup } from "antd-mobile";
import isLogin from "@/common/isLogin";
import data from "./data";
const { getJobTypesAll } = require("@/http/api");
import LoginModal from "@/pages/Mobile/components/Modal/LoginModal";
import FlexStyled from "@/styled/FlexStyled";
import { SearchBar } from 'antd-mobile';
import { LeftOutline } from 'antd-mobile-icons'
import './index.scss'
import errorSlice from "@/redux/services/errorSlice";
import { useDispatch } from "react-redux";

const TabItemOneStyled = styled.div`
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  .tab-import {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }
  
  .t-btn {
    width: 141px;
    height: 33px;
    background: #11bba6;
    border-radius: 6px;

    font-size: 13px;
    color: #ffffff;
  }
  .t-label {
    font-size: 11px;
    color: #888888;
  }
  input {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  input::placeholder {
    color: #dedede;
  }
  .border-right {
    border-right: 1px solid #efefef;
  }

  .t-dropdown {
    position: absolute;
    z-index: 30;
    left: -2px;
    right: -2px;
    top: 50px;
    padding: 6px 0;
    max-height: 191px;
    background: #ffffff;
    box-shadow: 0px 0 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow-y: scroll;
    &-item {
      height: 32px;
      padding-left: 14px;
    }
  }
  .t-highlight {
    color: #11bba6;
  }
`;

const MaskStyled = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const yearsOfWorkingConfig = [
  {
    label: "应届生",
    value: "0年",
  },
  {
    label: "1-3年",
    value: "1-3年",
  },
  {
    label: "3-5年",
    value: "3-5年",
  },
  {
    label: "5-10年",
    value: "5-10年",
  },
];

function HighlightText({ text, highlight }) {
  // 使用正则表达式来分割文本
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  console.log("parts", parts);
  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className="t-highlight">
            {part}
          </span>
        ) : (
          part // 不是高亮的文本部分
        )
      )}
    </span>
  );
}
// 函数用于重命名对象中的键
function renameObjectKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

// 通用的重命名键并处理子数组的函数
function renameKeysAndHandleSubTypes(
  data,
  renameMap,
  subTypeKey,
  newSubTypeKey
) {
  return data.map((item) => {
    // 重命名对象键
    const newItem = renameObjectKeys(item, renameMap);

    // 如果存在子类型属性，递归调用自身函数处理子数组
    if (newItem[subTypeKey]) {
      newItem[newSubTypeKey] = renameKeysAndHandleSubTypes(
        newItem[subTypeKey],
        renameMap,
        subTypeKey,
        newSubTypeKey
      );
      delete newItem[subTypeKey];
    }

    // 返回处理过的新对象
    return newItem;
  });
}
const TabItemOne = ({ style }) => {
  const navigate = useNavigate();
  const [work, setWork] = useState("");
  const [chJob, setChJob] = useState("");
  const lockRef = useRef();
  const [visible, setVisible] = useState(false);
  const [job, setJob] = useState("");
  const [cascaderValue, setCascaderValue] = useState([]);
  const [showText, setShowText] = useState(false);
  const filterOptions = useMemo(() => {
    return data.filter((item) => item.name.includes(job));
  }, [data, job]);
  const login = isLogin();
  // 创建映射，定义如何重命名键
  const renameMap = {
    key: "value",
    name: "label",
  };

  const [options, setOptions] = useState([]);
  useEffect(() => {
    getJobTypesAll().then((res) => {
      if (res) {
        setOptions(
          renameKeysAndHandleSubTypes(res, renameMap, "subTypes", "children")
        );
      }
    });
  }, []);

  const handleLoginSuccess = () => {
    setLoginVisible(false);
    // 登录成功后页面重刷新
    window.location.reload();
  };
  const [loginVisible, setLoginVisible] = useState(false);
  const dispatch2 = useDispatch();

  const throwErrorCustomWithErrorCodeModal = async (errorCode) => {
    dispatch2(errorSlice.actions.setCode(errorCode))
  };
  const handleClickOne = () => {
    if (!job) {
      Toast.show("请填写岗位名称");
      return;
    }
    if (!work) {
      Toast.show("请选择工作年限");
      return;
    }
    const login = isLogin();



    showToast("生成中...");
    generateResume({
      positionTitle: job,
      experience: work,
    })
      .then((res) => {
        if (res) {
          navigate(`/mobile/resume/preview?id=${res}`);
        }
      })
      .catch((err) => {
        if(err.code===10201){
          throwErrorCustomWithErrorCodeModal(10201);
          return
        }
        if(err.code === 1009){
          throwErrorCustomWithErrorCodeModal(1009);
        }
        Toast.show(err.msg || err.message);
      })
      .finally(() => {
        clearToast();
      });
  };

  const handleClickItem = (item) => {
    setJob(item.name);
    setVisible(false);
  };

  const confirmJob = (item, extend) => {
    setCascaderValue(item);
    if (item.length < 3) return;
    const items = extend.items;
    setJob(items[items.length - 1].label);
    setShowText(false);
  };
  let childArr = useRef([])
  useEffect(()=>{
    childArr.current =  options.flatMap(tree => getLeafNodes(tree));
  },[options])

  const [searchItem,setSearchItem] = useState([])

//   function getLeafNodes(arr) {
//     let leafNodes = [];
    
//     for (let obj of arr) {
//       if (!obj.children || obj.children.length === 0) {
//         leafNodes.push(obj);
//       } else {
//         for (let child of obj.children) {
//           leafNodes = leafNodes.concat(getLeafNodes([child]));
//         }
//       }
//     }
  
//     return leafNodes;
//   }

  function getLeafNodes(node, path = []) {
    if (node.children && node.children.length > 0) {
        return node.children.flatMap((child, index) =>
            getLeafNodes(child, path.concat([node.label])));
    } else {
        return [{ ...node, path: path.concat([node.label]) }];
    }
    }
    const highlight = (text, search) => {
        const parts = text.split(new RegExp(`(${search})`, 'gi'));
        return <div style={{display:'inline-flex'}}>{parts.map(part => part.toLowerCase() === search.toLowerCase() ? <div className="tab-item-one-search-item-high">{part}</div> :<div> {part}</div> )}</div>;
      };
      const searchValue = useRef('')
    const onSearchValue = (v)=>{
    searchValue.current = v
    const arr = childArr.current.map((w)=>{
        if(compareStr(w.label,v)){
            return w
        }

    }).filter((z)=>z)
    if(arr.length===0){
        arr.push({
            path:['自定义','岗位名称'],
            label:v
        })
    }
    setSearchItem(arr)
    setShowSearchContent(true)
  }
  const compareStr = (str1,str2)=>{
    if (str1.toLowerCase().indexOf(str2.toLowerCase()) !== -1) {
        return true
      } else {
        return false
      }
  }
  const [showSearchContent,setShowSearchContent] = useState(false)
  const onSelect = (value,content) => {
    console.log(value,content);
    setCascaderValue(value)
    if (value?.length > 2) {
        setJob(content.items[2].label)
        setVisible(false)
        setCascaderValue([])
      setSearchBarKey(n=>n+1)

    } 
  };
  const [searchBarKey,setSearchBarKey] = useState(0)
  const clickItem =(v)=>{
    setJob(v)
    setVisible(false)
    searchValue.current=''
    setShowSearchContent(false)
    setSearchBarKey(n=>n+1)
  }

  const workLabel =
    yearsOfWorkingConfig.find((item) => item.value === work)?.label || work;

  const dropdownVisible = job && filterOptions.length > 0 && visible && false;
  return (
    <TabItemOneStyled style={style}>
      <div className="tab-import flex-center flex-col" style={{ height: 115 }}>
        <div
          className="flex-center px-14"
          style={{ width: "100%", boxSizing: "border-box" }}
        >
          <div
            className="border-right flex flex-col"
            style={{ flex: 7, position: "relative" }}
          >
            <div className="t-label mb-10">岗位名称</div>
            <input
              value={job}
              readOnly
              onFocus={() => setVisible(true)}
              onChange={(e) => setJob(e.target.value)}
              // onCompositionStart={() => lockRef.current = true}
              // onCompositionEnd={(e) => {
              //     setChJob(e.target.value)
              // }}
              placeholder="请选择岗位名称"
            />
          
            <Popup
              visible={visible}
              onMaskClick={() => {
                setVisible(false);
              }}
              showCloseButton
              onClose={() => {
                setVisible(false);
              }}
              bodyStyle={{ height: "75vh" }}
            >
                <FlexStyled $justifyContent="center" style={{fontSize: '14px', color: '#333333',height:48,lineHeight:'48px',}}> 选择岗位 </FlexStyled>
                <SearchBar key={searchBarKey} onSearch={onSearchValue} placeholder='搜索岗位' className="tab-item-one-searchbar" onClear={() => {searchValue.current='';setShowSearchContent(false)}}/>
              {showSearchContent && <FlexStyled $flexDirection='column'  style={{ padding:'0 24px',height:'66vh',overflowY:'auto' }}>{
                    searchItem.map((item)=>{
                        return <FlexStyled> <div className="tab-item-one-search-item" onClick={()=>clickItem(item?.label)} >{highlight(item?.label,searchValue.current)}<div>{item.path.filter((v)=>v).join('-')}</div></div> </FlexStyled>
                    })
                }</FlexStyled>}
              {!showSearchContent &&
              <FlexStyled style={{ width:'100%',height:'63vh',overflowY:'auto' }}>
                     <CascaderView
                options={options}
                onClose={() => {
                  setVisible(false);
                }}
                className="tab-item-one-CascaderView"
                onChange={onSelect}
                popupClassName="edit-custom-picker"
                value={cascaderValue}
                // onConfirm={confirmJob}
              ></CascaderView>
              </FlexStyled>
             }
            </Popup>

            {dropdownVisible && (
                    <div className="t-dropdown">
                {filterOptions.map((item) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => handleClickItem(item)}
                      className="flex-center justify-start t-dropdown-item"
                    >
                      <HighlightText text={item.name} highlight={job} />
                    </div>
                  );
                })}
              </div>
              
            )}

            {dropdownVisible && (
              <MaskStyled onClick={() => setVisible(false)} />
            )}
          </div>
          <div className="flex flex-col pl-12" style={{ flex: 5 }}>
            <div className="t-label mb-10">工作年限</div>
            <input
              readOnly
              value={workLabel}
              onClick={() => {
                Picker.prompt({
                  columns: [yearsOfWorkingConfig],
                }).then((value) => {
                  if (value) {
                    setWork(value[0]);
                  }
                });
              }}
              placeholder="请输入工作年限"
            />
          </div>
        </div>

        <div className="flex-center pt-18">
          <div onClick={handleClickOne} className="flex-center t-btn">
            一键生成简历
          </div>
        </div>
        <LoginModal
          visible={loginVisible}
          onSuccess={handleLoginSuccess}
          onClose={() => setLoginVisible(false)}
        />
      </div>
    </TabItemOneStyled>
  );
};

export default withComponent(TabItemOne);
