import isLogin from "@/common/isLogin";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";


const BackButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 62px;
  padding: 0 11px;
  height: 30px;
  background: #F4F6F7;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    background-color: #EBEEF0;
  }
`;
const BackButtonTextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6E727C;
  line-height: 13px;
  white-space: nowrap;
`;

const icon26 = <div style={{width: 6, height: 12, display: 'flex', alignItems: 'center', justifyContent: 'center'}} dangerouslySetInnerHTML={{
    __html: `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="6px" height="12px" viewBox="0 0 6 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>切片</title>
        <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="简历顶部优化" transform="translate(-24.000000, -19.000000)" fill="#6E727C" fill-rule="nonzero">
                <g id="文本备份-9" transform="translate(24.000000, 19.000000)">
                    <path d="M5.35730454,12 C5.19272971,12 5.02813184,11.9372923 4.90256376,11.8114613 L0.188369398,6.45581703 C-0.0627897995,6.20457062 -0.0627897995,5.79653183 0.188369398,5.54484673 L4.90256378,0.18876382 C5.15372298,-0.0629212735 5.5604714,-0.0629212735 5.8116306,0.18876382 C6.0627898,0.440010234 6.0627898,0.848049023 5.8116306,1.09973412 L1.552177,6.00010101 L5.81163059,10.9000523 C6.06278979,11.1517374 6.06278979,11.5593375 5.81163059,11.8110226 C5.68647723,11.9372923 5.52187937,12 5.35730454,12 Z" id="-"></path>
                </g>
            </g>
        </g>
    </svg>`
  }}/>

const Back = ({ setLoginVisible }) => {
    const navigate = useNavigate()
    const onClickBack = () => {
        if (!isLogin()) {
            setLoginVisible(true)
        } else {
            navigate('/workspace');
        }
    }
    return <BackButtonStyled onClick={onClickBack}>
        <div style={{marginRight: 12}}>{icon26}</div>
        <BackButtonTextStyled>工作台</BackButtonTextStyled>
    </BackButtonStyled>
}

export default Back
