import configRender from "@/common/config/configRender";
import {useStore} from "@/components/resume/redux/hooks";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";


export default () => {
    const store = useStore();
    const moduleInstance = useModuleInstance();
    const fieldInstance = useFieldInstance();
    return (config, resumeData = store.getState().resumeData) => configRender(config, {
        context: fieldInstance || moduleInstance,
        resumeData,
        template: store.getState().template,
        // layoutConfig:store.getState().template.layoutConfig,
        // moduleConfig:store.getState().template.moduleConfig,
    });
}
