import withComponent from "@/hoc/withComponent"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import TabItemImport from "./TabItemImport"
import TabItemOne from "./TabItemOne"

const TabsStyled = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    margin-bottom: 53px;
    .t-list {
        width: 318px;
        margin: 0 auto;
        margin-bottom: 16px;
        border-radius: 21px;
        background-color: #01B6A1;
    }
`

const TabItemStyled = styled.div`
    border-radius: 21px 0px 0px 21px;
    width: 159px;
    height: 42px;
    color: #FFF;
    font-size: 15px;

    ${props => props.$active && `
        background: #FFFFFF;
        color: #01B6A1;
    `}
    ${props => props.$isImport && `
        border-radius: 0 21px 21px 0px;
    `}
`

const tabConfig = [
    {
        key: 'one',
        text: '一键生成'
    },
    {
        key: 'import',
        text: '导入简历'
    },
]

const Tabs = () => {
    const [tabKey, setTabKey] = useState('one')
    const navigate = useNavigate()

    const handleClickAgreement = () => {
        navigate('/mobile/agreement')
    }

    return <TabsStyled>
        <div className="t-list flex-center">
            {
                tabConfig.map(item => {
                    const isActive = tabKey === item.key
                    return <TabItemStyled
                        key={item.key}
                        $active={isActive}
                        $isImport={item.key === 'import'}
                        className="flex-center t-item"
                        onClick={() => setTabKey(item.key)}
                    >
                        {item.text}
                    </TabItemStyled>
                })
            }
        </div>
        <TabItemImport style={{ display: tabKey === 'import' ? 'block' : 'none' }}  />
        <TabItemOne style={{ display: tabKey === 'one' ? 'block' : 'none' }}   />
    </TabsStyled>
}

export default withComponent(Tabs)