import React, {createElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import withComponent from "@/hoc/withComponent";
import {ModuleContentContext} from "@/components/resume/context";
import {Popover, Tooltip} from "antd";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";
import styled from "styled-components"
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import focusSlice from "@/components/resume/redux/services/focusSlice";
import getBlockNameBySlot from "@/common/slot/getBlockNameBySlot";
import classNames from "classnames";
import {createPortal} from "react-dom";
import FlexStyled from "@/styled/FlexStyled";
import {icon1, icon2} from "@/components/resume/hoc/withField";
import { redoIcon, undoIcon } from "@/pages/ResumeDetail/Chat/icon";
import { AiDecisionWrapStyled } from "../styled";

const ModuleContentListContainerStyled = styled.div`
  &:hover {
    background-color: rgba(188, 188, 188, 0.3);
  }
`
const MenuStyled = styled.div`
  position: relative;
`
const MenuIconStyled = styled.div`
  width: 32px;
  height: 32px;
  background: #F2F2F2;
  border: 1px solid #DDDDDD;
  margin-left: 2px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(242, 242, 242, 0.8);
    cursor: pointer;
  }
`
const DisableMenuIconStyled = styled.div`
  width: 32px;
  height: 32px;
  background: #F2F2F2;
  border: 1px solid #DDDDDD;
  margin-left: 2px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  polygon {
    fill: #D8D8D8;
  }

`
const topIcon = <div style={{width: 22, height: 22}} dangerouslySetInnerHTML={{
    __html: `<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="教育背景、工作经历、校园经历、项目经历、实习经历" transform="translate(-89.000000, -694.000000)" fill="#000000" fill-rule="nonzero">
            <g id="加协作团队备份-17" transform="translate(89.000000, 694.000000)">
                <g id="fangxiang-shang" transform="translate(5.500000, 3.000000)">
                    <polygon id="路径" points="0 5.5 1.10785714 6.60785714 4.71428571 3.00928572 4.71428571 14.7142857 6.28571429 14.7142857 6.28571429 3.00928572 9.89214286 6.61571429 11 5.5 5.5 -8.8817842e-16"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const bottomIcon = <div style={{width: 22, height: 22}} dangerouslySetInnerHTML={{
    __html: `<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="教育背景、工作经历、校园经历、项目经历、实习经历" transform="translate(-89.000000, -748.000000)" fill="#000000" fill-rule="nonzero">
            <g id="加协作团队备份-16" transform="translate(89.000000, 748.000000)">
                <g id="fangxiang-shang备份" transform="translate(11.000000, 10.857143) scale(1, -1) translate(-11.000000, -10.857143) translate(5.500000, 3.000000)">
                    <polygon id="路径" points="-6.69940293e-16 5.5 1.10785714 6.60785714 4.71428571 3.00928572 4.71428571 15.7142857 6.28571429 15.7142857 6.28571429 3.00928572 9.89214286 6.61571429 11 5.5 5.5 0"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const deleteIcon = <div style={{width: 22, height: 22}} dangerouslySetInnerHTML={{
    __html: `<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="教育背景、工作经历、校园经历、项目经历、实习经历" transform="translate(-89.000000, -802.000000)" fill="#000000">
            <g id="加协作团队备份-15" transform="translate(89.000000, 802.000000)">
                <g id="编组" transform="translate(4.500000, 3.500000)">
                    <path d="M11.5555556,15 L1.44444444,15 C1.34477778,15 1.26388889,14.9180488 1.26388889,14.8170732 L1.26388889,4.81893293 C1.26388889,4.71795732 1.34477778,4.6360061 1.44444444,4.6360061 L2.34722222,4.6360061 C2.44688889,4.6360061 2.52777778,4.71795732 2.52777778,4.81893293 L2.52777778,13.7195122 L10.4722222,13.7195122 L10.4722222,4.81893293 C10.4722222,4.71795732 10.5531111,4.6360061 10.6527778,4.6360061 L11.5555556,4.6360061 C11.6552222,4.6360061 11.7361111,4.71795732 11.7361111,4.81893293 L11.7361111,14.8170732 C11.7361111,14.9180488 11.6552222,15 11.5555556,15" id="Fill-1"></path>
                    <path d="M6.5,1.09756098 C7.14006944,1.09756098 7.66927778,1.58259146 7.75152083,2.20856707 L5.24847917,2.20856707 C5.33072222,1.58259146 5.85993056,1.09756098 6.5,1.09756098 L6.5,1.09756098 Z M8.84063194,2.20856707 C8.75450694,0.976189024 7.73797917,8.8817842e-16 6.5,8.8817842e-16 C5.26202083,8.8817842e-16 4.24549306,0.976189024 4.15936806,2.20856707 L0.180555556,2.20856707 C0.0808888889,2.20856707 0,2.29051829 0,2.3914939 L0,3.30612805 C0,3.40719512 0.0808888889,3.48905488 0.180555556,3.48905488 L12.8194444,3.48905488 C12.9191111,3.48905488 13,3.40719512 13,3.30612805 L13,2.3914939 C13,2.29051829 12.9191111,2.20856707 12.8194444,2.20856707 L8.84063194,2.20856707 Z" id="Fill-3"></path>
                    <path d="M8.62152778,11.9817073 L7.71875,11.9817073 C7.61908333,11.9817073 7.53819444,11.8997561 7.53819444,11.7987805 L7.53819444,5.94512195 C7.53819444,5.84414634 7.61908333,5.76219512 7.71875,5.76219512 L8.62152778,5.76219512 C8.72119444,5.76219512 8.80208333,5.84414634 8.80208333,5.94512195 L8.80208333,11.7987805 C8.80208333,11.8997561 8.72119444,11.9817073 8.62152778,11.9817073" id="Fill-5"></path>
                    <path d="M5.28125,11.9817073 L4.37847222,11.9817073 C4.27880556,11.9817073 4.19791667,11.8997561 4.19791667,11.7987805 L4.19791667,5.94512195 C4.19791667,5.84414634 4.27880556,5.76219512 4.37847222,5.76219512 L5.28125,5.76219512 C5.38091667,5.76219512 5.46180556,5.84414634 5.46180556,5.94512195 L5.46180556,11.7987805 C5.46180556,11.8997561 5.38091667,11.9817073 5.28125,11.9817073" id="Fill-7"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const MaskStyled = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    z-index: 1;
`
const Menu = (props) => {
    const {index, onMouseEnter, onMouseLeave, getContainer} = props;
    const right = -14;
    const moduleInstance = useModuleInstance();
    const width = useMemo(() => {
        let v = 40
        if (!getContainer() || !moduleInstance.container) {
            return v
        }
        const position = moduleInstance.container.getBoundingClientRect();
        const position2 = getContainer().getBoundingClientRect();
        if (position.left < position2.left) {
            v += (position2.left - position.left) + 6
        }
        return v
    }, [moduleInstance.container, getContainer()])
    const length = useSelector((state) => state.resumeData[moduleInstance.for_block_name]?.data?.content?.length);
    const preDisable = index === 0;
    const nextDisable = index === length - 1;
    const PreComponent = preDisable ? DisableMenuIconStyled : MenuIconStyled;
    const NextComponent = nextDisable ? DisableMenuIconStyled : MenuIconStyled;
    return <MenuStyled onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{right, width}}>
        <Tooltip title={"上移"} placement={"bottom"} overlayClassName="tooltipPointerEventsNone">
            <PreComponent onClick={preDisable ? undefined : props.onClickPre}>
                {topIcon}
            </PreComponent>
        </Tooltip>
        <Tooltip title={"下移"} placement={"bottom"} overlayClassName="tooltipPointerEventsNone">
            <NextComponent onClick={nextDisable ? undefined : props.onClickNext}>
                {bottomIcon}
            </NextComponent>
        </Tooltip>
        <Tooltip title={"删除"} placement={"bottom"} overlayClassName="tooltipPointerEventsNone">
            <MenuIconStyled onClick={props.onClickDelete}>
                {deleteIcon}
            </MenuIconStyled>
        </Tooltip>
    </MenuStyled>
}


export default withComponent((props) => {
    const {tagName, style, className, index, children} = props;
    const ref = useRef();
    const configRender = useConfigRender();
    const resumeInstance = useResumeInstance();
    const moduleInstance = useModuleInstance();
    const [hover, setHover] = useState(false);
    const isFocus = useSelector((state) => state.focus?.moduleId === moduleInstance.data?.id && state.focus?.moduleContentIndex === index);
    const dispatch = useDispatch();
    const instance = useRef({
        index: props.index
    }).current;
    const [open, setOpen] = useState(false);
    const isAiFocus = useSelector(state => state.focus.filedId === index && state.focus?.moduleId === moduleInstance.data?.id)
    const onClickPre = useCallback(() => {
        dispatch(resumeDataSlice.actions.moveModuleContent({
            block_name: moduleInstance.for_block_name,
            currentIndex: index,
            moveIndex: index - 1
        }));
        setOpen(false)
    }, [index]);
    const onClickNext = useCallback(() => {
        dispatch(resumeDataSlice.actions.moveModuleContent({
            block_name: moduleInstance.for_block_name,
            currentIndex: index,
            moveIndex: index + 1
        }));
        setOpen(false)
    }, []);
    const onClickDelete = useCallback(() => {
        dispatch(resumeDataSlice.actions.deleteModuleContent({
            block_name: moduleInstance.for_block_name,
            index: index,
        }));
        setOpen(false)
    }, []);
    const onFocusIn = useCallback(() => {
        dispatch(focusSlice.actions.setModuleContentId(index));
    }, [index]);
    const onFocusOut = useCallback(() => {
        dispatch(focusSlice.actions.setModuleContentId(null));
    }, [index])
    const onMouseEnter = useCallback(() => {
        setHover(true)
    }, [])
    const onMouseLeave = useCallback(() => {
        setHover(false)
    }, []);


    const handler = (state) => {
        if (state.aiDecision) {
            return state.aiDecision.find((item) => {
                let for_block_name = moduleInstance?.for_block_name ? moduleInstance?.for_block_name : getBlockNameBySlot(item.slotName);
                return (item.blockName === for_block_name) && (index === Number(item.moduleContentIndex)) && item.status === undefined;
            })
        }
    }
    const currentAiDecision = useSelector(handler);
    const [position, setPosition] = useState()
    const onClickS = () => {
        resumeInstance.aiDecision.onClickSuccess(currentAiDecision);
        setPosition(undefined);
    }
    const onClickC = () => {
        resumeInstance.aiDecision.onClickCancel(currentAiDecision);
        setPosition(undefined);
    }
    const onClickMask = () => {
        dispatch(focusSlice.actions.setAiDecisionFiledId(index))
        if (moduleInstance?.data?.id) {
            dispatch(focusSlice.actions.setModuleId(moduleInstance.data.id))
        }
    }
    useEffect(() => {
        setTimeout(() => {
            if (!currentAiDecision) return;
            try {
                if (ref.current) {
                    const rect = ref.current.getBoundingClientRect();
                    const rootContainerRect = resumeInstance.rootContainer.getBoundingClientRect();
                    const position = {
                        x: (rect.x - rootContainerRect.x) + rect.width - 102,
                        y: (rect.y - rootContainerRect.y) + rect.height,
                    }
                    setPosition(position)
                }
            } catch (e) {
            }
        }, 1000)
    }, [currentAiDecision]);
    return <ModuleContentContext.Provider value={instance}>
        <div style={{ position: 'relative' }}>
            <Popover content={<Menu index={props.index}
                                    getContainer={() => ref.current}
                                    onClickPre={onClickPre}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    onClickNext={onClickNext}
                                    onClickDelete={onClickDelete}/>}
                     overlayClassName="ant-popover-custom"
                     autoAdjustOverflow={false}
                     trigger="hover"
                     open={open || isFocus}
                     onOpenChange={setOpen}
                     transitionName=""
                     getPopupContainer={resumeInstance.getModuleContentToolContainer ? resumeInstance.getModuleContentToolContainer : null}
                     placement="leftTop"
            >
                {
                    resumeInstance.rootContainer && position && currentAiDecision && <MaskStyled className="ai-decision-mask" onClick={onClickMask} />
                }
                {
                    createElement(tagName, {
                        ref: ref,
                        onFocus: onFocusIn,
                        onBlur: onFocusOut,
                        onMouseEnter,
                        onMouseLeave,
                        style,
                        className: classNames(
                            className, 
                            currentAiDecision ? 'resume-field-highlight' : undefined, 
                            'module-content-list-container',
                            isFocus?'module-content-list-container-focus':undefined,
                            !isAiFocus ? 'module-content-list-container-ai-blur' : undefined
                        ),
                    }, children?.map?.((config) => configRender(config)))
                }
            </Popover>
            {resumeInstance.rootContainer && position && currentAiDecision && isAiFocus && createPortal(<>
                <FlexStyled $justifyContent="flex-end" style={{
                    position: "absolute", top: position.y, left: position.x, zIndex: 10,
                }}>
                    <AiDecisionWrapStyled className="ai-decision-action">
                        <Tooltip placement="bottom" title={"撤销"} >
                        <FlexStyled style={{marginRight: 8, color: '#777777', fontSize: 12, width: 45, cursor: 'pointer' }} onClick={onClickC}>
                            <FlexStyled $justifyContent="center" $alignItems="center"  style={{ marginRight: 4}}>{undoIcon}</FlexStyled>
                            撤销
                        </FlexStyled>
                        </Tooltip>
                        <Tooltip placement="bottom" title={"保留"} >
                            <FlexStyled style={{color: '#11BBA6', fontSize: 12, width: 45, cursor: 'pointer' }} onClick={onClickS}>
                                <FlexStyled $justifyContent="center" $alignItems="center" style={{ marginRight: 4}}>{redoIcon}</FlexStyled>
                                保留
                            </FlexStyled>
                        </Tooltip>
                    </AiDecisionWrapStyled>
                </FlexStyled>
            </>, resumeInstance.rootContainer)}
        </div>
    </ModuleContentContext.Provider>
})
