import { SearchBar } from "antd-mobile";
import { LeftOutline } from "antd-mobile-icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import { setConfigParams } from '../TemplateList/indexSlice';
import "./index.scss";
import classNames from "classnames";
import { getJobSearchDescription } from "@/http/api";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import useQueryParam from "@/hooks/useQueryParam";

const FilterItem = ({ title, content, onClick }) => {
  // let str = content.replace(/\n/g, "<br>")
  let str = content;
  return (
    <div className="choose-job-box" onClick={onClick}>
      <div className="choose-job-box-title">{title}</div>
      <div className="choose-job-box-content">{str}</div>
    </div>
  );
};

const useArr = [
  {
    id: 8090,
    delFlag: null,
    jobTitle: "教育培训行业IT培训UI设计培训讲师",
    description:
      "\n    1. 负责UI设计相关课程的研发、优化和授课工作。\n    2. 根据市场需求和学员反馈，进行课程内容的更新和调整。\n    3. 设计和实施有效的培训计划，提高学员的UI设计技能和创意能力。\n    4. 结合行业发展趋势，引入最新的UI设计技术和工具，确保培训内容与时俱进。\n    5. 跟踪学员学习进度，提供学习指导和建议，确保学员的学习效果。\n    6. 参与市场推广活动，宣传培训项目和成果。\n    7. 完成公司交办的其他与教育培训相关的工作。\n  ",
    requirement:
      "\n    1. 本科及以上学历，计算机、设计或相关专业优先。\n    2. 至少3年以上UI设计或教育培训行业从业经验。\n    3. 熟练掌握UI设计原则、设计流程和工具，如Sketch、Adobe XD等。\n    4. 具备良好的教学能力和课堂组织能力，能够激发学员的学习兴趣和动力。\n    5. 具备较强的沟通能力和团队协作精神，能够与公司内部和外部进行有效的沟通和协作。\n    6. 对教育培训行业有热情，具备较强的学习能力和适应能力，能够适应行业变化和发展。\n    7. 具备良好的英文读写能力，能够阅读和理解相关的英文技术文档。\n  ",
    createTime: "2024-07-26T09:02:48.000+0000",
    updateTime: "2024-07-26T09:02:48.000+0000",
    source: "ERNIE-Speed",
    corporatename: null,
    corporateicon: null,
    jobTypeId: null,
    mark1: null,
    mark2: null,
    mark3:
      "该职位主要负责教育培训行业IT领域的UI设计培训工作，为学员提供专业的UI设计知识和技能，助力学员在UI设计领域取得优异表现。",
    formattedDescription: null,
  },
  {
    id: 8115,
    delFlag: null,
    jobTitle: "UI设计师（视觉/交互设计方向）",
    description:
      "\n        1. 负责产品的视觉设计和界面风格设计，包括网站、移动应用等。\n        2. 根据产品需求和用户体验需求，进行交互设计，优化用户体验。\n        3. 与产品经理、开发人员等团队成员紧密合作，确保设计实现并达到产品目标。\n        4. 参与设计评审，对设计质量和用户体验负责。\n        5. 关注行业趋势，不断提升设计技能和创新能力。\n        6. 工作地点：公司设计部门所在地。",
    requirement:
      "\n        1. 拥有视觉设计、交互设计或相关领域的本科及以上学历。\n        2. 至少2年以上UI设计工作经验，有成功作品集。\n        3. 熟练掌握视觉设计、交互设计相关工具，如Sketch、Adobe XD、Figma等。\n        4. 具备良好的用户研究能力，能够从用户角度思考设计问题。\n        5. 具备良好的沟通和协作能力，能够跨团队协同工作。\n        6. 对设计行业有热情，关注行业趋势，具备快速学习和适应新技能的能力。\n        7. 具备良好的英语阅读和沟通能力（如需要与国际团队合作）。\n        8. 具有良好的时间管理和项目管理能力。",
    createTime: "2024-07-26T09:03:34.000+0000",
    updateTime: "2024-07-26T09:03:34.000+0000",
    source: "ERNIE-Speed",
    corporatename: null,
    corporateicon: null,
    jobTypeId: null,
    mark1: null,
    mark2: null,
    mark3:
      "我们寻找一位富有创意和热情的UI设计师，能够深入理解用户需求并设计出吸引人的视觉和交互体验。这位设计师将参与我们的设计团队，共同为公司产品打造卓越的视觉和交互设计。",
    formattedDescription: null,
  },
];
const ChooseJob = ({ onSearch, searchWord }) => {
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const [data, setData] = useState([
    "测试",
    "Java开发工程师",
    "产品经理",

    "销售代表",
    "广告传媒",
  ]);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setSearchValue(state?.searchValue);
  }, []);
  const [isFocus, setIsFocus] = useState(false);
  const [arr, setArr] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(state?.searchValue || "");
  }, []);
  const [loading,setLoading] = useState(true)
  const fetchData = (value) => {
    setLoading(true)
    getJobSearchDescription({ query: { jobTitle: value || "" } }).then(
      (data) => {
        setLoading(false)
        setArr(
          data.map((v) => {
            const { jobTitle, description, requirement } = v;
            return {
              name: jobTitle,
              content: description + `\n ` + requirement,
              ...v,
            };
          })
        );
      }
    );

    // setArr(useArr.map((v)=>{
    //     const {jobTitle,description,requirement} = v
    // }))
  };

  const handleSearch = (value) => {
    // if(value.length === 0){
    //   return
    // }
    fetchData(value);

    // dispatch(setConfigParams({ type: 'searchWord', value }))
    focusFunc(false);
  };

  const [recommendJob, setRecommendJob] = useState("");

  const handleBack = () => {
    if (isFocus) {
      setIsFocus(false);
      return;
    }
    navigate("/mobile/interview",
      // {state:{chooseKey:state.chooseKey}}
    );
  };

  const handleClickTag = (item) => {
    setSearchValue(item);
    searchRef.current?.focus();
  };

  const handleClickItem = (item) => {
    navigate("/mobile/interview/chooseJobDes", {
      state: {
        ...item,
        searchValue: searchValue,
        chooseKey:state?.chooseKey
      },
    });
  };

  const focusFunc = (bool) => {
    setIsFocus(bool);
  };

  const renderDom = isFocus ? (
    <div className="content search-content">
      <div className="title">热门搜索</div>
      <div className="jz-tag-list">
        {data.map((item) => {
          const cls = classNames({
            tag: true,
            active: item === searchValue,
          });
          return (
            <div
              key={item}
              className={cls}
              onClick={() => handleClickTag(item)}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="content">
      <div className="jz-tag-list">
        {!loading&&arr?.length === 0 && (
          <div style={{ width: "100%" }}>
            {" "}
            <img
              width={"120px"}
              height={"120px"}
              style={{
                marginTop: "120px",
                marginLeft: "calc(50% - 60px)",
              }}
              src="https://pub.jianzeppt.cn/image/mobile/%E6%9A%82%E6%97%A0%E6%95%B0%E6%8D%AE.png"
            ></img>
            <div
              style={{
                fontFamily: "PingFangSC, PingFang SC",
                fontWeight: 400,
                fontSize: "13px",
                color: "#999999",
                textAlign:'center'
              }}
            >
              {" "}
              暂无搜索内容
            </div>
          </div>
        )}
        {arr?.length !== 0 &&
          arr.map((item) => {
            return (
              <FilterItem
                onClick={() => handleClickItem(item)}
                title={item.name}
                content={item.content}
              ></FilterItem>
            );
          })}
      </div>
    </div>
  );
  return (
    <div className="page-chooseJob">
      <div className="search-header">
        <LeftOutline className="icon" onClick={handleBack} />
        <SearchBar
          onFocus={() => focusFunc(true)}
          placeholder="请输入岗位"
          ref={searchRef}
          value={searchValue}
          onClear={() => setIsFocus(true)}
          onChange={(v) => setSearchValue(v)}
          className="input"
          onSearch={handleSearch}
        />
      </div>
      {renderDom}
    </div>
  );
};

export default ChooseJob;
