import moment from "moment"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import recorder from "./recorder"
import { jobStt, updateAnswerQuestion } from "@/http/api"
import wsClient from "../utils/wsClient"
import useQueryParam from "@/hooks/useQueryParam"
import { message } from "antd"


const VoiceWrapStyled = styled.div`
    .voice-btn {
        width: 242px;
        height: 36px;
        background: #11BBA6;
        border-radius: 6px;

        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin: 0 auto;
        margin-top: 16px;
    }
    .voice-btn:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`

const VoiceComponentStyled = styled.div`
    position: relative;
    padding: 14px;
    height: 282px;


    border-radius: 8px;
    border: 1px solid #EAEAEB;
    .voice-loading {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #D3D3D3;
    }
    .voice-timer {
        display: flex;
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #11BBA6;
    }
`

const icon1 = <div className="flex-center" style={{ marginRight: 8 }} dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="语音回答" transform="translate(-452.000000, -705.000000)" fill="#11BBA6" fill-rule="nonzero">
            <g id="编组-3" transform="translate(164.000000, 243.000000)">
                <g id="编组-9" transform="translate(288.000000, 460.000000)">
                    <g id="结束录音" transform="translate(0.000000, 2.000000)">
                        <path d="M8.00106064,9.5789532 C9.04632235,9.5789532 9.88949044,8.7294766 9.88949044,7.68421491 L9.89581278,3.8947383 C9.89581278,2.8463085 9.04949043,2 8.00107449,2 C6.95581278,2 6.10633619,2.84632235 6.10633619,3.8947383 L6.10633619,7.68421491 C6.10633619,8.72947661 6.95579894,9.5789532 8.00106064,9.5789532 Z M11.3484298,7.68420106 C11.3484298,9.57893936 9.74736915,10.9052617 8.00106064,10.9052617 C6.25790639,10.9052617 4.65369149,9.57893936 4.65369149,7.68420106 L3.58,7.68420106 C3.58,9.84104682 5.29789255,11.6221075 7.36947661,11.928416 L7.36947661,14 L8.63263086,14 L8.63263086,11.9284298 C10.7042149,11.6221075 12.4221075,9.84106065 12.4221075,7.68420106 L11.3484298,7.68420106 L11.3484298,7.68420106 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const icon2 = <div className="flex-center" style={{ marginRight: 8 }} dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="语音回答" transform="translate(-445.000000, -758.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-3" transform="translate(164.000000, 243.000000)">
                <g id="结束录音" transform="translate(281.000000, 515.000000)">
                    <path d="M10.9999999,1.83333333 C14.2749345,1.83333333 17.3010989,3.58049006 18.9385662,6.41666659 C20.5760335,9.25284313 20.5760335,12.7471567 18.9385662,15.5833333 C17.3010989,18.4195098 14.2749345,20.1666665 10.9999999,20.1666665 C5.93738976,20.1666665 1.83333333,16.0626101 1.83333333,10.9999999 C1.83333333,5.93738976 5.93738976,1.83333333 10.9999999,1.83333333 Z M10.9999999,3.66666665 C8.38005227,3.66666665 5.95912077,5.06439203 4.64914693,7.33333326 C3.33917308,9.60227449 3.33917308,12.3977254 4.64914693,14.6666667 C5.95912077,16.9356078 8.38005227,18.3333332 10.9999999,18.3333332 C15.050088,18.3333332 18.3333331,15.050088 18.3333331,10.9999999 C18.3333331,6.94991184 15.050088,3.66666665 10.9999999,3.66666665 L10.9999999,3.66666665 Z M8.24999995,7.9444444 L13.7499999,7.9444444 C13.9187536,7.9444444 14.0555555,8.08124628 14.0555555,8.24999995 L14.0555555,13.7499999 C14.0555555,13.9187536 13.9187536,14.0555555 13.7499999,14.0555555 L8.24999995,14.0555555 C8.08124628,14.0555555 7.9444444,13.9187536 7.9444444,13.7499999 L7.9444444,8.24999995 C7.9444444,8.08124628 8.08124628,7.9444444 8.24999995,7.9444444 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

  
const VoiceComponent = ({ onChange, answerId, questionId }) => {
    const [timer, setTimer] = useState(0);
    const ref = useRef(null)
    const [fullContent, setFullContent] = useState('')
    const [textContent, setTextContent] = useState('')
    const [loading, setLoading] = useState(false)
    const resumeId = useQueryParam('id')

    useEffect(() => {
        // 创建定时器
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer >= 120) { // 检查是否超过2分钟
                    clearInterval(ref.current); // 停止录制和计时
                    return prevTimer; // 返回当前时间，不再增加
                }
                return prevTimer + 1;
            });
        }, 1000);

        ref.current = intervalId
        // 组件卸载时清除定时器
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        // 定时发送数据
        const intervalId = setInterval(() => {
            const data = recorder.getNextData()
            for (let i = 0; i < data.length; i++) {
                wsClient.send(data[i])
            }
        }, 500)
        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        wsClient.onMessage = data => {
            const { status, text } = JSON.parse(data)
            if (status === 'finish') {
                setFullContent((v) => v + text)
                setTextContent('')
            } else if (status === 'pending') {
                setTextContent(text)
            }
        }
    }, [])

    const stopRecordingAndTimer = () => {
        clearInterval(ref.current)
        wsClient.close()
        recorder.stop()
    }

    const handleClick = async () => {
        if (loading) return 
        stopRecordingAndTimer()

        message.loading({
            content: '提交中...',
            duration: 0
        })
        setLoading(true)

        const answer = fullContent + textContent
        const formData = new FormData()
        formData.append('type', 'text')
        formData.append('questionId', questionId)
        formData.append('resumeId', parseInt(resumeId))
        formData.append('answerId', answerId)
        formData.append('answer', answer)
        // 提交一下数据
        updateAnswerQuestion({
            body: {
                type: 'text',
                questionId,
                resumeId: parseInt(resumeId),
                // 新逻辑不传
                // answerId,
                answer
            }
        }).then(res => {
            onChange && onChange()
        }).catch(err => {

        }).finally(() => {
            message.destroy()
            setLoading(false)
        })
        
        // jobStt({
        //     body: formData
        // }).then(res => {
        //     console.log('res', res)
        //     onChange && onChange(res)
        // })
    }

    const formattedTime = moment().startOf('day').seconds(timer).format('mm:ss');
    return <VoiceWrapStyled>
        <VoiceComponentStyled>
            <div className="voice-loading">正在识别您的内容...</div>
            <p>{fullContent}{textContent}</p>
            <div className="voice-timer">
                {icon1}  {formattedTime} / 2:00
            </div>
        </VoiceComponentStyled>
        <div className="voice-btn-wrap">
            <div onClick={handleClick} className="voice-btn flex-center">{icon2} 结束并提交</div>
        </div>
    </VoiceWrapStyled>
}

export default VoiceComponent