import { useEffect, useMemo, useState } from "react"
import { getResumeDataById, saveResumePageUserData } from "@/http/api"
// import { setData, setResumeData } from "../ResumeEdit/editSlice"
import { useDispatch, useSelector } from "react-redux"
import { setData } from "../pages/ResumeEdit/editSlice";
import { Module } from "@/common/module/module";
import { CUSTOM_MODULE_SLOT_ENUM, EDUCATIONAL_BACKGROUND_SLOT_ENUM, HONORS_AND_AWARDS_SLOT_ENUM, INTEREST_SLOT_ENUM, INTERNSHIP_EXPERIENCE_SLOT_ENUM, JOB_INTEREST_SLOT_ENUM, LANGUAGE_PROFICIENCY_SLOT_ENUM, PROJECT_EXPERIENCE_SLOT_ENUM, SELF_EVALUATION_SLOT_ENUM, SKILLS_SLOT_ENUM, WORK_EXPERIENCE_SLOT_ENUM } from "@/common/slot/slot";
import { CUSTOM_MODULE_FIELD_ENUM, EDUCATIONAL_BACKGROUND_FIELD_ENUM, HONORS_AND_AWARDS_FIELD_ENUM, INTEREST_FIELD_ENUM, INTERNSHIP_EXPERIENCE_FIELD_ENUM, JOB_INTEREST_FIELD_ENUM, LANGUAGE_PROFICIENCY_FIELD_ENUM, PROJECT_EXPERIENCE_FIELD_ENUM, SELF_EVALUATION_FIELD_ENUM, SKILLS_FIELD_ENUM, WORK_EXPERIENCE_FIELD_ENUM } from "@/common/field/field";
import { Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";


const mapModuleNameToTitle = {
    [Module.JOB_INTEREST]: {
        slot: JOB_INTEREST_SLOT_ENUM.TITLE_TEXT,
        field: JOB_INTEREST_FIELD_ENUM.TITLE
    },
    [Module.EDUCATIONAL_BACKGROUND]: {
        slot: EDUCATIONAL_BACKGROUND_SLOT_ENUM.TITLE_TEXT,
        field: EDUCATIONAL_BACKGROUND_FIELD_ENUM.TITLE
    },
    [Module.WORK_EXPERIENCE]: {
        slot: WORK_EXPERIENCE_SLOT_ENUM.TITLE_TEXT,
        field: WORK_EXPERIENCE_FIELD_ENUM.TITLE
    },
    [Module.PROJECT_EXPERIENCE]: {
        slot: PROJECT_EXPERIENCE_SLOT_ENUM.TITLE_TEXT,
        field: PROJECT_EXPERIENCE_FIELD_ENUM.TITLE
    },
    [Module.INTERNSHIP_EXPERIENCE]: {
        slot: INTERNSHIP_EXPERIENCE_SLOT_ENUM.TITLE_TEXT,
        field: INTERNSHIP_EXPERIENCE_FIELD_ENUM.TITLE
    },
    [Module.SKILLS]: {
        slot: SKILLS_SLOT_ENUM.TITLE_TEXT,
        field: SKILLS_FIELD_ENUM.TITLE
    },
    [Module.SELF_EVALUATION]: {
        slot: SELF_EVALUATION_SLOT_ENUM.TITLE_TEXT,
        field: SELF_EVALUATION_FIELD_ENUM.TITLE
    },
    [Module.INTEREST]: {
        slot: INTEREST_SLOT_ENUM.TITLE_TEXT,
        field: INTEREST_FIELD_ENUM.TITLE
    },
    [Module.LANGUAGE_PROFICIENCY]: {
        slot: LANGUAGE_PROFICIENCY_SLOT_ENUM.TITLE_TEXT,
        field: LANGUAGE_PROFICIENCY_FIELD_ENUM.TITLE
    },
    [Module.HONORS_AND_AWARDS]: {
        slot: HONORS_AND_AWARDS_SLOT_ENUM.TITLE_TEXT,
        field: HONORS_AND_AWARDS_FIELD_ENUM.TITLE
    },
    [Module.CUSTOM_MODULE]: {
        slot: CUSTOM_MODULE_SLOT_ENUM.TITLE_TEXT,
        field: CUSTOM_MODULE_FIELD_ENUM.TITLE
    },
}

export const getTitleVisible = (resumeData, moduleName) => {
    const title = mapModuleNameToTitle[moduleName]
    if (resumeData?.[moduleName]?.[`${title?.field}Visible`] === false) return false
    return true
}



const useModuleTitle = ({ moduleName, customId }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const data = useSelector(state => state.mobileEdit.data)
    const slotName = mapModuleNameToTitle[moduleName]?.slot
    const fieldName = mapModuleNameToTitle[moduleName]?.field
    const titleValue = useMemo(() => {
        const key = slotName
        const value = data?.resumeData?.[customId || moduleName]?.data?.[key]
        return value
    }, [data.resumeData, slotName, moduleName, customId])

    const titleVisible = useMemo(() => {
        const key = fieldName
        const name = `${key}Visible`
        const visible = data.resumeData?.[customId || moduleName]?.[name]
        if (visible === false) return false
        return true
    }, [data, fieldName, moduleName, customId])

    const save = async () => {
        // 保存
        const pageId = data?.resume?.pageId
        const resumeData = data?.resumeData
        if (pageId && resumeData) {
            try {
                // Toast.show({
                //     icon: 'loading',
                //     content: '保存中…',
                //     duration: 0,
                // })
                await saveResumePageUserData({ query: { pageId }, body: resumeData });    
                Toast.show({
                    icon: 'success',
                    content: '保存成功',
                })
                navigate(-1)
            } catch (error) {
                Toast.clear()
            }
        }
    }

    const onChange = (value, visible) => {
        const title = mapModuleNameToTitle[moduleName]
        if (!title) return 
        const moduleKey = customId || moduleName

        const moduleData = {
            ...data.resumeData[moduleKey],
            data: {
                ...data.resumeData[moduleKey].data,
                [title.slot]: value
            },
            [`${title.field}Visible`]: visible
        }
        const newData = {
            ...data,
            resumeData: {
                ...data.resumeData,
                [moduleKey]: moduleData
            }
        }
        dispatch(setData(newData))
    }
    return {
        titleValue,
        titleVisible,
        onChange,
        save
    }
}

export default useModuleTitle