import styled, { keyframes, createGlobalStyle } from "styled-components";
import { Input, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import {
  getJobDescription,
  generateQuestions,
  listResumeUser,
  getInterviewQuestionListTemplate,
  updateJobDescription,
  addJobDescription 
} from "@/http/api";
import useQueryParam from "@/hooks/useQueryParam";
import useSelector from "@/components/resume/redux/hooks/useSelector";
import useDispatch from "@/components/resume/redux/hooks/useDispatch";
import interviewSlice from "@/components/resume/redux/services/interviewSlice";
import resumeSlice from "@/components/resume/redux/services/resumeSlice";
import JdModal from "../TargetPosition/JdModal";
import Interview from "../Interview";
import { processInterviewData } from "../Interview";
import EmptyResumeModal from "../EmptyResumeModal";
import { useNavigate } from "react-router-dom";
import { isSVIP } from "@/common/isVip";
import InfoModal from "../Interview/components/InfoModal";
import PayModal from "@/components/PayModal";

const GlobalStyle = createGlobalStyle`
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: rgba(17,187,166,0.05)!important;
    color: #11BBA6 !important;
  }
`;

const TransitInterViewStyled = styled.div`
  width: 820px;
  background: #ffffff;
  border-radius: 8px;
  height: 100%;

  padding: 18px 24px;
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      font-size: 14px;
      color: #4c4c4c;
      font-weight: 500;
      line-height: 1;
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 8px 7px 12px;
      background: #f4f4f6;
      border-radius: 4px;
      font-size: 12px;
      color: #333333;
      cursor: pointer;
    }
  }

  .content {
    margin: 22px auto 16px;
    position: relative;
    .textarea {
      width: 772px;
      height: 288px !important;
      border: none;
      background: #fafafa;
    }

    .tips {
      position: absolute;
      left: 16px;
      top: 16px;
      color: #c5c5c5;
      pointer-events: none;
      p {
        margin: 0;
        line-height: 17px;
      }
    }

    .block {
      position: absolute;
      left: 145px;
      top: 16px;
      width: 80px;
      height: 16px;
      cursor: pointer;
    }

    .ti-select {
      width: 100%;
    }
  }

  .btn {
    width: 351px;
    height: 44px;

    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: #11bba6;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: rgba(17, 187, 166, 0.9);
    }
  }
`;
const rotate = keyframes`
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }`;
const RotateAnimationSvg = styled.div`
  animation: ${rotate} 2s linear infinite;
`;
const EmptyResumeListStyled = styled.div`
  width: 820px;
  height: 100%;
  background: url("https://oss.jianzeppt.com/asd1.png");
  background-size: cover;
`;

const TransitInterViewContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const icon1 = (
  <RotateAnimationSvg
    className="icon1"
    style={{ height: 26, width: 26 }}
    dangerouslySetInnerHTML={{
      __html: `<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="面试问题页面流程优化" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="练面试-默认展示当前jd" transform="translate(-629.000000, -634.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-2" transform="translate(545.000000, 625.000000)">
                <g id="模块管理备份-2" transform="translate(84.000000, 9.000000)">
                    <g id="loading-2" transform="translate(13.000000, 13.000000) rotate(45.000000) translate(-13.000000, -13.000000) translate(6.500000, 6.500000)">
                        <path d="M0,2.6 C0,4.03594035 1.16405965,5.2 2.6,5.2 C4.03594035,5.2 5.2,4.03594035 5.2,2.6 C5.2,1.16405965 4.03594035,0 2.6,0 C1.16405965,0 0,1.16405965 0,2.6 Z" id="路径" fill-opacity="0.8"></path>
                        <path d="M7.8,2.6 C7.8,3.52889055 8.29555718,4.38722082 9.09999998,4.8516661 C9.90444279,5.31611137 10.8955572,5.31611137 11.7,4.8516661 C12.5044428,4.38722082 13,3.52889055 13,2.6 C13,1.16405965 11.8359403,0 10.4,0 C8.96405965,0 7.8,1.16405965 7.8,2.6 Z" id="路径" fill-opacity="0.6"></path>
                        <path d="M7.8,10.4 C7.8,11.3288905 8.29555718,12.1872208 9.09999998,12.6516661 C9.90444279,13.1161114 10.8955572,13.1161114 11.7,12.6516661 C12.5044428,12.1872208 13,11.3288905 13,10.4 C13,8.96405965 11.8359403,7.8 10.4,7.8 C8.96405965,7.8 7.8,8.96405965 7.8,10.4 Z" id="路径" fill-opacity="0.3"></path>
                        <path d="M0,10.4 C0,11.8359403 1.16405965,13 2.6,13 C4.03594035,13 5.2,11.8359403 5.2,10.4 C5.2,8.96405965 4.03594035,7.8 2.6,7.8 C1.16405965,7.8 0,8.96405965 0,10.4 Z" id="路径" fill-opacity="0.97"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`,
    }}
  />
);

export const icon2 = (
  <div
    style={{ height: 12, width: 12 }}
    dangerouslySetInnerHTML={{
      __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="面试问题页面流程优化" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="练面试-默认展示当前jd" transform="translate(-1086.000000, -91.000000)" fill="#333333" fill-rule="nonzero">
            <g id="编组-5备份" transform="translate(996.000000, 84.000000)">
                <g id="超级简历" transform="translate(96.000000, 13.000000) rotate(-360.000000) translate(-96.000000, -13.000000) translate(90.000000, 7.000000)">
                    <path d="M7.7057857,1.39376887 C7.9173156,1.17934942 8.26037555,1.17010826 8.48314213,1.37282887 C8.70590872,1.57554948 8.72896206,1.91795869 8.53537983,2.14871077 L8.50282473,2.18463366 L4.68826333,6.02922433 L8.49945696,9.81010802 C8.70613357,10.0151516 8.72173196,10.3441804 8.53537983,10.5678564 L8.50282473,10.6040599 C8.29770637,10.8106526 7.96868119,10.8261289 7.74507636,10.6397021 L7.70887283,10.607147 L3.4991597,6.4311116 C3.29256705,6.22599325 3.27709067,5.89696807 3.46351745,5.67336324 L3.49607257,5.63715971 L7.7057857,1.39376887 L7.7057857,1.39376887 Z" id="路径" transform="translate(6.000000, 5.998276) scale(-1, 1) translate(-6.000000, -5.998276) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
    }}
  />
);

export const icon3 = (
  <div
    style={{ height: 24, width: 18 }}
    dangerouslySetInnerHTML={{
      __html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="改简历、练面试、接offer-移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="展开更多简历状态" transform="translate(-228.000000, -668.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-3" transform="translate(12.000000, 658.000000)">
                <g id="超级简历" transform="translate(216.000000, 10.000000)">
                    <polygon id="路径" points="13.7145959 15.7695949 14.971667 17.026666 20 11.998333 14.971667 6.97 13.7145473 8.22709541 16.5969622 11.1094374 4 11.1094374 4 12.8872286 16.5969622 12.8872286"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
    }}
  />
);

const formatTime = (dateStr) => {
  let date = new Date(dateStr);
  let year = date.getUTCFullYear(); // 获取年份
  let month = date.getUTCMonth() + 1; // 获取月份
  let day = date.getUTCDate(); // 获取日期
  // 如果月份或日期小于10，前面加0
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  return `${year}${month}${day}`; // 拼接年月日
};

const processDesc = (desc = "") => {
  if (!desc) return "";
  // 使用换行符分割文本
  const lines = desc.split("\n");
  // 第一行是职位名称
  const firstLine = lines[0];
  // 剩余的数据，从第二行开始
  const remainingData = lines.slice(1).join("\n");

  return {
    jobTitle: firstLine,
    content: remainingData,
  };
};

const TransitInterView = ({ style, resumeList }) => {
  const [inputValue, setInputValue] = useState();
  const [dataList, setDataList] = useState([]);
  const [visibleTargetJd, setVisibleTargetJd] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const selectJDId = useSelector((state) => state.interview.selectJdId);
  const resumeId = useQueryParam("id");
  const dispatch = useDispatch();
  const [interViewVisible, setInterViewVisible] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [selectListId, setSelectListId] = useState();
  const [emptyPageVisible, setEmptyPageVisible] = useState(false);
  const [svipModalVisible, setSvipModalVisible] = useState(false);
  const [payModalVisible, setPayModalVisible] = useState(false);
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState("");
  const [selectId, setSelectId] = useState();


  const updateCountRef = useRef(0);

  useEffect(() => {
    getDataList();
  }, [resumeId]);

  useEffect(() => {
    setSvipModalVisible(isSVIP() ? false : true);
  }, []);

  useEffect(() => {
    if (updateCountRef.current === 0) {
      updateCountRef.current += 1;
      return;
    }
    handleResumeList();
  }, [resumeList]);

  const handleResumeList = () => {
    const temp = resumeList.map((item, index) => {
      return {
        label: item.name + " + " + formatTime(item.createTime),
        value: item.id,
      };
    });

    if (temp.length === 0) {
      setEmptyPageVisible(true);
      
    } else {
      setEmptyPageVisible(false);
      setSelectId(temp[0]?.value);
    }
    console.log(selectId)
    setOptionList(temp);
    setSelectListId(temp?.[0]?.value);
  };

  //更新 岗位描述
  const handleChangeJd = (type, description,id) => {
    if (type === "select") {
      handleUpdateContent({
        target: {
          value: description,
        },
      });
    } else if (type === "add") {
      addJob(description);
    }
  };

  const addJob = (description = "") => {
    addJobDescription({
      body: {
        resumeUserId: resumeId,
        description,
      },
    })
      .then((res) => {
        getDataList();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const handleUpdateContent = (e) => {
    const { content } = processDesc(e.target.value);
    const newDataList = dataList.map((item) => {
        console.log(item)
      if (item.id === selectJDId) {
        return {
          ...item,
          title: jobTitle,
          content,
          description: e.target.value,
        };
      } else {
        return item;
      }
    });
    // 模板添加
    if (isTemplate.current) {
      addJobDescription({
        body: {
          content,
          description: e.target.value,
          resumeUserId: selectId,
          title: jobTitle,
        },
      })
        .then((res) => {
          updateId.current = res.id;
        })
        .catch((err) => {
          console.log(err);
        });

      isTemplate.current = false;
      isFromUrl.current = false;
    } else {
      updateJobDescription({
        body: {
          content,
          description: e.target.value,
          id: updateId.current,
          resumeUserId: selectId,
          title: jobTitle,
        },
      });
      isTemplate.current = false;
      isFromUrl.current = false;
    }

    setDataList(newDataList);
  };

  //生成问题
  const generation = async () => {
    if (!isSVIP()) {
      setSvipModalVisible(true);
      return;
    }
    if (btnLoading) return;
    setBtnLoading(true);
    let jdId = parseInt(dataList[0].id);
    const isTemplateData = !updateId.current 

    try {
      if (isTemplateData) {
        const idTitle = "jobDescriptionTemplateId";
        if(isFromUrl.current){
          jdId = urlId.current
        }
        const queryQuestionList = await getInterviewQuestionListTemplate({
          query: { [idTitle]: jdId, resumeId: selectId },
        });

        if (queryQuestionList) {
          const newList = processInterviewData(queryQuestionList);
          dispatch(interviewSlice.actions.setList(newList));
        }
        setInterViewVisible(true)
      } else {
        const res = await generateQuestions({
          query: {
            resumeId: selectId,
            userResumeJobDescriptionId: updateId.current,
          },
        });
        if (res) {
             setInterViewVisible(true)

          const newList = processInterviewData(res);
          dispatch(interviewSlice.actions.setList(newList));
        }
      }
    } catch (error) {
      console.log("err", error);
    } finally {
      setBtnLoading(false);
    }
  };

  //下拉选择简历
  const onChangeSelect = (v) => {
    const item = optionList.find((i) => i.value === v);
    console.log(item)
    setSelectId(item.value)
    dispatch(resumeSlice.actions.changeName({ value: item.label }));
    getDataList(v);
  };

  const isTemplate = useRef(false);
  const isFromUrl = useRef(false);
  const updateId = useRef("");
  const urlContent = useRef("");

  //获取岗位描述
  const getDataList = (id) => {
    getJobDescription({
      query: {
        resumeId: id || resumeId,
      },
    })
      .then((data) => {
        if (data) {
          const { templateId, id } = data;

          if (templateId) {
            isTemplate.current = true;
            updateId.current = "";
          } else {
            isTemplate.current = false;
            updateId.current = id;
          }
          console.log(data);
          let newDataList = [{ ...data }].map((item) => {
            const { jobTitle } = item;
            setJobTitle(jobTitle);
            const { content } = processDesc(item.description);
            return {
              id: item.id ?? item.templateId,
              title: jobTitle,
              content,
              description: item.description,
            };
          });
          if (newDataList && newDataList.length > 0) {
            const jdId = newDataList[newDataList.length - 1].id;
            console.log(jdId)
            dispatch(interviewSlice.actions.setJdId(jdId));
            // 选择目标岗位跳转
            if (!urlContent.current) {
              setDataList(newDataList);
            } else if (urlContent.current && times === 0) {
              newDataList[0].description = urlContent.current;
              isFromUrl.current = true;
              setDataList(newDataList);
              times++;
            }
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //重新生成
  const handleToTransit = () => {
    setInterViewVisible(false);
  };

  const handleConfirmModal = () => {
    setSvipModalVisible(false);
    setPayModalVisible(true);
  };
  console.log('isTemplate',isTemplate.current)
  return (
    <TransitInterViewContainer style={style}>
      {!emptyPageVisible ? (
        <>
          {interViewVisible ? (
            <Interview selectId={selectId} isTemplate={isTemplate.current} onReGeneration={handleToTransit} />
          ) : (
            <TransitInterViewStyled>
              <>
                <div className="title">
                  <div className="left">选择简历</div>
                  <div
                    className="right"
                    style={{ background: "#ffffff" }}
                  ></div>
                </div>
                <div className="content">
                  {optionList.length ? (
                    <>
                      <GlobalStyle />
                      <Select
                        options={optionList}
                        onChange={(v) => onChangeSelect(v)}
                        defaultValue={optionList?.[0]?.value}
                        className="ti-select"
                      ></Select>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="title">
                  <div className="left">目标岗位描述（可选）</div>
                  <div
                    onClick={() => setVisibleTargetJd(true)}
                    className="right"
                  >
                    选择目标岗位 {icon2}
                  </div>
                </div>
                <div className="content">
                  <Input.TextArea
                    value={dataList?.[0]?.description || ""}
                    onChange={handleUpdateContent}
                    className="textarea"
                  />
                  {!dataList?.[0]?.description && (
                    <div className="tips">
                      <p>
                        请输入岗位描述，或者
                        <span
                          style={{
                            color: "#19A493",
                            textDecoration: "underline",
                          }}
                        >
                          选择目标岗位
                        </span>
                      </p>
                      <p style={{ marginTop: 17 }}>职位要求：</p>
                      <p>
                        1、本科及以上学历，计算机软件相关专业，3年以上互联网开发经验；
                      </p>
                      <p>
                        2、熟练掌握Java技术，对多线程、数据结构、GC等有深刻认识；
                      </p>
                    </div>
                  )}
                  {!dataList?.[0]?.description && (
                    <div
                      className="block"
                      onClick={() => setVisibleTargetJd(true)}
                    />
                  )}
                </div>
                <div
                  onClick={generation}
                  style={{ opacity: btnLoading ? 0.5 : 1 }}
                  className="btn"
                >
                  {btnLoading && icon1} 生成面试问题{!btnLoading && icon3}
                </div>
                <JdModal
                  type={"select"}
                  visible={visibleTargetJd}
                  onCancel={() => setVisibleTargetJd(false)}
                  onChange={handleChangeJd}
                />
              </>
            </TransitInterViewStyled>
          )}
        </>
      ) : (
        <EmptyResumeListStyled>
          <EmptyResumeModal
            title="面试问题基于简历生成，请先完成简历"
            btnText="新建简历"
            onClick={() => navigate("/")}
          />
        </EmptyResumeListStyled>
      )}

      <InfoModal
        visible={svipModalVisible}
        onConfirm={handleConfirmModal}
        onCancel={() => setSvipModalVisible(false)}
        title={"练面试为SVIP会员专属功能，请先升级"}
      />
      <PayModal
        id={9}
        defaultVipType="svip"
        visible={payModalVisible}
        onSuccess={() => setPayModalVisible(false)}
        onCancel={() => setPayModalVisible(false)}
      />
    </TransitInterViewContainer>
  );
};

export default TransitInterView;
