import withComponent from "@/hoc/withComponent";
import styled from "styled-components";
import Header from "./Header";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  create_order_edit,
  getUserRemainingDetail,
  pay_alipay,
  pay_order_query,
  pay_weixin,
} from "@/http/api";
import { message, Spin, Popover, Modal } from "antd";
import FlexStyled from "@/styled/FlexStyled";
import CardRender from "@/components/PayModal/CardRender";
import Sider from "@/components/PayModal/Sider";
import { presentReportSvg, eye, paper } from "@/svg";
import useForceUpdate from "@/hooks/useForceUpdate";
import Footer from "./Footer";
import { v4 } from "uuid";
import GetReportSuccessModal from "@/components/PayModal/GetReportSuccessModal.js";
import GetReportModal from "./GetReportModal";
import {
  singlePageOrderConfig,
  vipTypeEnum,
  close2Icon,
  upgradeSVipOrderConfig,
  vipOrderConfig,
  sVipOrderConfig,
  vip_life,
  upgradeVipOrderConfig,
} from "@/const/pay";
import store from 'store'
import ExportSinglePagePPT from "@/components/PayModal/ExportSinglePagePPT";
import Deduction from "@/components/PayModal/Deduction";
import Pointer from "@/styled/Pointer";
import Half from "@/components/PayModal/Half";
import { isB } from "@/common/AOrB";
import { fetchUserThunk } from "@/redux/thunk/user";
import "./index.scss";

const OriginPriceStyled = styled.div`
  width: 884px;
  height: 544px;
  pointer-events: auto;
`;

export default withComponent((props) => {
  let {
    id,
    onCancel,
    onSuccess,
    defaultVipType='vip',
    canSinglePage,
    pageNum,
    errorCode,
    isHalf,
    zIndex,
    name,
    onClickUserCancel,
    visible,
    
  } = props;
  const {user} = store.get('user')
  const that = useRef({
    QRObj: {
      payUrl: "",
      wechatUrl: "",
    },
    orderDataMap: null,
    tabIndex: undefined,
    singlePageVisible: false,
    singlePageSuccessVisible: false,
    vipType: defaultVipType, // svip vip upgradeSVip singlePage
    orderConfig: null,
    changeOrderLoading: true,
    selectVipType: null,
  }).current;
  const [, update] = useForceUpdate();
  const dispatch = useDispatch();

  //获取二维码
  const getQRUrl = async (orderConfig, orderDataMap) => {
    const getOutTradeNoByTabIndex = () => {
      const orderType = orderConfig[that.tabIndex]?.id;
      return orderDataMap.get(orderType).outTradeNo;
    };
    //获取微信二维码
    const getWeChatQR = async () => {
      let outTradeNo = getOutTradeNoByTabIndex();
      if (!outTradeNo) return;
      const { code_url } = await pay_weixin({}, { outTradeNo });
      return code_url;
    };
    //获取支付宝二维码
    const getPayQR = async () => {
      let outTradeNo = getOutTradeNoByTabIndex();
      if (!outTradeNo) return;
      const { code_url } = await pay_alipay({}, { outTradeNo });
      return code_url;
    };
    return await Promise.all([getWeChatQR(), getPayQR()]);
  };

  const onPay = (orderConfig, orderDataMap) => {
    const orderType = orderConfig.id;
    dispatch(fetchUserThunk()).finally(() => {
      if(that.tabIndex === 0 && !store.enterpriseMbtiMobileState){
         setGetModalOpen(true);
      }else{
        // 传的参数后面没用到
        onSuccess?.(orderDataMap.get(orderType).outTradeNo, orderType);
      }
    });

    
  };
  //获取订单数据
  const fetchOrderDataMap = async (orderConfig, isUpgrade) => {
    const dataPromiseArr = orderConfig.map((item) => {
      let priceDiscount;
      if (
        !isUpgrade &&
        false &&
        (item.id === 20 || item.id === 21 || item.id === 28)
      ) {
      }
      return create_order_edit(
        {},
        {
          isUpgrade: isUpgrade ? 1 : 0,
          orderType: item.id,
          pptId: id,
          priceDiscount,
          pageNum: pageNum,
          abType: isB() ? 1 : 0,
        }
      ).catch(() => void 0);
    });
    const dataArr = await Promise.all(dataPromiseArr);
    const orderDataMap = new Map();
    let newOrderConfig = [];
    dataArr.forEach((item, index) => {
      if (item) {
        newOrderConfig.push(orderConfig[index]);
        const orderType = orderConfig[index]?.id;
        orderDataMap.set(orderType, item);
      }
    });
    return { orderDataMap, orderConfig: newOrderConfig };
  };

  const cancelCheckPay = () => {
    clearTimeout(that.checkPayTimer);
  };

  //检查支付情况
  const checkPay = async (orderConfig, orderDataMap, checkPayId) => {
    cancelCheckPay();
    if (that.isDestroy) return;
    const checkPayTimer = setTimeout(
      async () => {
        const order_queryPromiseArr = orderConfig.map((item, index) => {
          if (!orderDataMap.get(item.id)) {
            return undefined;
          }
          return pay_order_query({
            out_trade_no: orderDataMap.get(item.id).outTradeNo,
          });
        });
        const resArr = await Promise.all(
          order_queryPromiseArr.filter((item) => !!item)
        );
        resArr.forEach((res, index) => {
          if (res.isPaid === 1) {
            onPay(orderConfig[index], orderDataMap);
            cancelCheckPay(checkPayTimer);
          }
        });
        if (checkPayId !== that.checkPayId) {
          return;
        }
        if (that.isDestroy) return;
        checkPay(orderConfig, orderDataMap, checkPayId).catch(() => void 0);
      },
      that.$5 ? 10 * 1000 : 1000
    );
    that.checkPayTimer = checkPayTimer;
  };

  const changeTabIndex = async (index, id) => {
    if (that.tabIndex === index) {
      return;
    }
    that.tabIndex = index;
    that.selectVipType = id;
    that.QRObj = { payUrl: undefined, wechatUrl: undefined };
    update();
    const [wechatUrl, payUrl] = await getQRUrl(
      that.orderConfig,
      that.orderDataMap
    );
    that.QRObj = { payUrl, wechatUrl };
    update();
  };

  //初始化函数
  const initHandler = () => {
    changeVipByType(that.vipType).catch((err) => {});
    fetchUpgradeSVipInfo();
  };

  useEffect(() => {
    changeVipByType(defaultVipType);
  }, []);

  const changeVipByType = async (type) => {
    const id = v4();
    that.changeID = id;
    that.tabIndex = undefined;
    try {
      that.changeOrderLoading = true;
      that.vipType = type;
      let defaultOrderConfig;
      if (type === vipTypeEnum.vip) {
        defaultOrderConfig = JSON.parse(JSON.stringify(vipOrderConfig));
      } else if (type === vipTypeEnum.svip) {
        defaultOrderConfig = JSON.parse(JSON.stringify(sVipOrderConfig));
      } else if (type === vipTypeEnum.singlePage) {
        defaultOrderConfig = JSON.parse(JSON.stringify(singlePageOrderConfig));
      } else if (type === vipTypeEnum.upgradeSVip) {
        defaultOrderConfig = JSON.parse(JSON.stringify(upgradeVipOrderConfig));
      }
      that.orderDataMap = null;
      that.orderConfig = null;
      update();
      let { orderConfig, orderDataMap } = await fetchOrderDataMap(
        defaultOrderConfig,
        type === vipTypeEnum.upgradeSVip
      );
      if (that.changeID !== id) {
        return;
      }
      console.log(orderDataMap, "orderDataMap");
      that.orderConfig = orderConfig;
      that.orderDataMap = orderDataMap;
      await changeTabIndex(0, vip_life);
      if (that.changeID !== id) {
        return;
      }
      update();
      const checkPayId = v4();
      that.checkPayId = checkPayId;
      checkPay(orderConfig, orderDataMap, checkPayId).catch(() => void 0);
    } catch (e) {
      if (e && e.msg) {
        message.error(e.msg);
      }
      onCancel?.();
    } finally {
      if (that.changeID === id) {
        that.changeOrderLoading = false;
        update();
      }
    }
  };

  const fetchUpgradeSVipInfo = async () => {
    // if (vipType === vipTypeEnum.upgradeSVip) {
    that.upgradeSVipInfo = await getUserRemainingDetail();
    update();
    // const { payMoney, remainingSum, usedDays, vipDays } = await getUserRemainingDetail();
    // }
  };
  useEffect(() => {
    initHandler();
    that.$5Timer = setTimeout(() => {
      that.$5 = true;
    }, 5 * 60 * 1000);
    that.$30Timer = setTimeout(() => {
      that.$30 = true;
      onCancel();
    }, 30 * 60 * 1000);
    return () => {
      that.isDestroy = true;
      clearTimeout(that.$5Timer);
      clearTimeout(that.$30Timer);
    };
  }, []);
  useEffect(() => {
    return () => {
      cancelCheckPay();
    };
  }, [that.vipType]);
  const [modalOpen, setModalOpen] = useState(false);
  const viewReport = () => {
    setModalOpen(true);
    // setGetModalOpen(true)
  };
  const [getModalOpen, setGetModalOpen] = useState(false);
  const [usePhone,setUsePhone] = useState('')
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const onGetReportSuccess = (v) => {
    setUsePhone(v?.phone)
    setGetModalOpen(false);
    setSuccessModalOpen(true);
  };
  
  const vipType = that.vipType;
  const isUpgrade = vipType === vipTypeEnum.upgradeSVip;
  const orderConfigData = useMemo(() => {
    return (
      that.orderConfig?.map?.((item) => {
        const orderType = item.id;
        if (orderType === 30) return null;
        const target = that.orderDataMap?.get?.(orderType);
        const re = /([0-9]+\.[0-9]{2})[0-9]*/;
        let priceA;
        if (target) {
          let footerText;
          let hitText;
          const price = Number(target.actualPrice);
          const originPrice = target.originPrice;
          const title = item.text;
          let reduceText;
          let price1;
          switch (orderType) {
            case 11: {
              footerText = `¥${price}/月`;
              price1 = price;
              break;
            }
            case 12: {
              const priceText = (price / 12).toString().replace(re, "$1");
              footerText = `¥${priceText}/月`;
              price1 = price / 12;
              hitText = "限时5折";
              break;
            }
            case 13: {
              footerText = `${Number(price / 36).toFixed(2)}/月`;
              price1 = price / (100 * 12);
              hitText = `超值特惠`;
              break;
            }
            case 26: {
              price1 = price / 36;
              hitText = `超值特惠`;
              break;
            }
            case 14: {
              footerText = `3个/天 任意挑选`;
              break;
            }
            case 20: {
              price1 = price;
              footerText = `${price}/月`;
              hitText = "体验首选";
              reduceText = that.upgradeSVipInfo?.remainingSum;
              break;
            }
            case 21: {
              price1 = price / 12;
              const priceText = (price / 12).toString().replace(re, "$1");
              footerText = `${priceText}/月`;
              hitText = "限时3折";
              reduceText = that.upgradeSVipInfo?.remainingSum;
              break;
            }
            case 22: {
              price1 = price / (100 * 12);
              const priceText = (price / (100 * 12))
                .toString()
                .replace(re, "$1");
              footerText = `¥${priceText}/月`;
              hitText = "限时1折，即将涨价";
              reduceText = that.upgradeSVipInfo?.remainingSum;
              break;
            }
            case 28: {
              price1 = price / 36;
              const priceText = (price / (100 * 12))
                .toString()
                .replace(re, "$1");
              footerText = `${priceText}/月`;
              hitText = "限时1折，即将涨价";
              reduceText = that.upgradeSVipInfo?.remainingSum;
              break;
            }
            case 30: {
              footerText = `¥${price}`;
              price1 = price;
              break;
            }
          }
          footerText = price;
          price1 = target.baseMoney;
          return {
            id: orderType,
            price,
            originPrice,
            title,
            footerText,
            reduceText,
            price1,
            priceA,
            hitText,
            baseMoney: Number(Number(target?.baseMoney)?.toFixed?.(2)),
          };
        }
      }) || []
    );
  }, [that.orderConfig, vipType, isUpgrade, that.upgradeSVipInfo]);
  const listFillCount = useMemo(() => {
    if (orderConfigData) {
      return 3 - orderConfigData.length;
    } else {
      return 3;
    }
  }, [orderConfigData]);
  const listFillElement = useMemo(
    () =>
      Array.from({ length: listFillCount }).map((item, index) => (
        <FlexStyled key={index} flexShrink={0} style={{ width: 198 }} />
      )),
    [listFillCount]
  );
  return (
    <>
      {vipType === vipTypeEnum.singlePage ? (
        <ExportSinglePagePPT
          name={name}
          onCancel={onCancel}
          orderConfigData={orderConfigData}
          tabIndex={that.tabIndex}
          orderDataMap={that.orderDataMap}
          QRObj={that.QRObj}
        />
      ) : (
        <>
          {isHalf && !isUpgrade && vipType === vipTypeEnum.svip && <Half />}
          <OriginPriceStyled>
            <Pointer
              onClick={onClickUserCancel}
              style={{ position: "absolute", top: 0, right: -34 }}
            >
              {close2Icon}
            </Pointer>
            <Header
              visible={visible}
              vipType={that.vipType}
              onClickTag={changeVipByType}
              upgradeSVipInfo={that.upgradeSVipInfo}
            />
            <FlexStyled style={{ background: "#F7F7F7" }}>
              <Sider
                selectVipType={that.selectVipType}
                vipType={that.vipType}
                errorCode={errorCode}
                zIndex={zIndex}
              />
              <FlexStyled
                $flexDirection="column"
                $flexGrow={1}
                style={{ padding: "20px 20px 0 20px" }}
              >
                {!that.changeOrderLoading ? (
                  <>
                    <FlexStyled>
                      {orderConfigData.map((item, index) => {
                        return (
                          <CardRender
                            key={item?.id}
                            {...item}
                            isActive={that.tabIndex === index}
                            vipType={vipType}
                            isUpgrade={isUpgrade}
                            upgradeSVipInfo={that.upgradeSVipInfo}
                            index={index}
                            onClick={() => changeTabIndex(index, item.id)}
                          />
                        );
                      })}
                      {listFillElement}
                    </FlexStyled>
                    {that.tabIndex === 0 && !store.enterpriseMbtiMobileState&& presentReportSvg}
                    <div
                      className="pay-modal-origin-price-window"
                      style={{
                        display: that.tabIndex === 0 && !store?.enterpriseMbtiMobileState? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="word">
                        {paper} <div>送职业测评及报告</div>
                      </div>
                      <div className="red-word"> ￥198</div>
                      <div className="view-word" onClick={viewReport}>
                        {" "}
                        {eye}样例报告
                      </div>
                    </div>
                    <Modal
                      className="pay-modal-origin-price-modal"
                      open={modalOpen}
                      footer={null}
                      zIndex={99999991}
                      title={
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "20px",
                          }}
                        >
                          样例报告
                        </div>
                      }
                      onCancel={() => {
                        setModalOpen(false);
                      }}
                      classNames={{ body: "pay-modal-origin-price" }}
                      style={{
                        content: {
                          padding: "20px 0",
                          height: "472px",
                          overflow: "scroll",
                        },
                      }}
                    >
                      <div
                        style={{
                          height: "640px",
                          overflowY: "scroll",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                      >
                        <img
                          style={{ width: "100%" }}
                          src="https://pub.jianzeppt.cn/image/MBTI%E6%8A%A5%E5%91%8A%E5%9B%BE%281%29.png"
                        ></img>
                      </div>
                    </Modal>
                    <GetReportModal
                      open={getModalOpen}
                      onSuccess={onGetReportSuccess}
                      onCancel={() => {
                          window.location.reload()
                        setGetModalOpen(false)}}
                    ></GetReportModal>
                    <GetReportSuccessModal
                      open={successModalOpen}
                      phone={usePhone}
                      onCancel={() => {setSuccessModalOpen(false);
                        window.location.reload()
                      }}
                    ></GetReportSuccessModal>
                    {that.upgradeSVipInfo && (
                      <Deduction
                        upgradeSVipInfo={that.upgradeSVipInfo}
                        style={{ marginTop: 10 }}
                      />
                    )}
                    <Footer
                      vipType={that.vipType}
                      orderConfigData={orderConfigData}
                      tabIndex={that.tabIndex}
                      orderDataMap={that.orderDataMap}
                      QRObj={that.QRObj}
                    />
                  </>
                ) : (
                  <FlexStyled
                    style={{ height: "100%", width: "100%" }}
                    $alignItems="center"
                    $justifyContent="center"
                  >
                    <Spin />
                  </FlexStyled>
                )}
              </FlexStyled>
            </FlexStyled>
          </OriginPriceStyled>
        </>
      )}
    </>
  );
});
