import styled from "styled-components"
import useResumeData from "@/pages/Mobile/hooks/useResumeData"
import { DatePicker, Form, Input, Picker } from 'antd-mobile'
import { BASE_FIELD_ENUM } from "@/common/field/field";
import { useEffect, useState } from "react";
import moment from "moment";
import { processBasicInfo, processBasicInfoExport } from "./utils";
import { pickerModuleList } from "./config";
import { isEmpty } from 'lodash'
import JzButton from "@/components/JzButton";
import FiledManagePopup from "../components/FiledManagePopup";
import useResumeFieldVisible from "@/pages/Mobile/hooks/useResumeFieldVisible";


const BasicModuleEditStyled = styled.div`
    padding-right: 20px;
    padding-left: 4px;
    margin-bottom: 75px;

    .adm-form-item-label {
        font-size: 12px !important;
    }
    .adm-input-element, .adm-form-item-child-inner {
        font-size: 15px !important;
    }
    .adm-list-default .adm-list-body {
        border: none;
    }
    .resume-wrap-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 15px;
        padding-bottom: 24px;
        background-color: #FFFFFF;
    }
`


const moduleName = '基本信息'
const minDate = moment("1961/1/1").toDate()
const maxDate = new Date()


const BasicModuleEdit = () => {
    const { data: resumeData, onChange } = useResumeData()
    const { fieldList, onChangeFieldList } = useResumeFieldVisible({ moduleName })
    const [form] = Form.useForm()
    const basicInfoData = resumeData?.[moduleName]?.data || {}
    const [fieldManagePopupVisible, setFieldManagePopupVisible] = useState(false)

    const onFinish = () => {
        const values = form.getFieldsValue()
        const newValues = processBasicInfoExport(values)
        onChange && onChange(moduleName, newValues)
    }

    const handleFieldChange = (fl) => {
        onChangeFieldList(fl)
        setFieldManagePopupVisible(false)
    }


    useEffect(() => {
        if (!isEmpty(basicInfoData)) {
            const newData = processBasicInfo(basicInfoData)
            form.setFieldsValue(newData)
        }

    }, [basicInfoData])


    return <BasicModuleEditStyled>
        <Form
            form={form}
            onFinish={onFinish}
        >
            {
                fieldList.filter(field => field.required || field.checked).map((field, index) => {
                    const info = field.value
                    const pickerConfig = pickerModuleList.find(item => item.key === info)
                    if (info === BASE_FIELD_ENUM.AGE) {
                        return <Form.Item
                            key={index}
                            label={info}
                            name={info}
                            trigger='onConfirm'
                            onClick={(e, datePickerRef) => {
                                datePickerRef.current?.open() // ⬅️
                            }}>
                            <DatePicker min={minDate} max={maxDate} title={info} className="edit-custom-picker">
                                {value => value ? moment(value).format('YYYY年MM月DD日') : '请选择'}
                            </DatePicker>
                        </Form.Item>
                    } else if (pickerConfig) {
                        return <Form.Item
                            key={index}
                            label={info}
                            name={info}
                            trigger='onConfirm'
                            onClick={(e, pickerRef) => {
                                pickerRef.current?.open() // ⬅️
                            }}>
                            <Picker popupClassName="edit-custom-picker" title={info} columns={pickerConfig.columns}>
                                {(items, { open }) => {
                                    return (
                                        <div>
                                            {items.every(item => item === null)
                                                ? '请选择'
                                                : items.map(item => item?.label ?? '请选择').join(' - ')}
                                        </div>
                                    )
                                }}
                            </Picker>
                        </Form.Item>
                    }
                    return <Form.Item key={index} label={info} name={info}>
                        <Input placeholder='请输入' />
                    </Form.Item>
                })
            }
        </Form>
        <div className="resume-wrap-footer flex">
            <JzButton onClick={() => setFieldManagePopupVisible(true)} className="flex-1" style={{ marginRight: 8 }} >信息管理</JzButton>
            <JzButton onClick={() => onFinish()} className="flex-2" $type="primary">保存</JzButton>
        </div>
        <FiledManagePopup 
            visible={fieldManagePopupVisible} 
            fieldList={fieldList}
            onChange={handleFieldChange}
            onCancel={() => setFieldManagePopupVisible(false)}
        />
    </BasicModuleEditStyled>
}

export default BasicModuleEdit