import JzButton from "@/components/JzButton"
import { Popup } from "antd-mobile"
import { isArray } from "lodash"
import { useEffect, useState } from "react"
import styled from "styled-components"


const PopupContentStyled = styled.div`
    
    .manage-title {
        font-size: 16px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        color: #2C2C2C;
        padding-top: 20px;
        margin-bottom: 20px;
    }
    .manage-tip {
        font-size: 12px;
        font-weight: 400;
        color: #B4B4B4;
        padding: 0 20px;
    }
    .manage-field-list {
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        row-gap: 24px;
        padding-bottom: 32px;
        border-bottom: 1px solid #F4F5F6;;
        &-item {
            font-size: 14px;
            color: #2C2C2C;
        }
        .required {
            border-radius: 4px;
            border: 1px solid #11BBA6;
            padding: 2px 4px;
            font-size: 12px;
            color: #11BBA6;
        }
    }
`

const CheckedStyled = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    ${props => props.$checked && `background: #11BBA6; border: none;`}
`

const svg = <div className="flex-center" dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="信息管理-选择要展示或隐藏的模块" transform="translate(-329.000000, -505.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-16" transform="translate(0.000000, 334.000000)">
                <g id="编组-9备份-10" transform="translate(327.000000, 169.000000)">
                    <g id="字体下拉备份-11" transform="translate(2.000000, 2.000000)">
                        <g id="duihao-10" transform="translate(1.500000, 2.872500)">
                            <path d="M3.46165985,6.25669199 C3.28421975,6.25669199 3.10748101,6.1886616 2.97212157,6.05330216 L0.20251315,3.27247224 C-0.0675043834,3.00105202 -0.0675043834,2.56060783 0.20251315,2.28918761 C0.473232028,2.01776739 0.91157218,2.01776739 1.18158971,2.28918761 L3.46165985,4.57837522 L7.81841029,0.204091175 C8.08842782,-0.0680303916 8.52676797,-0.0680303916 8.79748685,0.204091175 C9.06750438,0.475511397 9.06750438,0.915254237 8.79748685,1.18667446 L3.95119813,6.05330216 C3.81583869,6.1886616 3.6383986,6.25669199 3.46165985,6.25669199 Z" id="路径"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

function sortCustomFields(customFieldList) {
    // 定义权重映射
    const fieldOrder = {
      '姓名': 1,
      '手机号': 3,
      '邮箱': 5,
      '学历': 9
    };

    // 获取字段名称的权重
    function getWeight(fieldName, index) {
      // 如果字段名称在fieldOrder中定义了权重，则返回该权重
      if (fieldOrder.hasOwnProperty(fieldName)) {
        return fieldOrder[fieldName];
      }
      // 否则，分配一个介于已命名字段之间的权重
      // 这个权重基于索引，但要确保它不会与已命名字段的权重冲突
      return index * 2; // 通过乘以2确保介于已命名字段的奇数权重之间
    }
  
    // 创建一个新的数组，用于排序，以避免修改原始数组
    const sortedFields = customFieldList
      .map((field, index) => ({ ...field, sortWeight: getWeight(field.label, index) })) // 添加排序权重属性
      .sort((a, b) => a.sortWeight - b.sortWeight); // 根据排序权重属性进行排序
  
    return sortedFields;
  }
  



const Checked = ({ checked, onClick }) => {

    return <CheckedStyled className="flex-center" onClick={onClick} $checked={checked}>
        {checked && svg}
    </CheckedStyled>
}


const FiledManagePopup = ({ visible, onCancel, fieldList = [], onChange }) => {
    const [customFiledList, setCustomFieldList] = useState(fieldList || [])

    useEffect(() => {
        if (isArray(fieldList) && fieldList.length > 0) {
            setCustomFieldList(fieldList)
        }
    }, [fieldList])


    const handleClickSave = () => {
        onChange && onChange(customFiledList)
    }

    const handleCheck = (field) => {
        const newList = customFiledList.map(item => {
            if (item.value === field.value) {
                return {
                    ...item,
                    checked: !item.checked
                }
            }
            return item
        })
        setCustomFieldList(newList)
    }


    return <Popup
        visible={visible}
        onMaskClick={onCancel}
        onClose={onCancel}
        showCloseButton
        bodyStyle={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            minHeight: '40vh',
        }}
    >
        <PopupContentStyled>
            <div className="manage-title flex-center">基本信息管理</div>
            <div className="manage-tip">取消选中将隐藏该信息的展示</div>
            <div className="manage-field-list">
                {sortCustomFields(customFiledList).map(field => {
                    return <div className="manage-field-list-item flex justify-between">
                        <div>{field.label}</div>
                        {
                            field.required ? <div className="required">必填</div> : <Checked onClick={() => handleCheck(field)} checked={field.checked} />
                        }
                    </div>

                })}
            </div>
            <div className="flex-center px-16 pt-12 pb-24">
                <JzButton onClick={onCancel} className="flex-1" style={{ marginRight: 8 }} >取消</JzButton>
                <JzButton onClick={handleClickSave} className="flex-2" $type="primary">保存</JzButton>
            </div>
        </PopupContentStyled>
    </Popup>
}

export default FiledManagePopup