import useSelector from "@/components/resume/redux/hooks/useSelector"
import { useEffect } from "react"
import styled from "styled-components"
import {v4} from "uuid";
import { messageType } from "..";
import useDispatch from "@/components/resume/redux/hooks/useDispatch";
import resumeSlice from "@/components/resume/redux/services/resumeSlice";

const ResumeStylerStyled = styled.div`

`

const CardWrapStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    margin-top: 12px;
`

const CardItemStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 48px;
    background: rgba(255,255,255,0.89);
    border-radius: 4px;
    border: 1px solid #E9E9EC;

    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6E727C;
    cursor: pointer;
    &:hover {
        border: 1px solid #00BCA6;
        color: #00BCA6;
        svg path{
            fill: #00BCA6;
        }
    }
`

const icon1 = <div dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="简历美化-选择美化的地方" transform="translate(-944.000000, -473.000000)" fill="#6E727C" fill-rule="nonzero">
            <g id="帮我写内容备份-7" transform="translate(944.000000, 473.000000)">
                <path d="M9.38531899,3 L9.34507203,3.46032472 C9.28721702,4.12439972 8.71872856,4.64257946 8.05213811,4.64257947 C7.37548594,4.64257947 6.81957465,4.13446147 6.7592042,3.45780929 L6.71895724,3 L2,3 L2,7.80699748 L3.7507432,7.80699748 L3.7507432,12.4907386 C3.7507432,13.3233478 4.42739537,14 5.26000457,14 L10.8467871,14 C11.6793963,14 12.3560485,13.3233478 12.3560485,12.4907386 L12.3560485,7.80699748 L14.1067917,7.80699748 L14.1067917,3 L9.38531899,3 Z M13.1006174,6.80082324 L11.3498742,6.80082324 L11.3498742,12.4907386 C11.3498742,12.7674365 11.123485,12.9938257 10.8467871,12.9938257 L5.26000457,12.9938257 C4.98330666,12.9938257 4.75691745,12.7674365 4.75691745,12.4907386 L4.75691745,6.80082324 L3.00617426,6.80082324 L3.00617426,4.00617426 L5.84610107,4.00617426 C6.13034529,4.96958609 7.01074777,5.64875371 8.05465356,5.64875371 C9.09855934,5.64875371 9.97896181,4.96958609 10.263206,4.00617426 L13.1031329,4.00617426 L13.1031329,6.80082324 L13.1006174,6.80082324 Z" id="形状"></path>
            </g>
        </g>
    </g>
</svg>
`}} />

const icon2 = <div dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="简历美化-选择美化的地方" transform="translate(-1040.000000, -473.000000)" fill="#6E727C" fill-rule="nonzero">
            <g id="帮我写内容备份-8" transform="translate(1040.000000, 473.000000)">
                <path d="M4.72727272,3.63636364 L12.9090909,3.63636364 C13.2103371,3.63636364 13.4545455,3.88057195 13.4545455,4.18181817 L13.4545455,8 L12.3636364,8 L12.3636364,4.72727272 L4.72727272,4.72727272 L4.72727272,6.36363636 L2,4.18181817 L4.72727272,2 L4.72727272,3.63636364 Z M11.2727273,12.3636364 L3.09090909,12.3636364 C2.78966286,12.3636364 2.54545454,12.119428 2.54545454,11.8181818 L2.54545454,8 L3.63636364,8 L3.63636364,11.2727273 L11.2727273,11.2727273 L11.2727273,9.63636364 L14,11.8181818 L11.2727273,14 L11.2727273,12.3636364 L11.2727273,12.3636364 Z" id="形状"></path>
            </g>
        </g>
    </g>
</svg>
`}} />

const ResumeStyler = ({ onChange, pushMessage }) => {
    const dispatch = useDispatch()
    const isChangeColor = useSelector(state => state.resume?.isChangeColor);

    useEffect(() => {
        if (isChangeColor) {
            pushMessage({
                id: v4(),
                type: messageType.SYSTEM,
                props: {value: "已为您更换配色", initAnimation: false}
            });
            // dispatch(resumeSlice.actions.isChangeColor(false))
        }
    }, [isChangeColor])

    return <ResumeStylerStyled>
        <span>好的，选择你要美化的地方</span>
        <CardWrapStyled>
            <CardItemStyled onClick={() => onChange('color')}>
                {icon1}
                <p>更换配色</p>
            </CardItemStyled>
            <CardItemStyled onClick={() => onChange('template')}>
                {icon2}
                <p>切换模板</p>
            </CardItemStyled>
        </CardWrapStyled>
    </ResumeStylerStyled>
}

export default ResumeStyler
