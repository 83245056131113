import useFieldVisible from "@/components/resume/hooks/useFieldVisible";
import React, { createElement, useEffect, useRef, Fragment, Children, useState } from "react";
import { FieldContext } from "@/components/resume/context";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance"
import classNames from "classnames";
import { useDispatch, useSelector } from "@/components/resume/redux/hooks";
import useForceUpdate from "@/hooks/useForceUpdate";
import { createPortal } from "react-dom";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";
import FlexStyled from "@/styled/FlexStyled";
import styled from "styled-components"
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import getResumePath from "@/common/resume/getResumePath";
import aiDecisionSlice from "@/components/resume/redux/services/aiDecisionSlice";
import getBlockNameBySlot from "@/common/slot/getBlockNameBySlot";
import getFieldNameBySlot from "@/common/getFieldNameBySlot";
import { Tooltip } from "antd";
import { redoIcon, undoIcon } from "@/pages/ResumeDetail/Chat/icon";
import { AiDecisionWrapStyled } from "../styled";
import focusSlice from "../redux/services/focusSlice";
import { Module } from "@/common/module/module";

const IconStyled = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;

  &:hover {
    opacity: 0.9;
  }
;
`

export const icon1 = <IconStyled style={{ width: 12, height: 12, position: "relative" }} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="4" transform="translate(-175.000000, -133.000000)" fill="#F89D9D" fill-rule="nonzero">
            <g id="秒出助手备份-4" transform="translate(175.000000, 133.000000)">
                <path d="M3.63200726,1.29779735 C3.84266351,1.50830472 3.84278419,1.8497253 3.63227682,2.06038155 L2.89836748,2.79429087 L2.95097604,2.99825562 L7.2817947,3.02770559 C9.44575973,3.02770559 11.2,4.78194585 11.2,6.94591089 C11.2,9.10987592 9.44575973,10.8641162 7.2817947,10.8641162 L1.87521274,10.8641162 C1.57748068,10.8641162 1.33612117,10.6227567 1.33612117,10.3250246 C1.33612117,10.0272926 1.57748068,9.78593304 1.87521274,9.78593304 L7.2817947,9.78593304 C8.85029563,9.78593304 10.1218169,8.51441181 10.1218169,6.94591089 C10.1218169,5.37740996 8.85029563,4.10588873 7.2817947,4.10588873 L2.94706761,4.10588873 L2.84004262,4.28731345 L3.63231099,5.12123036 C3.77384246,5.25814856 3.83008255,5.45857404 3.78056652,5.64719881 C3.7310976,5.83564413 3.58379886,5.98273466 3.39528377,6.03193699 C3.20676867,6.08113932 3.00637397,6.02479657 2.87112025,5.88456376 L0.958145246,3.97223301 C0.856882246,3.87108259 0.8,3.73387286 0.8,3.59078886 C0.8,3.44770898 0.856879343,3.31049805 0.958108364,3.20938158 L2.86938888,1.29810109 C3.05653412,1.11082352 3.34709244,1.08991455 3.55730335,1.23542845 L3.63200726,1.29779735 Z" id="路径"></path>
            </g>
        </g>
    </g>
</svg>`
}} />
export const icon2 = <IconStyled style={{ width: 12, height: 12, position: "relative" }} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="4" transform="translate(-195.000000, -133.000000)" fill="#58CFC0">
            <g id="秒出助手备份-2" transform="translate(195.000000, 133.000000)">
                <path d="M4.53059901,9.83600402 L0.487073363,6.39138189 C0.232496025,6.17451106 0.201928855,5.79232682 0.418799677,5.53774948 C0.426011124,5.52928421 0.433454397,5.52101919 0.441121026,5.51296384 C0.688580444,5.25295751 1.09608234,5.23163484 1.36932593,5.46439523 L5.13283901,8.67031399 L5.13283901,8.67031399 L10.5466946,2.00185246 C10.7751572,1.72044595 11.1838355,1.6669184 11.4770642,1.87999524 C11.7508068,2.07891233 11.8114648,2.46207866 11.6125477,2.73582119 C11.6061178,2.74466974 11.5994522,2.75334452 11.5925579,2.7618362 L5.95542721,9.70507958 C5.6073188,10.1338441 4.97753889,10.1992287 4.54877441,9.85112032 C4.54265659,9.84615334 4.5365977,9.84111422 4.53059901,9.83600402 Z" id="路径"></path>
            </g>
        </g>
    </g>
</svg>`
}} />
const MaskStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    z-index: 1;
`
export default (Component) => {
    return (config) => {
        const resumeInstance = useResumeInstance();
        const moduleInstance = useModuleInstance();
        const dispatch = useDispatch();
        const ref = useRef();
        const instance = useRef({
            for_block_name: moduleInstance?.for_block_name, for_field_name: config.field_name,
        }).current;
        const [position, setPosition] = useState()
        const { field_name } = config;
        const handler = (state) => {
            if (state.aiDecision) {
                return state.aiDecision.find((item) => {
                    let for_block_name = moduleInstance?.for_block_name ? moduleInstance?.for_block_name : getBlockNameBySlot(item.slotName);
                    return (item.blockName === for_block_name) && (item.fieldName === config.field_name) && item.status === undefined;
                })
            }
        }
        const currentAiDecision = useSelector(handler);
        const isAiFocus = useSelector(state => state.focus.filedId === config.field_name && state.focus?.moduleId === moduleInstance?.data?.id)
        const visible = useFieldVisible(moduleInstance?.data?.id, field_name);
        const onClickS = () => {
            resumeInstance.aiDecision.onClickSuccess(currentAiDecision);
            setPosition(undefined);
        }
        const onClickC = () => {
            resumeInstance.aiDecision.onClickCancel(currentAiDecision);
            setPosition(undefined);
        }
        const onClickMask = () => {
            dispatch(focusSlice.actions.setAiDecisionFiledId(config.field_name))
            if (moduleInstance?.data?.id) {
                dispatch(focusSlice.actions.setModuleId(moduleInstance.data.id))
            }
        }
        const props = { ...config };
        props['data-type'] = "field";
        props.className = classNames(config.className, 'field_box', currentAiDecision ? 'resume-field-highlight' : undefined);
        props.fieldRef = ref;
        instance.element = visible ? <Fragment>
            {resumeInstance.rootContainer && position && currentAiDecision && isAiFocus && createPortal(<>
                <FlexStyled $justifyContent="flex-end" style={{
                    position: "absolute", top: position.y, left: position.x, zIndex: 10,
                }}>
                    <AiDecisionWrapStyled className="ai-decision-action">
                        <Tooltip placement="bottom" title={"撤销"} >
                            <FlexStyled style={{ marginRight: 8, color: '#777777', fontSize: 12, width: 45, cursor: 'pointer' }} onClick={onClickC}>
                                <FlexStyled $justifyContent="center" $alignItems="center" style={{ marginRight: 4 }}>{undoIcon}</FlexStyled>
                                撤销
                            </FlexStyled>
                        </Tooltip>
                        <Tooltip placement="bottom" title={"保留"} >
                            <FlexStyled style={{ color: '#11BBA6', fontSize: 12, width: 45, cursor: 'pointer' }} onClick={onClickS}>
                                <FlexStyled $justifyContent="center" $alignItems="center" style={{ marginRight: 4 }}>{redoIcon}</FlexStyled>
                                保留
                            </FlexStyled>
                        </Tooltip>
                    </AiDecisionWrapStyled>

                </FlexStyled>
            </>, resumeInstance.rootContainer)}
            {resumeInstance.rootContainer && position && currentAiDecision && createPortal(<>
                <FlexStyled $justifyContent="flex-end" style={{
                    position: "absolute", 
                    top: position.ny, left: position.nx, 
                    zIndex: 10,
                    width: position.width, height: position.height
                }}>
                    <MaskStyled className="ai-decision-mask" onClick={onClickMask} style={{ width: position.width, height: position.height }} />
                </FlexStyled>
            </>, resumeInstance.rootContainer)}
            <FieldContext.Provider value={instance}>
                {createElement(Component, props)}
            </FieldContext.Provider>
        </Fragment> : undefined;
        useEffect(() => {
            if (!visible) return;
            setTimeout(() => {
                if (!currentAiDecision) return;
                try {
                    if (ref.current) {
                        const rect = ref.current.getBoundingClientRect();
                        const rootContainerRect = resumeInstance.rootContainer.getBoundingClientRect();
                        const position = {
                            x: (rect.x - rootContainerRect.x) + rect.width - 102,
                            y: (rect.y - rootContainerRect.y) + rect.height,
                            nx: (rect.x - rootContainerRect.x),
                            ny: (rect.y - rootContainerRect.y),
                            width: rect.width,
                            height: rect.height
                        }
                        setPosition(position)
                    }
                } catch (e) {
                }
            }, 1000)
        }, [currentAiDecision, visible]);
        if (!visible) return;
        return instance.element
    }
}
