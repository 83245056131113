import withComponent from "../../hoc/withComponent";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import FlexStyled from "../../styled/FlexStyled";
import config from "./config";
import { configMap } from "./config";
import { getSearchTagsJson } from "@/http/api";

const TabItemStyled = styled.div`
  display: inline-block;
  & > div:nth-child(1) {
    height: 28px;
    padding: 0 14px;
    border-radius: 4px;
    line-height: 28px;
    margin: 11px 0;
    margin-right: 20px;
    box-sizing: border-box;
    white-space: nowrap;
    /* float: left; */
    font-size: 16px;
    font-family: PingFangSC-Medium, "PingFang SC";
    font-weight: 500;
    color: #000000;
    cursor: pointer;

    ${(props) =>
      props.$isActive ? "    background: #11BBA6;\n    color: #FFFFFF;" : ""}
    &:hover {
      background: #11bba6;
      color: #ffffff;
    }
  }
`;

const TabLineStyled = styled.div`
  background: rgba(248, 248, 248);
  border-radius: 6px;
  display: flex;
  min-height: 45px;
  padding: 12px 0 4px;
  position: relative;
  width: 1296px;
  flex-wrap: wrap;
  left: ${(props) => props.$left} .active {
    background: #11bba6;
    color: rgba(100, 200, 187);
  }
  & > div {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    margin-right: 24px;

    font-size: 14px;
    line-height: 34px;
    margin-left: 24px;
    transition: all 0.2s linear;
  }
`;

const TabItemTextStyled = styled.div`
  display: inline-block;
  height: 50px;
  /* padding: 4px 0px; */
  border-radius: 4px;
  line-height: 50px;

  font-size: 16px;
  font-family: PingFangSC-Medium, "PingFang SC";
  font-weight: 400;
  color: #000000;
`;
const TabContainerStyled = styled.div`
  padding: 30px 0;
  background: #ffffff;
  box-shadow: 2px 5px 12px -2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  user-select: none;
`;
const TabMoreContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 26px;
  cursor: pointer;
`;

const GroupContainerStyled = styled.div`
  display: flex;
`;
const GroupTitleStyled = styled.div`
  width: 82px;
  height: 50px;
  line-height: 50px;
  flex-shrink: 0;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #000000;
  display: inline-block;
  text-align: center;
  margin-left: 6px;
`;
const GroupTagsContainerStyled = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  flex-grow: 1;
  padding-right: 70px;
  overflow: hidden;
  box-sizing: border-box;
`;

const icon1 = (
  <div
    style={{ height: 8, width: 8, position: "relative" }}
    dangerouslySetInnerHTML={{
      __html: `<svg
        viewBox='0 0 1024 1024'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        width='8'
        height='8'
        fill='rgb(153,153,153)'
                style="position: absolute;top: 0;"

    >
        <path
            d='M573.056 272l308.8 404.608A76.8 76.8 0 0 1 820.736 800H203.232a76.8 76.8 0 0 1-61.056-123.392L450.976 272a76.8 76.8 0 0 1 122.08 0z'
            p-id='3015'
        ></path>
    </svg>`,
    }}
  />
);
const icon2 = (
  <div
    style={{ height: 8, width: 8, position: "relative" }}
    dangerouslySetInnerHTML={{
      __html: `<svg
        viewBox='0 0 1024 1024'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        width='8'
        height='8'
        fill='rgb(153,153,153)'
        style="position: absolute;top: 0;"
    >
        <path
            d='M573.056 752l308.8-404.608A76.8 76.8 0 0 0 820.736 224H203.232a76.8 76.8 0 0 0-61.056 123  .392l308.8 404.608a76.8 76.8 0 0 0 122.08 0z'
            p-id='2102'
        ></path>
    </svg>`,
    }}
  />
);

const Group = (props) => {
  const {
    activeIndex = 0,
    title,
    children = {},
    onClick,
    notNeedKaoYan,
    tags={},
    filterKey
  } = props;
  const ref = useRef();
  const liRef = useRef();
  const [hasMore, setHasMore] = useState(false);
  const [moreVisible, setMoreVisible] = useState(false);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState();

  const [lineWidth, setLineWidth] = useState(1000);
  let onClickMore = () => {
    setMoreVisible((state) => !state);
  };
  const [activeKey, setActiveKey] = useState(0);
  let trueChild;
  if (Array.isArray(children)) {
    trueChild = children;
  } else {
    trueChild = Object.keys(children);
  }
  const childArr = Object.keys(children);

  const onClickItem = (index) => {
    console.log(index)
    const left = document
      .getElementById("item" + index + title)
      .getBoundingClientRect().left;
    // 最左边距 82
    const boxLeft = document
      .getElementById("filterBox")
      .getBoundingClientRect().left;
    console.log(left, boxLeft);

    setWidth(
      getFullWidthIncludingMargin(
        document.getElementById("text" + index + title)
      )
    );
    setWidth('unset')
    setLeft(-(left - boxLeft - 82));
    setLineWidth(document.getElementById("filterBox").offsetWidth - 82 * 2);
    setMoreVisible(true);
    onClick(index);
  };
  useEffect(() => {
    onClickItem(activeIndex);
  }, activeIndex);



  function getFullWidthIncludingMargin(element) {
    const style = window.getComputedStyle(element);
    const marginLeft = parseFloat(style.marginLeft);
    const marginRight = parseFloat(style.marginRight);
    const fullWidth = element.offsetWidth + marginLeft + marginRight;
    return fullWidth;
  }

  useEffect(() => {
    const dom = ref.current;
    let width = 0;
    [...dom.children].forEach((child) => {
      width += child.offsetWidth;
    });
    if (width > ref.current.offsetWidth) {
      setHasMore(true);
    }
  }, []);
  const onClickSubText = (v) => {
    setActiveKey(v);
    props.onClickSubText(v);
  };

  const positionMap = {
    用途: "use",
    风格: "style",
    职位: "first_job",
    颜色: "color",
  };
  const getNumber = (item, type) => {
    if (type) {
      return tags[positionMap[type]]?.find((v) => v.key === item)?.doc_count;
    }
    return null
  };

  return (
    <GroupContainerStyled id="filterBox">
      <GroupTitleStyled>{title}：</GroupTitleStyled>
      <GroupTagsContainerStyled
        ref={ref}
        style={{ height: moreVisible ? undefined : 50 }}
      >
        {trueChild.map((text, index) => {
          if (text === "考研简历" && notNeedKaoYan) return;
          if (typeof text === "object") {
            return;
          }
          return (
            <TabItemStyled
              key={text}
              $isActive={activeIndex === index}
              id={"item" + index + title}
              ref={liRef}
              style={{
                width: activeIndex === index ? width : "unset",
                display: (getNumber(text, title)|| text==='全部') ? 'block' : 'none' 
              }}
            >
              <div
                id={"text" + index + title}
                onClick={() => onClickItem(index)}
                style={{ display: "flex" }}
              >
                {text}
                <div
                  style={{
                    color: activeIndex === index ? "#fff" : "rgba(203,203,203)",
                    marginLeft: 5,
                  }}
                >
                  {getNumber(text, title)}
                </div>
              </div>
              {activeIndex === index && title === "职位" && text !== "全部" && (
                <TabLineStyled style={{ left: left, width: lineWidth }}>
                  {configMap.children[text].map((v) => (
                    <div
                      onClick={() => onClickSubText(v)}
                      key={v}
                      style={{
                        display: "flex",
                        color: activeKey === v ? "#11BBA6" : "#000",
                      }}
                    >
                      {v}
                    </div>
                  ))}
                </TabLineStyled>
              )}
            </TabItemStyled>
          );
        })}
        {hasMore && (
          <TabMoreContainerStyled onClick={onClickMore}>
            <TabItemTextStyled style={{ marginRight: 3 }}>
              {moreVisible ? "收起" : "更多"}
            </TabItemTextStyled>
            <div>{moreVisible ? icon1 : icon2}</div>
          </TabMoreContainerStyled>
        )}
      </GroupTagsContainerStyled>
    </GroupContainerStyled>
  );
};

export default withComponent((props) => {
  const { changeFilterCurrent, filterCurrent, width, changePosition, tags,newKey } =
    props;
  return (
    <FlexStyled $justifyContent="center">
      <TabContainerStyled style={{ width }}>
        {config
          .slice(0, 2)
          .concat(config.slice(3, 4))
          .map((item, groupIndex) => {
            //隐藏行业
            if (item.title === "行业") {
              return;
            }

            if (item.title === "语言") {
              return;
            }

            //考研、留学简历隐藏职位
            if (
              (filterCurrent[2] === 5 || filterCurrent[2] === 6) &&
              item.title === "职位"
            ) {
              return;
            }
            
            return (
              <Group
                tags={tags}
                filterKey = {newKey}
                key={item.title}
                changeFilterCurrent={changeFilterCurrent}
                title={item.title}
                activeIndex={filterCurrent[groupIndex]}
                notNeedKaoYan={filterCurrent[1] === 2}
                onClick={(index) => {console.log(22222222222);changeFilterCurrent(groupIndex, index)}}
                children={item.children}
                onClickSubText={changePosition}
              />
            );
          })}
      </TabContainerStyled>
    </FlexStyled>
  );
});
