import React from 'react';
import {useDispatch} from 'react-redux';
import FlexStyled from '../../styled/FlexStyled';
import LoginQR from "./LoginQR";
import Checkbox from 'antd/es/checkbox/Checkbox';
import withComponent from '../../hoc/withComponent';
import Modal from "antd/es/modal/Modal";
import {fetchUserThunk} from "../resume/redux/thunk/user";
import styled from "styled-components";
import {s10B_SVG} from "@/const/logo";

const BoxStyled = styled.div`
  width: 780px;
  height: 500px;
  background: #FFFFFF;
  border-radius: 6px;
  pointer-events: auto;
  position: relative;
  display: flex;
`
const RightBoxStyled = styled.div`
  width: 460px;
  height: 500px;
  position: relative;
`
const LeftBoxStyled = styled.div`
  width: 320px;
  height: 500px;
  position: relative;
  padding-top: 74px;
`
const LeftTitleStyled = styled.div`
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 24px;
  text-align: center;
`
const LeftQRPositionStyled = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`
const LeftQRStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  border: 1px solid #E6E6E6;
  margin-top: 20px;
`
const LeftDesStyled = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7B7B7B;
  line-height: 20px;
  text-align: center;
`
const LeftCheckStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`
const CloseStyled = styled.div`
  position: absolute;
  top: -2px;
  right: -46px;
  cursor: pointer;
`
const LogoStyled = styled.div`
  position: absolute;
  left: 30px;
  top: 26px;
`
const BgStyled = styled.div`
  position: absolute;
  top: 111px;
  left: 71px;
  width: 318px;
  height: 317px;
`
const closeIcon = <div dangerouslySetInnerHTML={{
    __html: `<svg width='30px' height='30px' viewBox='0 0 24 24' version='1.1'>
                    <g
                        id='页面-1'
                        stroke='none'
                        strokeWidth='1'
                        fill='none'
                        fillRule='evenodd'
                    >
                        <g id='System' transform='translate(-288.000000, 0.000000)'>
                            <g id='close_line' transform='translate(288.000000, 0.000000)'>
                                <rect
                                    id='矩形'
                                    fillRule='nonzero'
                                    x='0'
                                    y='0'
                                    width='24'
                                    height='24'
                                ></rect>
                                <path
                                    d='M12,13.4144 L17.6568,19.0713 C18.0473,19.4618 18.6805,19.4618 19.071,19.0713 C19.4615,18.6807 19.4615,18.0476 19.071,17.657 L13.4142,12.0002 L19.071,6.34335 C19.4615,5.95283 19.4615,5.31966 19.071,4.92914 C18.6805,4.53861 18.0473,4.53861 17.6568,4.92914 L12,10.586 L6.34309,4.92912 C5.95257,4.5386 5.3194,4.5386 4.92888,4.92912 C4.53836,5.31965 4.53836,5.95281 4.92888,6.34334 L10.5857,12.0002 L4.92888,17.6571 C4.53836,18.0476 4.53836,18.6807 4.92888,19.0713 C5.3194,19.4618 5.95257,19.4618 6.34309,19.0713 L12,13.4144 Z'
                                    id='路径'
                                    fill='white'
                                ></path>
                            </g>
                        </g>
                    </g>
                </svg>`
}}/>
const bgSvg = <div style={{width: 318, height: 317}} dangerouslySetInnerHTML={{
    __html: `<svg width="325px" height="317px" viewBox="0 0 318 317" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="登陆、付费" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="登录页面" transform="translate(-261.000000, -198.000000)">
            <g id="编组-2" transform="translate(261.000000, 198.000000)">
                <g id="undraw_job_hunt_re_q203" transform="translate(0.000000, 8.000000)" fill-rule="nonzero">
                    <path d="M222.917706,307.959791 C222.58831,308.309441 222.246007,308.65472 221.89947,309 L182.376155,309 C182.0512,308.663462 181.730686,308.318182 181.409955,307.959791 C181.397048,307.946679 181.383933,307.929196 181.37103,307.916084 C177.077218,303.117135 174.373699,296.14164 172.688226,288.785847 C172.579907,288.340043 172.484704,287.889869 172.389293,287.435323 C171.947354,285.368016 171.587693,283.278856 171.293201,281.207179 C171.241156,280.848787 171.189116,280.490396 171.145745,280.132004 C170.747168,277.155607 170.478504,274.227287 170.30522,271.47814 C170.283639,271.124119 170.26185,270.778839 170.240268,270.43356 C169.922828,264.96047 169.919935,259.473427 170.231603,254 C170.231603,254 170.283648,254 170.383292,254.004371 C170.673551,254.017483 171.371065,254.048077 172.397971,254.109266 C175.227157,254.284091 180.565223,254.721154 186.84325,255.752622 C187.185536,255.80944 187.532298,255.870629 187.878835,255.931817 C193.18902,256.816894 198.427108,258.096602 203.550309,259.760487 C203.871031,259.869753 204.187312,259.979019 204.507843,260.088284 L204.512284,260.110137 L208.442003,280.000864 L210.07122,262.295451 C211.3666,262.885486 212.627492,263.514856 213.836342,264.187933 C214.174205,264.37587 214.503584,264.563829 214.828539,264.756115 C217.592091,266.335791 220.100684,268.331338 222.267813,270.673944 C235.157687,285.083912 232.397687,297.964165 222.917706,307.959791 Z" id="路径" fill="#00BCA6" opacity="0.299669"></path>
                    <path d="M206.069107,283.034194 L206.758056,283.79689 C214.721091,292.70341 219.473355,300.970465 220.93747,308.489681 C220.960719,308.598911 220.976772,308.707792 221,308.817026 L220.490539,308.913461 L219.999584,309 C218.505298,300.802149 212.941937,292.327981 205.998374,284.55764 C205.777038,284.30501 205.552288,284.048628 205.320014,283.799067 C202.351564,280.535674 199.160145,277.404928 195.933053,274.477644 C195.685266,274.24888 195.430305,274.019768 195.175343,273.79066 C190.895872,269.948855 186.590468,266.473089 182.750858,263.543332 C182.480401,263.335016 182.206185,263.130136 181.935358,262.929012 C175.416007,257.996766 170.357646,254.72743 169.230244,254.010746 C169.094327,253.920951 169.017416,253.873828 169,253.862143 L169.266841,253.43637 L169.267207,253.429178 L169.537829,253 C169.555254,253.011707 169.813906,253.16899 170.281219,253.473797 C172.036121,254.604287 176.753,257.715703 182.62084,262.139281 C182.884466,262.340038 183.158704,262.544941 183.429139,262.753235 C186.547555,265.127518 189.958204,267.848072 193.40252,270.826022 C194.268901,271.572615 195.113719,272.318081 195.936973,273.062422 C196.195694,273.288143 196.450655,273.517255 196.698455,273.746019 C200.18736,276.909957 203.310911,280.006015 206.069107,283.034194 Z" id="路径" fill="#FFFFFF"></path>
                    <path d="M187.94177,254 C187.820694,254.289494 187.690622,254.578989 187.555963,254.864029 C186.531693,257.065502 185.221618,259.124306 183.659139,260.987949 C183.430544,261.255174 183.197351,261.526854 182.955182,261.794079 C182.300493,262.511134 181.60391,263.204436 180.865434,263.873985 C177.844092,266.590443 174.263407,268.620888 170.372228,269.824207 C170.246546,269.864291 170.125681,269.904375 170,269.940005 C170.022332,270.291852 170.044883,270.639245 170.06722,271 C170.192901,270.96437 170.313986,270.92874 170.439452,270.888656 C174.568253,269.652313 178.36852,267.51943 181.56501,264.644485 C182.346692,263.943035 183.090651,263.201199 183.793798,262.422059 C184.035748,262.159287 184.26916,261.887608 184.493375,261.615929 C186.164205,259.621879 187.555014,257.411973 188.627772,255.046633 C188.753454,254.761593 188.878893,254.476552 189,254.187036 C188.641346,254.124719 188.287069,254.062362 187.94177,254 Z" id="路径" fill="#FFFFFF"></path>
                    <path d="M204.001013,259 C203.947208,259.25998 203.884429,259.519961 203.817278,259.779941 C202.602775,264.733403 200.016375,269.258864 196.345243,272.853864 C196.08976,273.105031 195.829901,273.351792 195.561292,273.58974 C195.484953,273.66465 195.399866,273.743966 195.319387,273.814469 C188.543233,279.831521 179.227547,282.254482 170.313451,280.318382 C170.210417,280.300756 170.103025,280.274318 170,280.252286 C170.053591,280.613614 170.103034,280.979349 170.161207,281.340678 C170.264241,281.362732 170.362884,281.384742 170.465909,281.402368 C179.602386,283.261712 189.092741,280.726611 196.018031,274.576784 C196.125652,274.479842 196.237645,274.387307 196.34067,274.285959 C196.609493,274.039198 196.869353,273.792437 197.129212,273.545676 C200.886106,269.847698 203.542899,265.209192 204.811888,260.132456 C204.879039,259.868052 204.941743,259.603665 205,259.339296 C204.66838,259.224733 204.336992,259.110161 204.001013,259 Z" id="路径" fill="#FFFFFF"></path>
                    <path d="M213.987899,263 C213.992161,263.245082 213.992161,263.49454 213.987899,263.739622 C213.915035,270.656081 211.241112,277.289827 206.501231,282.313329 C206.256887,282.575916 206.003808,282.829751 205.750943,283.083586 C205.475924,283.35055 205.201114,283.617514 204.917575,283.871349 C195.906689,292.017974 182.644839,293.295531 172.253079,287.018026 C172.166294,286.972697 172.081829,286.923035 172,286.869226 C172.104808,287.333131 172.209415,287.788283 172.322949,288.243413 C172.401345,288.287178 172.475478,288.330942 172.554087,288.370331 C183.231894,294.342741 196.51426,292.840421 205.598201,284.632832 C205.881741,284.37462 206.165285,284.116409 206.431356,283.845068 C206.693169,283.59561 206.941771,283.341775 207.190587,283.079188 C211.997334,277.993861 214.772204,271.313084 214.987003,264.30854 C214.995728,264.072211 215,263.835882 215,263.599553 C214.668525,263.393895 214.328107,263.196945 213.987899,263 Z" id="路径" fill="#FFFFFF"></path>
                    <path d="M125.139172,73 L48.4084332,73 C47.0789062,72.9985014 46.0014794,71.9053486 46,70.5564136 L46,11.4438078 C46.0014624,10.0948298 47.078864,9.00160356 48.4084332,9 L142.591567,9 C143.921136,9.00160356 144.998538,10.0948298 145,11.4438078 L145,52.8492486 C144.987628,63.9729978 136.102876,72.9874478 125.139172,73 L125.139172,73 Z" id="路径" fill="#FFFFFF"></path>
                    <path d="M125.807134,73 L48.6415692,73 C47.1833855,72.9982768 46.0017223,71.8143147 46,70.3532917 L46,11.6469282 C46.0016279,10.1858304 47.183311,9.00175004 48.6415692,9 L143.358431,9 C144.81672,9.00167507 145.998447,10.1857993 146,11.6469282 L146,52.7678165 C145.987326,63.9364788 136.954092,72.9873015 125.807134,73 L125.807134,73 Z M48.6415692,9.87966015 C47.6679601,9.88085514 46.8790246,10.6714232 46.8779507,11.6469282 L46.8779507,70.3532917 C46.8790417,71.328754 47.6680026,72.1192491 48.6415692,72.1203398 L125.807134,72.1203398 C136.469475,72.1082738 145.110007,63.4509181 145.122049,52.7678165 L145.122049,11.6469282 C145.120975,10.6714232 144.33204,9.88085514 143.358431,9.87966015 L48.6415692,9.87966015 Z" id="形状" fill="#CCCCCC"></path>
                    <path d="M124.028021,29 L66.9749155,29 C65.8841998,29 65,28.1045695 65,27 C65,25.8954305 65.8841998,25 66.9749155,25 L124.028021,25 C125.117589,25.0016419 126,25.8965913 126,27 C126,28.1034087 125.117589,28.9983581 124.028021,29 L124.028021,29 Z" id="路径" fill="#CCCCCC"></path>
                    <path d="M124.025182,43 L66.9748184,43 C65.8841563,43 65,42.1045695 65,41 C65,39.8954305 65.8841563,39 66.9748184,39 L124.025182,39 C125.115844,39 126,39.8954305 126,41 C126,42.1045695 125.115844,43 124.025182,43 L124.025182,43 Z" id="路径" fill="#CCCCCC"></path>
                    <path d="M124.025182,56 L66.9748184,56 C65.8841563,56 65,55.1045695 65,54 C65,52.8954305 65.8841563,52 66.9748184,52 L124.025182,52 C125.115844,52 126,52.8954305 126,54 C126,55.1045695 125.115844,56 124.025182,56 Z" id="路径" fill="#CCCCCC"></path>
                    <circle id="椭圆形" fill="#AEE7E1" cx="142.5" cy="11.5" r="11.5"></circle>
                    <path d="M141.427327,18 C141.023698,18 140.643637,17.8099137 140.40154,17.4869518 L137.256451,13.2934607 C136.981584,12.9269481 136.923129,12.4416372 137.103106,12.02034 C137.283083,11.5990427 137.67415,11.305764 138.128995,11.2509793 C138.583841,11.1961946 139.033362,11.3882268 139.30823,11.7547394 L141.365857,14.4980124 L146.6506,6.5710507 C146.904737,6.1898472 147.342941,5.97318052 147.800144,6.00266691 C148.257347,6.0321533 148.664089,6.30331308 148.867154,6.71400298 C149.07022,7.12469287 149.038758,7.6125193 148.78462,7.99372274 L142.494438,17.4289272 C142.26355,17.77519 141.879037,17.9879354 141.463017,17.9995982 C141.45112,17.9998119 141.439224,18 141.427327,18 Z" id="路径" fill="#FFFFFF"></path>
                    <path d="M97.5913581,124 L20.8607862,124 C9.89710446,123.98745 1.01236921,114.973029 1,103.849317 L1,62.4437996 C1.00156104,61.0947846 2.07903022,60.0015863 3.40863758,60 L97.5913581,60 C98.9209661,60.0015863 99.9984366,61.0947839 100,62.4437996 L100,121.556205 C99.9984365,122.90522 98.9209654,123.998416 97.5913581,124 L97.5913581,124 Z" id="路径" fill="#FFFFFF"></path>
                    <path d="M97.3582213,124 L20.192823,124 C9.04588867,123.987302 0.0126737498,114.936509 0,103.767885 L0,62.6469193 C0.00165226429,61.1857541 1.1834469,60.0016579 2.64177436,60 L97.3582213,60 C98.8165494,60.0016579 99.9983453,61.1857534 100,62.6469193 L100,121.353085 C99.9983453,122.81425 98.8165487,123.998345 97.3582213,124 Z M2.64177436,60.8796572 C1.66812604,60.880835 0.879121941,61.6713764 0.877948827,62.6469193 L0.877948827,103.767885 C0.889991361,114.45095 9.53050472,123.108277 20.192823,123.120343 L97.3582213,123.120343 C98.3319006,123.119242 99.1209505,122.328659 99.1220512,121.353085 L99.1220512,62.6469193 C99.1208756,61.6713757 98.3318703,60.880835 97.3582213,60.8796572 L2.64177436,60.8796572 Z" id="形状" fill="#CCCCCC"></path>
                    <path d="M78.0251815,80 L20.9748185,80 C19.8841564,80 19,79.1045695 19,78 C19,76.8954305 19.8841564,76 20.9748185,76 L78.0251815,76 C79.1158436,76 80,76.8954305 80,78 C80,79.1045695 79.1158436,80 78.0251815,80 Z" id="路径" fill="#CCCCCC"></path>
                    <path d="M78.0251794,93 L20.9748206,93 C19.8841573,93 19,92.1045695 19,91 C19,89.8954305 19.8841573,89 20.9748206,89 L78.0251794,89 C79.1158427,89 80,89.8954305 80,91 C80,92.1045695 79.1158427,93 78.0251794,93 Z" id="路径" fill="#CCCCCC"></path>
                    <path d="M78.0251794,108 L20.9748206,108 C19.8841573,108 19,107.104569 19,106 C19,104.895431 19.8841573,104 20.9748206,104 L78.0251794,104 C79.1158427,104 80,104.895431 80,106 C80,107.104569 79.1158427,108 78.0251794,108 Z" id="路径" fill="#CCCCCC"></path>
                    <circle id="椭圆形" fill="#4CD0C0" cx="96.5" cy="63.5" r="11.5"></circle>
                    <path d="M95.4272782,70 C95.0236534,70 94.643597,69.8099157 94.4015028,69.4869573 L91.2564487,65.2935108 C90.9815838,64.9270021 90.9231294,64.4416964 91.1031047,64.0204036 C91.2830801,63.5991107 91.6741426,63.3058352 92.1289828,63.2510511 C92.583823,63.1962669 93.0333399,63.3882972 93.3082047,63.7548059 L95.3658085,66.4980497 L100.650493,58.5711724 C101.043351,57.9818893 101.839537,57.8226536 102.428824,58.2155094 C103.018111,58.6083652 103.177348,59.4045461 102.784489,59.9938293 L96.4943769,69.4289333 C96.2634912,69.7751923 95.8789831,69.9879355 95.4629675,69.9995982 C95.451071,69.9998119 95.4391746,70 95.4272782,70 Z" id="路径" fill="#FFFFFF"></path>
                    <path d="M192.879485,178 L84.9630801,178 C73.9429294,177.98748 65.0124329,168.994655 65,157.897591 L65,97.1425146 C65.0019826,95.407781 66.3980194,94.0019989 68.1207296,94 L192.879485,94 C194.602152,94.002021 195.998114,95.4078251 196,97.1425146 L196,174.857273 C195.998134,176.592005 194.602194,177.997882 192.879485,178 L192.879485,178 Z" id="路径" fill="#FFFFFF"></path>
                    <path d="M193.67126,178 L86.1150846,178 C75.0110638,177.987311 66.012625,168.94329 66,157.78302 L66,97.3458223 C66.0020628,95.4988368 67.4912782,94.0020757 69.3289547,94 L193.67126,94 C195.508863,94.0021678 196.997964,95.4989098 197,97.3458223 L197,174.653967 C196.998053,176.500951 195.508934,177.997805 193.67126,178 L193.67126,178 Z M69.3289547,94.8789991 C67.9740914,94.8805951 66.8761544,95.9840958 66.8745689,97.3458223 L66.8745689,157.78302 C66.8865651,168.458094 75.4938141,177.108944 86.1150846,177.121001 L193.67126,177.121001 C195.026121,177.119286 196.123961,176.015692 196.125431,174.653967 L196.125431,97.3458223 C196.123937,95.9841417 195.026077,94.8806219 193.67126,94.8789991 L69.3289547,94.8789991 Z" id="形状" fill="#CCCCCC"></path>
                    <path d="M168.40693,121 L93.59307,121 C92.6670558,121.001157 91.8108694,120.524913 91.3475155,119.750935 C90.8841615,118.976957 90.8841615,118.023043 91.3475155,117.249065 C91.8108694,116.475087 92.6670558,115.998843 93.59307,116 L168.40693,116 C169.332944,115.998843 170.189131,116.475087 170.652485,117.249065 C171.115838,118.023043 171.115838,118.976957 170.652485,119.750935 C170.189131,120.524913 169.332944,121.001157 168.40693,121 L168.40693,121 Z" id="路径" fill="#CCCCCC"></path>
                    <path d="M168.41018,138 L93.5931788,138 C92.6671258,138.001157 91.8109034,137.524913 91.34753,136.750935 C90.8841567,135.976957 90.8841567,135.023043 91.34753,134.249065 C91.8109034,133.475087 92.6671258,132.998843 93.5931788,133 L168.41018,133 C169.335434,133 170.190403,133.476499 170.65303,134.250001 C171.115657,135.023503 171.115657,135.976497 170.65303,136.749999 C170.190403,137.523501 169.335434,138 168.41018,138 L168.41018,138 Z" id="路径" fill="#CCCCCC"></path>
                    <path d="M168.406821,157 L93.5898196,157 C92.1595017,157 91,155.880711 91,154.5 C91,153.119289 92.1595017,152 93.5898196,152 L168.406821,152 C169.332874,151.998843 170.189097,152.475087 170.65247,153.249065 C171.115843,154.023043 171.115843,154.976957 170.65247,155.750935 C170.189097,156.524913 169.332874,157.001157 168.406821,157 L168.406821,157 Z" id="路径" fill="#CCCCCC"></path>
                    <circle id="椭圆形" fill="#00BCA6" cx="194" cy="99" r="15"></circle>
                    <path d="M191.534515,107 C191.029948,107 190.554843,106.762368 190.252202,106.358628 L186.320584,101.116226 C185.976977,100.658036 185.903904,100.051333 186.128893,99.5246566 C186.353882,98.9979799 186.84275,98.6313441 187.411346,98.5628576 C187.979942,98.494371 188.541883,98.7344383 188.88549,99.1926284 L191.457686,102.622069 L198.064033,92.7123358 C198.555641,91.9769462 199.549978,91.7787355 200.285975,92.2694149 C201.021972,92.7600944 201.221451,93.754206 200.73173,94.4908535 L192.868494,106.286056 C192.579861,106.718921 192.09919,106.984872 191.579135,106.999447 C191.564263,106.999721 191.549383,107 191.534515,107 Z" id="路径" fill="#FFFFFF"></path>
                    <polygon id="路径" fill="#FFB6B6" points="233 297.005376 226.853785 299 216 276.944056 225.071741 274"></polygon>
                    <path d="M236,301.80557 L216.499757,307 L216.417263,306.754343 C215.739295,304.735048 215.891281,303.874473 216.845586,301.969184 C217.788204,300.073847 219.452886,298.632844 221.468624,297.967332 L221.469103,297.967171 L224.127202,294.089069 L231.676946,294.567009 L233.379199,294 L236,301.80557 Z" id="路径" fill="#2F2E41"></path>
                    <polygon id="路径" fill="#FFB6B6" points="246.998612 303 240.223538 303 237 278 247 278.000443"></polygon>
                    <path d="M248.999609,308 L228,307.999223 L228,307.734034 C228,305.554256 228.850395,304.836675 230.394388,303.295526 C231.923818,301.760252 234.004831,300.899626 236.173631,300.905457 L236.174151,300.905457 L240.010011,298 L247.166863,300.905901 L249,300.905977 L248.999609,308 Z" id="路径" fill="#2F2E41"></path>
                    <path d="M198.092173,103.701708 C198.351507,105.074742 199.181962,106.257721 200.360077,106.932327 C201.538193,107.606933 202.942948,107.703868 204.196349,107.19705 L211.378661,115 L217,111.614631 L206.708463,100.743598 C205.729205,98.5609737 203.335931,97.4854674 201.137682,98.2401615 C198.939433,98.9948555 197.631046,101.341202 198.092173,103.701708 Z" id="路径" fill="#FFB6B6"></path>
                    <polygon id="路径" fill="#E4E4E4" points="246.670558 143 250 139.51777 230.74585 121.491514 211.367207 104 206 111.666557 226.307323 130.828093"></polygon>
                    <path d="M234.48803,261.419308 C234.48803,257.101974 234.574797,254.022608 234.644457,251.548078 C234.848389,244.306431 234.903164,242.359913 232.089957,229.889141 L231.367378,226.686501 L227.106626,239.277755 C225.745752,243.29432 225.050345,247.500545 225.047109,251.73491 L231.616687,290.563295 L218.189401,290.563295 L208.355418,248.586049 L208.35081,235.745807 C206.54973,233.909944 206.54973,230.998289 208.35081,229.162425 L211.617965,209.925634 L214.924908,184 L251.147018,184 C251.147018,184 257.539611,197.052219 251.186819,209.999321 L251.186111,261.435369 L247.903894,297 L237.77096,297 L234.48803,261.419308 Z" id="路径" fill="#2F2E41"></path>
                    <path d="M249.520089,185.984681 L216.173153,183.153947 L216,183.139377 L218.66123,134.341918 C218.981316,128.064137 223.027371,122.619993 228.872005,120.602874 L228.872005,117.18797 C228.873963,115.428112 230.273977,114.001973 232.001559,114 L236.873912,114 C238.060421,113.995956 239.145996,114.67941 239.673056,115.762298 L242.055582,120.61638 L251.06493,127.958552 C255.514642,131.584939 251.93331,167.942485 251.740792,169.860801 L253.919727,180.959466 C254.162273,182.195686 253.848832,183.477598 253.0654,184.453494 C252.281967,185.429391 251.11082,185.996783 249.873276,186 C249.756221,186 249.638493,185.994515 249.520089,185.984681 Z" id="路径" fill="#E4E4E4"></path>
                    <path d="M213.62232,198.917296 C214.683573,198.039181 215.306459,196.729858 215.32337,195.341644 C215.340281,193.95343 214.749481,192.628927 213.709942,191.724536 L217,181.535995 L211.377178,178 L206.930416,192.454307 C205.515691,194.366516 205.736039,197.048688 207.443317,198.697563 C209.150596,200.346438 211.805163,200.440836 213.62232,198.917292 L213.62232,198.917296 Z" id="路径" fill="#FFB6B6"></path>
                    <polygon id="路径" fill="#E4E4E4" points="225 128.546698 220.231438 127 213.343766 156.899313 207 186.634102 216.541194 188 223.940219 156.383747"></polygon>
                    <circle id="椭圆形" fill="#FFB6B6" cx="230" cy="101" r="11"></circle>
                    <path d="M215.015798,94.9440976 C215.115211,93.8805369 215.40812,92.846356 215.878911,91.8966845 C216.383141,93.0374975 217.955781,93.2354381 219.032095,92.6676541 C220.108584,92.0998522 220.82979,91.0214572 221.598933,90.053952 C223.628727,87.5009376 226.420103,85.4300345 229.583595,85.0528409 C232.747245,84.6756651 236.234247,86.3373293 237.429619,89.4084297 C237.965969,88.6334148 239.110489,88.5472515 239.920004,88.9976075 C240.701973,89.5106956 241.312218,90.2629808 241.66702,91.1512575 C243.276566,94.5875945 243.435531,98.5668199 242.105619,102.130302 C240.773495,105.637986 237.483903,110.363935 234.089861,111.687801 C232.029314,112.491552 229.948149,111.462829 227.756693,111.687801 C227.871456,109.19919 228.335138,106.128607 228.449897,103.639995 C228.512776,102.277113 228.521905,100.752313 227.627331,99.7546897 C227.071766,99.2058875 226.310728,98.942051 225.549855,99.0344707 C224.788982,99.1268905 224.106805,99.5660277 223.687654,100.233224 C223.576874,99.4594561 223.325773,98.714647 222.947717,98.0384436 C222.552071,97.3544758 221.817914,96.9631238 221.053299,97.0285993 C220.138305,97.1846424 219.573087,98.1620177 218.733337,98.5713235 C217.756738,98.9786266 216.639746,98.7120699 215.93131,97.9026536 C215.252094,97.0871052 214.921515,96.0188091 215.015798,94.9440976 L215.015798,94.9440976 Z" id="路径" fill="#2F2E41"></path>
                    <polygon id="路径" fill="#000000" opacity="0.1" points="231 158 218 184 227.75033 184"></polygon>
                    <path d="M318,308 C318,308.265467 317.945694,308.520297 317.847932,308.708011 C317.750171,308.895726 317.617455,309.000818 317.479201,309 L70.5207969,309 C70.2331687,309 70,308.552282 70,308 C70,307.447718 70.2331687,307 70.5207969,307 L317.479201,307 C317.617457,306.999173 317.750175,307.104263 317.847938,307.291979 C317.9457,307.479695 318,307.734531 318,308 Z" id="路径" fill="#CACACA"></path>
                </g>
                <text id="求职模板-在线编辑" font-family="DingTalk-JinBuTi, DingTalk JinBuTi" font-size="15" font-weight="normal" line-spacing="21" letter-spacing="1" fill="#839693">
                    <tspan x="3" y="223">求职模板 </tspan>
                    <tspan x="3" y="244">         在线编辑</tspan>
                </text>
                <text id="轻松斩获高薪Offer" font-family="DingTalk-JinBuTi, DingTalk JinBuTi" font-size="15" font-weight="normal" line-spacing="21" letter-spacing="1" fill="#839693">
                    <tspan x="182" y="14">轻松斩获高薪Offer</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>`
}}/>

const Content = (props) => {
    const {onCancel} = props;
    const dispatch = useDispatch();
    const fetchUser = async () => {
        try {
            await dispatch(fetchUserThunk());
            props.onSuccess?.();
        } catch (error) {
            message.error('网络异常')
        }
    };
    return (
        <BoxStyled>
            <CloseStyled onClick={onCancel}>
                {closeIcon}
            </CloseStyled>
            <RightBoxStyled>
                <LogoStyled>{s10B_SVG}</LogoStyled>
                <BgStyled>{bgSvg}</BgStyled>
            </RightBoxStyled>
            <LeftBoxStyled>
                <LeftTitleStyled>微信登录</LeftTitleStyled>
                <LeftQRPositionStyled>
                    <LeftQRStyled>
                        <LoginQR height={218} width={218} onSuccess={fetchUser} reload={props.reload}/>
                    </LeftQRStyled>
                </LeftQRPositionStyled>
                <LeftDesStyled>
                    <div>使用微信扫一扫登录</div>
                    <div>“了了简历”</div>
                </LeftDesStyled>
                <LeftCheckStyled>
                    <Checkbox checked={true} style={{position: 'relative', top: 1}}/>
                    <FlexStyled style={{marginLeft: 6}}>
                        <a
                            target='_blank'
                            href={'/agreement/user'}
                            style={{color: '#BBBBBB', fontSize: 14, fontWeight: 400}}>
                            我已阅读并接受《用户协议》
                        </a>
                    </FlexStyled>
                </LeftCheckStyled>
            </LeftBoxStyled>
        </BoxStyled>
    );
};


export default withComponent((props) => {
    const {getContainer = null, visible, mask = true, zIndex} = props;
    return (
        <Modal
            open={visible}
            zIndex={zIndex}
            getContainer={getContainer}
            destroyOnClose
            centered
            mask={mask}
            width={780}
            modalRender={() => {
                return <Content {...props} />;
            }}
        />
    );
});
