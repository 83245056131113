import styled from "styled-components"


export const TitleStyled = styled.div`
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #888888;
    margin-bottom: 16px;
`

export const TargetItemImageWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 12px;
    row-gap: 12px;
    padding-bottom: 20px;
    border-bottom: 1px solid #F4F4F4;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .img-item {
        position: relative;
        border-radius: 8px;
        border: 1px solid #E4E4E4;
        width: 100%;
        padding-bottom: 100%;
        overflow: hidden;
    }
`

export const TargetLinkItem = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #F4F4F4;
`

export const ButtonStyled = styled.div`
    margin-top: 20px;
    width: 100%;
    height: 36px;
    background: #F5F5F5;
    border-radius: 4px;

    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
`

export const PortfolioListStyled = styled.div`
    .resume-wrap-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 15px;
        padding-bottom: 24px;
        background-color: #FFFFFF;
    }
`

export const UploadStyled = styled.div`
    position: relative;
    width: 100%;
    background: #F5F5F5;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    padding-bottom: 100%;
    .add-svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    svg {
        width: 24px;
        height: 24px;
    }
    input {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
`