import JzImage from "@/components/JzImage";
import config from "@/const";
import withComponent from "@/hoc/withComponent";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlexStyled from "@/styled/FlexStyled";

const FooterStyled = styled.div`
  background: #222222;
  color: #777777;
  padding-top: 36px;
  .logo-img {
    width: 131px;
    height: 34px;
    margin-bottom: 40px;
  }
  .hover-white{
    color:rgb(153, 153, 153);
    &:hover{
      color:#fff;
    }
  }

`;

const Footer = () => {
  const navigate = useNavigate();

  const handleClickAgreement = () => {
    navigate("/agreement/user");
  };

  return (
    <FooterStyled>
      <div
        className="flex justify-between"
        style={{ maxWidth: 800, margin: "0 auto" }}
      >
        <div className="flex flex-col">
          <JzImage className="logo-img" src="sldknfijxcbnvjhbu.png" />

          {/* <div onClick={handleClickAgreement} className="mb-22" style={{ color: '#BBB' }}>
                    用户协议
                </div> */}

          <FlexStyled
            justifyContent="center"
            alignItems="center"
            style={{ paddingBottom: 18 }}
          >
            <a
              rel="nofollow"
              target="_blank"
            className="hover-white"
              href={"/pptx/template/abu"}
            >
              关于我们
            </a>{" "}
            <span
              style={{
                height: 14,
                width: 1,
                background: "#D8D8D8",
                margin: "0 24px",
              }}
            />{" "}
            <a
            className="hover-white"
              rel="nofollow"
              target="_blank"
              href={"/agreement/user"}
            >
              用户协议
            </a>{" "}
            <span
              style={{
                height: 14,
                width: 1,
                background: "#D8D8D8",
                margin: "0 24px",
              }}
            />{" "}
            <a
            className="hover-white"
              rel="nofollow"
              target="_blank"
              href={"/agreement/law"}
            >
              版权声明
            </a>
          </FlexStyled>

          <div className="flex" style={{ paddingBottom: 30 }}>
            <div style={{ textAlign: "left" }}>
              <div style={{ marginBottom: 8 }}>
                Copyright © 2022 {"了了简历" || config.productName} All Rights
                Reserved·{config.companyName}{" "}
                <a
                  style={{ color: "#777" }}
                  rel="nofollow"
                  target="_blank"
                  href="http://beian.miit.gov.cn/"
                >
                  {config.record}
                </a>
              </div>
              <div style={{ marginBottom: 8 }}></div>
            </div>
          </div>
        </div>

        <div className="flex-center flex-col mb-30">
          <JzImage
            style={{ width: 88, height: 88, marginBottom: 9 }}
            src="nfdkscxvdfn123123.png"
          />
          <div style={{ color: "#999", fontSize: 12 }}>微信、手机制作简历</div>
        </div>
      </div>
    </FooterStyled>
  );
};

export default withComponent(Footer);
