import { Modal } from 'antd';
import FlexStyled from '@/styled/FlexStyled';
import { Dialog } from 'antd-mobile';
import Pointer from '@/styled/Pointer';
import {BtnStyleEl, BtnStyleEl2} from "@/components/ErrorModal";
import JzImage from '@/components/JzImage';
import '@/pages/Mobile/pages/My/index.scss'

const closeIcon = <div dangerouslySetInnerHTML={{
    __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='输入主题，流程弹窗◉◉' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='弹窗' transform='translate(-1124.000000, -381.000000)' fill='#999999' fill-rule='nonzero'>
            <g id='搜索' transform='translate(1124.000000, 381.000000)'>
                <g id='编组-11' transform='translate(4.500000, 4.500000)'>
                    <polygon id='路径' points='0.0573613767 13.6704343 13.6387704 0 14.9426386 1.32956573 1.36012649 15'></polygon>
                    <polygon id='路径' points='13.709369 14.9408834 0 1.40095181 1.29063098 0.0591165973 15 13.5979328'></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;

const SaveModal = ({ visible, onConfirm, onCancel }) => {

    return (
        <Dialog
                bodyClassName = 'user-quit-dialog'
                visible={visible}
            header={(
                    <div className="mdl-title">
                     
                    <JzImage src='delete-icon.png' style={{ width: 20, height: 20, marginRight: 6 }} />

                    <div className="title">确定退出吗？</div>
                    </div>
                    )}
            content= {(<>
                <div className="mdl-text">退出后作答内容将不会被保留</div>
            </>)}
            closeOnAction
            maskStyle={{
                height: 'calc(100vh - 80px)'
            }}
            actions={
                [[
                    {
                    text: '取消',
                    key: 'cancel',
                    style: { color: '#000000' },
                    onClick: onCancel
                },
                {
                    text: '确认',
                    key: 'upgrade',
                    style: { color: '#11BBA6' },
                    onClick: onConfirm
                }
                ]]
            }
        />
    )
}


export default SaveModal