import isModuleMainList from "@/common/config/isModuleMainList";
import {Module} from "@/common/module/module";

const deleteBlockConfig = (config) => {
    if (!config) return;
    if (config.block_config) {
        delete config.block_config
    }
    if (config.children) {
        config.children?.forEach?.((configChild) => {
            deleteBlockConfig(configChild);
        })
    }
}

function getLinkConfig(config) {
    let children = [...config.children];
    if (children && children instanceof Array) {
        children.forEach((configChild) => {
            if (configChild && configChild.tagName === "link") {
                this.linkConfig.push(configChild);
                const index = config.children.findIndex((c) => c === configChild);
                config.children.splice(index, 1)
            }
        })
    }
}


function handler2(config, for_block_name, for_field_name) {
    if (!config) return;
    if (typeof config === "string") {
        return;
    }
    let block_config = config.block_config;
    config.for_block_name = for_block_name;
    config.for_field_name = for_field_name;
    if (config.type === "module_main" && isModuleMainList(config.for_block_name || config.block_name)) {
        config.contentTemplateConfig = config.children
        config.children = [];
    }
    if (config.block_name) {
        if (block_config) {
            config.block_name = undefined;
        }
    }
    if (config.block_name) {
        if (this.currentLayoutType === "main") {
            // 不添加基础信息和头像
            if (config.block_name === Module.BASE || config.block_name === Module.AVATAR) return
            config.for_layout = "main"
            this.mainModuleConfig.push(config.block_name)
        }
        if (this.currentLayoutType === "left") {
            if (config.block_name === Module.BASE || config.block_name === Module.AVATAR) return
            config.for_layout = "left"
            this.leftModuleConfig.push(config.block_name)
        }
        block_config = config;
        for_block_name = config.block_name
    }
    if (config.field_name) {
        for_field_name = config.field_name;
    }
    if (config["data-layout-type"]) {
        this.currentLayoutType = config["data-layout-type"];
    }
    if (config.children) {
        if (config.children instanceof Array) {
            config.children.forEach((configChild) => {
                if (block_config) {
                    if (configChild && typeof configChild !== "string") {
                        configChild.block_config = block_config;
                    }
                }
                handler2.call(this, configChild, for_block_name, for_field_name);
            })
        }
    }
    if (config["data-layout-type"]) {
        this.currentLayoutType = undefined
    }
}


function moduleBlock(parent, config) {
    if (!config) return;
    if (config.block_name) {
        this.moduleConfig[config.block_name] = config;
        if (config.block_name === Module.BASE || config.block_name === Module.AVATAR) return
        // 不删除基本信息和头像

        if (parent && config.for_layout) {
            const index = parent.children.findIndex((c) => c === config);
            parent.children.splice(index, 1);
            return;
        }
    }
    if (config.children && config.children.forEach) {
        [...config.children].forEach((configChild) => {
            moduleBlock.call(this, config, configChild);
        })
    }
}


function moduleIncludeFieldHandler() {
    if(this.moduleConfig){
        Object.keys(this.moduleConfig).forEach((key) => {
            const config = this.moduleConfig[key];
            const fieldSet = new Set();
            function t(config) {
                if(config && config.field_name){
                    fieldSet.add(config.field_name)
                }
                if (config.children) {
                    config.children?.forEach?.((configChild) => {
                        t(configChild);
                    })
                }
            }
            t(config);
            this.moduleIncludeField[key] = [...fieldSet]
        })
    }

}

const configHandler = (config, for_block_name, for_field_name) => {
    if (!config) return;
    const obj = {
        layoutConfig: config,
        moduleConfig: {},
        linkConfig: [],
        leftModuleConfig: [],
        mainModuleConfig: [],
        moduleIncludeField: {},
        currentLayoutType: undefined
    };
    handler2.call(obj, config);
    deleteBlockConfig(config);
    moduleBlock.call(obj, null, config);
    moduleIncludeFieldHandler.call(obj, null, config);
    getLinkConfig.call(obj, config);

    return obj
}

export default configHandler
