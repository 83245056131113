import { Outlet, useLocation, useNavigate } from "react-router-dom"
import styled, { ThemeProvider } from "styled-components"
import { useEffect,useState } from "react"
import ErrorModal from "./components/ErrorModal"
import { getParam, isWechatBrowser, wxLogin } from "./utils"
import withComponent from "@/hoc/withComponent"
import './index.scss'
import './reset.scss'
import { Toast } from "antd-mobile"
import useResumeUserList from "./hooks/useResumeUserList"
import withStore from "@/components/resume/hoc/withStore"
import {useDispatch, useSelector} from 'react-redux';


const theme = {
    colors: {
      primary: '#11BBA6',
    },
}

const GlobalStyled = styled.div`
    max-width: 650px;
    margin: 0 auto;
`

const Mobile = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    useResumeUserList()
    const errorCode = useSelector((state) => state.error.code);
    const [index, setIndex] = useState(0)
    useEffect(()=>{
        if(errorCode){

            setIndex(errorCode)
        }
    },errorCode)
    useEffect(() => {
        // 存在重定向
        const redirectUrl = decodeURIComponent(decodeURIComponent(getParam('jz_redirect_url')))


        if (redirectUrl) {
            const u = new URL(redirectUrl)
            const path = u.pathname + u.search
            navigate(path)
        }
    }, [])

    // 微信内自动触发登录
    useEffect(() => {
        if (isWechatBrowser() && getParam('code')) {
            Toast.show({
                icon: 'loading',
                content: '登录中…',
            })
            wxLogin().finally(() => {
                Toast.clear()
            })
        }
    }, [])
    
    return <ThemeProvider theme={theme}>
        <GlobalStyled>
            <Outlet />
            <ErrorModal key={index} errorCode = {errorCode} />
        </GlobalStyled>
    </ThemeProvider>
}

export default withStore(withComponent(Mobile))