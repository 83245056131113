import {Module} from "@/common/module/module";
import {CONTACT_INFORMATION_SLOT_ENUM} from "@/common/slot/slot";

export default (resumeData, id) => {
    switch (id) {
        case Module.CONTACT_INFORMATION: {
            return resumeData[id]?.data[CONTACT_INFORMATION_SLOT_ENUM.TITLE]
        }
        default:{
            return resumeData?.[id]?.data?.title_text
        }
    }
}
