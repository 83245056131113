import styled from 'styled-components';
import withComponent from '@/hoc/withComponent';
import {Tooltip} from "antd";
import useVersionCanNext from "@/pages/ResumeDetail/hooks/useVersionCanNext";
import {useCallback} from "react";
import useResumeDetailInstance from "@/pages/ResumeDetail/hooks/useResumeDetailInstance";

const RevocationStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: ${(props) => (props['data-disabled'] ? 'not-allowed' : 'pointer')};

  path {
    fill: ${(props) => (props['data-disabled'] ? (props.isDark ? '#999999' : '#555555') : (props.isDark ? '#6E727C' : 'white'))};
  }
`;
const icon = <div style={{width: 24, height: 24}} dangerouslySetInnerHTML={{
    __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='新建文档流程～～' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='顶部流程优化' transform='translate(-274.000000, -115.000000)' fill='#555555' fill-rule='nonzero'>
            <g id='文本备份-21' transform='translate(274.000000, 115.000000)'>
                <path d='M8.74335833,6.20892642 C8.77167248,6.22143005 8.78648746,6.24303167 8.78653027,6.26315932 L8.78652981,8.54764305 L14.2377184,8.54764305 C16.9960257,8.54229811 19.2438955,10.562864 19.2989669,13.0812054 C19.3540261,15.598988 17.1971355,17.7001258 14.4417215,17.7966126 L14.2343919,17.8000277 L6.59962592,17.8000277 C6.44284002,17.8000277 6.32224514,17.6891656 6.32224514,17.5612565 L6.32224514,16.6837695 C6.32224514,16.5558604 6.44284002,16.445026 6.59962592,16.445026 L14.2377184,16.445026 C16.1539458,16.4475683 17.7298064,15.040493 17.7828602,13.2640624 C17.8359365,11.4868741 16.3453196,10.0013624 14.4308529,9.90724572 L14.2425038,9.9026743 L8.78652981,9.90261704 L8.78652981,12.1875261 C8.78648746,12.2072284 8.77167248,12.22883 8.74335833,12.2413337 C8.7093863,12.2563359 8.66772073,12.252008 8.63976007,12.2315232 L4.79711927,9.40621647 C4.73475471,9.36023186 4.7,9.29365451 4.7,9.22513005 C4.7,9.15660558 4.73475471,9.09002823 4.79733825,9.04388239 L8.64003454,6.2185355 C8.658492,6.20501321 8.68316252,6.1985823 8.70762449,6.2002426 L8.74335833,6.20892642 Z' id='路径备份-3' transform='translate(11.999999, 12.000007) scale(-1, 1) translate(-11.999999, -12.000007) '></path>
            </g>
        </g>
    </g>
</svg>`,
}}/>;

export default withComponent(() => {
    const resumeDetailInstance = useResumeDetailInstance()
    const canNext = useVersionCanNext()
    const onClick = useCallback(() => {
        resumeDetailInstance.resumeRef.current?.versionNext?.()
    }, [])
    return <RecoverContainer disabled={!canNext} onClick={canNext ? onClick : undefined} style={{marginRight: 16}}/>;
});
export const RecoverContainer = (props) => {
    const {disabled, onClick, style, isDark} = props;
    return <Tooltip placement='bottom'
                    title={'重做'}
                    mouseEnterDelay={1}
                    mouseLeaveDelay={0}
                    arrowPointAtCenter>
        <RevocationStyled data-disabled={disabled} isDark={true} onClick={onClick} style={style}>
            {icon}
        </RevocationStyled>
    </Tooltip>
};
