import styled from "styled-components"
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Select, Switch, Typography, message } from 'antd';
import useSelector from "@/components/resume/redux/hooks/useSelector";
import { addJobDescription, deleteJobDescription, deleteResumeOfUser, generateQuestions, getJobDescription, modifyResumeWithJD, updateJobDescription, userBehaviorLog } from "@/http/api";
import { useLocation, useParams } from "react-router-dom";
const { Paragraph, Text } = Typography;
import TargetPositionFooter, { dropdownSvg } from "./TargetPositionFooter";
import JdModal from "./JdModal";
import { processInterviewData } from "../Interview";
import useDispatch from "@/components/resume/redux/hooks/useDispatch";
import interviewSlice from "@/components/resume/redux/services/interviewSlice";
import TabList from "./TabList";
import useClickOutside from "../../hooks/useClickOutside";
import Dialog from "@/components/Dialog";

const TargetPositionStyled = styled.div`
    .wrap {
        .target-content {
            position: relative;
            z-index: 1;
            flex: 1;
            padding: 10px;
            background-color: #fff;
            /* margin-right: 12px; */
            padding-bottom: 7px;
            border-radius: 4px;
            border-top-left-radius: 0;
            box-shadow: 6px 3 12px -4px rgba(0,0,0,0.1);
            .header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 6px;
                .name {
                    font-size: 14px;
                    font-weight: bold;
                }
                .right {
                    display: flex;
                    .dropdown {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        height: 32px;
                        border-radius: 8px;
                        border: 1px solid #ECECEC;
                        color: #999;
                    }
                }
            }
        }
    }
    .ant-select-open {
        background-color: #F4F6F7 !important; 
        color: #6E727C !important;
    }
`

export const ButtonStyled = styled.div`
    background: #FFFFFF;
    /* box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1); */
    /* border: 1px solid #EDF1F1; */
    outline: 1px solid #E2F0EE;
    box-sizing: content-box;
    border-radius: 6px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    /* color: #999999; */
    color: #69B4A9;
    padding: 5px 10px;
    margin-right: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.$visible && `color: #69B4A9; outline: none;background: #E2F0EE;`}
    ${props => props.isSave && `color: #69B4A9; outline: none;background: #E2F0EE;`}
    &:hover {
        cursor: pointer;
        color: #69B4A9; 
        outline: none;
        background: #E2F0EE;
    }
`


const ContentStyled = styled.div`
    position: relative;
    z-index: 1001;
    border-radius: 4px;
    border: 1px solid #ECECEC;
    font-size: 14px;
    color: #444444;
    padding: 10px;
    ${props => props.$focus && `border: 1px solid #11BBA6; outline: 2px solid #E4F8F5;padding-bottom: 6px;`}
    /* ${props => !props.$visible && `border: none; padding: 0;`} */
    ${props => !props.$visible && `border: none; background: #F7F7F7;`}
    .desc-wrap {
        height: 122px;
        background: #F7F7F7;
    }
    .desc {
        height: 102px;
        line-height: 1.5;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis; 
        white-space: pre-wrap;
    }
    .expand {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D1D1D1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
    }
    .expand:hover {
        cursor: pointer;
        color: #11BBA6;
        svg path{
            fill: #11BBA6;
        }
    }
    textarea {
        line-height: 1.5;
        outline: none;
        border: none;
        height: 260px;
        resize:none;
        overflow-y: scroll;
        width: 100%;
    }
`

const MaskStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
`

const processDesc = (desc = '') => {
    if (!desc) return ''
    // 使用换行符分割文本
    const lines = desc.split('\n');
    // 第一行是职位名称
    const firstLine = lines[0];
    // 剩余的数据，从第二行开始
    const remainingData = lines.slice(1).join('\n');

    return {
        jobTitle: firstLine,
        content: remainingData
    }
}


// 自定义钩子用于获取查询参数值
function useQueryParam(param) {
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    return query.get(param);
}


const TargetPosition = (props) => {
    const { setResumeLoading, onChangeTab, tabKey } = props
    const [visible, setVisible] = useState(false)
    const [isModify, setIsModify] = useState(false)
    const [isSave, setIsSave] = useState(false)
    const resumeData = useSelector(state => state.resumeData);
    const [focus, setFocus] = useState(false)
    const selectId = useSelector((state) => state.interview.selectJdId)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    useClickOutside({
        enable: loading,
        whiteList: [],
        callback: () => {
            setOpen(true)
        }
    })
    const cancelRef = useRef(new AbortController())
    const inputRef = useRef(null)
    const [dataList, setDataList] = useState([])
    const [jdModalVisible, setJdModalVisible] = useState(false)
    const [jdModalType, setJdModalType] = useState('select')
    const resumeId = useQueryParam('id');
    const contentRef = useRef(null)
    const targetJd = useMemo(() => {
        return dataList.find((item) => item.id === selectId) || dataList[0] || {}
    }, [selectId, dataList])
    const dispatch = useDispatch()

    useEffect(() => {
        // 初次通过获取
        getDataList()
    }, [resumeId])

    useEffect(() => {
        // 组件挂载时添加监听器
        document.addEventListener('mousedown', handleClickOutside);

        // 组件卸载时移除监听器
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    function handleClickOutside(event) {
        // 获取ContentStyled组件的DOM节点
        const contentStyledNode = contentRef.current;
      
        // 检查点击的元素是否是ContentStyled组件或其子元素
        // 如果不是，则调用handleBlur函数
        if (contentStyledNode && !contentStyledNode.contains(event.target)) {
          handleBlur();
        }
    }

    const getDataList = () => {
        getJobDescription({
            query: {
                resumeId,
            }
        }).then(data => {
            if (data) {
                let newDataList = data.map(item => {
                    const { jobTitle, content } = processDesc(item.description)
                    return {
                        id: item.id,
                        title: jobTitle,
                        content,
                        description: item.description
                    }
                })
                if (newDataList && newDataList.length > 0) {
                    const jdId = newDataList[newDataList.length - 1].id
                    dispatch(interviewSlice.actions.setJdId(jdId))
                    setDataList(newDataList)
                }
            }
        }).catch(err => {
            console.log('err', err)
        })
    }

    const addJob = (description = '') => {
        addJobDescription({
            body: {
                resumeUserId: resumeId,
                description
            }
        }).then(res => {
            getDataList()
        }).catch(err => {
            console.log('err', err)
        })
    }

    const deleteJob = (id) => {
        console.log('删除', {
            resumeUserId: resumeId,
            id
        })
        deleteJobDescription({
            body: {
                resumeUserId: resumeId,
                id
            }
        }).then(res => {
            getDataList()
            message.success('删除成功')
        })
    }

    const modifyResumeByJd = async () => {
        window.customMessage(async (disableContinue=false) => {
            try {
                setLoading(true)
                setResumeLoading(true);
                cancelRef.current = new AbortController()
                const res = await modifyResumeWithJD({
                    body: {
                        id: resumeId,
                        resumeBody: {
                            userData: resumeData
                        },
                        jobDescription: targetJd.description,
                        jobTitle: targetJd.title
                    },
                    config: {
                        signal: cancelRef.current.signal
                    }
                })
                if (res) {
                    window.processModifyResume(res, true, disableContinue)
                } else {
                    console.log('没数据')
                }
            } catch (error) {
                // message.error(error?.msg || '接口出错')
            } finally {
                setOpen(false)
                setLoading(false)
                setResumeLoading(false)
            }
        })
    }
    window.modifyResumeByJd = modifyResumeByJd

    const generateInterviewByJd = async () => {
        window.customMessageWithInterview(async (disableContinue = false) => {
            setLoading(true)
            message.loading({
                content: '生成中...',
                duration: 0
            })
            cancelRef.current = new AbortController()
            try {
                const data = await generateQuestions({
                    query: {
                        resumeId: parseInt(resumeId),
                        userResumeJobDescriptionId: targetJd.id
                    },
                    config: {
                        signal: cancelRef.current.signal
                    }
                })
                if (data) {
                    const newList = processInterviewData(data)
                    dispatch(interviewSlice.actions.setList(newList))
                    onChangeTab && onChangeTab("question")
                    window.customMessageWithInterviewSuccess(disableContinue)
                }
            } catch (error) {
                
            } finally {
                setOpen(false)
                setLoading(false)
                message.destroy()
            }
        })
    }
    window.generateInterviewByJd = generateInterviewByJd

    const handleSave = () => {
        const body = {
            ...targetJd,
            resumeUserId: resumeId
        }
        updateJobDescription({
            body
        }).then(res => {
            message.success('保存成功')
            setIsSave(true)
            setIsModify(false)
        }).catch(err => {
            console.log('err', err)
            message.error('保存失败')
        })
    }

    // 点击触发简历流程
    const handleClick = async (e) => {
        if (tabKey === 'resume') {
            modifyResumeByJd()
        } else if (tabKey === 'question') {
            generateInterviewByJd()
        }
    }

    const handleExpand = (event) => {
        setVisible(!visible)
        setTimeout(() => {
            inputRef.current.focus()
        }, 0)
    }

    const handleClickAdd = () => {
        setJdModalVisible(true)
        setJdModalType('add')
    }

    const handleUpdateContent = (e) => {
        setIsModify(true)
        const newDataList = dataList.map(item => {
            if (item.id === selectId) {
                const { jobTitle, content } = processDesc(e.target.value)
                return {
                    ...item,
                    title: jobTitle,
                    content,
                    description: e.target.value
                }
            } else {
                return item
            }
        })
        setDataList(newDataList)
    }

    const handleChangeJd = (type, description) => {
        if (type === 'select') {
            handleUpdateContent({
                target: {
                    value: description
                }
            })
            // handleSave(description)
        } else if (type === 'add') {
            addJob(description)
        }
    }

    const handleSelectJd = () => {
        userBehaviorLog({ query: { type: 'CHOSE_POSITION'}})
        setJdModalVisible(true)
        setJdModalType('select')
    }

    const handleBlur = () => {
        setVisible(false)
        setFocus(false)
    }

    const handleOk = () => {
        cancelRef.current.abort()

        setOpen(false)
        setLoading(false)
        setResumeLoading(false)
        message.destroy()
    }

    return <TargetPositionStyled>
        <div className="wrap">
            <TabList 
                onDelete={deleteJob}
                onAdd={handleClickAdd}
                value={selectId}
                options={dataList}
            />
            <div className="target-content">
                
                <ContentStyled
                    ref={contentRef}
                    tabIndex={1}
                    $focus={focus} 
                    $visible={visible}
                >   
                    {
                        !visible ? <div className="desc-wrap">
                            <div className="desc">
                                {targetJd.description}
                            </div>
                            <div onClick={handleExpand} className="expand">
                                展开
                                {dropdownSvg}
                            </div>
                        </div> :
                        <textarea 
                            ref={inputRef}
                            value={targetJd.description} 
                            onChange={handleUpdateContent} 
                            onFocus={() => setFocus(true)}
                        /> 
                    }
                
                    {visible && isModify && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button size="small" style={{ fontSize: 12, width: 54 }} onClick={handleSave} type="primary">保存</Button>
                    </div>}
                </ContentStyled>
                <TargetPositionFooter
                    isSave={isSave}
                    tabKey={tabKey}
                    onClick={handleClick}
                    onExpand={handleExpand}
                    onSelect={handleSelectJd}
                    visible={visible}
                />
            </div>
            <JdModal 
                type={jdModalType}
                visible={jdModalVisible} 
                onCancel={() => setJdModalVisible(false)}
                onChange={handleChangeJd}
            />
        </div>
        <Dialog
            type="error"
            width={400}
            open={open}
            titleText='AI正在为您生成中，是否终止'
            descText='注意：此操作将计入您的AI使用额度'
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            cancelText={<>
                <div style={{ marginLeft: 6 }}>取消</div>
            </>}
            okText={<>
                <div style={{ marginLeft: 6 }}>确定</div>
            </>}
        />
    </TargetPositionStyled>
}

export default TargetPosition