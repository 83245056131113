import withComponent from "@/hoc/withComponent";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";
import { useSelector } from 'react-redux';
import { Input } from "antd-mobile";
import { useDispatch } from 'react-redux';
import { setConfigParams,clearFilterWithoutSearchWord, clearFilter} from '@/pages/Mobile/pages/TemplateList/indexSlice.js';

const TabItemSearchStyled = styled.div`
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  .search-input {
      margin-left: 15px;
    .adm-input-element{
        font-size: 14px;
    }
  }
  .input-wrapper {
    width: 100%;
    margin: 0  18px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 27px;
    outline: 3px solid rgba(45,196,195,0.2);
    display:flex;
    align-items: center;    
    margin-bottom:14px;
    .search-button{
        width: 114px;
        margin:6px;
        height: 36px;
        background: #11BBA6;
        border-radius: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 36px;
        text-align: center;
        font-style: normal;
    }
  }
  .jz-tag-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .tag {
      background: rgba(67, 203, 186);
      box-sizing: border-box;
      white-space: nowrap;
      display: inline-block;
      padding: 0 10px;
      height: 24px;
      line-height: 24px;
      border-radius: 14px;
      font-size: 11px;
      text-align: center;
      margin-right: 8px;
      margin-bottom: 12px;
      padding: 0 16px;
      border: none;
      color: #ffffff;
    }
    .active {
      background: #24c6b2;
      color: #fff;
      outline: none;
      border: none;
    }
  }
`;

const TabItemImport = ({ style }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState("");
  const uploadRef = useRef();
  const jobList = [
    "Java",
    "Android",
    "测试工程师",
    "运维工程师",
    "数据开发",
    "网路工程师",
    "IT技术支持",
  ];
  const handleClickTag = (item) => {setSearchValue(item)};
  const dispatch = useDispatch()
  const selectConfig = useSelector(state => state.mobileIndex.filter)

  const handleSearch = () => {
        dispatch(clearFilterWithoutSearchWord({ type: 'searchWord', value:searchValue }))
        navigate('/mobile/template?fromHome=true',{replace:true})
    };

  const [searchValue, setSearchValue] = useState("");
    return (
    <TabItemSearchStyled style={style}>
      <div className="tab-import flex-center flex-col" style={{  marginBottom: '-29px' }}>
        <div className="input-wrapper">
            <Input
            className="search-input"
            placeholder="在100万+专业简历中搜索"
            clearable
            onChange={(e) => setSearchValue(e)}
            value={searchValue}
            onEnterPress={handleSearch}
            ></Input>
            <div className="search-button" onClick={handleSearch}> 搜索简历 </div>
        </div>

        <div className="jz-tag-list">
          {jobList.map((item) => {
            const cls = classNames({
              tag: true,
              active: item === searchValue,
            });
            return (
              <div
                key={item}
                className={cls}
                onClick={() => handleClickTag(item)}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </TabItemSearchStyled>
  );
};

export default withComponent(TabItemImport);
