export default (key) => {
    switch (key) {
        case '姓名': {
            return`['基本信息'].data['姓名']`
        }
        case '性别': {
            return`['基本信息'].data['头像']`
        }
        case '头像': {
            return`['基本信息'].data['头像']`
        }
        case '生日': {
            return`['基本信息'].data['生日']`
        }
        case '手机号': {
            return`['基本信息'].data['手机号']`
        }
        case '微信号': {
            return`['基本信息'].data['微信号']`
        }
        case '邮箱': {
            return`['基本信息'].data['邮箱']`
        }
        case '现居地': {
            return`['基本信息'].data['现居地']`
        }
        case '婚姻状况': {
            return`['基本信息'].data['婚姻状况']`
        }
        case '工作年限': {
            return`['基本信息'].data['工作年限']`
        }
        case '身高体重': {
            return`['基本信息'].data['身高体重']`
        }
        case '民族': {
            return`['基本信息'].data['民族']`
        }
        case '籍贯': {
            return`['基本信息'].data['籍贯']`
        }
        case '政治面貌': {
            return`['基本信息'].data['政治面貌']`
        }
        case '求职意向': {
            return`['基本信息'].data['求职意向']`
        }
        case '生日年份': {
            return`['基本信息'].data['生日年份']`
        }
        case '生日月份': {
            return`['基本信息'].data['生日月份']`
        }
        case '生日日期': {
            return`['基本信息'].data['生日日期']`
        }
        case '学历': {
            return`['基本信息'].data['学历']`
        }
        case 'height': {
            return`['基本信息'].data['height']`
        }
        case 'weight': {
            return`['基本信息'].data['weight']`
        }
    }

}
