import {Module} from "@/common/module/module";
import {BASE_FIELD_ENUM, EDUCATIONAL_BACKGROUND_FIELD_ENUM, JOB_INTEREST_FIELD_ENUM} from "@/common/field/field";

export const cityConfig = [
    {
        "name": "热门城市",
        "children": [
            {
                "name": "广州",
                "id": "1947",
                "province": "广东"
            },
            {
                "name": "深圳",
                "id": "1971",
                "province": "广东"
            },
            {
                "name": "北京",
                "id": "1",
                "province": ""
            },
            {
                "name": "上海",
                "id": "7",
                "province": ""
            },
            {
                "name": "杭州",
                "id": "927",
                "province": "浙江"
            },
            {
                "name": "成都",
                "id": "2280",
                "province": "四川"
            },
            {
                "name": "西安",
                "id": "2808",
                "province": "陕西"
            },
            {
                "name": "南京",
                "id": "811",
                "province": "江苏"
            },
            {
                "name": "厦门",
                "id": "1165",
                "province": "福建"
            },
            {
                "name": "天津",
                "id": "18",
                "province": ""
            },
            {
                "name": "重庆",
                "id": "2240",
                "province": ""
            },
            {
                "name": "武汉",
                "id": "1693",
                "province": "湖北"
            },
            {
                "name": "香港",
                "id": "3292",
                "province": ""
            },
            {
                "name": "澳门",
                "id": "3314",
                "province": ""
            },
            {
                "name": "台湾",
                "id": "3219",
                "province": ""
            }
        ],
        "id": 0
    },
    {
        "name": "安徽",
        "children": [
            {
                "name": "合肥",
                "id": "1029"
            },
            {
                "name": "芜湖",
                "id": "1039"
            },
            {
                "name": "蚌埠",
                "id": "1048"
            },
            {
                "name": "淮南",
                "id": "1056"
            },
            {
                "name": "马鞍山",
                "id": "1063"
            },
            {
                "name": "淮北",
                "id": "1070"
            },
            {
                "name": "铜陵",
                "id": "1075"
            },
            {
                "name": "安庆",
                "id": "1080"
            },
            {
                "name": "黄山",
                "id": "1092"
            },
            {
                "name": "滁州",
                "id": "1100"
            },
            {
                "name": "阜阳",
                "id": "1109"
            },
            {
                "name": "宿州",
                "id": "1118"
            },
            {
                "name": "六安",
                "id": "1124"
            },
            {
                "name": "亳州",
                "id": "1132"
            },
            {
                "name": "池州",
                "id": "1137"
            },
            {
                "name": "宣城",
                "id": "1142"
            }
        ],
        "id": 1
    },
    {
        "name": "湖南",
        "children": [
            {
                "name": "长沙",
                "id": "1810"
            },
            {
                "name": "株洲",
                "id": "1820"
            },
            {
                "name": "湘潭",
                "id": "1830"
            },
            {
                "name": "衡阳",
                "id": "1836"
            },
            {
                "name": "邵阳",
                "id": "1849"
            },
            {
                "name": "岳阳",
                "id": "1862"
            },
            {
                "name": "常德",
                "id": "1872"
            },
            {
                "name": "张家界",
                "id": "1882"
            },
            {
                "name": "益阳",
                "id": "1887"
            },
            {
                "name": "郴州",
                "id": "1894"
            },
            {
                "name": "永州",
                "id": "1906"
            },
            {
                "name": "怀化",
                "id": "1918"
            },
            {
                "name": "娄底",
                "id": "1931"
            },
            {
                "name": "湘西土家族苗族自治州",
                "id": "1937"
            }
        ],
        "id": 2
    },
    {
        "name": "云南",
        "children": [
            {
                "name": "昆明",
                "id": "2581"
            },
            {
                "name": "曲靖",
                "id": "2596"
            },
            {
                "name": "玉溪",
                "id": "2606"
            },
            {
                "name": "保山",
                "id": "2616"
            },
            {
                "name": "昭通",
                "id": "2622"
            },
            {
                "name": "丽江",
                "id": "2634"
            },
            {
                "name": "普洱",
                "id": "2640"
            },
            {
                "name": "临沧",
                "id": "2651"
            },
            {
                "name": "楚雄彝族自治州",
                "id": "2660"
            },
            {
                "name": "红河哈尼族彝族自治州",
                "id": "2671"
            },
            {
                "name": "文山壮族苗族自治州",
                "id": "2685"
            },
            {
                "name": "西双版纳傣族自治州",
                "id": "2694"
            },
            {
                "name": "大理白族自治州",
                "id": "2698"
            },
            {
                "name": "德宏傣族景颇族自治州",
                "id": "2711"
            },
            {
                "name": "怒江傈僳族自治州",
                "id": "2717"
            },
            {
                "name": "迪庆藏族自治州",
                "id": "2722"
            }
        ],
        "id": 3
    },
    {
        "name": "河北",
        "children": [
            {
                "name": "石家庄",
                "id": "36"
            },
            {
                "name": "唐山",
                "id": "60"
            },
            {
                "name": "秦皇岛",
                "id": "75"
            },
            {
                "name": "邯郸",
                "id": "83"
            },
            {
                "name": "邢台",
                "id": "103"
            },
            {
                "name": "保定",
                "id": "123"
            },
            {
                "name": "张家口",
                "id": "149"
            },
            {
                "name": "承德",
                "id": "167"
            },
            {
                "name": "沧州",
                "id": "179"
            },
            {
                "name": "廊坊",
                "id": "196"
            },
            {
                "name": "衡水",
                "id": "207"
            }
        ],
        "id": 4
    },
    {
        "name": "江苏",
        "children": [
            {
                "name": "南京",
                "id": "811"
            },
            {
                "name": "无锡",
                "id": "825"
            },
            {
                "name": "徐州",
                "id": "834"
            },
            {
                "name": "常州",
                "id": "845"
            },
            {
                "name": "苏州",
                "id": "853"
            },
            {
                "name": "南通",
                "id": "863"
            },
            {
                "name": "连云港",
                "id": "872"
            },
            {
                "name": "淮安",
                "id": "880"
            },
            {
                "name": "盐城",
                "id": "889"
            },
            {
                "name": "扬州",
                "id": "899"
            },
            {
                "name": "镇江",
                "id": "906"
            },
            {
                "name": "泰州",
                "id": "913"
            },
            {
                "name": "宿迁",
                "id": "920"
            }
        ],
        "id": 5
    },
    {
        "name": "吉林",
        "children": [
            {
                "name": "长春",
                "id": "581"
            },
            {
                "name": "吉林",
                "id": "592"
            },
            {
                "name": "四平",
                "id": "602"
            },
            {
                "name": "辽源",
                "id": "609"
            },
            {
                "name": "通化",
                "id": "614"
            },
            {
                "name": "白山",
                "id": "622"
            },
            {
                "name": "松原",
                "id": "629"
            },
            {
                "name": "白城",
                "id": "635"
            },
            {
                "name": "延边朝鲜族自治州",
                "id": "641"
            }
        ],
        "id": 6
    },
    {
        "name": "山东",
        "children": [
            {
                "name": "济南",
                "id": "1358"
            },
            {
                "name": "青岛",
                "id": "1369"
            },
            {
                "name": "淄博",
                "id": "1382"
            },
            {
                "name": "枣庄",
                "id": "1391"
            },
            {
                "name": "东营",
                "id": "1398"
            },
            {
                "name": "烟台",
                "id": "1404"
            },
            {
                "name": "潍坊",
                "id": "1417"
            },
            {
                "name": "济宁",
                "id": "1430"
            },
            {
                "name": "泰安",
                "id": "1443"
            },
            {
                "name": "威海",
                "id": "1450"
            },
            {
                "name": "日照",
                "id": "1455"
            },
            {
                "name": "莱芜",
                "id": "1460"
            },
            {
                "name": "临沂",
                "id": "1463"
            },
            {
                "name": "德州",
                "id": "1476"
            },
            {
                "name": "聊城",
                "id": "1488"
            },
            {
                "name": "滨州",
                "id": "1497"
            },
            {
                "name": "菏泽",
                "id": "1505"
            }
        ],
        "id": 7
    },
    {
        "name": "甘肃",
        "children": [
            {
                "name": "兰州",
                "id": "2926"
            },
            {
                "name": "嘉峪关",
                "id": "2935"
            },
            {
                "name": "金昌",
                "id": "2936"
            },
            {
                "name": "白银",
                "id": "2939"
            },
            {
                "name": "天水",
                "id": "2945"
            },
            {
                "name": "武威",
                "id": "2953"
            },
            {
                "name": "张掖",
                "id": "2958"
            },
            {
                "name": "平凉",
                "id": "2965"
            },
            {
                "name": "酒泉",
                "id": "2973"
            },
            {
                "name": "庆阳",
                "id": "2981"
            },
            {
                "name": "定西",
                "id": "2990"
            },
            {
                "name": "陇南",
                "id": "2998"
            },
            {
                "name": "临夏回族自治州",
                "id": "3008"
            },
            {
                "name": "甘南藏族自治州",
                "id": "3017"
            }
        ],
        "id": 8
    },
    {
        "name": "福建",
        "children": [
            {
                "name": "福州",
                "id": "1151"
            },
            {
                "name": "厦门",
                "id": "1165"
            },
            {
                "name": "莆田",
                "id": "1172"
            },
            {
                "name": "三明",
                "id": "1178"
            },
            {
                "name": "泉州",
                "id": "1191"
            },
            {
                "name": "漳州",
                "id": "1204"
            },
            {
                "name": "南平",
                "id": "1216"
            },
            {
                "name": "龙岩",
                "id": "1227"
            },
            {
                "name": "宁德",
                "id": "1235"
            }
        ],
        "id": 9
    },
    {
        "name": "广东",
        "children": [
            {
                "name": "广州",
                "id": "1947"
            },
            {
                "name": "韶关",
                "id": "1960"
            },
            {
                "name": "深圳",
                "id": "1971"
            },
            {
                "name": "珠海",
                "id": "1978"
            },
            {
                "name": "汕头",
                "id": "1982"
            },
            {
                "name": "佛山",
                "id": "1990"
            },
            {
                "name": "江门",
                "id": "1996"
            },
            {
                "name": "湛江",
                "id": "2004"
            },
            {
                "name": "茂名",
                "id": "2014"
            },
            {
                "name": "肇庆",
                "id": "2021"
            },
            {
                "name": "惠州",
                "id": "2030"
            },
            {
                "name": "梅州",
                "id": "2036"
            },
            {
                "name": "汕尾",
                "id": "2045"
            },
            {
                "name": "河源",
                "id": "2050"
            },
            {
                "name": "阳江",
                "id": "2057"
            },
            {
                "name": "清远",
                "id": "2062"
            },
            {
                "name": "东莞",
                "id": "2071"
            },
            {
                "name": "中山",
                "id": "2072"
            },
            {
                "name": "潮州",
                "id": "2073"
            },
            {
                "name": "揭阳",
                "id": "2077"
            },
            {
                "name": "云浮",
                "id": "2083"
            }
        ],
        "id": 10
    },
    {
        "name": "湖北",
        "children": [
            {
                "name": "武汉",
                "id": "1693"
            },
            {
                "name": "黄石",
                "id": "1707"
            },
            {
                "name": "十堰",
                "id": "1714"
            },
            {
                "name": "宜昌",
                "id": "1723"
            },
            {
                "name": "襄阳",
                "id": "1737"
            },
            {
                "name": "鄂州",
                "id": "1747"
            },
            {
                "name": "荆门",
                "id": "1751"
            },
            {
                "name": "孝感",
                "id": "1757"
            },
            {
                "name": "荆州",
                "id": "1765"
            },
            {
                "name": "黄冈",
                "id": "1774"
            },
            {
                "name": "咸宁",
                "id": "1785"
            },
            {
                "name": "随州",
                "id": "1792"
            },
            {
                "name": "恩施土家族苗族自治州",
                "id": "1796"
            },
            {
                "name": "仙桃",
                "id": "1805"
            },
            {
                "name": "潜江",
                "id": "1806"
            },
            {
                "name": "天门",
                "id": "1807"
            }
        ],
        "id": 11
    },
    {
        "name": "浙江",
        "children": [
            {
                "name": "杭州",
                "id": "927"
            },
            {
                "name": "宁波",
                "id": "941"
            },
            {
                "name": "温州",
                "id": "953"
            },
            {
                "name": "嘉兴",
                "id": "965"
            },
            {
                "name": "湖州",
                "id": "973"
            },
            {
                "name": "绍兴",
                "id": "979"
            },
            {
                "name": "金华",
                "id": "986"
            },
            {
                "name": "衢州",
                "id": "996"
            },
            {
                "name": "舟山",
                "id": "1003"
            },
            {
                "name": "台州",
                "id": "1008"
            },
            {
                "name": "丽水",
                "id": "1018"
            }
        ],
        "id": 12
    },
    {
        "name": "海南",
        "children": [
            {
                "name": "海口",
                "id": "2214"
            },
            {
                "name": "三亚",
                "id": "2219"
            }
        ],
        "id": 13
    },
    {
        "name": "贵州",
        "children": [
            {
                "name": "贵阳",
                "id": "2483"
            },
            {
                "name": "六盘水",
                "id": "2494"
            },
            {
                "name": "遵义",
                "id": "2499"
            },
            {
                "name": "安顺",
                "id": "2514"
            },
            {
                "name": "毕节地区",
                "id": "2521"
            },
            {
                "name": "铜仁地区",
                "id": "2530"
            },
            {
                "name": "黔西南布依族苗族自治州",
                "id": "2541"
            },
            {
                "name": "黔东南苗族侗族自治州",
                "id": "2550"
            },
            {
                "name": "黔南布依族苗族自治州",
                "id": "2567"
            }
        ],
        "id": 14
    },
    {
        "name": "辽宁",
        "children": [
            {
                "name": "沈阳",
                "id": "466"
            },
            {
                "name": "大连",
                "id": "480"
            },
            {
                "name": "鞍山",
                "id": "491"
            },
            {
                "name": "抚顺",
                "id": "499"
            },
            {
                "name": "本溪",
                "id": "507"
            },
            {
                "name": "丹东",
                "id": "514"
            },
            {
                "name": "锦州",
                "id": "521"
            },
            {
                "name": "营口",
                "id": "529"
            },
            {
                "name": "阜新",
                "id": "536"
            },
            {
                "name": "辽阳",
                "id": "544"
            },
            {
                "name": "盘锦",
                "id": "552"
            },
            {
                "name": "铁岭",
                "id": "557"
            },
            {
                "name": "朝阳",
                "id": "565"
            },
            {
                "name": "葫芦岛",
                "id": "573"
            }
        ],
        "id": 15
    },
    {
        "name": "青海",
        "children": [
            {
                "name": "西宁",
                "id": "3027"
            },
            {
                "name": "海东",
                "id": "3035"
            },
            {
                "name": "海北",
                "id": "3042"
            },
            {
                "name": "黄南",
                "id": "3047"
            },
            {
                "name": "海南",
                "id": "3052"
            },
            {
                "name": "果洛",
                "id": "3058"
            },
            {
                "name": "玉树",
                "id": "3065"
            },
            {
                "name": "海西",
                "id": "3072"
            }
        ],
        "id": 16
    },
    {
        "name": "山西",
        "children": [
            {
                "name": "太原",
                "id": "220"
            },
            {
                "name": "大同",
                "id": "231"
            },
            {
                "name": "阳泉",
                "id": "243"
            },
            {
                "name": "长治",
                "id": "249"
            },
            {
                "name": "晋城",
                "id": "263"
            },
            {
                "name": "朔州",
                "id": "271"
            },
            {
                "name": "晋中",
                "id": "278"
            },
            {
                "name": "运城",
                "id": "290"
            },
            {
                "name": "忻州",
                "id": "304"
            },
            {
                "name": "临汾",
                "id": "319"
            },
            {
                "name": "吕梁",
                "id": "337"
            }
        ],
        "id": 17
    },
    {
        "name": "江西",
        "children": [
            {
                "name": "南昌",
                "id": "1246"
            },
            {
                "name": "景德镇",
                "id": "1256"
            },
            {
                "name": "萍乡",
                "id": "1261"
            },
            {
                "name": "九江",
                "id": "1267"
            },
            {
                "name": "新余",
                "id": "1281"
            },
            {
                "name": "鹰潭",
                "id": "1284"
            },
            {
                "name": "赣州",
                "id": "1288"
            },
            {
                "name": "吉安",
                "id": "1307"
            },
            {
                "name": "宜春",
                "id": "1321"
            },
            {
                "name": "抚州",
                "id": "1332"
            },
            {
                "name": "上饶",
                "id": "1344"
            }
        ],
        "id": 18
    },
    {
        "name": "四川",
        "children": [
            {
                "name": "成都",
                "id": "2280"
            },
            {
                "name": "自贡",
                "id": "2300"
            },
            {
                "name": "攀枝花",
                "id": "2307"
            },
            {
                "name": "泸州",
                "id": "2313"
            },
            {
                "name": "德阳",
                "id": "2321"
            },
            {
                "name": "绵阳",
                "id": "2328"
            },
            {
                "name": "广元",
                "id": "2338"
            },
            {
                "name": "遂宁",
                "id": "2346"
            },
            {
                "name": "内江",
                "id": "2352"
            },
            {
                "name": "乐山",
                "id": "2358"
            },
            {
                "name": "南充",
                "id": "2370"
            },
            {
                "name": "眉山",
                "id": "2380"
            },
            {
                "name": "宜宾",
                "id": "2387"
            },
            {
                "name": "广安",
                "id": "2398"
            },
            {
                "name": "达州",
                "id": "2404"
            },
            {
                "name": "雅安",
                "id": "2412"
            },
            {
                "name": "巴中",
                "id": "2421"
            },
            {
                "name": "资阳",
                "id": "2426"
            },
            {
                "name": "阿坝藏族羌族自治州",
                "id": "2431"
            },
            {
                "name": "甘孜藏族自治州",
                "id": "2445"
            },
            {
                "name": "凉山彝族自治州",
                "id": "2464"
            }
        ],
        "id": 19
    },
    {
        "name": "河南",
        "children": [
            {
                "name": "郑州",
                "id": "1516"
            },
            {
                "name": "开封",
                "id": "1529"
            },
            {
                "name": "洛阳",
                "id": "1540"
            },
            {
                "name": "平顶山",
                "id": "1556"
            },
            {
                "name": "安阳",
                "id": "1567"
            },
            {
                "name": "鹤壁",
                "id": "1577"
            },
            {
                "name": "新乡",
                "id": "1583"
            },
            {
                "name": "焦作",
                "id": "1596"
            },
            {
                "name": "濮阳",
                "id": "1607"
            },
            {
                "name": "许昌",
                "id": "1614"
            },
            {
                "name": "漯河",
                "id": "1621"
            },
            {
                "name": "三门峡",
                "id": "1627"
            },
            {
                "name": "南阳",
                "id": "1634"
            },
            {
                "name": "商丘",
                "id": "1648"
            },
            {
                "name": "信阳",
                "id": "1658"
            },
            {
                "name": "周口",
                "id": "1669"
            },
            {
                "name": "驻马店",
                "id": "1680"
            },
            {
                "name": "济源",
                "id": "1691"
            }
        ],
        "id": 20
    },
    {
        "name": "陕西",
        "children": [
            {
                "name": "西安",
                "id": "2808"
            },
            {
                "name": "铜川",
                "id": "2822"
            },
            {
                "name": "宝鸡",
                "id": "2827"
            },
            {
                "name": "咸阳",
                "id": "2840"
            },
            {
                "name": "渭南",
                "id": "2855"
            },
            {
                "name": "延安",
                "id": "2867"
            },
            {
                "name": "汉中",
                "id": "2881"
            },
            {
                "name": "榆林",
                "id": "2893"
            },
            {
                "name": "安康",
                "id": "2906"
            },
            {
                "name": "商洛",
                "id": "2917"
            }
        ],
        "id": 21
    },
    {
        "name": "广西",
        "children": [
            {
                "name": "南宁",
                "id": "2090"
            },
            {
                "name": "柳州",
                "id": "2103"
            },
            {
                "name": "桂林",
                "id": "2114"
            },
            {
                "name": "梧州",
                "id": "2132"
            },
            {
                "name": "北海",
                "id": "2140"
            },
            {
                "name": "防城港",
                "id": "2145"
            },
            {
                "name": "钦州",
                "id": "2150"
            },
            {
                "name": "贵港",
                "id": "2155"
            },
            {
                "name": "玉林",
                "id": "2161"
            },
            {
                "name": "百色",
                "id": "2168"
            },
            {
                "name": "贺州",
                "id": "2181"
            },
            {
                "name": "河池",
                "id": "2186"
            },
            {
                "name": "来宾",
                "id": "2198"
            },
            {
                "name": "崇左",
                "id": "2205"
            }
        ],
        "id": 22
    },
    {
        "name": "宁夏",
        "children": [
            {
                "name": "银川",
                "id": "3079"
            },
            {
                "name": "石嘴山",
                "id": "3086"
            },
            {
                "name": "吴忠",
                "id": "3090"
            },
            {
                "name": "固原",
                "id": "3096"
            },
            {
                "name": "中卫",
                "id": "3102"
            }
        ],
        "id": 23
    },
    {
        "name": "新疆",
        "children": [
            {
                "name": "乌鲁木齐",
                "id": "3107"
            },
            {
                "name": "克拉玛依",
                "id": "3116"
            },
            {
                "name": "吐鲁番",
                "id": "3121"
            },
            {
                "name": "哈密",
                "id": "3125"
            },
            {
                "name": "昌吉",
                "id": "3129"
            },
            {
                "name": "阿克苏",
                "id": "3151"
            },
            {
                "name": "喀什",
                "id": "3166"
            },
            {
                "name": "和田",
                "id": "3179"
            },
            {
                "name": "伊犁",
                "id": "3188"
            },
            {
                "name": "塔城",
                "id": "3199"
            },
            {
                "name": "阿勒泰",
                "id": "3207"
            },
            {
                "name": "石河子",
                "id": "3215"
            }
        ],
        "id": 24
    },
    {
        "name": "西藏",
        "children": [
            {
                "name": "拉萨",
                "id": "2727"
            },
            {
                "name": "昌都",
                "id": "2736"
            },
            {
                "name": "山南",
                "id": "2748"
            },
            {
                "name": "日喀则",
                "id": "2761"
            },
            {
                "name": "那曲",
                "id": "2780"
            },
            {
                "name": "阿里",
                "id": "2791"
            },
            {
                "name": "林芝",
                "id": "2799"
            }
        ],
        "id": 25
    },
    {
        "name": "黑龙江",
        "children": [
            {
                "name": "哈尔滨",
                "id": "651"
            },
            {
                "name": "齐齐哈尔",
                "id": "670"
            },
            {
                "name": "鸡西",
                "id": "687"
            },
            {
                "name": "鹤岗",
                "id": "697"
            },
            {
                "name": "双鸭山",
                "id": "706"
            },
            {
                "name": "大庆",
                "id": "715"
            },
            {
                "name": "伊春",
                "id": "725"
            },
            {
                "name": "佳木斯",
                "id": "743"
            },
            {
                "name": "七台河",
                "id": "754"
            },
            {
                "name": "牡丹江",
                "id": "759"
            },
            {
                "name": "黑河",
                "id": "770"
            },
            {
                "name": "绥化",
                "id": "777"
            },
            {
                "name": "大兴安岭地区",
                "id": "788"
            }
        ],
        "id": 26
    },
    {
        "name": "内蒙古",
        "children": [
            {
                "name": "呼和浩特",
                "id": "352"
            },
            {
                "name": "包头",
                "id": "362"
            },
            {
                "name": "乌海",
                "id": "372"
            },
            {
                "name": "赤峰",
                "id": "376"
            },
            {
                "name": "通辽",
                "id": "389"
            },
            {
                "name": "鄂尔多斯",
                "id": "398"
            },
            {
                "name": "呼伦贝尔",
                "id": "407"
            },
            {
                "name": "巴彦淖尔",
                "id": "421"
            },
            {
                "name": "乌兰察布",
                "id": "429"
            },
            {
                "name": "兴安盟",
                "id": "441"
            },
            {
                "name": "锡林郭勒盟",
                "id": "448"
            },
            {
                "name": "阿拉善盟",
                "id": "461"
            }
        ],
        "id": 27
    },
    {
        "name": "港澳台",
        "children": [
            {
                "name": "台湾省",
                "id": "3219",
                "province": ""
            },
            {
                "name": "香港特别行政区",
                "id": "3292",
                "province": ""
            },
            {
                "name": "澳门特别行政区",
                "id": "3314",
                "province": ""
            }
        ],
        "id": 28
    },
    {
        "name": "海外",
        "children": [
            {
                "name": "海外",
                "id": "",
                "province": ""
            }
        ],
        "id": 29
    }
]
const educationBackgroundConfig = ['初中及以下', '高中', '中技', '中专', '大专', '本科', '硕士', '博士']
const marriageConfig = ['未婚', '已婚', '保密']
const yearsOfWorkingConfig = ['在校生', '应届生', '1年经验', '2年经验', '3年经验', '4年经验', '5年经验', '6年经验', '7年经验', '8年经验', '9年经验', '10年经验', '10年以上经验']
const politicsConfig = ['中共党员', '中共预备党员', '共青团员', '民主党派人士', '无党派民主人士', '普通公民'];
const educationConfig = ['初中及以下', '高中', '中技', '中专', '大专', '本科', '硕士', '博士'];
const jobInterestJobTypeConfig = ['全职', '兼职', '实习'];
export const jobInterestRoleConfig = [
    {
        "name": "职能支持",
        "children": [
            {
                "name": "市场",
                "children": [
                    {
                        "name": "营销推广",
                        "id": "yingxiaotg"
                    },
                    {
                        "name": "品牌公关",
                        "id": "pinpaigg"
                    },
                    {
                        "name": "商务合作",
                        "id": "shangwuhz"
                    },
                    {
                        "name": "销售代表",
                        "id": "xiaosoudb"
                    },
                    {
                        "name": "市场调研",
                        "id": "diaoyandy"
                    }
                ],
                "id": "sc"
            },
            {
                "name": "财务",
                "children": [
                    {
                        "name": "财务",
                        "id": "caiwu"
                    },
                    {
                        "name": "会计",
                        "id": "kuaiji"
                    },
                    {
                        "name": "审计",
                        "id": "shenji"
                    },
                    {
                        "name": "出纳",
                        "id": "chuna"
                    },
                    {
                        "name": "税务",
                        "id": "shuiwu"
                    },
                    {
                        "name": "统计",
                        "id": "tongji"
                    },
                    {
                        "name": "成本管理",
                        "id": "chengben"
                    },
                    {
                        "name": "资产管理",
                        "id": "zichan"
                    }
                ],
                "id": "cw"
            },
            {
                "name": "法务",
                "children": [
                    {
                        "name": "法务",
                        "id": "fawu"
                    },
                    {
                        "name": "律师",
                        "id": "lvshi"
                    },
                    {
                        "name": "合规",
                        "id": "hegui"
                    },
                    {
                        "name": "知识产权",
                        "id": "zhishicq"
                    },
                    {
                        "name": "法律顾问",
                        "id": "falvgw"
                    }
                ],
                "id": "fw"
            },
            {
                "name": "人事",
                "children": [
                    {
                        "name": "人力资源",
                        "id": "renli"
                    },
                    {
                        "name": "HRBP",
                        "id": "hrbp"
                    },
                    {
                        "name": "猎头",
                        "id": "lietou"
                    },
                    {
                        "name": "薪酬福利",
                        "id": "xinchou"
                    },
                    {
                        "name": "绩效考核",
                        "id": "jixiao"
                    },
                    {
                        "name": "企业文化",
                        "id": "wenhua"
                    },
                    {
                        "name": "招聘",
                        "id": "zhaopin"
                    },
                    {
                        "name": "培训",
                        "id": "peixun"
                    }
                ],
                "id": "rs"
            },
            {
                "name": "行政",
                "children": [
                    {
                        "name": "行政",
                        "id": "xingzheng"
                    },
                    {
                        "name": "前台",
                        "id": "qiantai"
                    },
                    {
                        "name": "秘书",
                        "id": "mishu"
                    },
                    {
                        "name": "文员",
                        "id": "wenyuan"
                    },
                    {
                        "name": "总助",
                        "id": "zongzhu"
                    },
                    {
                        "name": "总机",
                        "id": "zongji"
                    }
                ],
                "id": "xz"
            }
        ],
        "id": "znzc"
    },
    {
        "name": "互联网通信",
        "children": [
            {
                "name": "技术",
                "children": [
                    {
                        "name": "前端开发",
                        "id": "qianduan"
                    },
                    {
                        "name": "后端开发",
                        "id": "houduan"
                    },
                    {
                        "name": "移动开发",
                        "id": "yidong"
                    },
                    {
                        "name": "测试",
                        "id": "ceshi"
                    },
                    {
                        "name": "运维",
                        "id": "yunwei"
                    },
                    {
                        "name": "DBA",
                        "id": "dba"
                    },
                    {
                        "name": "硬件开发",
                        "id": "yingjian"
                    },
                    {
                        "name": "项目管理",
                        "id": "xiangmugl21"
                    },
                    {
                        "name": "网络运输",
                        "id": "wangluo"
                    }
                ],
                "id": "js"
            },
            {
                "name": "产品",
                "children": [
                    {
                        "name": "产品经理",
                        "id": "chanpingjl22"
                    },
                    {
                        "name": "产品策划",
                        "id": "chanpinch"
                    },
                    {
                        "name": "游戏策划",
                        "id": "youxich"
                    },
                    {
                        "name": "产品助理",
                        "id": "chanpinzl"
                    }
                ],
                "id": "chanpin"
            },
            {
                "name": "设计",
                "children": [
                    {
                        "name": "UI交互设计",
                        "id": "ui"
                    },
                    {
                        "name": "平面设计",
                        "id": "pingmiansj"
                    },
                    {
                        "name": "网页设计",
                        "id": "wangyesj"
                    },
                    {
                        "name": "动画人物设计",
                        "id": "donghuarw"
                    },
                    {
                        "name": "游戏原画",
                        "id": "youxiyh"
                    },
                    {
                        "name": "游戏场景",
                        "id": "youxicj"
                    },
                    {
                        "name": "游戏特效设计",
                        "id": "youxitx"
                    }
                ],
                "id": "sheji"
            },
            {
                "name": "运营编辑",
                "children": [
                    {
                        "name": "产品运营",
                        "id": "chanpinyy"
                    },
                    {
                        "name": "新媒体运营",
                        "id": "xinmeitiyy"
                    },
                    {
                        "name": "游戏运营",
                        "id": "youxiyy"
                    },
                    {
                        "name": "用户运营",
                        "id": "yonghuyy"
                    },
                    {
                        "name": "活动运营",
                        "id": "huodongyy"
                    },
                    {
                        "name": "社区运营",
                        "id": "shequyy"
                    },
                    {
                        "name": "内容运营",
                        "id": "neirongyy"
                    },
                    {
                        "name": "客服",
                        "id": "kefu"
                    }
                ],
                "id": "yy"
            }
        ],
        "id": "hlwtx"
    },
    {
        "name": "金融投资",
        "children": [
            {
                "name": "银行",
                "children": [
                    {
                        "name": "客户经理",
                        "id": "kehujl31"
                    },
                    {
                        "name": "大堂经理",
                        "id": "datangjl31"
                    },
                    {
                        "name": "银行柜员",
                        "id": "guiyuan"
                    },
                    {
                        "name": "支行行长",
                        "id": "zhihanghz"
                    },
                    {
                        "name": "风险控制",
                        "id": "fengxiankz31"
                    }
                ],
                "id": "yh"
            },
            {
                "name": "证券基金",
                "children": [
                    {
                        "name": "交易员",
                        "id": "jiaoyi"
                    },
                    {
                        "name": "投资顾问",
                        "id": "touzigw"
                    },
                    {
                        "name": "客户经理",
                        "id": "kehujl32"
                    },
                    {
                        "name": "基金经理",
                        "id": "jijinjl"
                    },
                    {
                        "name": "证券分析",
                        "id": "zhengquanfx"
                    },
                    {
                        "name": "风险控制",
                        "id": "fengxiankz32"
                    },
                    {
                        "name": "债券发行",
                        "id": "zhaiquanfx"
                    },
                    {
                        "name": "基金会计",
                        "id": "jijinkj"
                    },
                    {
                        "name": "行业研究",
                        "id": "hangyeyj"
                    }
                ],
                "id": "zqjj"
            },
            {
                "name": "保险",
                "children": [
                    {
                        "name": "销售代表",
                        "id": "xiaoshoudb33"
                    },
                    {
                        "name": "综合柜员",
                        "id": "zonghegy"
                    },
                    {
                        "name": "培训讲师",
                        "id": "peixunjs33"
                    },
                    {
                        "name": "业务员",
                        "id": "yewu"
                    },
                    {
                        "name": "理财规划",
                        "id": "licaigh"
                    },
                    {
                        "name": "风险控制",
                        "id": "fengxiankz33"
                    },
                    {
                        "name": "产品研发",
                        "id": "chanpinyf"
                    }
                ],
                "id": "bx"
            },
            {
                "name": "信托期货",
                "children": [
                    {
                        "name": "客户经理",
                        "id": "kehujl34"
                    },
                    {
                        "name": "信托经理",
                        "id": "xintuojl"
                    },
                    {
                        "name": "产品经理",
                        "id": "chanpinjl34"
                    },
                    {
                        "name": "资产管理",
                        "id": "zichangl34"
                    },
                    {
                        "name": "资产证券化",
                        "id": "zichanzqh"
                    },
                    {
                        "name": "风险控制",
                        "id": "fengxiankz34"
                    },
                    {
                        "name": "期货经纪人",
                        "id": "qihuojjr"
                    }
                ],
                "id": "xtqh"
            }
        ],
        "id": "jrtz"
    },
    {
        "name": "房地产建筑",
        "children": [
            {
                "name": "房地产",
                "children": [
                    {
                        "name": "投资分析",
                        "id": "touzifx41"
                    },
                    {
                        "name": "项目策划",
                        "id": "xiangmuch"
                    },
                    {
                        "name": "项目管理",
                        "id": "xiangmugl41"
                    },
                    {
                        "name": "项目招投标",
                        "id": "xiangmuztb"
                    },
                    {
                        "name": "资产管理",
                        "id": "zichangl41"
                    },
                    {
                        "name": "合同管理",
                        "id": "hetonggl"
                    },
                    {
                        "name": "房产中介",
                        "id": "fangchanzj"
                    },
                    {
                        "name": "房产经纪人",
                        "id": "fangchanjjr"
                    }
                ],
                "id": "fdc"
            },
            {
                "name": "土建",
                "children": [
                    {
                        "name": "建筑工程师",
                        "id": "jianzhugc"
                    },
                    {
                        "name": "工程造价",
                        "id": "gongchengzj"
                    },
                    {
                        "name": "给排水工程师",
                        "id": "geipaishui"
                    },
                    {
                        "name": "测绘工程师",
                        "id": "cehuigc42"
                    },
                    {
                        "name": "水电工程师",
                        "id": "shuidian"
                    },
                    {
                        "name": "工程监理",
                        "id": "jianli"
                    },
                    {
                        "name": "现场管理",
                        "id": "xianchang"
                    }
                ],
                "id": "tj"
            },
            {
                "name": "物业",
                "children": [
                    {
                        "name": "物业管理",
                        "id": "wuyegl"
                    },
                    {
                        "name": "设施管理",
                        "id": "sheshigl"
                    },
                    {
                        "name": "物业招商",
                        "id": "wuyezs"
                    },
                    {
                        "name": "客服顾问",
                        "id": "kefugw"
                    },
                    {
                        "name": "物业维修",
                        "id": "wuyewx"
                    },
                    {
                        "name": "机电维修",
                        "id": "jidianwx"
                    },
                    {
                        "name": "保洁",
                        "id": "baojie"
                    },
                    {
                        "name": "绿化",
                        "id": "lvhua"
                    },
                    {
                        "name": "保安",
                        "id": "baoan"
                    }
                ],
                "id": "wy"
            },
            {
                "name": "设计",
                "children": [
                    {
                        "name": "室内设计",
                        "id": "shineisj"
                    },
                    {
                        "name": "景观设计",
                        "id": "jingguansj"
                    },
                    {
                        "name": "结构设计",
                        "id": "jiegousj"
                    },
                    {
                        "name": "软装设计",
                        "id": "ruanzhuang"
                    },
                    {
                        "name": "硬装设计",
                        "id": "yingzhuang"
                    },
                    {
                        "name": "幕墙设计",
                        "id": "muqiang"
                    },
                    {
                        "name": "城市规划",
                        "id": "chengshigh"
                    }
                ],
                "id": "sj4"
            },
            {
                "name": "家装",
                "children": [
                    {
                        "name": "工长",
                        "id": "gongzhang"
                    },
                    {
                        "name": "木工",
                        "id": "mugong"
                    },
                    {
                        "name": "泥瓦工",
                        "id": "niwa"
                    },
                    {
                        "name": "油漆工",
                        "id": "youqi"
                    },
                    {
                        "name": "水电工",
                        "id": "shuidiang"
                    },
                    {
                        "name": "安装施工",
                        "id": "anzhang"
                    }
                ],
                "id": "jz"
            }
        ],
        "id": "fdcjz"
    },
    {
        "name": "休闲服务",
        "children": [
            {
                "name": "旅游",
                "children": [
                    {
                        "name": "导游",
                        "id": "daoyou"
                    },
                    {
                        "name": "旅游顾问",
                        "id": "lvyougw"
                    },
                    {
                        "name": "线路策划",
                        "id": "xianluch"
                    },
                    {
                        "name": "计调",
                        "id": "jidiao"
                    },
                    {
                        "name": "领队",
                        "id": "lingdui"
                    },
                    {
                        "name": "票务",
                        "id": "piaowu"
                    },
                    {
                        "name": "会展策划",
                        "id": "huizhan"
                    }
                ],
                "id": "ly"
            },
            {
                "name": "酒店",
                "children": [
                    {
                        "name": "大堂经理",
                        "id": "datangjl5"
                    },
                    {
                        "name": "礼宾",
                        "id": "libin"
                    },
                    {
                        "name": "总机",
                        "id": "zongji5"
                    },
                    {
                        "name": "商务中心",
                        "id": "shangwuzx"
                    },
                    {
                        "name": "行李员",
                        "id": "xingli"
                    },
                    {
                        "name": "客房服务",
                        "id": "kefangfw"
                    },
                    {
                        "name": "餐厅服务",
                        "id": "cantingfw"
                    },
                    {
                        "name": "厨师",
                        "id": "chushi"
                    }
                ],
                "id": "jd"
            },
            {
                "name": "餐饮",
                "children": [
                    {
                        "name": "西餐厨师",
                        "id": "xican"
                    },
                    {
                        "name": "中餐厨师",
                        "id": "zhongcan"
                    },
                    {
                        "name": "面点师",
                        "id": "miandian"
                    },
                    {
                        "name": "调酒师",
                        "id": "tiaojiu"
                    },
                    {
                        "name": "咖啡师",
                        "id": "kafei"
                    },
                    {
                        "name": "服务员",
                        "id": "fuwu"
                    },
                    {
                        "name": "传菜员",
                        "id": "chuancai"
                    }
                ],
                "id": "cy"
            },
            {
                "name": "美容",
                "children": [
                    {
                        "name": "美容师",
                        "id": "meirong"
                    },
                    {
                        "name": "发型师",
                        "id": "faxing"
                    },
                    {
                        "name": "美甲师",
                        "id": "meijia"
                    },
                    {
                        "name": "化妆师",
                        "id": "huazhuang"
                    },
                    {
                        "name": "美体师",
                        "id": "meiti"
                    },
                    {
                        "name": "美发培训",
                        "id": "meifapx"
                    },
                    {
                        "name": "美容整形",
                        "id": "meirongzx"
                    }
                ],
                "id": "mr"
            },
            {
                "name": "体育保健",
                "children": [
                    {
                        "name": "健身教练",
                        "id": "jianshenjl"
                    },
                    {
                        "name": "健身顾问",
                        "id": "jianshengw"
                    },
                    {
                        "name": "按摩师",
                        "id": "anmo"
                    },
                    {
                        "name": "足疗师",
                        "id": "zuliao"
                    },
                    {
                        "name": "体育教练",
                        "id": "tiyujl"
                    },
                    {
                        "name": "赛事策划",
                        "id": "saishich"
                    },
                    {
                        "name": "体育馆管理",
                        "id": "tiyuguan"
                    },
                    {
                        "name": "运动员",
                        "id": "yundong"
                    }
                ],
                "id": "tybj"
            },
            {
                "name": "生活",
                "children": [
                    {
                        "name": "家政",
                        "id": "jiazheng"
                    },
                    {
                        "name": "保姆",
                        "id": "baomu"
                    },
                    {
                        "name": "月嫂",
                        "id": "yuesao"
                    },
                    {
                        "name": "钟点工",
                        "id": "zhongdian"
                    },
                    {
                        "name": "家电维修",
                        "id": "jiadianwx"
                    },
                    {
                        "name": "婚礼策划",
                        "id": "hunlich"
                    },
                    {
                        "name": "宠物美容",
                        "id": "chongwumr"
                    },
                    {
                        "name": "摄影师",
                        "id": "sheying"
                    }
                ],
                "id": "sh"
            }
        ],
        "id": "xxfw"
    },
    {
        "name": "教育培训",
        "children": [
            {
                "name": "教育",
                "children": [
                    {
                        "name": "舞蹈老师",
                        "id": "wudaols"
                    },
                    {
                        "name": "英语老师",
                        "id": "yingyuls"
                    },
                    {
                        "name": "音乐老师",
                        "id": "yinyuels"
                    },
                    {
                        "name": "语文老师",
                        "id": "yuwenls"
                    },
                    {
                        "name": "化学老师",
                        "id": "huaxuels"
                    },
                    {
                        "name": "数学老师",
                        "id": "shuxuels"
                    },
                    {
                        "name": "物理老师",
                        "id": "wulils"
                    },
                    {
                        "name": "政治老师",
                        "id": "zhengzhils"
                    },
                    {
                        "name": "历史老师",
                        "id": "lishils"
                    },
                    {
                        "name": "幼师",
                        "id": "youshi"
                    },
                    {
                        "name": "家教",
                        "id": "jiajiao"
                    }
                ],
                "id": "jy"
            },
            {
                "name": "培训",
                "children": [
                    {
                        "name": "培训讲师",
                        "id": "peixunjs"
                    },
                    {
                        "name": "教务助理",
                        "id": "jiaowuzl"
                    },
                    {
                        "name": "课程顾问",
                        "id": "kechenggw"
                    },
                    {
                        "name": "课程设计",
                        "id": "kechengsj"
                    }
                ],
                "id": "px"
            },
            {
                "name": "咨询",
                "children": [
                    {
                        "name": "法律咨询",
                        "id": "falvzx"
                    },
                    {
                        "name": "翻译咨询",
                        "id": "fanyi"
                    },
                    {
                        "name": "心理咨询",
                        "id": "xinlizx"
                    },
                    {
                        "name": "财务咨询",
                        "id": "caiwuzx"
                    },
                    {
                        "name": "调研员",
                        "id": "diaoyan"
                    }
                ],
                "id": "zx"
            }
        ],
        "id": "jypx"
    },
    {
        "name": "广告传媒",
        "children": [
            {
                "name": "广告",
                "children": [
                    {
                        "name": "广告销售",
                        "id": "guanggaoxs"
                    },
                    {
                        "name": "广告优化",
                        "id": "guanggaoyh"
                    },
                    {
                        "name": "广告设计",
                        "id": "guanggaosj"
                    },
                    {
                        "name": "文案策划",
                        "id": "wenanch"
                    },
                    {
                        "name": "广告执行",
                        "id": "guanggaozx"
                    }
                ],
                "id": "gg"
            },
            {
                "name": "影视",
                "children": [
                    {
                        "name": "导演",
                        "id": "daoyan"
                    },
                    {
                        "name": "编导",
                        "id": "biandao"
                    },
                    {
                        "name": "导演助理",
                        "id": "daoyanzl"
                    },
                    {
                        "name": "影视制作",
                        "id": "yingshizz"
                    },
                    {
                        "name": "艺术指导",
                        "id": "yishuzd"
                    },
                    {
                        "name": "摄像师",
                        "id": "shexiang"
                    },
                    {
                        "name": "后期制作",
                        "id": "houqizz"
                    },
                    {
                        "name": "音效师",
                        "id": "yinxiao"
                    },
                    {
                        "name": "配音员",
                        "id": "peiyin"
                    },
                    {
                        "name": "灯光师",
                        "id": "dengguang"
                    }
                ],
                "id": "ys1"
            },
            {
                "name": "媒体",
                "children": [
                    {
                        "name": "主编",
                        "id": "zhubian"
                    },
                    {
                        "name": "编辑",
                        "id": "bianji"
                    },
                    {
                        "name": "记者",
                        "id": "jizhe"
                    },
                    {
                        "name": "美术编辑",
                        "id": "meishubj"
                    },
                    {
                        "name": "排版设计",
                        "id": "paiban"
                    },
                    {
                        "name": "出版",
                        "id": "chuban"
                    }
                ],
                "id": "mt"
            },
            {
                "name": "娱乐",
                "children": [
                    {
                        "name": "经纪人",
                        "id": "jingjiren"
                    },
                    {
                        "name": "练习生",
                        "id": "lianxi"
                    },
                    {
                        "name": "主持人",
                        "id": "zhuchi"
                    },
                    {
                        "name": "模特",
                        "id": "mote"
                    },
                    {
                        "name": "演员",
                        "id": "yanyuan"
                    },
                    {
                        "name": "歌手",
                        "id": "geshou"
                    }
                ],
                "id": "yule"
            }
        ],
        "id": "ggcm"
    },
    {
        "name": "医疗制药",
        "children": [
            {
                "name": "医疗服务",
                "children": [
                    {
                        "name": "外科医生",
                        "id": "waike"
                    },
                    {
                        "name": "内科医生",
                        "id": "neike"
                    },
                    {
                        "name": "放射科医生",
                        "id": "fangshe"
                    },
                    {
                        "name": "麻醉医生",
                        "id": "mazui"
                    },
                    {
                        "name": "护士",
                        "id": "hushi"
                    },
                    {
                        "name": "理疗师",
                        "id": "liliao"
                    },
                    {
                        "name": "中医",
                        "id": "zhongyi"
                    },
                    {
                        "name": "心理医生",
                        "id": "xinliys"
                    },
                    {
                        "name": "检验师",
                        "id": "jianyan"
                    },
                    {
                        "name": "药剂师",
                        "id": "yaoji"
                    },
                    {
                        "name": "兽医",
                        "id": "shouyi"
                    }
                ],
                "id": "ylfw"
            },
            {
                "name": "医疗器械",
                "children": [
                    {
                        "name": "器械销售",
                        "id": "qixiexs"
                    },
                    {
                        "name": "质量管理",
                        "id": "zhilianggl"
                    },
                    {
                        "name": "器械采购",
                        "id": "qixiecg"
                    },
                    {
                        "name": "器械研发",
                        "id": "qixieyf"
                    },
                    {
                        "name": "供应链",
                        "id": "gongyingl82"
                    },
                    {
                        "name": "器械维修",
                        "id": "qixiewx"
                    }
                ],
                "id": "yiliaoqx"
            },
            {
                "name": "制药",
                "children": [
                    {
                        "name": "药品研发",
                        "id": "yaopinyf"
                    },
                    {
                        "name": "化学分析",
                        "id": "huaxuefx"
                    },
                    {
                        "name": "药品注册",
                        "id": "yaopinzc"
                    },
                    {
                        "name": "产品经理",
                        "id": "chanpjl83"
                    },
                    {
                        "name": "医药代表",
                        "id": "yiyaodb"
                    },
                    {
                        "name": "医药招商",
                        "id": "yiyaozs"
                    }
                ],
                "id": "zhiyao"
            }
        ],
        "id": "ylzy"
    },
    {
        "name": "消费运输",
        "children": [
            {
                "name": "消费品",
                "children": [
                    {
                        "name": "研发",
                        "id": "yanfa"
                    },
                    {
                        "name": "产品",
                        "id": "chanpin9"
                    },
                    {
                        "name": "生产",
                        "id": "shengchan"
                    },
                    {
                        "name": "品牌",
                        "id": "pinpai"
                    },
                    {
                        "name": "采购",
                        "id": "caigou"
                    },
                    {
                        "name": "供应链",
                        "id": "gongyingl91"
                    },
                    {
                        "name": "质检",
                        "id": "zhijian"
                    }
                ],
                "id": "xfp"
            },
            {
                "name": "贸易",
                "children": [
                    {
                        "name": "跟单",
                        "id": "gendan"
                    },
                    {
                        "name": "买手",
                        "id": "maishou"
                    },
                    {
                        "name": "采购",
                        "id": "caigou92"
                    }
                ],
                "id": "my"
            },
            {
                "name": "交通运输",
                "children": [
                    {
                        "name": "铁路乘务员",
                        "id": "tielucwy"
                    },
                    {
                        "name": "列车长",
                        "id": "lieche"
                    },
                    {
                        "name": "公交司机",
                        "id": "gongjiao"
                    },
                    {
                        "name": "的士司机",
                        "id": "dishi"
                    },
                    {
                        "name": "飞行员",
                        "id": "feixing"
                    },
                    {
                        "name": "空乘",
                        "id": "kongcheng"
                    },
                    {
                        "name": "地勤",
                        "id": "diqin"
                    },
                    {
                        "name": "船长",
                        "id": "chuanzhang"
                    },
                    {
                        "name": "水手",
                        "id": "shuishou"
                    },
                    {
                        "name": "安检",
                        "id": "anjian"
                    },
                    {
                        "name": "调度员",
                        "id": "diaodu"
                    },
                    {
                        "name": "海关事务",
                        "id": "haiguan"
                    }
                ],
                "id": "ys2"
            },
            {
                "name": "物流仓储",
                "children": [
                    {
                        "name": "快递",
                        "id": "kuaidi"
                    },
                    {
                        "name": "邮递",
                        "id": "youdi"
                    },
                    {
                        "name": "理货",
                        "id": "lihuo"
                    },
                    {
                        "name": "仓库管理",
                        "id": "cangkugl"
                    },
                    {
                        "name": "订单处理",
                        "id": "dingdan"
                    },
                    {
                        "name": "集装箱业务",
                        "id": "jizhuangx"
                    },
                    {
                        "name": "物流管理",
                        "id": "wuliugl"
                    },
                    {
                        "name": "货运代理",
                        "id": "huoyundl"
                    }
                ],
                "id": "wl"
            }
        ],
        "id": "xfys"
    },
    {
        "name": "制造能源",
        "children": [
            {
                "name": "汽车制造",
                "children": [
                    {
                        "name": "机械设计",
                        "id": "jixiesj"
                    },
                    {
                        "name": "动力系统工程师",
                        "id": "donglixt"
                    },
                    {
                        "name": "底盘工程师",
                        "id": "dipan"
                    },
                    {
                        "name": "总装工艺工程师",
                        "id": "zongzhuangy"
                    },
                    {
                        "name": "项目管理",
                        "id": "xiangmugl101"
                    },
                    {
                        "name": "二手车评估师",
                        "id": "qichepg"
                    },
                    {
                        "name": "汽车销售",
                        "id": "qichexs"
                    },
                    {
                        "name": "汽车美容",
                        "id": "qichemr"
                    }
                ],
                "id": "qczz"
            },
            {
                "name": "机械制造",
                "children": [
                    {
                        "name": "机械工程师",
                        "id": "jixiegc"
                    },
                    {
                        "name": "自动化工程师",
                        "id": "zidonghua"
                    },
                    {
                        "name": "机电工程师",
                        "id": "jidiangc"
                    },
                    {
                        "name": "结构工程师",
                        "id": "jiegougc"
                    },
                    {
                        "name": "焊接工艺工程师",
                        "id": "hanjiegy"
                    },
                    {
                        "name": "液压工程师",
                        "id": "yeyagc"
                    },
                    {
                        "name": "模具设计工程",
                        "id": "mujusj"
                    }
                ],
                "id": "jxzz"
            },
            {
                "name": "能源",
                "children": [
                    {
                        "name": "燃气技术",
                        "id": "ranqijs"
                    },
                    {
                        "name": "热能工程师",
                        "id": "renenggc"
                    },
                    {
                        "name": "电力工程师",
                        "id": "dianligc"
                    },
                    {
                        "name": "管道设计",
                        "id": "guandaosj"
                    },
                    {
                        "name": "自控工程师",
                        "id": "zikonggc"
                    },
                    {
                        "name": "水利工程师",
                        "id": "shuiligc"
                    },
                    {
                        "name": "测绘工程师",
                        "id": "cehuigc103"
                    },
                    {
                        "name": "地质工程师",
                        "id": "dizhigc"
                    },
                    {
                        "name": "钻井工程师",
                        "id": "zuanjing"
                    },
                    {
                        "name": "地质勘查",
                        "id": "dizhikc"
                    },
                    {
                        "name": "采矿",
                        "id": "caikuang"
                    }
                ],
                "id": "ny"
            },
            {
                "name": "化工",
                "children": [
                    {
                        "name": "材料工程师",
                        "id": "cailiao"
                    },
                    {
                        "name": "配方工程师",
                        "id": "peifang"
                    },
                    {
                        "name": "工艺工程师",
                        "id": "gongyi"
                    }
                ],
                "id": "hg"
            }
        ],
        "id": "zhizaony"
    },
    {
        "name": "公共事业",
        "children": [
            {
                "name": "公务事业",
                "children": [
                    {
                        "name": "警察",
                        "id": "jingcha"
                    },
                    {
                        "name": "公务员",
                        "id": "gongwu"
                    },
                    {
                        "name": "事业单位人员",
                        "id": "shiyedw"
                    },
                    {
                        "name": "国企员工",
                        "id": "guoqi"
                    }
                ],
                "id": "gwsy"
            },
            {
                "name": "学术科研",
                "children": [
                    {
                        "name": "大学教授",
                        "id": "jiaoshou"
                    },
                    {
                        "name": "研究员",
                        "id": "yanjiu"
                    }
                ],
                "id": "xsky"
            },
            {
                "name": "非盈利组织",
                "children": [
                    {
                        "name": "义工",
                        "id": "yigong"
                    },
                    {
                        "name": "志愿者",
                        "id": "zhiyuanz"
                    },
                    {
                        "name": "支教老师",
                        "id": "zhijiao"
                    }
                ],
                "id": "fylzz"
            },
            {
                "name": "农林牧渔",
                "children": [
                    {
                        "name": "饲养员",
                        "id": "siyang"
                    },
                    {
                        "name": "农艺师",
                        "id": "nongyi"
                    },
                    {
                        "name": "畜牧师",
                        "id": "xumu"
                    },
                    {
                        "name": "护林员",
                        "id": "hulin"
                    },
                    {
                        "name": "园艺师",
                        "id": "yuanyi"
                    },
                    {
                        "name": "动物养殖",
                        "id": "dongwuyz"
                    },
                    {
                        "name": "饲料研发",
                        "id": "siliaoyf"
                    }
                ],
                "id": "nlmy"
            }
        ],
        "id": "ggsy"
    }
]
const genderConfig = ["男","女"]

export default (for_block_name, for_field_name) => {
    switch (for_block_name) {
        case Module.BASE: {
            switch (for_field_name) {
                case BASE_FIELD_ENUM.EDUCATION_BACKGROUND: {
                    return educationBackgroundConfig
                }
                case BASE_FIELD_ENUM.MARITAL_STATUS: {
                    return marriageConfig
                }
                case BASE_FIELD_ENUM.YEARS_OF_WORKING: {
                    return yearsOfWorkingConfig
                }
                case  BASE_FIELD_ENUM.POLITICS_STATUS: {
                    return politicsConfig
                }
                case  BASE_FIELD_ENUM.GENDER: {
                    return genderConfig
                }
            }
            break
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            switch (for_field_name) {
                case EDUCATIONAL_BACKGROUND_FIELD_ENUM.EDUCATION: {
                    return educationConfig
                }
            }
            break
        }
        case Module.JOB_INTEREST: {
            switch (for_field_name) {
                case JOB_INTEREST_FIELD_ENUM.JOB_TYPE: {
                    return jobInterestJobTypeConfig
                }
                case JOB_INTEREST_FIELD_ENUM.ROLE: {
                    return jobInterestRoleConfig
                }
                case JOB_INTEREST_FIELD_ENUM.CITY: {
                    return cityConfig
                }
            }
            break
        }
    }
}
