import React, { useState, useEffect, useMemo } from 'react';
import { createColumnHelper, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import styled from 'styled-components';
import EditableHeader from './EditableHeader';
import { message } from 'antd';


const TableStyled = styled.div`
    .table {
        background-color: #fff;
        border-bottom: 1px solid #CCCCCC;
        border-right: 1px solid #CCCCCC;
    }
    .cell {
        border-top: 1px solid #CCCCCC;
        border-left: 1px solid #CCCCCC;
    }
    .edit-header {
        background: rgba(233,254,251,0.42);
        border-bottom: 1px solid #11BBA6;
        border-right: 1px solid #11BBA6;
        margin-bottom: 4px;
    }
    input {
        outline: none;
        border: none;
        width: 100%;
        text-align: center;
        padding: 0;
        background-color: transparent;
    }
`

const EditCellStyled = styled.div`
    width: 100%;
    position: relative;
    height: 45px;
    ${props => props.$focus && `outline: 1px solid #11BBA6; z-index; 100;`}
`


const columnHelper = createColumnHelper()
const EditableCell = ({
    style = {},
    value: initialValue, // 初始单元格值
    row: { index }, // 行索引
    column: { id }, // 列ID
    updateMyData, // 用于更新数据的函数
    onChangeFocus,
}) => {
    const [value, setValueState] = useState(initialValue);
    const [focus, setFocus] = useState(false)

    const onChange = e => setValueState(e.target.value);
    const onBlur = () => {
        setFocus(false)
        onChangeFocus(false)
        if (initialValue !== value) {
            updateMyData(index, id, value);
        }
    };

    const onFocus = () => {
        setFocus(true)
        onChangeFocus(true)
    }

    // 如果初始值发生变化，我们需要同步状态
    useEffect(() => {
        setValueState(initialValue);
    }, [initialValue]);

    return (
        <EditCellStyled $focus={focus} className='flex-center'>
            <input value={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
        </EditCellStyled>
    )
}

const ScoreTable = ({ headerVisible, value, onChange }) => {
    const [data, setData] = useState(value);
    const [tableFocus, setTableFocus] = useState(false)
    const columns = useMemo(() => {
        return [
            columnHelper.accessor('subject', {
                header: () => '学科',
                cell: info => {
                    return <EditableCell
                        style={{ width: 100 }}
                        value={info.getValue()}
                        row={info.row}
                        column={info.column}
                        onChangeFocus={(v) => setTableFocus(v)}
                        updateMyData={updateMyData}
                    />;
                },
            }),
            columnHelper.accessor('score', {
                header: () => '分数',
                cell: info => {
                    return <EditableCell
                        value={info.getValue()}
                        row={info.row}
                        column={info.column}
                        onChangeFocus={(v) => setTableFocus(v)}
                        updateMyData={updateMyData}
                    />;
                },
            }),
        ];
    }, [data]);
    const [focusIndex, setFocusIndex] = useState('')

    const updateMyData = (rowIndex, columnId, value) => {
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            }),
        );
    };
    const deleteColumn = (rowIndex) => {
        if (data.length <= 1) {
            message.info('至少保留一个数据')
        } else {
            // 更新数据状态以移除对应的行
            setData(oldData => oldData.filter((row, index) => index !== rowIndex));
            setFocusIndex('')
        }
    };

    const addColumn = (side, index) => {
        const newRow = {
            subject: '填写课程',
            score: '填写成绩',
        };
        setData((oldData) => {
            // 根据side的值来决定新行的插入位置
            const start = side === 'left' ? index : index + 1;
            return [
                ...oldData.slice(0, start),
                newRow,
                ...oldData.slice(start),
            ];
        });
        setFocusIndex('')
    }

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onCellEdit: (cell, value) => {
            updateMyData(cell.row.index, cell.column.id, value);
        },
    });

    useEffect(() => {
        onChange(data)
    }, [data])

    return (
        <>
            {
                (headerVisible || tableFocus) && <EditableHeader
                    key={data}
                    table={table}
                    focusIndex={focusIndex}
                    onChangeFocusIndex={(v) => setFocusIndex(v)}
                    onAddColumn={addColumn}
                    onDeleteColumn={deleteColumn}
                />
            }
            <div className='table'>
                <div className='flex'>
                    {table.getRowModel().rows.map(row => (
                        <div className='flex flex-col flex-1' key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <div className='cell flex-center' key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};


const EditableTable = ({ headerVisible, value, onChange }) => {

    return <TableStyled>
        <ScoreTable headerVisible={headerVisible} value={value} onChange={onChange} />
    </TableStyled>

}


export default EditableTable