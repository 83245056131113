import styled from "styled-components";
import FlexStyled from "@/styled/FlexStyled";

const HeaderContainerStyled = styled(FlexStyled)`
  height: 60px;
  background: #111111;
  display: flex;
  align-items: center;
  padding: 0 50px;
  flex-shrink: 0;
  position: relative;
  z-index: 3;
`
export default HeaderContainerStyled
