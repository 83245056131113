import styled from "styled-components";
import { filterCurrentToName } from ".";
import FlexStyled from "@/styled/FlexStyled";
import Pointer from "@/styled/Pointer";
import { useState } from "react";

const FilterListBarStyled = styled.div`
  margin: 16px auto 0;
  .wrap {
    margin: 0 auto;
    width: ${(props) => props.width}px;
    display: flex;
    align-items: center;
    /* padding-top: 20px; */
    /* justify-content: center; */

    font-size: 14px;
    font-family: PingFangSC-Medium, "PingFang SC";
    font-weight: 500;
    color: rgb(136, 136, 136);
  }
  .item {
    height: 22px;
    line-height: 22px;
  }
  img {
    margin: 0 12px;
  }
  .item {
    border: 1px solid rgb(17, 17, 17);
    display: flex;
    border: 1px solid rgb(17, 17, 17);
    border-radius: 4px;
    align-items: center;
    padding: 0px 3px 0px 6px;
    position: relative;
    /* margin-right: 10px; */
    font-size: 14px;
    font-family: PingFangSC-Medium, "PingFang SC";
    font-weight: 500;
    color: rgb(17, 17, 17);
  }
  .page {
    margin-left: auto;
    color: #666666;
  }
`;

const FilterContainerStyled = styled.div`
  height: 22px;
  //border-radius: 4px;

  border: 1px solid #111111;
  display: flex;
  align-items: center;
  padding: 0 3px 0 6px;
  position: relative;
  margin-right: 10px;

  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #111111;
`;

const SearchComponent = (props) => {
    const [showChildren, setShowChildren] = useState(false);
    const onMouseEnter = () => {
        setShowChildren(true);
    };
    const onMouseLeave = () => {
        setShowChildren(false);
    };
    return (
        <FilterContainerStyled
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={
                showChildren
                    ? {
                        minWidth: 50,
                        // background: showChildren ? undefined : 'rgb(243, 243, 243)',
                        border: '1px solid #111111',
                    }
                    : {
                        minWidth: 50,
                        // background: showChildren ? undefined : 'rgb(243, 243, 243)',
                        border: '1px solid #ddd',
                    }
            }
        >
            <FlexStyled $justifyContent='space-between' style={{ width: '100%' }}>
                <div />
                <FlexStyled $alignItems='center'>
                    {props.value}
                    <Pointer onClick={props.onClickClose}>
                        <FlexStyled
                            $alignItems='center'
                            $justifyContent='center'
                            style={{
                                height: 22,
                                width: 22,
                                background: '#111111',
                                position: 'relative',
                                right: -4,
                            }}
                        >
                            <img
                                src='https://oss.jianzeppt.com/user/15189/c561d5c4-c253-4ea5-b1ca-d2ddfcba70de.png'
                                width={10}
                                height={10}
                            />
                        </FlexStyled>
                    </Pointer>
                </FlexStyled>
            </FlexStyled>
        </FilterContainerStyled>
    );
};

const FilterListBar = ({ filterCurrent, width, changeFilterCurrent, value, onDeleteValue, total,subText }) => {
    const { style, use, position, language,color } =
        filterCurrentToName(filterCurrent);

    const list = [
        {
            name:'颜色',
            value:color
        },
        {
            name: "风格",
            value: style,
        },
    
        // {
        //     name: "行业",
        //     value: profession,
        // },
        {
            name: "用途",
            value: use,
        },
        {
            name: "职位",
            value: position,
        },
        {
            name: "语言",
            value: language,
        },
    ];


    const handleDelete = (selectItem) => {
        const groupIndex = list.findIndex(i => i.name === selectItem.name)
        changeFilterCurrent && changeFilterCurrent(groupIndex, undefined,'isDelete')
    }

    const handleClearValue = () => {
        onDeleteValue && onDeleteValue('')
    }

    return (
        <FilterListBarStyled width={width}>
            <div className="wrap">
                <div className="start">{(list.some(item => item.value !== '全部') || value) && '全部结果'}</div>
                {list.map((item) => {
                    if(item.name==='职位'){
                        item.subText = subText ? ('-'+ subText ): ''
                    }
                    return (
                        item.value !== "全部" && (
                            <>
                                <img
                                    width="5"
                                    height="10"
                                    src="https://oss.jianzeppt.com/user/12426/b075ccd9-a7f8-4cda-a95e-8390ebc0532f.png"
                                ></img>
                                <div className="item">
                                    {item.name} : {item.value +( item.subText ? item.subText:'') } 
                                    <div
                                        onClick={() => handleDelete(item)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 14,
                                            height: 14,
                                            marginLeft: 4,
                                            cursor: 'pointer'
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: `<svg
                                                viewBox='0 0 1024 1024'
                                                version='1.1'
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='14'
                                                height='14'
                                                >
                                                <path
                                                    d='M504.224 470.288l207.84-207.84a16 16 0 0 1 22.608 0l11.328 11.328a16 16 0 0 1 0 22.624l-207.84 207.824 207.84 207.84a16 16 0 0 1 0 22.608l-11.328 11.328a16 16 0 0 1-22.624 0l-207.824-207.84-207.84 207.84a16 16 0 0 1-22.608 0l-11.328-11.328a16 16 0 0 1 0-22.624l207.84-207.824-207.84-207.84a16 16 0 0 1 0-22.608l11.328-11.328a16 16 0 0 1 22.624 0l207.824 207.84z'
                                                    p-id='2146'
                                                ></path>
                                                </svg>`,
                                        }}
                                    />
                                </div>
                            </>
                        )
                    );
                })}
                {
                    value && <>
                        <img
                            width="5"
                            height="10"
                            src="https://oss.jianzeppt.com/user/12426/b075ccd9-a7f8-4cda-a95e-8390ebc0532f.png"
                        ></img>
                        <SearchComponent value={value} onClickClose={handleClearValue} />
                    </>
                }
                {list.length > 0 && <div className="page">共 {total} 份简历</div>}
            </div>
        </FilterListBarStyled>
    );
};

export default FilterListBar;
