import getUserByLocal from '@/common/user/getUserByLocal';
import { login_anonymous } from '@/http/api';
import loginHandler from '@/common/user/loginHandler';
import Template from "../pages/Home";
import ResumeDetail from "../pages/ResumeDetail";
import PersonalCenter from "../pages/PersonalCenter";
import Workspace from "../pages/Workspace";
import Down from "../pages/Down";
import DownClientPage from "@/pages/DownClientPage";
import ResumeContainerPage from "@/pages/ResumeContainerPage";
import Agreement from "@/pages/Agreement";
import AgreementLaw from "@/pages/Agreement/law";
import AgreementMember from "@/pages/Agreement/member";
import Admin from "@/pages/Admin";
import InterviewReport from "@/pages/InterviewReport";
import Mobile from "@/pages/Mobile";
import MobileHome from "@/pages/Mobile/pages/Home";
import MobileMy from "@/pages/Mobile/pages/My";
import MobileMyList from "@/pages/Mobile/pages/My/List";
import MobileResume from "@/pages/Mobile/pages/Resume";
import BasicModuleEdit from "@/pages/Mobile/pages/ResumeEdit/BasicModuleEdit";
import JobInterestModuleEdit from "@/pages/Mobile/pages/ResumeEdit/JobInterestModuleEdit";
import ModuleTitleEdit from "@/pages/Mobile/pages/ModuleTitleEdit";
import GlobalLayout from '@/layout';
import GeneralModuleEdit from '@/pages/Mobile/pages/ResumeEdit/GeneralEdit';
import LongTextEdit from '@/pages/Mobile/pages/ResumeEdit/LongTextEdit';
import ModuleManage from '@/pages/Mobile/pages/ModuleManage';
import PortfolioList from '@/pages/Mobile/pages/ResumeEdit/PortfolioEdit/list';
import PortfolioItem from '@/pages/Mobile/pages/ResumeEdit/PortfolioEdit';
import PortfolioTextEdit from '@/pages/Mobile/pages/ResumeEdit/PortfolioEdit/TextEdit';
import Preview from '@/pages/Mobile/pages/Preview';
import UploadResume from '@/pages/Mobile/pages/UploadResume';
import WxDownload from '@/pages/Mobile/pages/WxDownload';
import TemplateList from '@/pages/Mobile/pages/TemplateList';
import SearchWord from '@/pages/Mobile/pages/SearchWord';
import IOSPay from '@/pages/Mobile/pages/IOSPay';
import History from '@/pages/Mobile/pages/History';
import MobileAgreement from '@/pages/Mobile/pages/Agreement'
import SwitchTemplateList from '@/pages/Mobile/pages/SwitchTemplate';
import { isMiniProgram, isWechat, wxAppLogin } from '@/pages/Mobile/utils';
import Promo from '@/pages/Mobile/pages/Promo';
import PcTip from '@/pages/Mobile/pages/PcTip';
import PcPromo from '@/pages/Promo';
import InterView from '@/pages/Mobile/pages/Interview';
import InterViewQuestion from '@/pages/Mobile/pages/Interview/question';
import HomeEditResume from '@/pages/Mobile/pages/ResumeEdit/HomeEditResume';
import ResumeScreenShot from '@/pages/ResumeScreenShot';

import InterViewChooseJob from '@/pages/Mobile/pages/Interview/chooseJob/index.js'
import InterViewJobDesc from '@/pages/Mobile/pages/Interview/chooseJob/chooseJobDes'
import Home from "../pages/HomeWelcome";
import ImportCase from '@/pages/Verify/ImportCase';
import VerifyStyle from '@/pages/Verify/VerifyStyle';


const handleMobileError = () => {
    const user = getUserByLocal()
    if (!user) {
        login_anonymous().then(user => {
            loginHandler(user)
            // if (isMiniProgram()) {
            //     wxAppLogin()
            // } else {
            //     window.location.reload()
            // }
            console.log('这里导致了reload')
            window.location.reload()
        }).catch(err => {
            console.log('error')
        })
    }
}

export default [
    {
        Component: GlobalLayout,
        children: [
            {
                path: "/",
                Component: Template,
            },
            {
                path: "/template",
                Component: Template,
            },
            {
                path: "/resume",
                Component: ResumeDetail
            },
            {
                path: "/personalCenter",
                Component: PersonalCenter
            },
            {
                path: "/workspace",
                Component: Workspace
            },
            {
                path: "/down",
                Component: Down
            },
            {
                path: "/promo",
                Component: PcPromo
            },
            {
                path: "/down/client",
                Component: DownClientPage
            },
            {
                path: "/resume/container",
                Component: ResumeContainerPage
            },
            {
                path: "/agreement/user",
                Component: Agreement
            },
            {
                path: "/agreement/law",
                Component: AgreementLaw
            },
            {
                path: "/agreement/member",
                Component: AgreementMember
            },
            {
                path: "/jzresumeAdmin",
                Component: Admin
            },
            {
                path: "/interviewReport",
                Component: InterviewReport
            },
            {
                path: "/resume-screenshot",
                Component: ResumeScreenShot
            },
            {
                path:'/verify/import-case',
                Component: ImportCase
            },
            {
                path:'/verify/verify-style',
                Component: VerifyStyle
            }]
    },
    {
        path: "/mobile",
        element: <Mobile onError={handleMobileError} />,
        children: [
            {
                path: '/mobile/index',
                loader: () => document.title = "了了简历",
                element: <MobileHome />
            },
            {
                path: '/mobile/my',
                loader: () => document.title = "我的",
                element: <MobileMy />
            },
            {
                path: '/mobile/interview',
                loader: () => document.title = "面试",
                element: <InterView />
            },
            {
                path: '/mobile/interview/question',
                loader: () => document.title = "面试问题",
                element: <InterViewQuestion />
            },
            {
                path: '/mobile/my_list',
                loader: () => document.title = "我的简历",
                element: <MobileMyList />
            },
            {
                path: '/mobile/resume',
                loader: () => document.title = "编辑简历",
                element: <MobileResume />
            },
            {
                path: '/mobile/resume/preview',
                loader: () => document.title = "预览简历",
                element: <Preview />
            },
            {
                path: '/mobile/resume/switch_template',
                loader: () => document.title = "全部模板",
                element: <SwitchTemplateList />
            },
            {
                path: '/mobile/edit/basic',
                loader: () => document.title = "编辑基本信息",
                element: <BasicModuleEdit />
            },
            {
                path: '/mobile/edit/job_interest',
                loader: () => document.title = "编辑求职意向",
                element: <JobInterestModuleEdit />
            },
            {
                path: '/mobile/module_title/edit',
                loader: () => document.title = "编辑模块标题",
                element: <ModuleTitleEdit />
            },
            {
                path: '/mobile/edit/home_edit',
                loader: () => document.title = "编辑简历",
                element: <HomeEditResume/>
            },
            {
                path: '/mobile/edit/general',
                loader: () => document.title = "编辑简历",
                element: <GeneralModuleEdit />
            },
            {
                path: '/mobile/edit/long_text',
                loader: () => document.title = "编辑简历",
                element: <LongTextEdit />
            },
            {
                path: '/mobile/edit/portfolio_list',
                loader: () => document.title = "编辑简历",
                element: <PortfolioList />
            },
            {
                path: '/mobile/edit/portfolio_item',
                loader: () => document.title = "编辑简历",
                element: <PortfolioItem />
            },
            {
                path: '/mobile/edit/portfolio_text',
                loader: () => document.title = "编辑简历",
                element: <PortfolioTextEdit />
            },
            {
                path: '/mobile/resume/module_manage',
                loader: () => document.title = "模块管理",
                element: <ModuleManage />
            },
            {
                path: '/mobile/resume/upload',
                loader: () => document.title = "上传简历",
                element: <UploadResume />
            },
            {
                path: '/mobile/resume/wx-download',
                loader: () => document.title = "下载简历",
                element: <WxDownload />
            },
            {
                path: '/mobile/template',
                loader: () => document.title = "全部模板",
                element: <TemplateList />
            },
            {
                path: '/mobile/search_word',
                loader: () => document.title = "全部模板",
                element: <SearchWord />
            },
            {
                path: '/mobile/ios_pay',
                loader: () => document.title = "支付",
                element: <IOSPay />
            },
            {
                path: '/mobile/history',
                loader: () => document.title = "记录",
                element: <History />
            },
            {
                path: '/mobile/agreement',
                loader: () => document.title = "用户协议",
                element: <MobileAgreement />
            },
            {
                path: '/mobile/promo',
                loader: () => document.title = "了了简历",
                element: <Promo />
            },
            {
                path: '/mobile/pc_tip',
                loader: () => document.title = "了了简历",
                element: <PcTip />
            },
            {
                path: '/mobile/interview/chooseJob',
                loader: () => document.title = "选择岗位",
                element: <InterViewChooseJob />
            },
            {
                path: '/mobile/interview/chooseJobDes',
                loader: () => document.title = "职位描述",
                element: <InterViewJobDesc/>
            },
        ]
    },
]
