import {color} from "@/const/themeConfig";
import { ThemeConfig } from "antd";

const AntDesignTheme = {
  "token": {
    "colorPrimary": color,
    colorTextQuaternary: '#E6E9EB'
  },
}

export default AntDesignTheme
