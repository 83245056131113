import {Module} from "@/common/module/module";
import {BASE_Slot, CAMPUS_ACTIVITIES_SLOT_ENUM, CONTACT_INFORMATION_SLOT_ENUM} from "@/common/slot/slot";
import getBlockNameBySlot from "@/common/slot/getBlockNameBySlot";
import getFieldNameBySlot from "@/common/getFieldNameBySlot";
import {CONTACT_INFORMATION_ENUM} from "@/common/field/field";


export default (for_block_name, for_field_name, slot_value, index, id) => {
    for_block_name = for_block_name ? for_block_name : getBlockNameBySlot(slot_value);
    for_field_name = for_field_name ? for_field_name : getFieldNameBySlot(slot_value);
    switch (for_block_name) {
        case Module.CONTACT_INFORMATION: {
            if(for_field_name===CONTACT_INFORMATION_SLOT_ENUM.TITLE){
                return `[${Module.CONTACT_INFORMATION}].data['${CONTACT_INFORMATION_SLOT_ENUM.TITLE}']`;
            }
            if(for_field_name===CONTACT_INFORMATION_SLOT_ENUM.PHONE_NUMBER){
                return `['基本信息'].data['${BASE_Slot.PHONE_NUMBER}']`
            }
            if(for_field_name===CONTACT_INFORMATION_SLOT_ENUM.MAIL){
                return `['基本信息'].data['${BASE_Slot.MAIL}']`
            }
            break
        }
        case Module.BASE: {
            return `['基本信息'].data['${slot_value}']`
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            if (index !== undefined) {
                if (slot_value) {
                    return `[${Module.EDUCATIONAL_BACKGROUND}].data.content[${index}]['${slot_value}']`;
                }else{
                    return `[${Module.EDUCATIONAL_BACKGROUND}].data.content[${index}]`;
                }
            }
            return `[${Module.EDUCATIONAL_BACKGROUND}].data['${slot_value}']`;
        }
        case Module.WORK_EXPERIENCE: {
            if (index !== undefined) {
                if (slot_value) {
                    return `[${Module.WORK_EXPERIENCE}].data.content[${index}]['${slot_value}']`;
                }else{
                    return `[${Module.WORK_EXPERIENCE}].data.content[${index}]`;
                }
            }
            return `[${Module.WORK_EXPERIENCE}].data['${slot_value}']`;
        }
        case Module.PROJECT_EXPERIENCE: {
            if (index !== undefined) {
                if (slot_value) {
                    return `[${Module.PROJECT_EXPERIENCE}].data.content[${index}]['${slot_value}']`;
                } else {
                    return `[${Module.PROJECT_EXPERIENCE}].data.content[${index}]`;
                }
            }
            return `[${Module.PROJECT_EXPERIENCE}].data['${slot_value}']`;
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            if (index !== undefined) {
                if (slot_value) {
                    return `[${Module.INTERNSHIP_EXPERIENCE}].data.content[${index}]['${slot_value}']`;
                }else{
                    return `[${Module.INTERNSHIP_EXPERIENCE}].data.content[${index}]`;
                }
            }
            return `[${Module.INTERNSHIP_EXPERIENCE}].data['${slot_value}']`;
        }
        case Module.CAMPUS_ACTIVITIES: {
            if (index !== undefined) {
                if (slot_value) {
                    return `[${Module.CAMPUS_ACTIVITIES}].data.content[${index}]['${slot_value}']`;
                }else{
                    return `[${Module.CAMPUS_ACTIVITIES}].data.content[${index}]`;
                }
            }
            return `[${Module.CAMPUS_ACTIVITIES}].data['${slot_value}']`;
        }

        default: {
            if (for_block_name === Module.CUSTOM_MODULE) {
                return `['${id}'].data['${slot_value}']`
            }
            return `['${for_block_name}'].data['${slot_value}']`
        }
    }
}
