import withComponent from "@/hoc/withComponent"
import styled from "styled-components"
import Header from "./components/Header"
import CommandList from "./components/CommandList"
import FeatureList from "./components/FeatureList"
import Footer from "./components/Footer"

const PromoStyled = styled.div`

`

const Promo = () => {

    return <PromoStyled>
        <Header />
        <CommandList />
        <FeatureList />
        <Footer />
    </PromoStyled>
}

export default withComponent(Promo)