import useStore from "@/components/resume/redux/hooks/useStore";
import {useCallback, useEffect, useMemo, useRef} from "react";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";

export default (onChange) => {
    const store = useStore();
    const resumeInstance = useResumeInstance();
    const instance = useRef({
        unSubscribe: undefined, open: resumeInstance.openEdit
    }).current;
    useMemo(() => {
        resumeInstance.dataMaster = instance
    }, []);
    const handler = useCallback(() => {
        if (!instance.open) return
        onChange?.(store.getState());
    }, [])
    const listenChange = useCallback(() => {
        instance.unSubscribe = store.subscribe(handler);
    }, []);
    const clearListenChange = useCallback(() => {
        instance.unSubscribe?.();
    }, []);

    useEffect(() => {
        listenChange()
        return () => {
            clearListenChange();
        }
    }, []);
}
