import { useDispatch, useSelector } from "react-redux"
import { cloneDeep, set } from "lodash"
import { setResumeData } from "../pages/ResumeEdit/editSlice"
import { saveResumePageUserData } from "@/http/api"
import { Toast } from "antd-mobile"
import { useNavigate } from "react-router-dom"
import { fetchResumeUserList } from '../mobileSlice'


const useUpdateResumeData = ({ disableNavigate = false } = {}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const data = useSelector(state => state.mobileEdit.data)
    const saveData = async (pageId, resumeData, option) => {
        // 保存
        if (pageId && resumeData) {
            try {
                await saveResumePageUserData({ query: { pageId }, body: resumeData });
                
                dispatch(fetchResumeUserList())

                if (!disableNavigate && !option.disableNavigate) {
                    navigate(-1)
                }
                if (option.isToast) {
                    Toast.show({
                        icon: 'success',
                        content: option.content || '保存成功',
                    })
                }
            } catch (error) {
                Toast.clear()
            }
        }
    }

    const onChangeByPath = (path, value, isSave = true, option = {}) => {
        const defaults = {
            isToast: true,
            content: '保存成功',
            disableNavigate: false
        }
        const settings = Object.assign({}, defaults, option)
        const newResumeData = path ? set(cloneDeep(data.resumeData), path, value) : cloneDeep(value)
        dispatch(setResumeData(newResumeData))
        if (isSave) {
            saveData(data?.resume?.pageId, newResumeData, settings)
        }
    }

    const onDeleteByPath = async (path, value, isSave = true, option = {}) => {
        const defaults = {
            isToast: true,
            content: '删除成功',
            disableNavigate: false
        }
        const settings = Object.assign({}, defaults, option)
        const newResumeData = path ? set(cloneDeep(data.resumeData), path, value) : cloneDeep(value)
        if (isSave) {
            await saveData(data?.resume?.pageId, newResumeData, settings)
            dispatch(setResumeData(newResumeData))
        }
    }

    return {
        onChangeByPath,
        onDeleteByPath
    }
}

export default useUpdateResumeData