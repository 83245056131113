import styled from "styled-components";
import React, { useMemo, useState } from 'react';
import { Button, Drawer, Select, Switch, message } from 'antd';
import { useDispatch, useSelector, useStore } from "@/components/resume/redux/hooks";
import withComponent from "@/hoc/withComponent";
import FlexStyled from "@/styled/FlexStyled";
import paginationSlice from "@/components/resume/redux/services/paginationSlice";
import useResumeDetailInstance from "@/pages/ResumeDetail/hooks/useResumeDetailInstance";
import resumeSlice from "@/components/resume/redux/services/resumeSlice";
import loadFont, { fontFamilyList } from "@/utils/loadFont";


const ContainerStyled = styled.div`
  width: 280px;
  height: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 0.5);
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  padding-top: 18px;
`
const TitleStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323232;
  line-height: 20px;
  padding-left: 16px;
  padding-bottom: 10px;
`
const Title2PositionStyled = styled.div`
  margin-left: 20px;
`
const Title2Styled = styled.div`
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
`
const LineStyled = styled.div`
  height: 1px;
  background: #F4F4F4;
  margin: 0 16px;
`
const ColorStatusPositionStyled = styled.div`
  position: absolute;
  top: 20px;
  right: 12px;
  display: flex;
  align-items: center;
`
const ColorPositionStyled = styled.div`
  position: relative;
  padding-top: 20px;
`
const ColorItemStyled = styled.div`
  width: 30px;
  height: 30px;
  background: #0A70BE;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.9;
  ${props => props.$active ? 'box-shadow: 0px 4px 10px 0px rgba(55, 182, 84, 0.5);' : ''}

`


const colorList = ['#0A70BE', '#254665', '#599BF7', '#FF7171', '#37B754', '#292929', '#EE7001', '#A29178', '#DA1810', '#048BB3', '#6B4B24', '#9D57CB'];
const fontSizeList = Array.from({ length: 7 }).map((item, index) => ({ value: index + 12, label: index + 12 + " px" }));
const moduleSpace = [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2].map((item) => ({ value: item, label: item }))
const pageSpace = [
    {
        label: '窄边',
        value: 0.5,
    },
    {
        label: '适中',
        value: 1,
    },
    {
        label: '宽阔',
        value: 1.5,
    },
]
const languageList = ['简体'].map((item) => ({ value: item, label: item }))


const selectFontFamilyList = fontFamilyList.map((item) => ({ value: item.id, label: item.name }))

export default withComponent((props) => {
    const { onClose, visible } = props;
    const resumeDetailInstance = useResumeDetailInstance();

    const [value, setValue] = useState();
    const store = useStore();
    const themeIndex = useSelector(state => state.resume?.themeIndex);
    const fontSize = useSelector(state => state.resume?.fontSize);
    const fontFamily = useSelector(state => state.resume?.fontFamily);
    const [fontFamilyLoading, setFontFamilyLoading] = useState(false)
    const resumeData = useSelector(state => state.resumeData);
    const theme = useSelector(state => state.theme);
    const template = useSelector(state => state.template);
    const pagination = useSelector(state => state.pagination);
    const dispatch = useDispatch();

    const handleChange = (value) => {
        console.log(value);
    }
    const onClickAutoOnePage = () => {
        dispatch(paginationSlice.actions.switchAutoPagination())
    }
    const onClickColor = (index) => {
        resumeDetailInstance.resumeRef.current?.changeThemeWithSave?.(index)
        // dispatch(resumeSlice.actions.isChangeColor(true))
    }
    const onChange = () => {
        if (themeIndex !== undefined) {
            resumeDetailInstance.resumeRef.current?.changeThemeWithSave?.(undefined)
        }
    }
    const handleSelect = (type, value) => {
        if (type === 'font-size') {
            dispatch(resumeSlice.actions.setFontSize(value))
        } else if (type === 'font-family') {
            setFontFamilyLoading(true)
            loadFont(fontFamilyList.find((item) => item.id === value)).then(() => {
                dispatch(resumeSlice.actions.setFontFamily(value))
            }).catch(() => {
                message.error('加载字体失败')
            }).finally(() => {
                setFontFamilyLoading(false)
            })
        } else if (type === 'page-margin') {
            dispatch(resumeSlice.actions.setPageMargin(value))
        } else if (type === 'module-margin') {
            dispatch(resumeSlice.actions.setModuleMargin(value))
        } 
    }
// serif
    return <>
        <Drawer className="ant-custom-drawer" width="auto" placement="left" onClose={onClose} open={visible}>
            <ContainerStyled>
                <TitleStyled>主题设置</TitleStyled>
                <LineStyled />
                <ColorPositionStyled>
                    <Title2PositionStyled>
                        <Title2Styled>颜色</Title2Styled>
                    </Title2PositionStyled>
                    <ColorStatusPositionStyled>
                        <Title2Styled style={{ marginRight: 6 }}>初始状态</Title2Styled>
                        <Switch onChange={onChange} checked={themeIndex === undefined} size="small" />
                    </ColorStatusPositionStyled>
                    <FlexStyled $flexWrap="wrap" style={{ marginLeft: 20, marginTop: 17 }}>
                        {theme?.colorList?.map?.((color, index) => {
                            return <ColorItemStyled key={index}
                                $active={themeIndex === index}
                                onClick={() => onClickColor(index)}
                                style={{ background: color, marginBottom: 12, marginRight: 20 }} />
                        })}
                    </FlexStyled>
                    <LineStyled style={{ marginTop: 12 }} />
                </ColorPositionStyled>
                <div>
                    <FlexStyled style={{ marginLeft: 20 }}>
                        <div style={{ marginRight: 12 }}>
                            <Title2Styled style={{ marginTop: 19, marginBottom: 8 }}>字体</Title2Styled>
                            <Select placeholder="设置字体" loading={fontFamilyLoading} value={fontFamily} style={{ width: 114 }} options={selectFontFamilyList} onChange={(value) => handleSelect('font-family', value)} />
                        </div>
                        <div>
                            <Title2Styled style={{ marginTop: 19, marginBottom: 8 }}>字号</Title2Styled>
                            <Select placeholder="设置字号" value={fontSize} defaultValue={12} style={{ width: 114 }} options={fontSizeList} onChange={(value) => handleSelect('font-size', value)} />
                        </div>
                    </FlexStyled>
                    <FlexStyled style={{ marginLeft: 20 }}>
                        <div style={{ marginRight: 12 }}>
                            <Title2Styled style={{ marginTop: 19, marginBottom: 8 }}>模块间距</Title2Styled>
                            <Select placeholder="设置模块间距" style={{ width: 114 }} options={moduleSpace} onChange={(value) => handleSelect('module-margin', value)}  />
                        </div>
                        <div>
                            <Title2Styled style={{ marginTop: 19, marginBottom: 8 }}>页边距</Title2Styled>
                            <Select placeholder="设置页边距" style={{ width: 114 }} options={pageSpace} onChange={(value) => handleSelect('page-margin', value)}  />
                        </div>
                    </FlexStyled>
                    {/* <div style={{ marginLeft: 20 }}>
                        <Title2Styled style={{ marginTop: 19, marginBottom: 8 }}>语言</Title2Styled>
                        <Select defaultValue={languageList[0]} style={{ width: 240 }} options={languageList} />
                    </div> */}
                    {/* <div onClick={onClickAutoOnePage} style={{ marginTop: 30, marginLeft: 20 }}>
                        <Button type="primary" style={{ width: 114, height: 36, marginRight: 12 }}>
                            自动分页
                        </Button>
                        <Button className="ant-custom-button-out" type="default" style={{ width: 114, height: 36 }}>
                            统一格式
                        </Button>
                    </div> */}
                </div>
            </ContainerStyled>
        </Drawer>
    </>
})
