import {useCallback, useMemo, useRef} from "react";
import withComponent from "@/hoc/withComponent";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import Input from "@/components/resume/Input"
import getNestedProperty from "@/utils/getNestedProperty";
import useForceUpdate from "@/hooks/useForceUpdate";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import debounce from "@/utils/debounce";
import getPlaceholderBySlot from "@/common/config/getPlaceholder";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import getResumePath from "@/common/resume/getResumePath";
import {getDetailStyle, getModuleContentStyle} from "@/common/getStyleByAutoOnePage";
import classNames from "classnames";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import focusSlice from "@/components/resume/redux/services/focusSlice";
import {autoOnePage1, autoOnePage5} from "@/const/config";
import {parseMargin} from "@/utils/margin";
import { Module } from "@/common/module/module";
import ApplicationInfo from "./ApplicationInfo";

export default withComponent((props) => {
    const {tagName, style, className, slot_value, index} = props;
    const moduleInstance = useModuleInstance();
    const fieldInstance = useFieldInstance();
    const moduleContentInstance = useModuleContentInstance();
    const instance = useRef({
        key: 0
    }).current;
    const [, update] = useForceUpdate();
    const dispatch = useDispatch();
    const onePageLevel = useSelector((state) => state.pagination.onePageLevel);
    const path = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, slot_value, moduleContentInstance?.index, moduleInstance.data.id);
    const placeholder = getPlaceholderBySlot(fieldInstance.for_block_name, fieldInstance.for_field_name, slot_value);
    const value = useSelector((state) => getNestedProperty(state.resumeData, path));

    if (moduleInstance.data.id === Module.APPLICATION_INFO) {
        return <ApplicationInfo slot_value={slot_value} />
    }
    const text = useMemo(() => {
        return value
    }, [value])
    const updateTextKey = useCallback(() => {
        instance.key++
    }, [])
    const onInput = useCallback((e) => {
        debounceChange(e.currentTarget.innerHTML)
    }, []);
    const debounceChange = useCallback(debounce((value) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({path: path, value: value}));
    }, 500), [path]);
    const onFocus = useCallback((e) => {
        dispatch(focusSlice.actions.setModuleId(moduleInstance.data.id));
        instance.isFocus = true;
        update()
    }, []);

    const onPre = useCallback(() => {
        debounceChange.flush();
    }, []);


    const onBlur = useCallback(() => {
        dispatch(focusSlice.actions.setModuleId(null));
        instance.isFocus = false;
        update()
    }, []);
    if (!instance.isFocus) {
        updateTextKey()
    }


    const autoOnePageStyle = getModuleContentStyle(onePageLevel);
    const margin = parseMargin(style?.margin);


    return useMemo(() => <Input style={{...style, margin: undefined, ...margin, ...autoOnePageStyle}}
                                className={classNames(className, "detail-input")}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onPre={onPre}
                                data={{
                                    'data-block-name': fieldInstance.for_block_name,
                                    'data-field-name': fieldInstance.for_field_name,
                                    "data-slot-value": slot_value,
                                }}
                                value={text}
                                onInput={onInput}
                                placeholder={placeholder}/>, [instance.key])
})

