import thunk from 'redux-thunk';
import reducers from './reducers';
import initState from './initState';
import {configureStore} from '@reduxjs/toolkit';


export const createStore = () => {
    return configureStore({
        reducer: reducers,
        devTools: __PRO__ ? false : {name: "全局"},
        preloadedState: initState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    });
}


export default createStore();
