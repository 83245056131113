import withComponent from "@/hoc/withComponent";
import { useMemo, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { generateResume, uploadResumeByWord } from "@/http/mobileApi";
import { clearToast, showToast } from "@/pages/Mobile/utils/toast";
import { Picker, Toast } from "antd-mobile";
import { Cascader, ConfigProvider,Input } from "antd";
import data from "./data";
import TDropDown from "./TDropDown";
const { getJobTypesAll } = require("@/http/api");
import LogoComponent from "@/components/Logo"

const TabItemOneStyled = styled.div`
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  .tab-import {
    height: 150px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }
  .t-btn {
    width: 206px;
    height: 44px;
    background: #11bba6;
    border-radius: 9px;

    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
  }
  .t-label {
    font-size: 14px;
    color: #888888;
    margin-bottom: 5px;
  }
  input {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
  input::placeholder {
    color: #dedede;
  }
  .border-right {
    border-right: 1px solid #efefef;
  }
  .tab-item1-cascader{
    outline: none;
    border: none;
    margin: 0;
    margin-top:-4px;
    padding: 0;
    width:90%;
    font-size: 18px;
    .ant-select-selector{
        outline: none;
        border: none;
        margin: 0;
        padding: 0;
        font-size: 18px;
    }
    .ant-select-arrow{
        display:none;
    }
  }
`;

const yearsOfWorkingConfig = [

  {
    name: "应届生",
    value: "0年" ,
  },
  {
    name: "1-3年",
    value: "1-3年",
  },
  {
    name: "3-5年",
    value: "3-5年",
  },
  {
    name: "5-10年",
    value: "5-10年",
  },
];
// 函数用于重命名对象中的键
function renameObjectKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

// 通用的重命名键并处理子数组的函数
function renameKeysAndHandleSubTypes(
  data,
  renameMap,
  subTypeKey,
  newSubTypeKey
) {
  return data.map((item) => {
    // 重命名对象键
    const newItem = renameObjectKeys(item, renameMap);

    // 如果存在子类型属性，递归调用自身函数处理子数组
    if (newItem[subTypeKey]) {
      newItem[newSubTypeKey] = renameKeysAndHandleSubTypes(
        newItem[subTypeKey],
        renameMap,
        subTypeKey,
        newSubTypeKey
      );
      delete newItem[subTypeKey];
    }

    // 返回处理过的新对象
    return newItem;
  });
}

const TabItemOne = ({ style }) => {
  const navigate = useNavigate();
  const [work, setWork] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [yearVisible, setYearVisible] = useState(false);
  const [job, setJob] = useState("");
  const filterOptions = useMemo(() => {
    return data.filter((item) => item.name.includes(job));
  }, [data, job]);
  const [cascaderValue, setCascaderValue] = useState([]);

  // 创建映射，定义如何重命名键
  const renameMap = {
    key: "value",
    name: "label",
  };

  const [options, setOptions] = useState([]);
  useEffect(() => {
    getJobTypesAll().then((res) => {
      if (res) {
        setOptions(
          renameKeysAndHandleSubTypes(res, renameMap, "subTypes", "children")
        );
      }
    });
  }, []);
  const handleClickOne = () => {
    if (loading) return;
    if (!job) {
      Toast.show("请选择岗位名称");
      return;
    }
    if (!work) {
      Toast.show("请选择工作年限");
      return;
    }
    setLoading(true);
    showToast("生成中...");
    generateResume({
      positionTitle: job,
      experience: work,
    })
      .then((res) => {
        if (res) {
          navigate(`/resume?id=${res}`);
        }
      })
      .catch((err) => {
        Toast.show(err.msg || err.message);
      })
      .finally(() => {
        setLoading(false);
        clearToast();
      });
  };

  const handleClickItem = (item) => {
    setJob(item.name);
    setVisible(false);
  };

  const handleClickYearItem = (item) => {
    setWork(item.value);
    setYearVisible(false);
  };
  const confirmJob = (item, extend) => {
    setCascaderValue(item);
    const items = extend;
    if (!item) return;
    setJob(items[items.length - 1].label);
  };

  const displayRender = (labels, selectedOptions = []) => {
    return labels.map((label, i) => {
      const option = selectedOptions[i];
      if (i === labels.length - 1) {
        return <span key={option.value}>{label} </span>;
      }
      return <span key={options.value}></span>;
    });
  };

  const workLabel =
    yearsOfWorkingConfig.find((item) => item.value === work)?.name || work;

  const dropdownVisible = job && filterOptions.length > 0 && visible;
  return (
    <TabItemOneStyled style={style}>
      <div className="tab-import ">
        <div
          className="flex-center"
          style={{ width: "100%", boxSizing: "border-box", marginBottom: 16,padding:'16px 30px 0' }}
        >
          <div
            className="border-right flex flex-col"
            style={{ flex: 7, position: "relative" }}
          >
            <div className=" t-label mb-5 ">岗位名称</div>
            {/* <input
              value={job}
              readOnly
              onFocus={() => setVisible(true)}
              onChange={(e) => setJob(e.target.value)}
              placeholder="请选择岗位名称"
            /> */}

            <ConfigProvider
              theme={{
                components: {
                  Cascader: {
                    // controlWidth: 290,
                    dropdownHeight: 300,
                  },
                },
              }}
            >
              <Cascader
                className="tab-item1-cascader"
                options={options}
                visible={visible}
                onClose={() => {
                  setVisible(false);
                }}
                placeholder="请选择岗位名称"
                popupClassName="edit-custom-picker"
                //   value={cascaderValue}
                onChange={confirmJob}
                displayRender={displayRender}
              >
                {}
              </Cascader>
            </ConfigProvider>

            {/* <TDropDown
              visible={dropdownVisible}
              options={filterOptions}
              onClick={handleClickItem}
              onClose={() => setVisible(false)}
              highlight={job}
            /> */}
          </div>
          <div
            className="flex flex-col pl-12"
            style={{ flex: 5, position: "relative" }}
          >
            <div className="t-label mb-5 ">工作年限</div>
            <Input
              readOnly
              value={workLabel}
              onClick={() => {
                setYearVisible(true);
              }}
              placeholder="请选择工作年限"
            />
            <TDropDown
              visible={yearVisible}
              options={yearsOfWorkingConfig}
              onClick={handleClickYearItem}
              onClose={() => setYearVisible(false)}
              // highlight={job}
            />
          </div>
        </div>

        <div className="flex-center">
          <div onClick={handleClickOne} className="flex-center t-btn">
            一键生成简历
          </div>
        </div>
      </div>
    </TabItemOneStyled>
  );
};

export default withComponent(TabItemOne);
