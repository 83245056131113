import {Popover} from "antd";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";
import {createPortal} from "react-dom";
import styled from "styled-components"

const MaskStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
`

export default (props) => {
    const {content, children, onOpenChange, open} = props;
    const resumeInstance = useResumeInstance();
    return <Popover open={open}
                    content={<div>
                        {
                            createPortal(<MaskStyled>

                            </MaskStyled>, resumeInstance.rootContainer)}
                        {content}
                    </div>}
                    overlayClassName="ant-popover-custom"
                    autoAdjustOverflow={false}
                    destroyTooltipOnHide
                    trigger="click"
                    onOpenChange={onOpenChange}
                    getTooltipContainer={resumeInstance.getModuleContentToolContainer}
                    transitionName=""
                    getPopupContainer={null}
                    placement="bottom">
        {children}
    </Popover>
}
