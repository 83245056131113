import {createSlice} from '@reduxjs/toolkit';
import initState from '../initState';


export default createSlice({
    name: 'focus',
    initialState: initState.focus,
    reducers: {
        set(state, {payload}) {
            return payload
        },
        setModuleId(state, {payload}) {
            state.moduleId = payload
        },
        setModuleContentId(state, {payload}) {
            state.moduleContentIndex = payload
        },
        setAiDecisionFiledId(state, {payload}) {
            state.filedId = payload
        }
    }
});
