import {BASE_FIELD_ENUM} from "@/common/field/field";
import {Module} from "@/common/module/module";

export default (block_id,field_name) => {
    switch (block_id) {
        case Module.BASE: {
            return `['基本信息'].icons.${field_name}`
        }
        default:{
            return `[${block_id}].icons.${field_name}`
        }
    }

}
