import useConfigRender from "@/components/resume/hooks/useConfigRender";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import {createElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import {Module} from "@/common/module/module";
import {JOB_INTEREST_FIELD_ENUM} from "@/common/field/field";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import getResumePath from "@/common/resume/getResumePath";
import getMenuConfigByField from "@/components/resume/const/getMenuConfigByField";
import {Popover} from "antd";
import Select from "@/components/resume/components/Select";
import withComponent from "@/hoc/withComponent";
import getNestedProperty from "@/utils/getNestedProperty";
import CommonSlotInputByPath from "@/components/resume/components/CommonSlotInputByPath";
import getBlockNameBySlot from "@/common/slot/getBlockNameBySlot";
import getFieldNameBySlot from "@/common/getFieldNameBySlot";
import useSelectedField from "../hooks/useSelectedField";

export default withComponent((props) => {
    const {tagName, style, className, children = [], canInputEdit} = props;
    const ref = useRef(null)
    const configRender = useConfigRender();
    const fieldInstance = useFieldInstance();
    let slot_value = fieldInstance.for_field_name;
    const moduleContentInstance = useModuleContentInstance();
    const [visible, setVisible] = useState(false);
    useSelectedField({ visible, ref, type: 'input' })
    const dispatch = useDispatch();
    const onClickMenuItem = useCallback((item) => {
        let value = item;
        let for_block_name = getBlockNameBySlot(slot_value) || fieldInstance?.for_block_name;
        let for_field_name = fieldInstance?.for_field_name;


        switch (for_block_name) {
            case Module.JOB_INTEREST: {
                switch (for_field_name) {
                    case JOB_INTEREST_FIELD_ENUM.CITY:
                    case JOB_INTEREST_FIELD_ENUM.ROLE: {
                        value = item.name;
                    }
                }
            }
        }
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: getResumePath(for_block_name, for_field_name, slot_value, moduleContentInstance?.index),
            value
        }));
        setVisible(false);
    }, []);
    let for_block_name = getBlockNameBySlot(slot_value) || fieldInstance.for_block_name;
    let for_field_name = fieldInstance.for_field_name;
    const jdList = useSelector(state => state.interview.jdListWithResume);
    const config = useMemo(() => {
        if (for_block_name === Module.JOB_INTEREST && for_field_name === JOB_INTEREST_FIELD_ENUM.ROLE) {
            return jdList
        }
        return getMenuConfigByField(for_block_name, for_field_name) || []
    }, [jdList]);

    
    const path = getResumePath(for_block_name, fieldInstance.for_field_name, slot_value, moduleContentInstance?.index);
    const getValueByState = (state, path) => {
        const resumeData = state.resumeData;
        return getNestedProperty(resumeData, path)
    }
    const value = useSelector((state) => getValueByState(state, path));
    let element = createElement(tagName, {style, className, ref}, value);
    if (canInputEdit) {
        element = createElement(tagName, {style, className, ref}, createElement(CommonSlotInputByPath, props));
    }
    return <Popover content={<Select onClickItem={onClickMenuItem} list={config}/>}
                    destroyTooltipOnHide
                    overlayClassName="ant-popover-custom-field-menu"
                    autoAdjustOverflow={false}
                    trigger="click"
                    getPopupContainer={null}
                    transitionName=""
                    open={visible}
                    placement="bottom"
                    onOpenChange={setVisible}>
        {element}
    </Popover>
})
