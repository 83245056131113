import { createPortal } from 'react-dom'
import { useState, useRef, memo, useEffect } from 'react'

const Portal = memo(({ id, className, children }) => {
    const vnode = document.getElementById(id) || document.createElement('div')
    className && (vnode.className = className)
    const el = useRef(vnode)
    const [dynamic] = useState(!el.current.parentElement)
    useEffect(() => {
        if (dynamic) {
            id && (el.current.id = id)
            document.body.appendChild(el.current)
        }
        return () => {
            if (dynamic && el.current.parentElement) {
                el.current.parentElement.removeChild(el.current)
            }
        }
    }, [id])
    return createPortal(children, el.current)
})


export default Portal
