import {Module} from "@/common/module/module";
import {BASE_Slot, JOB_INTEREST_SLOT_ENUM} from "@/common/slot/slot";
import {JOB_INTEREST_FIELD_ENUM} from "@/common/field/field";

export default (slot) => {
    switch (slot) {
        case BASE_Slot.NAME: {
            return Module.BASE
        }

        case BASE_Slot.DES: {
            return Module.BASE
        }
        case JOB_INTEREST_FIELD_ENUM.JOB_STATUS: {
            return Module.JOB_INTEREST
        }
        case JOB_INTEREST_SLOT_ENUM.JOB_TYPE: {
            return Module.JOB_INTEREST
        }
        case JOB_INTEREST_SLOT_ENUM.ROLE: {
            return Module.JOB_INTEREST
        }
        case JOB_INTEREST_SLOT_ENUM.CITY: {
            return Module.JOB_INTEREST
        }
        case JOB_INTEREST_SLOT_ENUM.SALARY: {
            return Module.JOB_INTEREST
        }

    }
}
