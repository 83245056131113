import templateSlice from "@/components/resume/redux/services/templateSlice";
import resumeSlice from "@/components/resume/redux/services/resumeSlice";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import themeSlice from "@/components/resume/redux/services/themeSlice";
import paginationSlice from "@/components/resume/redux/services/paginationSlice";

export default (dispatch,data)=>{
    dispatch(templateSlice.actions.set(data.template));
    dispatch(resumeSlice.actions.set(data.resume));
    dispatch(resumeDataSlice.actions.set(data.resumeData));
    dispatch(themeSlice.actions.set(data.theme));
    dispatch(paginationSlice.actions.set(data.pagination));
}
