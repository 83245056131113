import withComponent from "@/hoc/withComponent";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import {createElement, useMemo, useState} from "react";
import {
    CAMPUS_ACTIVITIES_FIELD_ENUM,
    CONTACT_INFORMATION_ENUM,
    CUSTOM_MODULE_FIELD_ENUM,
    EDUCATIONAL_BACKGROUND_FIELD_ENUM,
    HONORS_AND_AWARDS_FIELD_ENUM,
    INTEREST_FIELD_ENUM,
    INTERNSHIP_EXPERIENCE_FIELD_ENUM,
    JOB_INTEREST_FIELD_ENUM,
    LANGUAGE_PROFICIENCY_FIELD_ENUM,
    PORTFOLIO_FIELD_ENUM,
    PROJECT_EXPERIENCE_FIELD_ENUM,
    SELF_EVALUATION_FIELD_ENUM,
    SKILLS_FIELD_ENUM,
    WORK_EXPERIENCE_FIELD_ENUM
} from "@/common/field/field";
import SvgComponent from "@/components/resume/SvgComponent";
import * as React from "react";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import IconModal from "@/components/IconModal";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import getIconPathByField from "@/common/config/getIconPathByField";
import getNestedProperty from "@/utils/getNestedProperty";
import classNames from "classnames";
import {Module} from "@/common/module/module";

export default withComponent((props) => {
    const {tagName, className, style, width, height} = props;
    const moduleInstance = useModuleInstance();
    const fieldInstance = useFieldInstance();
    const dispatch = useDispatch();
    const getId = () => {
        let id;
        if (props["data_block_name"] === "基本信息") {
            id = "基本信息"
        } else {
            id = moduleInstance?.data?.id;
        }
        return id
    }
    const getField = () => {
        return fieldInstance?.for_field_name || "title";
    }

    const [visibleModal, setVisibleModal] = useState(false);
    const onClick = () => {
        setVisibleModal(true);
    }
    const onClickIcon = (value) => {
        if (getId() && getField()) {
            const path = getIconPathByField(getId(), getField())
            dispatch(resumeDataSlice.actions.changeValueByPath({path: path, value: value}));
        }
        setVisibleModal(false);
    }
    const canEdit = useMemo(() => {
        return  getId() && getField()
        if (getId() === Module.BASE) {
            return true
        }
        if (getId() && getField()) {
            switch (fieldInstance.for_field_name) {
                case CONTACT_INFORMATION_ENUM.TITLE:
                case WORK_EXPERIENCE_FIELD_ENUM.TITLE:
                case PROJECT_EXPERIENCE_FIELD_ENUM.TITLE:
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.TITLE:
                case SKILLS_FIELD_ENUM.TITLE:
                case INTEREST_FIELD_ENUM.TITLE:
                case PORTFOLIO_FIELD_ENUM.TITLE:
                case EDUCATIONAL_BACKGROUND_FIELD_ENUM.TITLE:
                case CAMPUS_ACTIVITIES_FIELD_ENUM.TITLE:
                case HONORS_AND_AWARDS_FIELD_ENUM.TITLE:
                case LANGUAGE_PROFICIENCY_FIELD_ENUM.TITLE:
                case SELF_EVALUATION_FIELD_ENUM.TITLE:
                case CUSTOM_MODULE_FIELD_ENUM.TITLE:
                case JOB_INTEREST_FIELD_ENUM.TITLE: {
                    return true
                }
            }
        }
    }, []);


    const path = getIconPathByField(getId(), getField())
    const value = useSelector((state) => getNestedProperty(state.resumeData, path));


    const originClassName = useMemo(() => value ? className.replace("xmfont", "").replace("xmicon", "").replace("wbdfont", "") : className, [
        className, value
    ])



    if (canEdit) {
        return <>
            {createElement(tagName, {
                ...props,
                style: {...props.style, cursor: "pointer"},
                className: classNames(originClassName, "iconfont"),
                onClick,
                dangerouslySetInnerHTML: value?{__html:value}:undefined,
            },!value? props.children:undefined)}
            {visibleModal && <IconModal visible={visibleModal}
                                        zIndex={9999}
                                        onClickIcon={onClickIcon}
                                        onCancel={() => setVisibleModal(false)}/>}
        </>
    } else {
        return createElement(tagName, props, props.children);
    }
})

