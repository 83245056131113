import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { listResumeUser } from '@/http/api'

// 定义异步 thunk action
export const fetchResumeUserList = createAsyncThunk(
    'mobile/fetchResumeUserList',
    async (_, { rejectWithValue }) => {
        try {
            const response = await listResumeUser({
                query: {
                    page: 1,
                    pageSize: 10
                }
            })
            if (response) {
                return response
            } else {
                return rejectWithValue("error")    
            }
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

const initialState = {
    resumeUserList: [],
    resumeUserCount: 0,
    pageStatus: {}
}

export const mobileSlice = createSlice({
    name: 'mobile',
    initialState,
    reducers: {
        set(state, {payload}) {
            state = {...state, ...payload};
        },
        setPageStatus(state, {payload}) {
            const{ pageStatus } = payload
            state.pageStatus = pageStatus
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchResumeUserList.fulfilled, (state, action) => {
            state.resumeUserList = action.payload.dataList
            state.resumeUserCount = action.payload.count
        })
        // 可以添加更多处理 rejected 或 pending 状态的逻辑
    },
})

export const { setPageStatus } = mobileSlice.actions

export default mobileSlice.reducer
