import {Scrollbars} from 'react-custom-scrollbars';
import {useMemo, useRef} from "react";
import useForceUpdate from "@/hooks/useForceUpdate";
import {Spin} from "antd";
import styled from "styled-components"


const IsNoMoreTextStyled = styled.div`
  font-size: 12px;
  color: gray;
`

const FooterStyled = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`


export default (props) => {
    const {autoHide = true, scrollFetchHandler, current, pageSize, total} = props;
    const scrollRef = useRef();
    const instance = useRef({
        isFetching: false
    }).current;
    const lastItemIndex = current * pageSize;
    const hasMore = lastItemIndex < total;
    const isNoMore = !hasMore && total !== undefined;

    const [, update] = useForceUpdate()
    const onScroll = async (e) => {
        if (isNoMore) return
        if (!scrollFetchHandler) return
        if (instance.isFetching) return
        const listHeight = scrollRef.current.view.clientHeight;
        const scrollTop = scrollRef.current.view.scrollTop;
        const scrollHeight = scrollRef.current.view.scrollHeight;
        const isScrolledToBottom = scrollHeight - (scrollTop + listHeight) < 40;
        if (isScrolledToBottom) {
            instance.isFetching = true;
            update()
            try {
                await scrollFetchHandler(current)
            } catch (e) {

            } finally {
                instance.isFetching = false;
                update()
            }
        }
    };
    return <Scrollbars autoHide={autoHide} ref={scrollRef} onScroll={onScroll}>
        {props.children}
        <FooterStyled>
            {isNoMore && <IsNoMoreTextStyled>———— 没有更多 ————</IsNoMoreTextStyled>}
            {instance.isFetching && <div><Spin/></div>}
        </FooterStyled>
    </Scrollbars>

}
