import withComponent from '@/hoc/withComponent';
import styled from 'styled-components';
import { vipTypeEnum } from '@/const/pay';
import FlexStyled from '@/styled/FlexStyled';
import { useEffect, useRef, useState } from 'react';
import useUpgrade from '@/pages/Mobile/components/Modal/PayModal/useUpgrade';
import { isSVIP } from '@/common/isVip';

const HeaderStyled = styled.div`
  height: 56px;
  background: #36384D;
  border-radius: 10px 10px 0px 0px;
  padding: 0 14px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  overflow: hidden;
  position: relative;
`;

const TagStyled = styled.div`
  width: 128px;
  height: 42px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 22px;
  background: #5E6178;
`;
const Tag2Styled = styled.div`
  transform: translateX(${props => props.state ? '110%' : '104%'}) translateY(20%);
  transition: transform 0.5s;
`;


const TagVIPStyled = styled(TagStyled)`
  ${props => props.isActive ? 'background: #16A698;' : ''}
`;
const TagSVIPStyled = styled(TagStyled)`
  ${props => props.isActive ? 'color: #984506;' : ''}
  ${props => props.isActive ? 'background: #FECF91;' : ''}
`;

const icon1 = <div style={{ width: 149, height: 28 }} dangerouslySetInnerHTML={{
  __html: `<svg width='149px' height='28px' viewBox='0 0 149 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <defs>
        <linearGradient x1='0%' y1='50%' x2='100%' y2='50%' id='linearGradient-1'>
            <stop stop-color='#FFBE55' offset='0%'></stop>
            <stop stop-color='#FC8526' offset='100%'></stop>
        </linearGradient>
    </defs>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='SVIP会员提示文案' transform='translate(-448.000000, -51.000000)' fill='url(#linearGradient-1)'>
            <path d='M448.976086,65.7808688 C448.544824,65.4358593 448.474903,64.8065669 448.819912,64.375305 C448.866044,64.3176402 448.918421,64.265263 448.976086,64.2191312 L453,61 L453,55 C453,52.790861 454.790861,51 457,51 L593,51 C595.209139,51 597,52.790861 597,55 L597,75 C597,77.209139 595.209139,79 593,79 L457,79 C454.790861,79 453,77.209139 453,75 L453,69 L448.976086,65.7808688 Z' id='形状结合'></path>
        </g>
    </g>
</svg>`,
}} />;
//

export default withComponent((props) => {
  const { vipType, onClickTag, upgradeSVipInfo, visible } = props;
  const that = useRef({ timer: undefined }).current;
  const [state, setState] = useState(false);
  const { isUpgrade, isVipLift, Deduction, upgradeInfo } = useUpgrade({ visible })


  const onClick = (e) => {
    const { type } = e.target.dataset;
    onClickTag?.(type);
  };
  
  
  // const isUpgrade = vipType === vipTypeEnum.upgradeSVip;

  useEffect(() => {
    that.timer = setTimeout(() => {
      setState(state => !state);
    }, 500);
  }, []);
  const onTransitionEnd = () => {
    setState(state => !state);
  };

  return <HeaderStyled>
    {
      isUpgrade ? <>
          {
            !isSVIP() && !isVipLift && <TagVIPStyled isActive={vipType === 'vip'} data-type='vip' onClick={onClick} style={{ marginRight: 12 }}>
              VIP会员
            </TagVIPStyled>
          }
        <TagSVIPStyled data-type='svip' isActive={vipType === 'svip' || vipType === 'upgradeSVip'} onClick={onClick} style={{ marginRight: 12 }}>
          升级SVIP会员
        </TagSVIPStyled>
      </> : <>
          <TagVIPStyled data-type='vip' onClick={onClick} isActive={vipType === 'vip'} style={{ marginRight: 12 }}>
          VIP会员
        </TagVIPStyled>
        <div style={{ position: 'relative' }}>
          <TagSVIPStyled data-type='svip' onClick={onClick} isActive={vipType === 'svip'}>
            SVIP会员
          </TagSVIPStyled>
        </div>
      </>
    }
  </HeaderStyled>;
});
