import styled from 'styled-components';
import Pointer from '@/styled/Pointer';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { LogoComponent } from '@/const/logo';
// import { LogoComponent } from '@/const';

const LayoutStyled = styled.div`
  @media screen and (min-width: 1600px) {
    width: 1504px;
  }

  @media screen and (max-width: 1300px) {
    width: 1100px;
  }
  width: 75pc;
`;

const FlexStyled = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  align-self: ${(props) => props.alignSelf};
  flex: ${(props) => props.flex};
  flex-wrap: ${(props) => props.flexWrap};
  flex-wrap: ${(props) => props.flexWrap};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
`;


export default (props) => {
  const location = useLocation();
  const onClick = () => {
    if (location.pathname !== '/pptx/templates') {
      window.open('/pptx/templates');
    }
  };
  return <FlexStyled justifyContent='center' style={{ background: '#111111' }}>
    <LayoutStyled>
      
      <FlexStyled
        justifyContent='space-between'
        alignItems='center'
        style={{ height: 81 }}
      >
        <LogoComponent />
      </FlexStyled>
    </LayoutStyled>
  </FlexStyled>;

}
