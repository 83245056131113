import withComponent from "@/hoc/withComponent";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import {Module} from "@/common/module/module";
import Avatar from "@/components/resume/moduleManage/AvatarMenu";
import DefaultMenu from "@/components/resume/moduleManage/settingMenu";

export default withComponent((props) => {
      const {setOpen} = props;
      const moduleInstance = useModuleInstance();
      switch (moduleInstance.for_block_name) {
            case Module.AVATAR: {
                  return <Avatar/>
            }
            default: {
                  return <DefaultMenu setOpen={setOpen}/>
            }
      }
})
