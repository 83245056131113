import styled from "styled-components";
import Pointer from "../../styled/Pointer";
import {useState} from "react";
import LoginModal from "./LoginModal";
import { useLocation } from 'react-router-dom';

const NotLoginStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 14px;
  cursor: pointer;
`;
const NotLogin = (props) => {
    const {type} = props;
    const [visible, setVisible] = useState(false);
    let location = useLocation();
    let color = location.pathname === '/workspace'? '#FFF':'black'
    const onClick = () => {
        setVisible(true)
    }
    const onCancel = () => {
        setVisible(false)

    }
    const onSuccess = () => {
        setVisible(false)

    }
    return <>
        <LoginModal visible={visible} onCancel={onCancel} onSuccess={onSuccess}/>
        <NotLoginStyled onClick={onClick}
                        style={{color: type === 'black' ? color : undefined}}>注册/登录</NotLoginStyled>
    </>
}

export default NotLogin


