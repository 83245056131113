import {Module} from "@/common/module/module";
import {
    CAMPUS_ACTIVITIES_SLOT_ENUM, CUSTOM_MODULE_SLOT_ENUM,
    EDUCATIONAL_BACKGROUND_SLOT_ENUM,
    HONORS_AND_AWARDS_SLOT_ENUM, INTEREST_SLOT_ENUM,
    INTERNSHIP_EXPERIENCE_SLOT_ENUM,
    JOB_INTEREST_SLOT_ENUM, LANGUAGE_PROFICIENCY_SLOT_ENUM, PORTFOLIO_SLOT_ENUM,
    PROJECT_EXPERIENCE_SLOT_ENUM,
    SELF_EVALUATION_SLOT_ENUM, SKILLS_SLOT_ENUM,
    WORK_EXPERIENCE_SLOT_ENUM
} from "@/common/slot/slot";
import {
    CAMPUS_ACTIVITIES_FIELD_ENUM,
    CUSTOM_MODULE_FIELD_ENUM,
    EDUCATIONAL_BACKGROUND_FIELD_ENUM,
    HONORS_AND_AWARDS_FIELD_ENUM,
    INTEREST_FIELD_ENUM,
    INTERNSHIP_EXPERIENCE_FIELD_ENUM, JOB_INTEREST_FIELD_ENUM,
    LANGUAGE_PROFICIENCY_FIELD_ENUM,
    PORTFOLIO_FIELD_ENUM, PROJECT_EXPERIENCE_FIELD_ENUM,
    SELF_EVALUATION_FIELD_ENUM,
    SKILLS_FIELD_ENUM,
    WORK_EXPERIENCE_FIELD_ENUM
} from "@/common/field/field";

export default (blockName, slotName) => {
    switch (blockName) {
        case Module.WORK_EXPERIENCE: {
            switch (slotName) {
                case WORK_EXPERIENCE_SLOT_ENUM.TITLE_TEXT:
                case WORK_EXPERIENCE_SLOT_ENUM.TITLE_ICON: {
                    return WORK_EXPERIENCE_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.JOB_INTEREST: {
            switch (slotName) {
                case JOB_INTEREST_SLOT_ENUM.TITLE_TEXT:
                case JOB_INTEREST_SLOT_ENUM.TITLE_ICON: {
                    return JOB_INTEREST_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.PROJECT_EXPERIENCE: {
            switch (slotName) {
                case PROJECT_EXPERIENCE_SLOT_ENUM.TITLE_TEXT:
                case PROJECT_EXPERIENCE_SLOT_ENUM.TITLE_ICON: {
                    return PROJECT_EXPERIENCE_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            switch (slotName) {
                case INTERNSHIP_EXPERIENCE_SLOT_ENUM.TITLE_TEXT:
                case INTERNSHIP_EXPERIENCE_SLOT_ENUM.TITLE_ICON: {
                    return INTERNSHIP_EXPERIENCE_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.CAMPUS_ACTIVITIES: {
            switch (slotName) {
                case CAMPUS_ACTIVITIES_SLOT_ENUM.TITLE_TEXT:
                case CAMPUS_ACTIVITIES_SLOT_ENUM.TITLE_ICON: {
                    return CAMPUS_ACTIVITIES_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.HONORS_AND_AWARDS: {
            switch (slotName) {
                case HONORS_AND_AWARDS_SLOT_ENUM.TITLE_TEXT:
                case HONORS_AND_AWARDS_SLOT_ENUM.TITLE_ICON: {
                    return HONORS_AND_AWARDS_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.SELF_EVALUATION: {
            switch (slotName) {
                case SELF_EVALUATION_SLOT_ENUM.TITLE_TEXT:
                case SELF_EVALUATION_SLOT_ENUM.TITLE_ICON: {
                    return SELF_EVALUATION_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            switch (slotName) {
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.TITLE_TEXT:
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.TITLE_ICON: {
                    return EDUCATIONAL_BACKGROUND_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.SKILLS: {
            switch (slotName) {
                case SKILLS_SLOT_ENUM.TITLE_TEXT:
                case SKILLS_SLOT_ENUM.TITLE_ICON: {
                    return SKILLS_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.INTEREST: {
            switch (slotName) {
                case INTEREST_SLOT_ENUM.TITLE_TEXT:
                case INTEREST_SLOT_ENUM.TITLE_ICON: {
                    return INTEREST_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.LANGUAGE_PROFICIENCY: {
            switch (slotName) {
                case LANGUAGE_PROFICIENCY_SLOT_ENUM.TITLE_TEXT:
                case  LANGUAGE_PROFICIENCY_SLOT_ENUM.TITLE_ICON: {
                    return LANGUAGE_PROFICIENCY_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.CUSTOM_MODULE: {
            switch (slotName) {
                case CUSTOM_MODULE_SLOT_ENUM.TITLE_TEXT:
                case CUSTOM_MODULE_SLOT_ENUM.TITLE_ICON: {
                    return CUSTOM_MODULE_FIELD_ENUM.TITLE
                }
            }
            break
        }
        case Module.PORTFOLIO: {
            switch (slotName) {
                case PORTFOLIO_SLOT_ENUM.TITLE_TEXT:
                case PORTFOLIO_SLOT_ENUM.TITLE_ICON: {
                    return PORTFOLIO_FIELD_ENUM.TITLE
                }
            }
            break
        }

    }
    return slotName

}
