// 配置一些全局信息
const mobile = {
    // 微信公众号的appId
    appId: 'wxbd0e26c40af83c6b',
}

export default mobile

// wx.mp.configs[0].appId=wxbd0e26c40af83c6b
// wx.mp.configs[0].secret=05f5bccdab09c4992508eaaebe005529
// wx.mp.configs[0].token=jianze
// wx.mp.configs[0].aesKey=28KfDpfVb2VbtASqkMUP8GLZ6wivtdH2EvYbsECN8F3